(function($$) {
	const CanvasUtils = $$.CanvasUtils;
	const $window = $(window);

	class BackgroundRenderer {
		constructor(width=1280, height=720) {
			this._hash = _.uniqueId('simple_renderer_');

			this._outputTargets = [];
			this.freezeBackgroundRender = false;

			// Array of transition class instances that's applied to this._baseCanvas one after another.
			// Some kind of filters/shaders can be implemented in a similar way/
			this._pipeline = [];
			this._filters = [];

			// This one is a base layer we have before applying canvases from pipeline to it
			this._baseCanvas = CanvasUtils.createCanvas(width, height);

			// And this is cached result after we applied things from pipeline to it. We will copy bitmap from it to output every tick.
			this._resultWithoutFilters = CanvasUtils.createCanvas(width, height);
			this._canvas = CanvasUtils.createCanvas(width, height);

			this._allocatedCallbacks = {};

			$window.on(`resize.${this._hash}`, () => {
				this._updateSizes();
			});

			// Starting ticker

			let requestFrame;
			const defered = () => {
				if (!this.freezeBackgroundRender) {
					this._redraw();
					this._updateOutput();
				}

				requestFrame();
			};

			requestFrame = () => {
				requestAnimationFrame(defered);
			};

			requestFrame();
		}

		// Applying stuff from pipeline to this._baseCanvas (output written to this._baseCanvas)
		_redraw(forced = false) {
			const draft = this._resultWithoutFilters;
			const draftContext = draft.getContext('2d');

			const outputContext = this._canvas.getContext('2d');
			let contentChanged = false;

			draftContext.clearRect(0, 0, draft.width, draft.height);
			draftContext.drawImage(this._baseCanvas, 0, 0);

			_.each(this._pipeline, (pipelineItem) => {
				if (pipelineItem.hasChangedSinceLastTime()) {
					contentChanged = true;
				}

				draftContext.drawImage(pipelineItem.getBitmap(), 0, 0);
			});

			if (!this._filters.length) {
				outputContext.drawImage(draft, 0, 0);
			} else if (contentChanged || forced) {
				if (this._pipeline[0] && this._pipeline[0].constructor.name === 'SimpleSlideTransition'){
					outputContext.drawImage(draft, 0, 0);
				} else {
					outputContext.drawImage(_.last(this._filters).getBitmap(), 0, 0);
				}

				requestAnimationFrame(_.bind(this._updateOutput, this));
			}
		}

		/**
		 * Copying bitmap from cached canvas to output targets
		 *
		 * @private
		 */
		_updateOutput() {
			this._simplifyPipeline();
			const callbacks = this._allocatedCallbacks;

			_.each(
				this._outputTargets,
				callbacks.updateOutputForeach || (callbacks.updateOutputForeach = (layer) => {
					CanvasUtils.copyBitmap(this._canvas, layer.canvas);
				})
			);

			if (!this._pipeline.length) {
				this.freezeBackgroundRender = true;
			}
		}

		_simplifyPipeline() {
			let firstSolid;
			const callbacks = this._allocatedCallbacks;
			const baseContext = this._baseCanvas.getContext('2d');

			// first looking
			_.eachRight(
				this._pipeline,
				callbacks.simplify1 || (callbacks.simplify1 = function(elem, i) {
					if (!elem.isSeeThrough()) {
						firstSolid = i;
						return false;
					}
				})
			);

			_.remove(
				this._pipeline,
				callbacks.simplify2 || (callbacks.simplify2 = function(elem, i) {
					if (_.isNumber(firstSolid) && i < firstSolid) {
						baseContext.drawImage(elem.getBitmap(), 0, 0);

						elem.destroy();
						return true;
					}

					return false;
				})
			);

			// we can also merge and remove everything static

			let cameAcrossFirstDynamicLayer = false;

			_.remove(
				this._pipeline,
				callbacks.simplify3 || (callbacks.simplify3 = function(elem, i) {
					if (cameAcrossFirstDynamicLayer) {
						return false;
					}

					if (elem.isStatic()) {
						baseContext.drawImage(elem.getBitmap(), 0, 0);

						elem.destroy();
						return true;
					}
				})
			);
		}

		_updateSizes() {
			const elementsChangedInSize = [];

			_.each(this._outputTargets, function(exportLayerData) {
				const oldWidth = exportLayerData.width;
				const oldHeight = exportLayerData.height;

				exportLayerData.width = exportLayerData.canvas.width;
				exportLayerData.height = exportLayerData.canvas.height;

				if (oldWidth !== exportLayerData.width || exportLayerData.height !== oldHeight) {
					elementsChangedInSize.push(exportLayerData);
				}
			});

			this._updateOutput(elementsChangedInSize);
		}

		getBitmap(copy = false, filtersApplied = true) {
			const canv = filtersApplied ? this._canvas : this._resultWithoutFilters;

			if (!copy) {
				return canv;
			}

			return CanvasUtils.cloneCanvas(canv);
		}

		setBaseImage(canvas) {
			CanvasUtils.copyBitmap(canvas, this._baseCanvas);
		}

		setOutputTarget(canvasElement) {
			if (!canvasElement || !(canvasElement instanceof HTMLCanvasElement)) {
				throw "canvasElement must be a canvas element";
			}

			const layerData = {
				canvas: canvasElement,
				context: canvasElement.getContext('2d'),
				width: canvasElement.width,
				height: canvasElement.height
			};

			canvasElement.width = layerData.width;
			canvasElement.height = layerData.height;

			this._outputTargets.push(layerData);
			this._updateOutput([layerData]);
		}

		registerTransition(transition) {
			this._pipeline.push(transition);
			this.freezeBackgroundRender = false;
		}

		redraw() {
			this._redraw(true);
		}

		addFilter(filter) {
			if (!(filter instanceof $$.AbstractFilter)) {
				throw "Not a filter";
			}

			filter.setSource(this._filters.length ? _.last(this._filters).getBitmap() : this._resultWithoutFilters);
			this._filters.push(filter);
			this.freezeBackgroundRender = false;
		}

		removeFilter(filter) {
			let indexOfFilter = -1;

			_.remove(this._filters, function(item, index) {
				if (item === filter) {
					indexOfFilter = index;
				}

				return item === filter;
			});

			if (indexOfFilter !== -1) {
				this._filters[indexOfFilter].setSource(this._filters.length === 1 ? this._resultWithoutFilters : this._filters[indexOfFilter - 1].getBitmap() );
			}
		}

		removeOutputTarget(canvasElement) {
			_.remove(this._outputTargets, function(exportLayerData) {
				return exportLayerData.canvas === canvasElement;
			});
		}
	}

	$$.BackgroundRenderer = BackgroundRenderer;

})(window.$$ || ($$.window = {}));
