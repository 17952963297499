var $$ = $$ || {};

$$.VideosguschenkaTest = class VideosguschenkaTest {
	constructor(root) {
		this.root = root;
		this.uniqueHash = `portfolio-entry-videosguschenka-${_.uniqueId()}}`;

		this.scrollPos = 0;
		this.isTouchDevice = false;
		this._isInitialTick = true; _.defer( () => this._isInitialTick = false );

		this._destroyed = false;

		this._cacheNodes();
		this._bindEvents();

		this.nodes.loader.show();
		this.nodes.wrapper.css('opacity', 0);
		this.wrapperScrollHeight = this.nodes.wrapper.prop('scrollHeight');

		this.scenesActions = [];
		this._bindScenes();
		this._ready();

		this._recheckBackground = _.debounce(() => {
			BackgroundCheck.refresh();
		}, 200);
	}

	_cacheNodes () {
		this.nodes = {
			mouseWheelCatcher: $('<div/>').appendTo(this.root),
			loader: this.root.children('.js-loading'),
			wrapper: this.root.find('.js-wrapper'),
			noiseOverlay: this.root.find('.js-noise-overlay'),
			noiseOverlayInner: this.root.find('.js-noise-overlay-inner'),
			logo: this.root.find('.js-logo'),
			miniLogo: this.root.find('.js-mini-logo'),
			cardsGroup1: this.root.find('.js-cards-group1'),
			cardsGroup2: this.root.find('.js-cards-group2'),
			bageBg: this.root.find('.js-bage-bg'),
			shirt: this.root.find('.js-shirt-buffer'),
			camera: this.root.find('.js-camera'),
			car: this.root.find('.js-car'),
			loudspeaker: this.root.find('.js-loudspeaker'),
			chair: this.root.find('.js-chair'),
			letterLogo: this.root.find('.js-letter-logo'),
			head: this.root.find('.js-head'),
			videoClips: this.root.find('.js-video-clip')
		};

		this.nodes.mouseWheelCatcher.css({
			position: 'absolute',
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
			display: 'none'
		});
	}

	_bindEvents () {
		this.nodes.mouseWheelCatcher.on('mousewheel', () => false);

		var isDetached = () => {
			if (!jQuery.contains(document.documentElement, this.root.get(0))) {
				this.destroy();
				return true;
			}

			return false;
		};

		this.nodes.noiseOverlay.add(this.nodes.wrapper).on('mousewheel', (event) => {
			var currentScrollPosition = this.nodes.wrapper.scrollTop();

			if (currentScrollPosition === 0 && event.deltaY > 0
				|| currentScrollPosition === this.wrapperScrollHeight - $$.windowHeight && event.deltaY < 0) {
				return;
			}

			event.stopPropagation();
			this.nodes.wrapper.stop().scrollTop(currentScrollPosition - 120 * event.deltaY / Math.abs(event.deltaY));
			return true;
		});

		this.scrollData = {
			relativeScrollTop: 0,
			direction: 1
		};

		this.nodes.wrapper.on('scroll', (event) => {
			var relativeScrollTop = 100 * this.nodes.wrapper.scrollTop() / (this.wrapperScrollHeight - $$.windowHeight);
			var direction = relativeScrollTop - this.scrollData.relativeScrollTop  > 0 ? 1 : -1;

			this.scrollData = {
				relativeScrollTop,
				direction
			};

			_.forEach(this.scenesActions, (actionParameters) => {
				if ((actionParameters.triggerPoint < this.scrollData.relativeScrollTop
					&& actionParameters.direction > 0)
					|| (actionParameters.triggerPoint > this.scrollData.relativeScrollTop
					&& actionParameters.direction < 0)) {
					this.scrollData.direction > 0 ? actionParameters.action() : actionParameters.backAction();
					actionParameters.direction = -actionParameters.direction;
				}
			});
		});

		$$.window.on('resize.' + this.uniqueHash, () => {
			if (isDetached()) {
				return
			}

			if (this.isTouchDevice) {
				this.nodes.noiseOverlay.height(this.wrapperScrollHeight);
			}

			this.wrapperScrollHeight = this.nodes.wrapper.prop('scrollHeight');
			this.resize();
		});

		var onceSetNoiseOverlayHeight = _.once(() => {
			this.nodes.noiseOverlay.css('position', 'absolute').height(this.nodes.wrapper.prop('scrollHeight'));
		});

		this.nodes.noiseOverlay.on(`touchstart.${this.uniqueHash}`, () => {
			this.isTouchDevice = true;
			onceSetNoiseOverlayHeight();
		});
	}

	_registerSceneParameters (triggerPoint, action, backAction) {
		this.scenesActions.push({
			triggerPoint,
			action,
			backAction: backAction || _.noop,
			direction: 1
		});
	}

	_bindScenes () {
		this._registerSceneParameters(4, () => {
			this.nodes.miniLogo.addClass('active');
		}, () => {
			this.nodes.miniLogo.removeClass('active');
		});

		this._registerSceneParameters(7, () => {
			this.nodes.cardsGroup1.addClass('active');
			this.nodes.cardsGroup2.addClass('active');
		}, () => {
			this.nodes.cardsGroup1.removeClass('active');
			this.nodes.cardsGroup2.removeClass('active');
		});

		this._registerSceneParameters(11, () => {
			this.nodes.cardsGroup1.addClass('active');
			this.nodes.cardsGroup2.addClass('active');
		}, () => {
			this.nodes.cardsGroup1.removeClass('active');
			this.nodes.cardsGroup2.removeClass('active');
		});

		this._registerSceneParameters(34, () => {
			this.nodes.bageBg.addClass('active');
		}, () => {
			this.nodes.bageBg.removeClass('active');
		});

		this._registerSceneParameters(38, () => {
			this.nodes.shirt.addClass('active');
		}, () => {
			this.nodes.shirt.removeClass('active');
		});

		this._registerSceneParameters(58, () => {
			this.nodes.camera.addClass('active');
		}, () => {
			this.nodes.camera.removeClass('active');
		});

		this._registerSceneParameters(65, () => {
			this.nodes.car.addClass('active');
		}, () => {
			this.nodes.car.removeClass('active');
		});

		this._registerSceneParameters(72, () => {
			this.nodes.chair.addClass('active');
			this.nodes.loudspeaker.addClass('active');
		}, () => {
			this.nodes.chair.removeClass('active');
			this.nodes.loudspeaker.removeClass('active');
		});

		this._registerSceneParameters(85, () => {
			this.nodes.letterLogo.addClass('active');
		}, () => {
			this.nodes.letterLogo.removeClass('active');
		});

		this._registerSceneParameters(90, () => {
			this.nodes.head.addClass('active');
		}, () => {
			this.nodes.head.removeClass('active');
		});

		this.nodes.videoClips.each((index, element) => {
			var videoClip = $(element);
			var videoClipHeight = videoClip.height();
			var scrollValue = this.wrapperScrollHeight - $$.windowHeight;
			var topSwitchingPoint = 100 * (videoClip.position().top - $$.windowHeight) / scrollValue;
			var bottomSwitchingPoint = 100 * (videoClip.position().top + videoClipHeight) / scrollValue;

			this._registerSceneParameters(topSwitchingPoint, () => {
				videoClip.get(0).play();
			}, () => {
				videoClip.get(0).pause();
			});

			this._registerSceneParameters(bottomSwitchingPoint, () => {
				videoClip.get(0).pause();
			}, () => {
				videoClip.get(0).play();
			});
		})
	}

	_ready () {
		if (this._isInitialTick) {
			this.nodes.loader.hide();
			this.nodes.wrapper.css('opacity', 1);
		} else {
			this.nodes.loader.velocity('fadeOut');
			this.nodes.wrapper.velocity('fadeIn');
		}

		try {
			BackgroundCheck.init({
				targets: '.p-header .logo, .js-breadcrumbs, .button-switch-state-right-wrapper, .js-slash-menu',
				images: '.b-videosguschenka img',
				classes: {
					dark: 'background--light',
					light: 'background--dark',
					complex: 'background--complex'
				}
			});

			_.defer(function() { BackgroundCheck.refresh(); })
		} catch(e) {}
	}


	destroy () {
		if (this._destroyed) {
			return;
		}

		this._destroyed = true;
		this._scrollMagicController.destroy();
		this.root.off();
		this.nodes.wrapper.off();

		$$.window.off('resize.' + this.uniqueHash);

		this._recheckBackground = _.noop;
	}

	pause () {
		this.nodes.logo.stop().fadeTo(1500, 0);

		this.nodes.noiseOverlay.stop().fadeTo(1500, 0, () => {
			this.nodes.noiseOverlay.hide();
		});
	}

	repositioned (isAnimationEnded = false) {
		if (this._destroyed) {
			return;
		}

		if (isAnimationEnded) {
			this.nodes.mouseWheelCatcher.hide();

			_.defer(() => {
				this.nodes.logo.stop().fadeTo(2500, 1);
				this.nodes.noiseOverlay.stop().show().fadeTo(1500, 1);
				$$.body.addClass('black-controls');
			});
		} else {
			$$.body.removeClass('black-controls');
			this.nodes.mouseWheelCatcher.show();
		}
	}
};
