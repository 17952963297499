var $$ = $$ || {};

$$.BunchOfNuts = class BunchOfNuts {
	constructor (root) {
		this.root = root;
		this.scrollOffsetTop = 0;

		this._cacheNodes();
		this._bindEvents();
		this._ready();
	}

	_cacheNodes () {
		this.nodes = {
			inner: this.root.find('.inner-work')
		};
	}

	pause () {
		$$.body.removeClass('black-controls');
	}

	resume () {
		$$.body.addClass('black-controls');
	}

	/**
	 * Вешает обработчики событий на компоненты/элементы.
	 *
	 * @private
	 */
	_bindEvents () {
		this.root.on('mousewheel', (event) => {
			event.preventDefault();

			var root = $(event.currentTarget);

			if (!((event.deltaY < 0 && this.scrollOffsetTop >= (this.nodes.inner.height() - $$.windowHeight - 30)) || (event.deltaY > 0 && this.scrollOffsetTop === 0))) {
				event.stopPropagation();

				if (event.deltaY < 0) {
					this.scrollOffsetTop += 50;

				} else {
					this.scrollOffsetTop -= 50;

					if (this.scrollOffsetTop <= 0) {
						this.scrollOffsetTop = 0;
					}
				}

				this.nodes.inner.css({
					top: -this.scrollOffsetTop
				});
			}
		});
	}

	_ready () {
		this.resize();
		$$.body.addClass('black-controls');
		this.root.width($$.window.width() + 17);
	}

	resize () {
		$$.window.on('resize.work', () => {
			this.root.width($$.window.width() + 17);

			var innerHeight = 0;

			this.nodes.inner.children('img').each(function () {
				innerHeight += $(this).outerHeight(true);
			});

			this.nodes.inner.css({
				width:  '100%',
				height: `${innerHeight}px`
			});
		});

		$$.window.trigger('resize.work');
	}
};
