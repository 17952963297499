var $$ = $$ || {};

$$.Samsung = class Samsung {
	constructor (root) {
		this.root = root;

		// Переменные и флаги

		this.currentSectionIndex = 0;

		this._hash = 'portfolio-entry-samsung-' + _.uniqueId();
		this._paused = false;
		this._destroyed = false;

		this._isInitialTick = true;
		_.defer(() => this._isInitialTick = false);

		this.firstSlide = true;
		this.firstSlideTimer = false;

		// Инициализируем

		this._cacheNodes();
		this._bindEvents();

		this._scrollMagicController = new ScrollMagic.Controller({
			container: this.nodes.payload.get(0)
		});

		this.resize();

		this.nodes.loader.show();
		this.nodes.payload.css('opacity', 0);

		this._recheckBackground = _.debounce((light = true) => {
			if (light && this.nodes.sections.eq(this.currentSectionIndex).hasClass('light')) {
				$$.body.removeClass('white-controls').addClass('black-controls');
			} else {
				$$.body.removeClass('black-controls').addClass('white-controls');
			}
		}, 200);

		$script.ready('vendor-postponed', () => {
			this._ready();
		});
	}

	_cacheNodes () {
		let payload = this.root.children('.samsung-payload');

		this.nodes = {
			navigation: $('.p-header .logo, .js-breadcrumbs, .button-switch-state-right-wrapper, .js-slash-menu'),
			loader: this.root.children('.js-loading'),
			payload: payload,
			mouseWheelCatcher: $('<div/>').appendTo(this.root),
			sections: this.root.find('section'),

			section1: payload.children('.section1'),
			s1Logo: payload.find('.section1 .front'),
			s1Back: payload.find('.section1 .back'),
			s1Container: payload.find('.section1 .s-container .card'),
			s1Logos: payload.find('.section1 .logos'),

			section2: payload.children('.section2'),
			s2Wrapper: payload.find('.section2 .wrapper'),
			triggerZoom1: payload.children('.trigger1'),

			section3: payload.children('.section3'),
			s3Left: payload.find('.section3 .left'),
			s3Right: payload.find('.section3 .right'),
			s3Title: payload.find('.section3 .title'),
			s3Text: payload.find('.section3 .text'),

			section4: payload.children('.section4'),
			s4Laptop: payload.find('.section4 .laptop'),
			s4Desktop: payload.find('.section4 .desktop'),

			section5: payload.children('.section5'),
			s5Benefits: payload.find('.section5 .benefits'),
			s5Pen: payload.find('.section5 .pen')
		};

		this.nodes.mouseWheelCatcher.css({
			position: 'absolute',
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
			display: 'none'
		});
	}

	/**
	 * Вешает обработчики событий на компоненты/элементы.
	 *
	 * @private
	 */
	_bindEvents () {
		this.nodes.mouseWheelCatcher.on('mousewheel', () => false);

		let isDetached = () => {
			if (!jQuery.contains(document.documentElement, this.root.get(0))) {
				this.destroy();
				return true;
			}

			return false;
		};

		$$.window.on('resize.' + this._hash, () => {
			if (isDetached()) {
				return
			}

			this.resize();
		});

		//this.nodes.payload.swipeUp((event) => {
		//	var isBottom = this.nodes.payload.scrollTop() + $$.windowHeight === this.nodes.payload.get(0).scrollHeight;
		//
		//	if (!isBottom) {
		//		event.stopPropagation();
		//	}
		//});
		//
		//this.nodes.payload.swipeDown((event) => {
		//	var isTop = this.nodes.payload.scrollTop() === 0;
		//
		//	if (!isTop) {
		//		event.stopPropagation();
		//	}
		//});

		this.nodes.payload.swipeUp((event) => {
			this._scrollController(event, -1);
		});

		this.nodes.payload.swipeDown((event) => {
			this._scrollController(event, +1);
		});

		this.nodes.payload.on('mousewheel', (event) => {
			this._scrollController(event, event.deltaY);
		});

		this.currentX = 3;

		this.nodes.section2.on('mousewheel', (event) => {
			if ($$.isMobile) {
				return;
			}

			if (this.isAnimating) {
				event.preventDefault();
				event.stopPropagation();
			}

			let newX = this.currentX + event.deltaY;

			if (0 < newX && newX < 4 && !this.isAnimating) {
				this.isAnimating = true;

				this.nodes.s2Wrapper.removeClass('x' + this.currentX);
				this.nodes.s2Wrapper.addClass('x' + newX);
				this.nodes.section2.removeClass('x' + this.currentX);
				this.nodes.section2.addClass('x' + newX);
				this.currentX = newX;

				event.preventDefault();
				event.stopPropagation();

				setTimeout(() => {
					this.isAnimating = false;
				}, 1000);
			}
		});
	}

	_scrollController (event, delta) {
		if (this.isAnimating) {
			event.preventDefault();
			event.stopPropagation();

			return false;
		}

		if (this.firstSlide) {
			event.preventDefault();
			event.stopPropagation();

			if (!this.firstSlideTimer) {
				this.firstSlideTimer = true;

				setTimeout(() => {
					this.firstSlide = false;
				}, 1000);
			}

			return false;
		}

		if (this.currentSectionIndex - delta >= 0
			&& this.currentSectionIndex - delta < this.nodes.sections.length && !this.isAnimating) {
			this.isAnimating = true;

			event.preventDefault();
			event.stopPropagation();

			let scrollTop = this.nodes.payload.scrollTop();
			let newTop = scrollTop + this.nodes.sections.eq(this.currentSectionIndex - delta).offset().top;

			if (delta > 0) {
				newTop = scrollTop - this.nodes.sections.eq(this.currentSectionIndex - delta).outerHeight(true);
			}

			this.nodes.payload.stop(true, true).animate({
				scrollTop: newTop
			}, 1000, () => {
				this.currentSectionIndex -= delta;
				this.isAnimating = false;

				this._recheckBackground();
			});
		}
	}

	_bindAnimationSequence () {
		if (this._destroyed) {
			return;
		}

		let timeline;

		// Section 1
		timeline = new TimelineMax()
			.from(this.nodes.s1Logo, 1, { backgroundColor: '#0c4da2', ease: Power2.easeOut }, 0.6)
			.to(this.nodes.s1Logo, 1, { transform: 'rotateY(180deg)', ease: Power2.easeOut }, 2)
			.to(this.nodes.s1Back, 1, { transform: 'rotateY(360deg)', ease: Power2.easeOut }, 2)
			.from(this.nodes.s1Logos, 1, { opacity: 0, ease: Power2.easeOut }, 3);

		new ScrollMagic.Scene({
			triggerHook: 0,
			triggerElement: this.nodes.section1.get(0)
		}).setTween(timeline)
			.addTo(this._scrollMagicController);

		// Section 3
		timeline = new TimelineMax()
			.from(this.nodes.s3Left, 1, { marginLeft: -760, opacity: 0, ease: Power2.easeOut }, 0.6)
			.from(this.nodes.s3Right, 1, { marginLeft: 760, opacity: 0, ease: Power2.easeOut }, 0.6)
			.from(this.nodes.s3Text, 1, { marginLeft: -100, opacity: 0, ease: Power2.easeOut }, 1.2)
			.from(this.nodes.s3Title, 1, { marginLeft: -100, opacity: 0, ease: Power2.easeOut }, 1.2);

		new ScrollMagic.Scene({
			triggerElement: this.nodes.section3.get(0)
		}).setTween(timeline)
			.addTo(this._scrollMagicController);

		// Section 4
		timeline = new TimelineMax()
			.from(this.nodes.s4Desktop, 1, { marginLeft: -465, opacity: 0, ease: Power2.easeOut }, 0.6)
			.from(this.nodes.s4Laptop, 1, { marginLeft: 560, opacity: 0, ease: Power2.easeOut }, 0.6);

		new ScrollMagic.Scene({
			triggerElement: this.nodes.section4.get(0)
		}).setTween(timeline)
			.addTo(this._scrollMagicController);

		// Section 5
		timeline = new TimelineMax()
			.from(this.nodes.s5Benefits, 1, { opacity: 0, ease: Power2.easeOut }, 0.6)
			.from(this.nodes.s5Pen, 1, { marginLeft: 360, opacity: 0, ease: Power2.easeOut }, 0.6);

		new ScrollMagic.Scene({
			triggerElement: this.nodes.section5.get(0)
		}).setTween(timeline)
			.addTo(this._scrollMagicController);
	}

	_ready () {
		if ($$.isMobile) {
			this.nodes.s2Wrapper.removeClass('x3');
			this.nodes.s2Wrapper.addClass('x1');
		}

		this.nodes.payload.animate({
			scrollTop: 0
		}, 0);

		this.nodes.payload
			.css({
				zIndex: '0',
				overflow: 'hidden'
			});

		if (this._isInitialTick) {
			this.nodes.loader.hide();
			this.nodes.payload.css('opacity', 1);
		} else {
			this.nodes.loader.velocity('fadeOut');
			this.nodes.payload.velocity('fadeIn');
		}

		this._recheckBackground();

		this._bindAnimationSequence();
	}

	destroy () {
		if (this._destroyed) {
			return;
		}

		this._destroyed = true;
		this._scrollMagicController.destroy();
		this._recheckBackground = _.noop;
		this.root.off();
		this.nodes.payload.off();

		$$.window.off('resize.' + this._hash);

		this._scrollMagicController = null;
	}

	pause () {
		this._paused = true;
		this._recheckBackground(false);
	}

	/**
	 * Эта функция должна вызываться извне и уведомлять нас, что позиция слайда на экране изменилась
	 *
	 * @param animationEnded Закончилась ли анимация. На самом деле вызывается только, когда мы и есть слайд к которому осуществлялся переход
	 */
	repositioned (animationEnded = false) {
		if (this._destroyed) {
			return;
		}

		if (animationEnded) {
			this.nodes.mouseWheelCatcher.hide();
			if (!this._paused) {
				$$.window.trigger('resize');
			}
		} else {
			this.nodes.mouseWheelCatcher.show();

			if (!this._isInitialTick) {
				this._scrollMagicController.update(true);
			}
		}
	}

	resize () {
		if (this._destroyed) {
			return;
		}

		var scaleX3 = $$.windowWidth / 418;
		var scaleX2Vertical = $$.windowHeight / 600;
		var scaleX2Horisontal = $$.windowWidth / 900;
		var scaleX2 = scaleX2Vertical > scaleX2Horisontal ? scaleX2Horisontal : scaleX2Vertical;

		var scaleX3Vertical = $$.windowHeight / 1000;
		var scaleX3Horisontal = $$.windowWidth / 1400;

		var scaleX1 = $$.windowHeight > 900 && $$.windowWidth > 1300 ? 1 : scaleX3Vertical > scaleX3Horisontal ? scaleX3Horisontal : scaleX3Vertical;

		scaleX1 = scaleX1.toFixed(3);
		scaleX2 = scaleX2.toFixed(3);
		scaleX3 = scaleX3.toFixed(3);

		if (!this.styles) {
			this.styles = $('<style></style>');
			$('head').append(this.styles);
		}

		this.styles.html(`.b-samsung .samsung-payload .section2 .wrapper.x3 { transform: scale(${scaleX3}) translate(35px, ${parseInt((262 - $$.windowHeight / scaleX3) / 2 + 20)}px); }` +
			`.b-samsung .samsung-payload .section2 .wrapper.x3 .laptop .page .screen { width: ${parseInt($$.windowWidth / scaleX3)}px; height: ${parseInt($$.windowHeight / scaleX3)}px; }` +

			`.b-samsung .samsung-payload .section2 .wrapper.x2 { transform: scale(${scaleX2}) translate(0, ${parseInt((700 - $$.windowHeight / scaleX2) / 2)}px); }` +
			`.b-samsung .samsung-payload .section2 .wrapper.x1 { transform: scale(${scaleX1}) ; }`);

		this.nodes.payload.animate({
			scrollTop: this.nodes.sections.eq(this.currentSectionIndex).offset().top
		}, 0);

		this._scrollMagicController.update();
	}

	resume (comingFromTop) {
		this._paused = false;

		if (this._destroyed) {
			return;
		}

		this.currentSectionIndex = comingFromTop ? 0 : this.nodes.sections.length - 1;

		this.nodes.payload.animate({
			scrollTop: comingFromTop ? 0 : this.nodes.payload.height()
		}, 0);

		this._recheckBackground();
	}
};
