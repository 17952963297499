var $$ = $$ || {};

$$.Application = class Application {
	constructor () {
		$$.isMobile = jsData.isMobile;
		$$.config = new $$.Config();

		this._createComponents();
		this._ready();
	}

	/**
	 * Создает необходимые компоненты.
	 *
	 * @private
	 */
	_createComponents () {
		$$.route = new $$.Route($$.body);

		const BackgroundEffects = $$.isMobile ? $$.BackgroundEffectsMobile : $$.BackgroundEffectsPC;

		/** @type BackgroundEffects */
		$$.backgroundEffects = new BackgroundEffects(
			jsData.backgrounds,
			jsData.pageFragmentBackgrounds,
			$('.js-background-container')
		);

		$$.route.createComponents();

		$$.menu = new $$.Menu($('.js-right-wrapper'));
	}

	_ready () {
		$script(['/site/assets/js/vendor-postponed.js'], 'vendor-postponed');
	}
};

$(function () {
	//$$.fpsMeter = new $$.FPSMeter();

	$$.application = new $$.Application();
});

var initAnalytics = function () {
	$$.yandexMetrika = new $$.YandexMetrika(window.yaCounter32355590);
	$$.googleAnalytics = new $$.GoogleAnalytics();

	var body = $('body');

	body.on('click.analytics', '.ga-feedback-button', () => {
		$$.yandexMetrika.reachGoal('feedback-button');
		$$.googleAnalytics.reachVirtualPage('feedback-button');
	});

	body.one('input.analytics', '.ga-menu-form input', () => {
		$$.yandexMetrika.reachGoal('menu-form-input');
		$$.googleAnalytics.reachVirtualPage('menu-form-input');
	});

	body.on('submitga.analytics', '.ga-menu-form', () => {
		$$.yandexMetrika.reachGoal('menu-form-submit');
		$$.googleAnalytics.reachVirtualPage('menu-form-submit');
	});

	body.one('input.analytics', '.ga-contacts-form input', () => {
		$$.yandexMetrika.reachGoal('contacts-form-input');
		$$.googleAnalytics.reachVirtualPage('contacts-form-input');
	});

	body.on('submitga.analytics', '.ga-contacts-form', () => {
		$$.yandexMetrika.reachGoal('contacts-form-submit');
		$$.googleAnalytics.reachVirtualPage('contacts-form-submit');
	});
};

$(window).load(initAnalytics);
