var $$ = $$ || {};

$$.Typed = class Typed {
	constructor (root, options = {}) {
		var defaultOptions = {
			changeSlideDuration: 1000,
			typeSpeed: 30,
			removeCursorDelay: 1000
		};

		this.root = root;
		this.options = _.merge(options, defaultOptions, _.defaults);

		this.typedTextContainer = this.root.parent();
		this.text = this.root.text();
		this.textFixedPart = this.text.slice(0, -this.text.length / 2);
		this.textTypedPart = this.text.slice(-this.text.length / 2);

		this._ready();
	}

	_ready () {
		this.isIOS = $('html').hasClass('ios');

		if (this.isIOS) return;

		this.root.before(`<span class="fixed-text">${this.textFixedPart}`);
		this.root.text('');

		this._type();
	}

	_type () {
		this.root.typed({
			strings: [this.textTypedPart],
			startDelay: this.options.changeSlideDuration / 2,
			typeSpeed: this.options.typeSpeed,
			cursorChar: '',
			showCursor: true,
			callback: () => {
				_.delay(() => {
					var cursor = this.root.parent().find('.typed-cursor');

					cursor.on('animationiteration', () => {
						cursor.remove();
					});

					if ($$.isMobile) {
						cursor.remove();
					}
				}, this.options.removeCursorDelay);
			}
		});
	}

	resume () {
		if (this.isIOS) return;

		this._type();
	}

	pause () {
		if (this.isIOS) return;

		$.removeData(this.root[0]);

		_.delay(() => {
			this.root.text('');
			this.root.parent().find('.typed-cursor').remove();
		}, this.options.changeSlideDuration);
	}
};
