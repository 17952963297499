(function($$) {
	const CanvasUtils = $$.CanvasUtils;

	const customDefaults = {
		scale: 0.75,
		alignment: 0.5,
		targetAlwaysVisible: false
	};

	function drawImageCentered(ctx, image, vw, vh, iw, ih, alignX = 0.5, alignY = 0.5) {
		ctx.drawImage(
			image,
			(vw - iw) * alignX, (vh - ih) * alignY,
			iw, ih
		);
	}

	// NOTE: Эта имплементация плохая, поскольку максимальный размер канваса ограничен 2560x2560. Вместо этого будем перерисовывать иначе.

	/*
	class DNProTransition extends $$.AbstractTransition {
		constructor(from, to, settings) {
			super(from, to, _.extend({}, customDefaults, settings));

			this._settings.scale = Math.max(0, Math.min(1, this._settings.scale));
			this._composedLayer = CanvasUtils.createCanvas(to.width * 2, to.height * 2);

			const cl = this._composedLayer;
			const context = this._composedLayer.getContext('2d');
			context.drawImage(from, 0, 0, cl.width, cl.height);
			drawImageCentered(context, to, cl.width, cl.height, cl.width * this._settings.scale, cl.height * this._settings.scale);
		}

		_repaint(progress) {
			const from = this._bitmapFrom;
			const output = this._result;
			const context = output.getContext('2d');

			const maxScale = (1 / this._settings.scale);
			const scale = (maxScale - 1) * progress;
			const cl = this._composedLayer;

			drawImageCentered(context, cl, from.width, from.height, from.width * (1 + scale), from.height * (1 + scale));
		}
	}

	export default DNProTransition; */


	class DNProTransition extends $$.AbstractTransition {
		constructor(from, to, settings) {
			super(from, to, _.extend({}, customDefaults, settings));

			const opts = this._settings;

			opts.scale = Math.max(0, Math.min(1, this._settings.scale));
			opts.alignment = !_.isArray(opts.alignment) ? [opts.alignment, opts.alignment] : opts.alignment;

			if (opts.targetAlwaysVisible) {
				// This should not be used for semi-transparent layers, but it gives better looking result for solid images
				this._composedLayer = CanvasUtils.cloneCanvas(from);

				const cl = this._composedLayer;
				const context = this._composedLayer.getContext('2d');
				context.drawImage(from, 0, 0, cl.width, cl.height);
				drawImageCentered(context, to, cl.width, cl.height, cl.width * opts.scale, cl.height * opts.scale, opts.alignment[0], opts.alignment[1]);
			}
		}

		_repaint(progress) {
			const from = this._settings.targetAlwaysVisible ? this._composedLayer : this._bitmapFrom;
			const to = this._bitmapTo;
			const output = this._result;
			const context = output.getContext('2d');

			const i1maxScale = (1 / this._settings.scale); // scale of `from`
			const i1Scale = 1 + ((i1maxScale - 1) * progress);
			const i2Scale = this._settings.scale + (progress * (1 - this._settings.scale));

			context.save();
			drawImageCentered(context, from, output.width, output.height, from.width * i1Scale, from.height * i1Scale, this._settings.alignment[0], this._settings.alignment[1]);
			context.globalAlpha = Math.max(0, Math.min(1, progress * 3.5));
			drawImageCentered(context, to,   output.width, output.height, from.width * i2Scale, from.height * i2Scale, this._settings.alignment[0], this._settings.alignment[1]);
			context.restore();
		}
	}

	$$.DNProTransition = DNProTransition;
})(window.$$ || (window.$$ = {}));

