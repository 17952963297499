var $$ = $$ || {};

$$.Menu = class Menu {
	constructor (root, options = {}) {
		var defaultOptions = {};

		this.root = root;
		this.options = _.merge(options, defaultOptions, _.defaults);

		this.states = {};
		this.currentState = null;

		this._cacheNodes();
		this._createComponents();
		this._bindEvents();
		this._ready();
	}

	_cacheNodes () {
		this.nodes = {
			body: $('body'),

			hamburgerButton: $('.js-button-switch-state-right-wrapper'), // todo как-то переделать
			feedbackButton: this.root.find('.js-feedback-button-link'),

			slashMenu: this.root.find('.js-slash-menu'),

			feedbackForm: this.root.find('.js-feedback-form'),
			menuContainer: this.root.find('.js-right-wrapper-with-menu')
		};
	}

	_createComponents () {
		this.feedbackForm = new $$.FeedbackForm(this.nodes.feedbackForm);

		this.slashMenu = new $$.SlashMenu(this.nodes.slashMenu);
	}

	_bindEvents () {
		this.nodes.hamburgerButton.on('touchstart click', () => {
			this._changeState(this.currentState !== this.states.closed ? this.states.closed : this.states.menu);

			$$.yandexMetrika.reachGoal('hamburger-button');
			$$.googleAnalytics.reachVirtualPage('hamburger-button');

			return false;
		});

		this.nodes.feedbackButton.on('touchstart click', () => {
			this._changeState(this.states.feedback);

			$$.yandexMetrika.reachGoal('write-us');
			$$.googleAnalytics.reachVirtualPage('write-us');

			return false;
		});

		this.nodes.body.on('menu.open', (event, message) => {
			if (message) {
				this.feedbackForm.setMessage(message);
			}

			this._changeState(this.states.feedback);
		});

		this.nodes.body.on('menu.close', () => {
			this._changeState(this.states.closed);
		});

		this.nodes.body.on('touchstart click', (event) => {
			var inRoot = $(event.target).parents().is(this.root);
			var inHamburgerButton = $(event.target).parents().is(this.nodes.hamburgerButton);

			if (!inRoot && !inHamburgerButton) {
				this._changeState(this.states.closed);
			}
		});

		this.nodes.slashMenu.on('touchstart click', '.link', (event) => {
			this.nodes.slashMenu.find('.active').removeClass('active');
			$(event.currentTarget).addClass('active');
		});
	}

	_ready () {
		this.states.closed = {
			stateClass: 'state-default'
		};

		this.states.menu = {
			stateClass: 'state-menu'
		};

		this.states.feedback = {
			stateClass: 'state-feedback'
		};

		this._changeState(this.states.closed);
	}

	_changeState (targetState) {
		if (!this.currentState) {
			this.currentState = this.states.closed;
		}

		this.root
			.removeClass(this.currentState.stateClass)
			.addClass(targetState.stateClass);

		this.nodes.hamburgerButton
			.removeClass(this.currentState.stateClass)
			.addClass(targetState.stateClass);

		if (targetState !== this.states.closed) {
			this.nodes.body.addClass('menu-open');
			this.nodes.hamburgerButton.addClass('active');
		} else {
			this.nodes.body.removeClass('menu-open');
			this.nodes.hamburgerButton.removeClass('active');
		}

		this.currentState = targetState;
	}
};
