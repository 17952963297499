var $$ = $$ || {};

$$.ServiceManager = class ServiceManager {
	constructor (root) {
		this.root = root;
		this.slides = {};
		this.currentSlideId = null;

		//this._ready();
	}

	/**
	 * Инициализирует слайд
	 *
	 * @param slide
	 * @param changeSlideDuration
	 * @private
	 */
	_initSlide (slide, changeSlideDuration) {
		var slideId = _.uniqueId();

		this.currentSlideId = slideId;
		slide.data('id', slideId);
		this.slides[slideId] = [];

		var components = slide.find('[data-provider]');

		components.each((index, component) => {
			component = $(component);

			let provider = component.data('provider'),
				ProviderClass = $$[provider];

			if (!_.isFunction(ProviderClass)) {
				console.error(`Неизвестный компонент "${provider}"`);
				return;
			}

			let componentInstance = new ProviderClass(component, { changeSlideDuration });
			this.slides[slideId].push(componentInstance);
		});

		return slideId;
	}

	/**
	 * Внутренний метод, проходящий по всем компонентам текущего слайда
	 * и выполняющий метод "action", если такой существует
	 *
	 * См методы `pauseCurrentSlide` и `resumeCurrentSlide`
	 *
	 * @param action
	 * @param params Дополнительные параметры, передаваемые в вызываемую функцию
	 * @private
	 */
	_execMethodOfSlideComponents (slideId, action, params) {
		_.forEach(this.getSlideComponents(slideId), function (instance) {
			if (_.isFunction(instance[action])) {
				instance[action].apply(instance, params);
			}
		});
	}

	/**
	 * (Ре)инициализирует слайд.
	 *
	 * Если уже был инициализирован - предполагается, что слайд на паузе
	 * и он возобновляется (если его компоненты имеют методы pause/resume)
	 *
	 * @param slide
	 * @param changeSlideDuration
	 * @param comingFromTop
	 */
	enterSlide (slide, changeSlideDuration, comingFromTop = true) {
		var slideId = slide.data('id');

		if (slideId === this.currentSlideId) {
			return;
		}

		this.pauseCurrentSlide();

		if (!slideId) {
			this._initSlide(slide, changeSlideDuration);
		} else {
			this.currentSlideId = slideId;
			this.resumeCurrentSlide(comingFromTop);
		}
	}

	getCurrentSlide () {
		if (this.currentSlideId == null) {
			return null;
		}

		return this.slides[this.currentSlideId];
	}

	getSlideComponents (slideId) {
		return this.slides[slideId] || [];
	}

	/**
	 * Сообщает текущему слайду, что анимация входа на экран окончена
	 */
	informAnimationEnd() {
		this._execMethodOfSlideComponents(this.currentSlideId, 'repositioned', [true]);
	}

	/**
	 * Сообщает текущему слайду, что его позиция на экране сменилась
	 */
	informAnimationProgress() {
		_.forEach(this.slides, function(slideComponents) {
			_.forEach(slideComponents, function (component) {
				if (_.isFunction(component['repositioned'])) {
					component['repositioned']();
				}
			});
		});
	}

	pauseCurrentSlide (...params) {
		this._execMethodOfSlideComponents(this.currentSlideId, 'pause', params);
	}

	resumeCurrentSlide (...params) {
		this._execMethodOfSlideComponents(this.currentSlideId, 'resume', params)
	}

	_ready () {
		$('.b-portfolio-work').each((index, event) => {
			let slide = $(event).closest('.js-slide');
			let slideId = this._initSlide(slide);

			this._execMethodOfSlideComponents(slideId, 'pause');
		});
	}
}
