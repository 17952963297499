var $$ = $$ || {};

$$.Gallery = class Gallery {
	constructor (root) {
		this.root = root;

		this.isMouseDown = false;
		this.currentIndex = 0;
		this.trackBarWidth = 0;

		this._cacheNodes();
		this._bindEvents();
		this._ready();
	}

	_cacheNodes () {
		this.nodes = {
			parent: this.root.closest('.b-content-blocks'),
			trackBar: this.root.find('.js-track-bar'),
			lever: this.root.find('.js-lever'),
			imageContainer: this.root.find('.items'),
			images: this.root.find('.item')
		};
	}

	/**
	 * Вешает обработчики событий на компоненты/элементы.
	 *
	 * @private
	 */
	_bindEvents () {
		this.nodes.trackBar.on('click', (event) => {
			this._moveGallery(event);
		});

		this.nodes.lever.on('mousedown', () => {
			this.isMouseDown = true;
			this.nodes.lever.addClass('hover');
		});

		$$.window.on('mouseup.gallery', () => {
			if (this.isMouseDown) {
				this.nodes.lever.removeClass('hover');
				this.isMouseDown = false;
			}
		});

		$$.window.on('mousemove.gallery', (event)=> {
			if (this.isMouseDown) {
				this._moveGallery(event);
			}
		});

		$$.window.on('resize.gallery', () => {
			this._calculateWidth();
		});

		this.root.swipeLeft(() => {
			this._goToImage(this.currentIndex + 1);
		});

		this.root.swipeRight(() => {
			this._goToImage(this.currentIndex - 1);
		});
	}

	_goToImage (index) {
		if ($('html').hasClass('landscape') && index >= this.nodes.images.length - 1) {
			return;
		}

		if (0 <= index && index < this.nodes.images.length) {
			var marginLeft = this.nodes.imageContainer.offset().left - this.nodes.images.eq(index).offset().left;

			this.nodes.imageContainer.stop(true, true).velocity({
				'margin-left': marginLeft
			});

			this.currentIndex = index;
		}
	}

	_moveGallery (event) {
		var left = 0;

		if (event.pageX < this.trackBarOffset.left) {
			left = 0;
		} else if (event.pageX > this.trackBarOffset.left + this.trackBarWidth) {
			left = this.trackBarWidth;
		} else {
			left = event.pageX - this.trackBarOffset.left;
		}

		this.nodes.lever.css({ transform: 'translateX(' + left + 'px)' });

		var leverPercentage = this.trackBarWidth / left;
		var imageContainerPosition = this.imageContainerWidth / leverPercentage;

		requestAnimationFrame(() => {
			this.nodes.imageContainer.css({ transform: 'translateX(' + -imageContainerPosition + 'px)' });
		});
	}

	_ready () {
		this.nodes.parent.css({ "user-select": "none" });

		_.defer(() => {
			this._calculateWidth();
		});
	}

	_calculateWidth () {
		this.trackBarOffset = this.nodes.trackBar.offset();
		this.trackBarWidth = this.nodes.trackBar.width();

		this.imageContainerWidth = 0;

		this.nodes.images.each((index, image)=> {
			this.imageContainerWidth += $(image).outerWidth(true);
		});

		this.nodes.imageContainer.width(this.imageContainerWidth * 2);

		this.imageContainerWidth -= this.root.width();
	}
};
