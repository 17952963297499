(function ($$) {
	$$.FPSMeter = class FPSMeter {
		constructor () {
			this.lastRun = null;
			this.game_running = true;
			this.show_fps = true;
			this.fps = 60;

			this.canvas = $('<canvas style="transform: translateZ(0)" id="mycanvas" width="300" height="200"></canvas>');
			this.context = this.canvas.get(0).getContext("2d");
			this.context.fillStyle = "White";
			this.context.font = "normal 26pt Arial";

			this.canvas.css({
				position: 'fixed',
				right: 0,
				top: 0,
				zIndex: '1000'
			});

			$$.body.append(this.canvas);

			this.loop();
		}

		showFPS () {
			this.context.clearRect(0, 0, 300, 200);
			this.context.fillText(this.fps + " fps", 10, 26);
		}

		loop () {
			if (!this.lastRun) {
				this.lastRun = new Date().getTime();
				requestAnimationFrame(_.bind(this.loop, this));
				return;
			}

			var delta = (new Date().getTime() - this.lastRun) / 1000;

			this.lastRun = new Date().getTime();
			this.fps = 1 / delta;

			if (this.show_fps) {
				this.showFPS();
			}

			if (this.game_running) {
				requestAnimationFrame(_.bind(this.loop, this));
			}

		}
	};
})(window.$$ || (window.$$ = {}));

