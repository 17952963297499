$$.SlashMenu = class SlashMenu {
	constructor (root, options = {}) {
		var defaultOptions = {
			opacity:      0.2,
			hoverOpacity: 1,
			duration:     200
		};

		this.root = root;
		this.options = _.merge(options, defaultOptions, _.defaults);

		this._cacheNodes();
		this._bindEvents();
		this._ready();
	}

	_cacheNodes () {
		this.nodes = {
			items:          this.root.find('.js-slash-item'),
			menuActiveLink: this.root.find('.js-slash-item.active'),
			menuSlash:      this.root.find('.js-slash'),
			body:           $('body')
		};
	}

	_bindEvents () {
		this.nodes.items.on('mouseenter', (event) => {
			this._updateSlashPosition($(event.currentTarget));
		});

		this.nodes.items.on('mouseleave', () => {
			this._updateSlashPosition();
		});

		this.nodes.items.on('click', (event) => {
			this.nodes.menuActiveLink = $(event.currentTarget);
			this.nodes.body.triggerHandler('menu.close');
		});

		this.root.on('slashmenu.update', () => {
			this.nodes.menuActiveLink = this.root.find('.js-slash-item.active');
			this._updateSlashPosition();

			if (!this.nodes.menuActiveLink.length) {
				this.nodes.menuSlash.hide();
			} else {
				this.nodes.menuSlash.show();
			}
		});

		$$.window.on('resize', () => {
			setTimeout(() => this._updateSlashPosition(), 0);
		});
	}

	_ready () {
		var position = { top: 0 };

		if (this.nodes.menuActiveLink.length) {
			position.top = this.nodes.menuActiveLink.position().top;
		}

		this.nodes.menuSlash.css(position);
	}

	_updateSlashPosition (item) {
		var position = {};

		if (item) {
			position.top = item.position().top;
			position.opacity = this.options.hoverOpacity;
		} else if (this.nodes.menuActiveLink.length) {
			position.top = this.nodes.menuActiveLink.position().top;
			position.opacity = this.options.opacity;
		}

		if (!_.isEmpty(position)) {
			this.nodes.menuSlash.velocity('stop', true).velocity(position, this.options.duration);
		}
	}
};
