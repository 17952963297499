var $$ = $$ || {};

$$.FeedbackForm = class FeedbackForm {
	constructor (root) {
		this.root = root;

		this.options = {
			textfieldValue: '',
			errorClass: 'field-error',
			attachedClass: 'attached',
			attachFileName: ''
		};

		this._cacheNodes();
		this._bindEvents();
		this._ready();
	}

	_cacheNodes () {
		this.nodes = {
			textfieldBorder: this.root.find('.js-textfiled-border'),
			textfieldCopyValue: this.root.find('.js-copy-textfield-value'),

			name: this.root.find('.js-name'),
			phone: this.root.find('.js-phone'),
			email: this.root.find('.js-email'),
			message: this.root.find('.js-message'),
			file: this.root.find('.js-file'),
			token: this.root.find('.js-token'),
			text: this.root.find('.js-text-input'),

			attachButton: this.root.find('.js-button-attach'),
			fileSize: this.root.find('.js-file-size'),
			lessOrMore: this.root.find('.js-less-or-more-than'),
			submitButton: this.root.find('.js-submit'),

			successMessage: this.root.next()
		};

		this.nodes.okButton = this.nodes.successMessage.find('.js-ok');
	}

	_bindEvents () {
		this.nodes.name
			.add(this.nodes.phone)
			.add(this.nodes.email)
			.on('focusin', (event) => {
				this.options.textfieldValue = $(event.currentTarget).val();
			})
			.on('input', (event) => {
				this._updateInput($(event.currentTarget));
			})
			.on('focusout', () => {
				this.options.textfieldValue = '';
			}
		);

		this.nodes.attachButton.on('click', () => {
			this.nodes.file.click();
		});

		this.nodes.file.on('change', (event) => {
			var name = event.currentTarget.files[0].name;
			var size = event.currentTarget.files[0].size / 1048576;

			this.nodes.attachButton.text(name).parent().addClass(this.options.attachedClass);

			if (size > parseInt(this.nodes.fileSize.text(), 10)) {
				this.nodes.lessOrMore.text('>');
				$(event.currentTarget).parent().parent().parent().addClass(this.options.errorClass);
			} else {
				this.nodes.lessOrMore.text('<');
				$(event.currentTarget).parent().parent().parent().removeClass(this.options.errorClass);
			}
		});

		this.nodes.okButton.on('click', () => {
			this.root.velocity('fadeIn');
			this.nodes.successMessage.velocity('fadeOut');
		});

		$$.window.off('resize.feedback');
		$$.window.on('resize.feedback', () => {
			setTimeout(() => {
				this._updateInput(this.nodes.name);
				this._updateInput(this.nodes.phone);
				this._updateInput(this.nodes.email);
			}, 0);
		});
	}

	_ready () {
		this.options.attachFileName = this.nodes.attachButton.text();

		this.nodes.successMessage.hide();

		this.nodes.message.val('');

		this.nodes.phone.inputmask("+7 (999) 999-99-99");

		$.validator.messages.required = 'Поле не заполнено';

		$.validator.addMethod("regx", function (value, element, regexpr) {
			return regexpr.test(value);
		});

		$.validator.addMethod('filesize', function (value, element, maxsize) {
			return this.optional(element) || (element.files[0].size <= maxsize)
		});

		this.root.validate({
			ignore: [],
			rules: {
				phone: {
					regx: /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/
				},
				email: {
					email: true
				},
				file: {
					filesize: 20971520 // 20MB
				}
			},

			messages: {
				phone: {
					regx: "Введите корректный телефон"
				},
				email: {
					email: "Введите корректный email адрес"
				},
				file: {
					filesize: ""
				}
			},

			submitHandler: () => {
				var formData = new FormData();

				var name = this.nodes.name.val();
				var phone = this.nodes.phone.val();
				var email = this.nodes.email.val();
				var message = this.nodes.message.val();
				var token = this.nodes.token.val();
				var text = this.nodes.text.val();
				var file = this.nodes.file[0].files[0];

				formData.append("name", name);
				formData.append("phone", phone);
				formData.append("email", email);
				formData.append("message", message);
				formData.append("file", file);
				formData.append("csrfToken", token);
				formData.append("text", text);

				$.ajax({
					type: 'post',
					url: '/feedback/default',
					data: formData,
					processData: false,
					contentType: false,

					success: () => {
						this.nodes.name.val('').triggerHandler('input');
						this.nodes.phone.val('').triggerHandler('input');
						this.nodes.email.val('').triggerHandler('input');
						this.nodes.message.val('');
						this.nodes.file.val('');

						this.nodes.attachButton
							.text(this.options.attachFileName)
							.parent()
							.removeClass(this.options.attachedClass);

						this.root.velocity('fadeOut');
						this.nodes.successMessage.velocity('fadeIn');

						this.root.trigger('submitga.analytics');
					}
				});
			}
		});
	}

	_updateInput (currentTarget) {
		var value = currentTarget.val();
		var copyValue = currentTarget.parent().find(this.nodes.textfieldCopyValue);

		if ((currentTarget.attr('type') === 'email') && (value.indexOf(' ') >= 0)) {
			value = value.replace(/\s/g, '');
			currentTarget.val(value);
		}

		copyValue.text(value);

		if (copyValue.width() < currentTarget.width()) {
			currentTarget.parent().find(this.nodes.textfieldBorder).css({
				width: currentTarget.width() - copyValue.width()
			});

			this.options.textfieldValue = value;
		} else {
			currentTarget.val(this.options.textfieldValue);
		}
	}

	setMessage (message) {
		this.nodes.message.val(message);
	}
};
