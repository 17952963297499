var $$ = $$ || {};

$$.Infralex = class Infralex extends $$.BaseProjectPageClass {
	constructor (root) {
		super(root, 'portfolio-entry-infralex-');

		this.sliderInterval = 0;
		this.isAnimating = true;
	}

	_cacheNodes () {
		super._cacheNodes();

		const payload = this.nodes.payload;

		$.extend(this.nodes, {
			section1: payload.children('.section1'),
			s1Video: payload.find('.section1 .video'),
			s1Laptop: payload.find('.section1 .laptop'),
			s1LaptopImage: payload.find('.section1 .laptop-image'),
			s1Page: payload.find('.section1 .page'),
			s1PageScreen: payload.find('.section1 .page-screen'),
			s1Phrases: payload.find('.section1 .phrases'),
			s1PhrasesScreen: payload.find('.section1 .phrases-screen'),
			s1Screen: payload.find('.section1 .screen'),

			s1Phrase1: payload.find('.section1 .phrase-1'),
			s1Phrase2: payload.find('.section1 .phrase-2'),
			s1Phrase3: payload.find('.section1 .phrase-3'),
			s1Phrase4: payload.find('.section1 .phrase-4'),

			s1Quotes: payload.find('.section1 .quotes'),
			s1Pin: payload.find('.section1 .pin'),

			slider: payload.find('.js-slider'),

			section2: payload.children('.section2'),

			s2Block1: payload.find('.section2 .block-1'),
			s2Block2: payload.find('.section2 .block-2'),
			s2Block3: payload.find('.section2 .block-3'),
			s2Block4: payload.find('.section2 .block-4'),
			s2Block5: payload.find('.section2 .block-5'),
			s2Block3Active: payload.find('.section2 .block-3-active'),
			s2Block5Active: payload.find('.section2 .block-5-active'),

			type: payload.find('.js-type'),
			cursor: payload.find('.js-cursor')
		});

		this.nodes.slides = this.nodes.slider.children();
	}

	_bindAnimationSequence () {
		if (this._destroyed) {
			return;
		}

		let timeline;

		let pageHeight = (this.nodes.s1Page.height() - this.nodes.s1Screen.height());

		timeline = new TimelineMax()
			.from(this.nodes.s1Video, 2, { opacity: 0, ease: Linear.easeNone }, 5);

		new ScrollMagic.Scene({
			triggerHook: 0,
			triggerElement: this.nodes.section1.get(0)
		}).setTween(timeline)
			.on("enter", () => {
				if (!$$.isMobile) {
					this.nodes.s1Video.get(0).play();
					this._startType();
				}
			})
			.addTo(this._scrollMagicController);

		timeline = new TimelineMax({
			onComplete: () => {
				this.isAnimating = false;
				this.nodes.mouseWheelCatcher.hide();
			}
		})
			.from(this.nodes.s1LaptopImage, 2, { opacity: 0, ease: Linear.easeNone }, 3)
			.from(this.nodes.s1PageScreen, 2, { opacity: 0, ease: Linear.easeNone }, 5);

		new ScrollMagic.Scene({
			triggerHook: 0,
			triggerElement: this.nodes.section1.get(0)
		}).setTween(timeline)
			.addTo(this._scrollMagicController);

		new ScrollMagic.Scene({
			offset: 0.12 * pageHeight,
			triggerHook: 0,
			triggerElement: this.nodes.section1.get(0)
		})
			.setClassToggle(this.nodes.s1Phrase1.get(0), "active")
			.addTo(this._scrollMagicController);

		new ScrollMagic.Scene({
			offset: 0.16 * pageHeight,
			triggerHook: 0,
			triggerElement: this.nodes.section1.get(0)
		})
			.setClassToggle(this.nodes.s1Phrase4.get(0), "active")
			.addTo(this._scrollMagicController);

		timeline = new TimelineMax()
			.fromTo(this.nodes.section1, 1, {
				background: '#db7857',
				ease: Linear.easeNone
			}, {
				background: '#ffffff',
				ease: Linear.easeNone
			})
			.fromTo(this.nodes.s1Video, 2, {
				opacity: 1,
				ease: Linear.easeNone
			}, {
				opacity: 0,
				ease: Linear.easeNone
			}, 1);

		new ScrollMagic.Scene({
			offset: 0.14 * pageHeight,
			triggerHook: 0,
			triggerElement: this.nodes.section1.get(0)
		}).setTween(timeline)
			.addTo(this._scrollMagicController);

		new ScrollMagic.Scene({
			offset: 0.33 * pageHeight,
			triggerHook: 0,
			triggerElement: this.nodes.section1.get(0)
		})
			.setClassToggle(this.nodes.s1Quotes.get(0), "active")
			.on("enter leave", (event) => {
				if (event.type == "enter") {
					this._startSlider();
				} else {
					this._stopSlider();
				}
			})
			.addTo(this._scrollMagicController);

		new ScrollMagic.Scene({
			offset: 0.46 * pageHeight,
			triggerHook: 0,
			triggerElement: this.nodes.section1.get(0)
		})
			.on("enter leave", (event) => {
				if (event.type == "enter") {
					this._stopSlider();
				} else {
					this._startSlider();
				}
			})
			.addTo(this._scrollMagicController);

		new ScrollMagic.Scene({
			offset: 0.95 * pageHeight,
			triggerHook: 0,
			triggerElement: this.nodes.section1.get(0)
		})
			.setClassToggle(this.nodes.s1Pin.get(0), "active")
			.addTo(this._scrollMagicController);

		timeline = new TimelineMax()
			.to(this.nodes.s1Page, 1, {
				css: { transform: "translateY(" + -pageHeight + "px)" },
				ease: Linear.easeNone
			});

		new ScrollMagic.Scene({
			duration: pageHeight,
			triggerHook: 0,
			triggerElement: this.nodes.section1.get(0)
		}).setTween(timeline)
			.setPin(this.nodes.section1.get(0))
			.addTo(this._scrollMagicController);

		timeline = new TimelineMax()
			.to(this.nodes.s1Phrases, 1, {
				css: { transform: "translateY(" + -pageHeight + "px)" },
				ease: Linear.easeNone
			});

		new ScrollMagic.Scene({
			duration: pageHeight,
			triggerHook: 0,
			triggerElement: this.nodes.section1.get(0)
		}).setTween(timeline)
			.addTo(this._scrollMagicController);

		new ScrollMagic.Scene({
			offset: pageHeight,
			triggerHook: 0,
			triggerElement: this.nodes.section1.get(0)
		})
			.on("enter", () => {
				this._springScroller._spring.target(pageHeight);
			})
			.addTo(this._scrollMagicController);

		// Section 2

		timeline = new TimelineMax()
			.from(this.nodes.s2Block3Active, 1, { opacity: 0, ease: Linear.easeNone }, 1.4)
			.from(this.nodes.s2Block5Active, 1, { opacity: 0, ease: Linear.easeNone }, 1.4);

		new ScrollMagic.Scene({
			triggerHook: 0,
			triggerElement: this.nodes.section2.get(0)
		}).setTween(timeline)
			.addTo(this._scrollMagicController);

		timeline = new TimelineMax()
			.from(this.nodes.s2Block1, 1, { opacity: 0, ease: Linear.easeNone })
			.from(this.nodes.s2Block2, 1, { opacity: 0, ease: Linear.easeNone }, 0.2)
			.from(this.nodes.s2Block3, 1, { opacity: 0, ease: Linear.easeNone }, 0.4)
			.from(this.nodes.s2Block4, 1, { opacity: 0, ease: Linear.easeNone }, 0.4)
			.from(this.nodes.s2Block5, 1, { opacity: 0, ease: Linear.easeNone }, 0.4);

		new ScrollMagic.Scene({
			triggerHook: 0,
			triggerElement: this.nodes.section2.get(0)
		}).setTween(timeline)
			.addTo(this._scrollMagicController);
	}

	_ready () {
		this.nodes.payload.imagesLoaded({
				background: true
			})
			.always(() => {
				super._ready();
			});
	}

	/**
	 * Эта функция должна вызываться извне и уведомлять нас, что позиция слайда на экране изменилась
	 *
	 * @param animationEnded Закончилась ли анимация. На самом деле вызывается только, когда мы и есть слайд к которому осуществлялся переход
	 */
	repositioned (animationEnded = false) {
		if (this._destroyed) {
			return;
		}

		if (animationEnded) {
			if (!this.isAnimating) {
				this.nodes.mouseWheelCatcher.hide();
			}

			if (!this._paused) {
				// а без этого ScrollMagic тупит почему-то и не хочет пересчитывать высоту triggerHook. Его собственными методами не чинится
				$$.window.trigger('resize');
			}

			_.defer(function () {
				$$.body.addClass('black-controls');
			});
		} else {
			this.nodes.mouseWheelCatcher.show();
			$$.body.removeClass('black-controls');
			this._updateScene();
		}
	}

	resize () {
		if (this._destroyed) {
			return;
		}

		var laptopFontSize = this.nodes.s1Laptop.width() / 1420;

		this.nodes.s1Laptop.css({
			fontSize: laptopFontSize
		});

		this.nodes.s1Phrases.height(this.nodes.s1Page.height());

		super.resize();

		this._springScroller.step(100);
	}

	_startType () {
		var typeString = this.nodes.type.text();

		this.nodes.type.text('');
		this.nodes.type.typed({
			strings: [typeString],
			startDelay: 500,
			typeSpeed: 30,
			cursorChar: '',
			showCursor: true,
			callback: () => {
				_.delay(() => {
					var cursor = this.nodes.type.parent().find('.typed-cursor');

					if ($$.isMobile) {
						cursor.remove();
					} else {
						cursor.on('animationiteration', () => {
							cursor.remove();
						});
					}
				}, 1000);
			}
		});
	}

	_startSlider () {
		this.slideWidth = this.nodes.s1Screen.width();

		setTimeout(() => {
			this._slide()
		}, 500);

		this.sliderInterval = setInterval(() => {
			this._slide()
		}, 3000);
	}

	_slide () {
		this.nodes.slides.eq(1).stop(true).velocity({
			marginLeft: -this.slideWidth
		}, 2000, () => {
			this.nodes.slides.eq(1).css({ marginLeft: 0 });
			this.nodes.slides.eq(0).clone().appendTo(this.nodes.slider);
			this.nodes.slides.eq(0).remove();

			this.nodes.slides = this.nodes.slider.children();
		});
	}

	_stopSlider () {
		clearInterval(this.sliderInterval);
	}
};
