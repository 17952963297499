(function($$) {
	const CanvasUtils = $$.CanvasUtils;

	const throttledStackBlur = _.throttle(function(canvas, radius) {
		CanvasUtils.blur(canvas, radius, true, true);
	}, 50);

	$$.BlurFilter = class BlurFilter extends $$.AbstractFilter {
		constructor(source, value, fallBackToCPUBlur = true) {
			super(source, value);

			this._fxCanvas = null;
			this._texture = null;
			this._shouldUseFallback = !!fallBackToCPUBlur;

			// try to create a WebGL canvas (will fail if WebGL isn't supported)
			try {
				if (!Modernizr.webgl) { throw "boop"; }
				this._fxCanvas = fx.canvas();
				this._texture = this._fxCanvas.texture(source)
			} catch (e) {
				this._fxCanvas = null;
				//this._fallbackCanv = $$.CanvasUtils.createCanvas(~~(source.width / 3), ~~(source.height / 4));
				this._fallbackCanv = $$.CanvasUtils.cloneCanvas(source);
			}

			this.setSource(source);
		}

		_applyEffect(progress) {
			progress = ~~progress;
			const outputContext = this._result.getContext('2d');

			if (progress === 0) {
				outputContext.clearRect(0, 0, this._result.width, this._result.height);
				outputContext.drawImage(this._source, 0, 0);
				return;
			}

			if (!this._fxCanvas) {
				// Тормозит вообще ад. Лучше без него. Потому он отключабельный.

				if (this._shouldUseFallback) {
					$$.CanvasUtils.copyBitmap(this._source, this._fallbackCanv, { mode: 'stretch' });
					throttledStackBlur(this._fallbackCanv, progress);
					$$.CanvasUtils.copyBitmap(this._fallbackCanv, this._result, { mode: 'stretch' });
				} else {
					outputContext.drawImage(this._source, 0, 0);
				}
			} else {
				this._texture.loadContentsOf(this._source);
				this._fxCanvas.draw(this._texture).triangleBlur(progress).update();
				outputContext.drawImage(this._fxCanvas, 0, 0);
			}
		}

		getBitmap(copy = false) {
			this._applyEffect(this._value);
			return super.getBitmap(copy);
		}

		isWebGLSupported() {
			return !!this._fxCanvas;
		}
	};
})(window.$$ || (window.$$ = {}));
