var $$ = $$ || {};

$$.Followme = class Followme {
	constructor (root) {
		this.root = root;

		// Переменные и флаги

		this._hash = 'portfolio-entry-apples-' + _.uniqueId();
		this._paused = false;
		this._destroyed = false;
		this._scrollMagicController = null;

		this._isInitialTick = true; setTimeout( () => this._isInitialTick = false, 0);
		this._waitingForDependenciesToLoad = true;

		// Инициализируем

		this._cacheNodes();
		this._bindEvents();
		this._initScrollSpring();

		this.resize();

		this.nodes.loader.show();
		this.nodes.payload.css('opacity', 0);

		$script.ready('vendor-postponed', () => {
			this._ready();
		});
	}

	_cacheNodes () {
		let payload = this.root.children('.followme-payload');

		this.nodes = {
			loader: this.root.children('.js-loading'),
			payload: payload,
			mouseWheelCatcher: $('<div/>').appendTo(this.root),

			fitText: payload.find('.js-fit-text'),
			fullScreenSections: payload.find('.fullscreen-section'),
			pintargets: null,
			logo: payload.find('.section1 .logo').css('visibility', 'hidden'),

			sliderSections: payload.find('.followme-section .b-fmw-content-block .b-fmw-slider').closest('.followme-section')
		};

		this.nodes.pintargets = this.nodes.fullScreenSections.children('.pin-target');

		this.nodes.mouseWheelCatcher.css({
			position: 'absolute',
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
			display: 'none'
		});
	}

	/**
	 * Вешает обработчики событий на компоненты/элементы.
	 *
	 * @private
	 */
	_bindEvents () {
		this.nodes.mouseWheelCatcher.on('mousewheel', () => false);

		this.root.add(this.nodes.pintargets) // pintargetы заворачиваются в position: fixed и браузер перестает с них опускать события
			.on('mousewheel', (event) => {
				if (this._waitingForDependenciesToLoad) {
					event.stopPropagation();
				}
			});

		let isDetached = () => {
			if (!jQuery.contains(document.documentElement, this.root.get(0))) {
				this.destroy();
				return true;
			}

			return false;
		};

		$$.window.on('resize.' + this._hash, () => {
			if (isDetached()) {
				return
			}

			this.resize();
		});
	}

	_initScrollSpring () {
		this._springScroller = new $$.SmoothScroller(this.nodes.payload, {
			velocityLimit: 0.35
		}, {
			preventMovingTargetTooFar: true,
			stepSize: $$.windowHeight
		});

		this._springScroller.pause();

		this._springScroller.onScroll = () => this._updateScene();
	}

	_bindAnimationSequence () {
		let elements = this.nodes.logo
			.css('visibility', 'visible')
			.blast({
				delimiter: "character",
				customClass: "piece"
			})
			.append('<span class="shutter"></div>').find('.shutter');

		//TweenMax.staggerTo(elements, 2.4, {left: '100%', ease: Power1.easeOut}, 0.05);
		TweenMax.staggerTo(elements, 1.5, {left: '100%', ease: Power1.easeOut, delay: 1}, 0);

		// Слайдеры

		this._scrollMagicController = new ScrollMagic.Controller({ container: this.nodes.payload.get(0) });

		this.nodes.sliderSections.each((i, section) => {
			let images = $(section).find('.b-fmw-slider > img');
			let pintarget = $(section).find('.pin-target');

			if (images.length <= 1) {
				return;
			}

			let timeline = new TimelineMax();

			images.each((i, image) => {
				if (i === 0) {
					return;
				}

				//timeline.add(TweenMax.from(image, 1, {
				//	opacity: 0,
				//	ease: Linear.none
				//}));

				// Почему не как выше: крутить могут и с тача, например. Так что мы хотим, чтобы
				// Сам переход осуществлялся быстро. Потому 80% шага мы стоим на месте и только 20% анимируемся.
				// Еще мы поставили tweenChanges: true, так что при ручной прокрутке анимация займет примерно 200мс.
				// А в центре двух пустышек потому, что обычно скролл у нас происходит через пружину и у нее,
				// можно сказать, EaseOut изинг. Так мы можем получить одинаковую скорость при движении вперед и назад.

				timeline.add(TweenMax.to({}, 0.4, {}));
				timeline.add(TweenMax.from(image, 0.4, {
					opacity: 0,
					ease: Sine.easeOut
				}));
				timeline.add(TweenMax.to({}, 0.4, {}));
			});

			new ScrollMagic.Scene({
				duration: () => $$.windowHeight * (images.length - 1),
				triggerHook: 0,
				triggerElement: section,
				tweenChanges: true
			})
				.setTween(timeline)
				.setPin(pintarget.get(0), {
					pushFollowers: true
				})
				.addTo(this._scrollMagicController);
		});
	}

	_ready () {
		this._waitingForDependenciesToLoad = false;

		this._springScroller.resume();

		this.nodes.payload
			.css({
				zIndex: '0', // без этого стики элементы будут отрисовываться поверх активного слайда
				overflow: 'auto'
			});

		// Если вызвано в тот же тик, что и конструктор - ничего не анимируем.
		if (this._isInitialTick) {
			this.nodes.loader.hide();
			this.nodes.payload.css('opacity', 1);
		} else {
			this.nodes.loader.velocity('fadeOut');
			this.nodes.payload.velocity('fadeIn');
		}

		this._bindAnimationSequence();
		this.resize();
	}

	_updateScene () {
		if (!this._scrollMagicController) {
			return;
		}

		this._scrollMagicController.update(true);
	}

	destroy () {
		if (this._destroyed) {
			return;
		}

		this._destroyed = true;
		this._scrollMagicController.destroy();
		this.root.off();
		this.payload.off();

		$$.window.off('resize.' + this._hash);

		this._scrollMagicController = _.noop();

		this._springScroller.destroy();
		this._springScroller = _.noop();
	}

	pause () {
		this._paused = true;

		if (this._waitingForDependenciesToLoad) {
			return;
		}

		this._springScroller.pause();
	}

	/**
	 * Эта функция должна вызываться извне и уведомлять нас, что позиция слайда на экране изменилась
	 *
	 * @param animationEnded Закончилась ли анимация. На самом деле вызывается только, когда мы и есть слайд к которому осуществлялся переход
	 */
	repositioned (animationEnded = false) {
		if (this._destroyed) {
			return;
		}

		if (animationEnded) {
			this.nodes.mouseWheelCatcher.hide();
			if (!this._waitingForDependenciesToLoad && !this._paused) {
				// а без этого ScrollMagic тупит почему-то и не хочет пересчитывать высоту triggerHook. Его собственными методами не чинится
				$$.window.trigger('resize');
			}
		} else {
			this.nodes.mouseWheelCatcher.show();
			this._updateScene();
		}
	}

	resize () {
		if (this._waitingForDependenciesToLoad || this._destroyed) {
			return;
		}

		this.nodes.fitText.fitText();
		this.nodes.fullScreenSections.css('min-height', $$.windowHeight);
		this.nodes.pintargets.height($$.windowHeight);

		this._springScroller.step($$.windowHeight);
		this._springScroller.recalculate();
		this._updateScene();
	}

	resume (comingFromTop) {
		this._paused = false;

		if (this._waitingForDependenciesToLoad || this._destroyed) {
			return;
		}

		this._springScroller.resume();
		this._scrollMagicController.scrollTo(comingFromTop ? 0 : this.nodes.payload.prop('scrollHeight'));
	}
};
