(function($$) {
	const defaultSettings = {
		duration: 2200,
		easing: 'easeInSine',
	};

	function getEasing(name) {
		var lib = $.Velocity ? $.Velocity.Easings : $.easing;
		return lib[name] || $.easing[$.easing.def] || $.easing.linear;
	}

	const customDefaults = {};

	/**
	 * Basically a black box that transitions from one bitmap to another.
	 * Has two inputs and one output. Can be configured
	 */
	class AbstractTransition extends $$.AbstractAnimatable {
		constructor(bitMapFrom, bitMapTo, settings) {
			if (!(bitMapFrom instanceof HTMLCanvasElement) || !(bitMapTo instanceof HTMLCanvasElement)) {
				throw new Error("Both bitMapFrom and bitMapTo must be canvas elements");
			}
			if (bitMapFrom.width !== bitMapTo.width || bitMapFrom.height !== bitMapTo.height) {
				throw new Error("bitMapFrom and bitMapTo must be of the same size");
			}

			super(_.extend({}, defaultSettings, settings));

			this._bitmapFrom = bitMapFrom;
			this._bitmapTo = bitMapTo;
			this._result = document.createElement('canvas');
			this._result.width = bitMapFrom.width;
			this._result.height = bitMapFrom.height;

			this._isResultCanvasUpToDate = false;
		}

		_repaint(progress) {
			throw "This method must be redefined to get this working";
		}

		_getCustomOptions() {
			return customDefaults;
		}

		_recalculateAnimationProgress(linearProgress) {
			const oldValue = this.getProgress();
			super._recalculateAnimationProgress(linearProgress);

			if (oldValue !== this.getProgress()) {
				this._isResultCanvasUpToDate = false;
			}
		}

		getBitmap(copy = false) {
			if (!this._isResultCanvasUpToDate) {
				this._repaint(this._animationProgress);
				this._isResultCanvasUpToDate = true;
			}

			if (!copy) {
				return this._result;
			}

			return CanvasUtils.cloneCanvas(this._result);
		}

		hasChangedSinceLastTime() {
			return !this._isResultCanvasUpToDate;
		}

		destroy() {
			super.destroy();
			this._bitmapFrom = null;
			this._bitmapTo = null;
			this._canvas = null;
		}

		/**
		 * If see through
		 *
		 * @returns {boolean}
		 */
		isSeeThrough() {
			return false;
		}

		/**
		 * If output changes or not
		 *
		 * @returns {boolean}
		 */
		isStatic() {
			return !this.isAnimating();
		}
	}

	$$.AbstractTransition = AbstractTransition;

})(window.$$ || (window.$$ = {}));
