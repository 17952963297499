(function($$) {
	const CanvasUtils = $$.CanvasUtils;

	const defaultSettings = {
		startImmediately: false,
		values: [0, 0], // min..max
		valueProcessor: function(val) { return val; },
		onUpdate: function() {}
	};

	class AnimatedFilterWrapper extends $$.AbstractAnimatable {
		constructor(filterInstance, settings) {
			if (!(filterInstance instanceof $$.AbstractFilter)) {
				throw "filterInstance is not a filter.";
			}

			super(settings);
			this._filter = filterInstance;
			this._lastValue = null;
		}

		_recalculateAnimationProgress(linearProgressValue) {
			super._recalculateAnimationProgress(linearProgressValue);

			const min = this._settings.values[0];
			const max = this._settings.values[1];
			const progress = this.getProgress();
			const value = this._settings.valueProcessor(progress * (max - min) + min);

			// This situation can happen upon initialization
			if (!this._filter || this._lastValue === value) {
				return;
			}

			this._lastValue = value;
			this._filter.setValue(value);
			this._settings.onUpdate();
		}

		getFilter() {
			return this._filter;
		}
	}

	$$.AnimatedFilterWrapper = AnimatedFilterWrapper;
})(window.$$ || (window.$$ = {}));
