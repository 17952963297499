var $$ = $$ || {};

/**
 * Растягивание элемента по ширине, либо высоте экрана, в зависимости от
 * его соотношения сторон и соотношения сторон экрана, что-бы элемент занимал всю
 * область экрана.
 * @param {Number} elementRatio - соотношение высоты к ширине елемента
 * @returns {{left: Number, top: Number, width: Number, height: Number}}
 */
$$.calculateFullScreenCSS = function (elementRatio, isPhone = false) {
	var screenRatio = $$.windowHeight / $$.windowWidth;
	var left = 0;
	var top = 0;
	var width = $$.windowWidth;
	var height = $$.windowHeight;
	var multiply = 1;

	if (isPhone) {
		multiply = 1.05;
	}

	if (screenRatio > elementRatio) {
		// Растягиваем по высоте
		width = height / elementRatio;
		left = ($$.windowWidth - width) / 2;
	} else {
		// Растягиваем по ширине
		height = $$.windowWidth * elementRatio;
		top = ($$.windowHeight - height) / 2;
	}

	return {
		left: parseInt(left),
		top: parseInt(top),
		width: parseInt(width),
		height: parseInt(height) * multiply
	};
};

/**
 * Расчитывает, сколько в пикселях будет занимать элемент, который занимает указанное
 * в sizesPercent пространство от того, как если бы он был растянут по высоте, либо ширине
 * @param {Number} ratio - соотношение высоты к ширине елемента
 * @param {{left: Number, top: Number, width: Number, height: Number}} sizesPercent
 * @returns {{left: Number, top: Number, width: Number, height: Number}}
 */
$$.calculateRegionScreenCSS = function (ratio, sizesPercent) {
	var sizes = $$.calculateFullScreenCSS(ratio);

	return {
		left: parseInt(sizesPercent.left * sizes.width / 100 + sizes.left),
		top: parseInt(sizesPercent.top * sizes.height / 100 + sizes.top),
		width: parseInt(sizesPercent.width * sizes.width / 100),
		height: parseInt(sizesPercent.height * sizes.height / 100)
	};
};

$$.calculateBigScreenCSS = function (ratio, sizesPercent) {
	var sizes = $$.calculateFullScreenCSS(ratio);
	var leftFullImage = ($$.windowWidth - sizes.width) / 2;
	var topFillImage = ($$.windowHeight - sizes.height) / 2;
	var bigWidth = sizes.width * 100 / sizesPercent.width;
	var bigHeight = sizes.height * 100 / sizesPercent.height;
	var bigLeft = leftFullImage - bigWidth * sizesPercent.left / 100;
	var bigTop = topFillImage - bigHeight * sizesPercent.top / 100;

	return {
		left: parseInt(bigLeft),
		top: parseInt(bigTop),
		width: parseInt(bigWidth),
		height: parseInt(bigHeight)
	};
};
