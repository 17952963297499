(function($$) {
	const CanvasUtils = $$.CanvasUtils;

	const customDefaults = {
		direction: 'left'
	};

	class SimpleSlideTransition extends $$.AbstractTransition {
		constructor(from, to, settings) {
			super(from, to, _.extend({}, customDefaults, settings));
			this._thirdCanvas = CanvasUtils.createCanvas(to.width, to.height);
		}

		_repaint(progress) {
			const from = this._bitmapFrom;
			const to = this._bitmapTo;
			const output = this._result;
			const context = output.getContext('2d');
			const direction = this._settings.direction;

			context.drawImage(from, 0, 0);

			if (direction === 'top' || direction === 'bottom') {
				context.drawImage(to, 0, to.height * (1 - progress) * (direction === 'top' ? -1 : 1));
			} else {
				context.drawImage(to, to.width * (1 - progress) * (direction === 'right' ? 1 : -1), 0);
			}
		}
	}

	$$.SimpleSlideTransition = SimpleSlideTransition;
})(window.$$ || (window.$$ = {}));
