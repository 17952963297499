/**
 * Эмиттер.
 *
 * Пример:
 *
 *   var e = new $$.Emitter();
 *
 *   e.on('event1', function() {});
 *   e.on('event2.namespace1', function() {});
 *   e.on('event2.namespace2', function() {});
 *   e.on('event3a.namespace3', function() {});
 *   e.on('event3b.namespace3', function() {});
 *   e.on('event3c.namespace3', function() {});
 *   e.emit('event1', { Some event data here... });
 *   e.emit('event2.namespace1');
 *   e.off('event1');
 *   e.off('event2.namespace1');
 *   e.off('event2.namespace2');
 *   e.off('.namespace3');
 *
 * Можно использовать более одного параметра в обработчиках событий:
 *
 *   e.on('event10', function(a, b, c) { ... });
 *   e.emit('event10', 2, 'qwe', { x: 3, y: 'zxc' });
 *
 * Пример наследования от эмиттера:
 *
 *   var TestClass = function() {
 *     $$.Emitter.call(this);
 *
 *     // ...
 *   };
 *
 *   $$.extend(TestClass, $$.Emitter);
 *
 *   _.extend(TestClass.prototype, {
 *     someMethod: function() {
 *       // ...
 *     }
 *   });
 *
 * У метода #emit есть алиас #trigger.
 *
 * Названия событий и пространств имён должны состоять только из латинских букв и цифр.
 */

/**
 * @constructor
 */
$$.Emitter = function() {
	this._itemContainer = new $$.Emitter.ItemContainer();
};

$$.Emitter.prototype = {
	/**
	 * Проверяет, является ли полный идентификатор события просто пространством имён.
	 *
	 * @param {String} eventId
	 * @return {Boolean}
	 * @private
	 */
	_isEventIdJustANamespace: function(eventId) {
		eventId = String(eventId);

		return !!eventId.match(/^\.[a-z\d]+$/i);
	},

	/**
	 * Разбирает на части полный идентификатор события.
	 *
	 * @param {String} eventId
	 * @return {Array} [eventName, namespace]
	 * @throws {Error}
	 * @private
	 */
	_parseAndValidateEventId: function(eventId) {
		eventId = String(eventId);

		// Либо просто название события.

		var match = eventId.match(/^[a-z_\-\d]+$/i);

		if (match) {
			return [match[0], null];
		}

		// Либо пространство имён + название события.

		match = eventId.match(/^([a-z_\-\d]+)\.([a-z_\-\d]+)$/i);

		if (!match) {
			throw Error(
				'Полные идентификаторы событий не могут быть пустыми,'
					+ ' должны состоять только из латинских букв и цифр'
					+ ' и могут содержать ровно одну точку в середине.)'
			);
		}

		return [match[1], match[2]];
	},

	/**
	 * �?нициирует срабатывание события.
	 *
	 * @param {String} eventId
	 */
	emit: function(eventId /*, eventData1, eventData2, ... */) {
		eventId = String(eventId);

		var parts = this._parseAndValidateEventId(eventId);
		var items = this._itemContainer.getItems(parts[0], parts[1]);
		var args = Array.prototype.slice.call(arguments, 1);

		var self = this;

		_.each(items, function(item) {
			item.callback.apply(self, args);
		});
	},

	/**
	 * Добавляет обработчик события.
	 *
	 * @param {String} eventId
	 * @param {Function} callback
	 */
	on: function(eventId, callback) {
		if (callback == null) {
			throw Error('Не передан обработчик события.');
		}

		if (!_.isFunction(callback)) {
			throw Error('Обработчик события не является функцией.');
		}

		var parts = this._parseAndValidateEventId(eventId);

		this._itemContainer.add(parts[0], parts[1], callback);
	},

	/**
	 * Удаляет обработчик события.
	 *
	 * Может удалять по названию события, по пространству имён, либо по их совокупности.
	 *
	 * @param [eventId]
	 */
	off: function(eventId) {
		if (eventId == null) {
			this._itemContainer.removeAll();
		}

		eventId = String(eventId);

		if (this._isEventIdJustANamespace(eventId)) {
			// Только пространство имён.
			this._itemContainer.remove(null, eventId.substr(1));
		} else {
			// Название события и, возможно, пространство имён.
			var parts = this._parseAndValidateEventId(eventId);
			this._itemContainer.remove(parts[0], parts[1]);
		}
	}
};

$$.Emitter.prototype.trigger = $$.Emitter.prototype.emit;

$$.Emitter.ItemContainer = function() {
	// {
	//   eventName1: {
	//     namespace1: [ { callback, *... }, ... ],
	//     namespace2: [ ... ],
	//     ...
	//   },
	//
	//   eventName2: {
	//     ...
	//   },
	//
	//   ...
	// }
	this._items = {};
};

$$.Emitter.ItemContainer.prototype = {
	/**
	 * @param {String} eventName
	 * @param {String|null} namespace
	 * @param {Function} callback
	 */
	add: function(eventName, namespace, callback) {
		eventName = String(eventName);
		namespace = namespace == null ? '*' : String(namespace);

		if (!this._items.hasOwnProperty(eventName)) {
			this._items[eventName] = {};
		}

		if (!this._items[eventName].hasOwnProperty(namespace)) {
			this._items[eventName][namespace] = [];
		}

		this._items[eventName][namespace].push({
			callback: callback
		});
	},

	/**
	 * @param {String} eventName
	 * @param {String|null} namespace
	 * @return {Array}
	 */
	getItems: function(eventName, namespace) {
		eventName = String(eventName);

		if (!this._items.hasOwnProperty(eventName)) {
			return [];
		}

		if (namespace == null) {
			// Return items for all namespaces of the event.

			var arraysOfItems = _.values(this._items[eventName]);

			return _.union.apply(null, arraysOfItems);
		}

		namespace = String(namespace);

		if (!this._items[eventName].hasOwnProperty(namespace)) {
			return [];
		}

		return this._items[eventName][namespace];
	},

	/**
	 * Удаляет по названию события, по пространству имён, либо по их совокупности.
	 *
	 * @param {String|null} eventName
	 * @param {String|null} namespace
	 */
	remove: function(eventName, namespace) {
		if (eventName == null && namespace == null) {
			throw Error('Only one of the arguments can be omitted.');
		}

		if (namespace == null) {
			this.removeByEventName(eventName);
		} else if (eventName == null) {
			this.removeByNamespace(namespace);
		} else {
			// �? "eventName", и "namespace" не null.

			eventName = String(eventName);
			namespace = String(namespace);

			if (!this._items.hasOwnProperty(eventName) || !this._items[eventName].hasOwnProperty(namespace)) {
				return;
			}

			delete this._items[eventName][namespace];
		}
	},

	/**
	 * @param {String} eventName
	 */
	removeByEventName: function(eventName) {
		eventName = String(eventName);

		if (!this._items.hasOwnProperty(eventName)) {
			return;
		}

		delete this._items[eventName];
	},

	/**
	 * @param {String} namespace
	 */
	removeByNamespace: function(namespace) {
		namespace = String(namespace);

		_.each(this._items, function(itemsByNamespace) {
			if (!itemsByNamespace.hasOwnProperty(namespace)) {
				return;
			}

			delete itemsByNamespace[namespace];
		});
	},

	/**
	 * Removes all
	 */
	removeAll: function() {
		_.each(this._items, function(itemsByNamespace, outerKey) {
			_.each(itemsByNamespace, function(item, key) {
				delete itemsByNamespace[key];
			});

			delete itemsByNamespace[outerKey];
		});
	}
};
