var $$ = $$ || {};

$$.Varinamama = class Varinamama {
	constructor (root) {
		this.root = root;
		this.uniqueHash = `portfolio-entry-varinamama-${_.uniqueId()}}`;

		this.scrollPos = 0;
		this.scrollHeight = 0;
		this.waitingForDependenciesToLoad = false;
		this._isInitialTick = true; _.defer( () => this._isInitialTick = false );

		this.isDestroyed = false;
		this.isPaused = false;

		this._cacheNodes();
		this._bindEvents();
		this._initScrollSpring();
		this.resize();

		this.nodes.loader.show();
		this.nodes.wrapper.css('opacity', 0);

		this._ready();

		this._recheckBackground = _.debounce(() => {
			BackgroundCheck.refresh();
		}, 200);
	}

	_cacheNodes () {
		this.nodes = {
			mouseWheelCatcher: $('<div/>').appendTo(this.root),
			loader: this.root.children('.js-loading'),
			sections: this.root.find('.js-section'),
			wrapper: this.root.find('.js-wrapper'),
			cards: this.root.find('.js-cards'),
			cups: this.root.find('.js-cups'),
			packing: this.root.find('.js-packing'),
			leftRecipe: this.root.find('.js-left-recipe'),
			rightRecipe: this.root.find('.js-right-recipe'),
			blanks: this.root.find('.js-blanks')
		};

		this.nodes.mouseWheelCatcher.css({
			position: 'absolute',
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
			display: 'none'
		});
	}

	_bindEvents () {
		this.nodes.mouseWheelCatcher.on('mousewheel', () => false);

		var isDetached = () => {
			if (!jQuery.contains(document.documentElement, this.root.get(0))) {
				this.destroy();
				return true;
			}

			return false;
		};

		$$.window.on('resize.' + this.uniqueHash, () => {
			if (isDetached()) {
				return
			}

			this.resize();
		});
	}

	_bindAnimations () {
		if (this.scrollMagicController) {
			throw 'Already initialized';
		} else if (this.isDestroyed) {
			return;
		}

		this.scrollMagicController = new ScrollMagic.Controller({
			container: this.nodes.wrapper.get(0),
			globalSceneOptions: {}
		});

		var visitCardsTimeLine = new TimelineMax({ onUpdate: () => { this._recheckBackground(); } })
			.from(this.nodes.cards, 1, { left: '90%', ease: Sine.easeOut });

		new ScrollMagic.Scene({
			duration: () => this.nodes.cards.height() * 0.5,
			triggerHook: 0.6,
			triggerElement: this.nodes.cards.get(0)
		})
			.setTween(visitCardsTimeLine)
			.addTo(this.scrollMagicController);

		var packingsTimeLine = new TimelineMax()
			.from(this.nodes.cups, 1, { left: '110%', ease: Sine.easeOut });

		new ScrollMagic.Scene({
			duration: () => this.nodes.packing.height() * 1.3,
			triggerHook: 0.7,
			triggerElement: this.nodes.packing.get(0)
		})
			.setTween(packingsTimeLine)
			.addTo(this.scrollMagicController);

		var blanksTimeLine = new TimelineMax()
			.from(this.nodes.leftRecipe, 1, { right: '120%', ease: Sine.easeOut })
			.from(this.nodes.rightRecipe, 1, { left: '90%', ease: Sine.easeOut });

		new ScrollMagic.Scene({
			duration: () => this.nodes.leftRecipe.height() * 0.9,
			triggerHook: 0.8,
			triggerElement: this.nodes.blanks.get(0)
		})
			.setTween(blanksTimeLine)
			.addTo(this.scrollMagicController);
	}

	_ready () {
		this.waitingForDependenciesToLoad = false;
		this._springScroller.resume();

		if (this._isInitialTick) {
			this.nodes.loader.hide();
			this.nodes.wrapper.css('opacity', 1);
		} else {
			this.nodes.loader.velocity('fadeOut');
			this.nodes.wrapper.velocity('fadeIn');
		}

		BackgroundCheck.init({
			targets: '.p-header .logo, .js-breadcrumbs, .button-switch-state-right-wrapper, .js-slash-menu',
			images: '.b-varinamama img',
			classes: {
				dark: 'background--light',
				light: 'background--dark',
				complex: 'background--complex'
			}
		});

		this._bindAnimations();
	}

	_initScrollSpring () {
		this._springScroller = new $$.SmoothScroller(this.nodes.wrapper, {
			velocityLimit: 0.35
		});

		this._updateScene();
		this._springScroller.onScroll = () => this._updateScene();
	}

	_resize () {
		this.scrollHeight = this.nodes.wrapper.prop('scrollHeight') - this.root.height();
	}

	_updateScene () {
		if (!this._scrollMagicController) {
			return;
		}

		this._scrollMagicController.update(true);
		this._recheckBackground();
	}

	destroy () {
		if (this.isDestroyed) {
			return;
		}

		this.isDestroyed = true;
		this.scrollMagicController.destroy();
		this.root.off();
		this.nodes.wrapper.off();

		$$.window.off('resize.' + this.uniqueHash);

		this.scrollMagicController = null;
		this._springScroller.callback(() => {});
		this._springScroller.destroy();
	}

	pause () {
		this.isPaused = true;
		this._springScroller.pause();
	}

	repositioned (isAnimationEnded = false) {
		if (this.isDestroyed) {
			return;
		}

		if (isAnimationEnded) {
			this.nodes.mouseWheelCatcher.hide();

			if (!this.waitingForDependenciesToLoad && !this.isPaused) {
				// а без этого ScrollMagic тупит почему-то и не хочет пересчитывать высоту triggerHook. Его собственными методами не чинится
				$$.window.trigger('resize');

				_.defer(() => {
					$$.body.addClass('black-controls');
				});
			}
		} else {
			this.nodes.mouseWheelCatcher.show();
			$$.body.removeClass('black-controls');
			this._updateScene();
		}
	}

	resume (comingFromTop) {
		this.isPaused = false;

		if (this.waitingForDependenciesToLoad || this.isDestroyed) {
			return;
		}

		this._springScroller.resume();
		this.scrollMagicController.scrollTo(comingFromTop ? 0 : this.nodes.wrapper.prop('scrollHeight'));
	}

	resize () {
		if (this.waitingForDependenciesToLoad || this.isDestroyed) {
			return;
		}

		this._springScroller.recalculate();
		this._updateScene();
	}
};
