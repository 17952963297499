var $$ = $$ || {};

$$.Contacts = class Contacts {
	constructor (root) {
		this.root = root;

		// todo MAGIC
		this.isVisible = $$.window.width() > 980;

		this._cacheNodes();
		this._createComponents();
		this._bindEvents();
	}

	_cacheNodes () {
		this.nodes = {
			toggle: this.root.find('.js-toggle-form-button'),
			arrow: this.root.find('.js-arrow'),
			openFeedbackInMenuButton: $('.js-feedback-button-link'),
			openFeedbackButton: $('.js-open-feedback-contacts'),
			closeButton: $('.js-close-feedback'),

			feedbackForm: this.root.find('.js-feedback-form')
		};
	}

	_createComponents () {
		this.feedbackForm = new $$.FeedbackForm(this.nodes.feedbackForm);

		this.nodes.feedbackForm.perfectScrollbar();
	}

	_bindEvents () {
		this.nodes.openFeedbackInMenuButton.on('click', () => {
			this._closeForm();
		});

		this.nodes.toggle.on('click', () => {
			this._toggleForm();
		});

		this.nodes.openFeedbackButton.on('click', () => {
			this._openForm();
		});

		this.nodes.closeButton.on('click', () => {
			this._closeForm();
		});

		$$.window.off('resize.contacts');
		$$.window.on('resize.contacts', () => {
			if (!this.isVisible) {
				this.root.css({
					left: -this.root.width()
				});
			}
		});
	}

	_toggleForm () {
		if (this.isVisible) {
			this._closeForm();
		} else {
			this._openForm();
		}
	}

	_closeForm () {
		this.isVisible = false;

		this.root
			.removeClass('active')
			.velocity('stop')
			.velocity({
				left: -this.root.width()
			},
			200,
			'linear',
			() => {
				this.nodes.arrow.addClass('to-right');
			}
		);
	}

	_openForm () {
		this.isVisible = true;

		this.root
			.addClass('active')
			.velocity('stop')
			.velocity({
				left: 0
			},
			200,
			'linear',
			() => {
				this.nodes.arrow.removeClass('to-right');
			}
		);
	}
}
