var $$ = $$ || {};

$$.TextBlock = class TextBlock {
	constructor (root) {
		this.root = root;

		this._cacheNodes();
		this._bindEvents();
		this._ready();
	}

	_cacheNodes () {
		this.nodes = {
			inner: this.root.parents('.b-content-blocks')
		};
	}

	/**
	 * Вешает обработчики событий на компоненты/элементы.
	 *
	 * @private
	 */
	_bindEvents () {
		if ($$.isMobile) {
			$$.window.on('resize.textblock', () => {
				this.root.css({
					"font-size": '24rem',
					"line-height": '33rem'
				});

				this._resize()
			});
		}
	}

	_ready () {
		if ($$.isMobile) {
			this._resize();
		}
	}

	_resize () {
		setTimeout(() => {
			if (this.nodes.inner.position().top < 60) {
				this.root.css({
					"font-size": '-=1px',
					"line-height": '1.1em'
				});

				this._resize();
			}
		}, 0);
	}
};
