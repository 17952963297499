var $$ = $$ || {};

$$.VideoFeedback = class VideoFeedback {
	constructor (root) {
		this.root = root;

		this.type = "";
		this.player = null;

		this._cacheNodes();
		this._bindEvents();
		this._ready();
	}

	_cacheNodes () {
		this.nodes = {
			siteHeader: $('.p-header'),
			siteGamburger: $('.js-button-switch-state-right-wrapper'),
			siteCategoryControls: $('.category-controls'),
			inner: this.root.parents('.b-content-blocks'),
			leftPart: this.root.find('.js-left-part'),
			rightPart: this.root.find('.js-right-part'),
			overlay: this.root.find('.js-video-overlay'),
			playButton: this.root.find('.js-play'),
			close: this.root.find('.js-close'),
			player: $()
		};
	}

	/**
	 * Вешает обработчики событий на компоненты/элементы.
	 *
	 * @private
	 */
	_bindEvents () {
		$$.window.on('resize.videofeedback', () => {
			this.nodes.player.css({
				width: this.nodes.inner.width(),
				height: this.nodes.inner.height()
			});
		});

		this.nodes.playButton.on('click', () => {
			if (!this.nodes.leftPart.hasClass('active')) {
				this.nodes.close.addClass('active');
				this.nodes.leftPart.addClass('active');
				this.nodes.rightPart.addClass('active');
				this.nodes.overlay.fadeOut();
				this.nodes.playButton.fadeOut();
				this.nodes.siteHeader.fadeOut();
				this.nodes.siteGamburger.fadeOut();
				this.nodes.siteCategoryControls.fadeOut();

				this._playVideo();
			}
		});

		this.nodes.close.on('click', () => {
			this.nodes.close.removeClass('active');
			this.nodes.leftPart.removeClass('active');
			this.nodes.rightPart.removeClass('active');
			this.nodes.overlay.fadeIn();
			this.nodes.playButton.fadeIn();
			this.nodes.siteHeader.fadeIn();
			this.nodes.siteGamburger.fadeIn();
			this.nodes.siteCategoryControls.fadeIn();

			this._pauseVideo();
		});
	}

	_ready () {
		this.type = this.root.data('video-type');
		this.id = this.root.data('video-id');

		this.nodes.inner.css({
			width: '100%',
			height: '100%'
		});

		this.nodes.player = $(this._makeVideoPlayerHtml(this.type,
			this.id,
			this.nodes.inner.width(),
			this.nodes.inner.height(),
			this.root.data('color')
		));

		this.nodes.leftPart.prepend(this.nodes.player);

		this._initApi(this.nodes.player);
	}

	_makeVideoPlayerHtml (videoType, videoId, width, height, color) {
		if (videoType === 'youtube') {
			return '<iframe id="feedback" class="player" type="text/html"'
				+ ' width="' + width + '" height="' + height + '" src="'
				+ '//www.youtube.com/embed/' + videoId + '?autoplay=0&amp;rel=0&amp;controls=0&amp;showinfo=0&amp;enablejsapi=1'
				+ '" frameborder="0" wmode="opaque" autoplay="false"></iframe>';
		} else if (videoType === 'vimeo') {
			return '<iframe class="player" width="' + width + '" height="' + height + '" src="'
				+ '//player.vimeo.com/video/' + videoId + '?api=1&autoplay=0&loop=0&title=0&byline=0&portrait=0' + (color ? '&color=' + color.slice(1) : '')
				+ '" frameborder="0"></iframe>';
		}

		return '';
	}

	_playVideo () {
		if (this.type === 'vimeo') {
			this.player.api("play");
		}
	}

	_pauseVideo () {
		if (this.type === 'vimeo') {
			this.player.api("pause");
		}
	}

	_initApi (element) {
		if (this.type === 'vimeo') {
			this.player = $f(element.get(0));
		}
	}

	pause () {
		if (this.nodes.leftPart.hasClass('active')) {
			this.nodes.siteHeader.fadeIn();
			this.nodes.siteGamburger.fadeIn();
			this.nodes.siteCategoryControls.fadeIn();

			this._pauseVideo();
		}

	}

	resume () {
		if (this.nodes.leftPart.hasClass('active')) {
			this.nodes.siteHeader.fadeOut();
			this.nodes.siteGamburger.fadeOut();
			this.nodes.siteCategoryControls.fadeOut();

			this._playVideo();
		}
	}
};
