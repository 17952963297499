var $$ = $$ || {};

$$.Artresidence = class Artresidence {
	constructor(root) {
		this.root = root;
		this.uniqueHash = `portfolio-entry-artresidence-${_.uniqueId()}}`;

		this.currentBackgroundTopPosition = 0;
		this.mobileOffsetRatioPosition = null;
		this.waitingForDependenciesToLoad = false;
		this.mobileContentIsScrolling = false;
		this.mobileContentIsScrolled = false;
		this.isDestroyed = false;
		this._scrollMagicController = null;
		this.isPaused = false;

		this._cacheNodes();
		this._bindEvents();
		this._initScrollSpring();
		this.resize();

		this._loadDependencies(() => {
			this._ready();
		});

		this._recheckBackground = _.throttle(() => {
			BackgroundCheck.refresh();
		}, 200);
	}

	_cacheNodes () {
		this.nodes = {
			mouseWheelCatcher: $('<div/>').appendTo(this.root),
			wrapper: this.root.find('.js-wrapper'),
			logo: this.root.find('.js-start-inner-logo'),
			startContent: this.root.find('.js-start-content'),
			laptop: this.root.find('.js-laptop'),
			contentInner:  this.root.find('.js-content-inner'),
			pad: this.root.find('.js-pad'),
			ringsWrapper: this.root.find('.js-rings-wrapper'),
			bottomRing: this.root.find('.js-bottom-ring'),
			phone: this.root.find('.js-phone'),
			mobile: this.root.find('.js-mobile'),
			videoWrapper: this.root.find('.js-video-wrapper')
		};

		this.nodes.mouseWheelCatcher.css({
			position: 'absolute',
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
			display: 'none'
		});
	}

	_bindEvents () {
		this.nodes.mouseWheelCatcher.on('mousewheel', () => false);

		var isDetached = () => {
			if (!jQuery.contains(document.documentElement, this.root.get(0))) {
				this.destroy();
				return true;
			}

			return false;
		};

		$$.window.on('resize.' + this.uniqueHash, () => {
			if (isDetached()) {
				return
			}

			this.nodes.contentInner.css('padding', $$.windowWidth * 0.0412 + 'px');
			this.resize();
		});

		this.nodes.wrapper.on('mousewheel', (event) => {
			if (this.mobileContentIsScrolling) {
				if (_.isNull(this.mobileOffsetRatioPosition)) {
					this.mobileOffsetRatioPosition = this.scrollSpring._spring.target();
				}

				if (event.deltaY > 0) {
					this._mobileContentScrolling();
				} else {
					this._mobileContentScrolling(true);
				}
			}
		});
	}

	_mobileContentScrolling (revert) {
		this.currentBackgroundTopPosition = this.currentBackgroundTopPosition + 12 * (revert ? -1 : 1);

		if (this.currentBackgroundTopPosition <= -213) {
			this.currentBackgroundTopPosition = -213;
			this.mobileContentIsScrolling = false;
			this.mobileContentIsScrolled = true;
			this.scrollSpring._spring.target(this.mobileOffsetRatioPosition);
			this.mobileOffsetRatioPosition = null;
			this.scrollSpring.resume();
		} else if (this.currentBackgroundTopPosition >= 0) {
			this.currentBackgroundTopPosition = 0;
			this.mobileContentIsScrolling = false;
			this.mobileContentIsScrolled = true;
			this.scrollSpring._spring.target(this.mobileOffsetRatioPosition);
			this.mobileOffsetRatioPosition = null;
			this.scrollSpring.resume();
		} else {
			this.mobileContentIsScrolling = true;
		}

		this.nodes.mobile.stop().animate({
			top: + this.currentBackgroundTopPosition + '%'
		});
	}

	_loadDependencies (callback = () => {}) {
		var dependencies = this.root.data('dependencies');

		try { dependencies = $.parseJSON(dependencies); }
		catch (e) { dependencies = []; }

		if (_.isArray(dependencies) && dependencies.length) {
			$script.order(dependencies, callback);
		} else {
			callback();
		}
	}

	_bindAnimations () {
		if (this.scrollMagicController) {
			throw 'Already initialized';
		} else if (this.isDestroyed) {
			return;
		}

		this.scrollMagicController = new ScrollMagic.Controller({
			container: this.nodes.wrapper.get(0),
			globalSceneOptions: {}
		});

		var logoTimeLine =  new TimelineMax();

		logoTimeLine.add(TweenMax.from(this.nodes.logo, 1, {
			right: '0%',
			ease: Sine.easeOut
		}));

		new ScrollMagic.Scene({
			duration: () => $$.windowHeight / 4,
			triggerHook: 0.4,
			triggerElement: this.nodes.logo.get(0),
			tweenChanges: true
		})
			.setTween(logoTimeLine)
			.addTo(this.scrollMagicController);

		var contentTimeLine = new TimelineMax();

		contentTimeLine.add(TweenMax.from(this.nodes.startContent, 1, {
			width: '0%',
			height: '0%',
			ease: Sine.easeOut
		}));

		this.startContentScrollMagicScene = new ScrollMagic.Scene({
			duration: () => $$.windowHeight / 4,
			triggerHook: 0.35,
			triggerElement: this.nodes.startContent.get(0),
			tweenChanges: true
		})
			.setTween(contentTimeLine)
			.addTo(this.scrollMagicController);

		var contentInnerTimeLine = new TimelineMax();

		contentInnerTimeLine.add(TweenMax.from(this.nodes.contentInner, 1, {
			onStart: _.bind(function () {
				this.nodes.contentInner.css('opacity', 1);
			}, this),
			onReverseComplete : _.bind(function () {
				this.nodes.contentInner.css('opacity', 0);
			}, this),
			onUpdate: _.bind(function () {
					var value = Math.max($$.windowWidth * 0.0206, $$.windowWidth * 0.0412
							* this.contentInnerPaddingScrollMagicScene.progress());

					this.nodes.contentInner.css('padding', value + 'px');
			}, this),
			padding:  $$.windowWidth * 0.0206 + 'px',
			ease: Sine.easeOut
		}));

		this.contentInnerPaddingScrollMagicScene = new ScrollMagic.Scene({
			duration: () => $$.windowHeight / 4,
			triggerHook: 0.4,
			triggerElement: this.nodes.contentInner.get(0),
			tweenChanges: true
		})
		.setTween(contentInnerTimeLine)
		.addTo(this.scrollMagicController);

		var laptopTimeLine = new TimelineMax();

		laptopTimeLine.add(TweenMax.from(this.nodes.laptop, 1, {
			right: '-150vw',
			top: '175%',
			ease: Sine.easeOut }));

		new ScrollMagic.Scene({
			duration: () => $$.windowHeight / 3,
			triggerHook: 0.53,
			triggerElement: this.nodes.laptop.get(0),
			tweenChanges: true
		})
			.setTween(laptopTimeLine)
			.addTo(this.scrollMagicController);

		var padTimeLine = new TimelineMax();

		padTimeLine.add(TweenMax.from(this.nodes.pad, 1, {
			top: '12vw',
			ease: Sine.easeOut
		}));

		new ScrollMagic.Scene({
			duration: () => $$.windowHeight,
			triggerHook: 1,
			triggerElement: this.nodes.pad.get(0),
			tweenChanges: true
		})
			.setTween(padTimeLine)
			.addTo(this.scrollMagicController);

		var ringsWrapperTimeLine = new TimelineMax();

		ringsWrapperTimeLine.add(TweenMax.from(this.nodes.ringsWrapper, 1, {
			left: '-8.4%',
			top: '0vw',
			ease: Sine.ease
		}));

		new ScrollMagic.Scene({
			duration: () => $$.windowHeight / 4,
			triggerHook: 0.5,
			triggerElement: this.nodes.ringsWrapper.get(0),
			tweenChanges: true
		})
			.setTween(ringsWrapperTimeLine)
			.addTo(this.scrollMagicController);

		var bottomRingTimeLine = new TimelineMax();
		var videoLayout = '<iframe src="https://player.vimeo.com/video/92729847?autoplay=1&loop=1&color=ffd737&title=0&byline=0&portrait=0" width="100%" height="100%" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>'

		bottomRingTimeLine.add(TweenMax.from(this.nodes.bottomRing, 1, {
			onComplete: _.bind(function () {
			    this.nodes.bottomRing.html(videoLayout);
			}, this),
			onUpdate: _.bind(function () {
			    this.nodes.bottomRing.html('');
			}, this),
			width: '33.3%',
			height: '48%',
			ease: Sine.ease }));

		new ScrollMagic.Scene({
			duration: () => $$.windowHeight / 4,
			triggerHook: 0.5,
			triggerElement: this.nodes.bottomRing.get(0),
			tweenChanges: true
		})
			.setTween(bottomRingTimeLine)
			.addTo(this.scrollMagicController);
	}

	_ready () {
		this.waitingForDependenciesToLoad = false;

		this.scrollSpring.resume();

		try {
			BackgroundCheck.init({
				targets: '.p-header .logo, .js-breadcrumbs, .button-switch-state-right-wrapper, .js-slash-menu',
				images: '.b-artresidence img',
				classes: {
					dark: 'background--light',
					light: 'background--dark',
					complex: 'background--complex'
				}
			});

			_.defer(function() { BackgroundCheck.refresh(); })
		} catch(e) {}

		this._bindAnimations();
	}

	_initScrollSpring () {
		this.scrollSpring = new $$.SmoothScroller(this.nodes.wrapper, {
			velocityLimit: 0.35
		}, {
			stepSize: 50
		});

		const self = this;

		this.scrollSpring.pause();
		this.scrollSpring.onScroll = () => this._updateScene();

		this.scrollSpring._spring.step(function () {
			let oldScrollPos = self.scrollSpring._scrollPos;

			// Высчитываем значение прокрутки
			if (!self.mobileContentIsScrolling) {
				self.scrollSpring._scrollPos = ~~(this.position() * self.scrollSpring._scrollHeight);
			}

			// Если нет фрейма в очереди - ставим
			if (self.scrollSpring._scrollAnimationFrameHandler !== null || oldScrollPos === self.scrollSpring._scrollPos
					|| self.mobileContentIsScrolling) {
				return;
			}

			if (!self.mobileContentIsScrolled
					&& self.nodes.phone.get(0).getBoundingClientRect().top / $$.windowHeight > 0.28
					&& self.nodes.phone.get(0).getBoundingClientRect().top / $$.windowHeight < 0.32) {
				self.scrollSpring.pause();
				self.mobileContentIsScrolling = true;
			} else {
				if (self.nodes.phone.get(0).getBoundingClientRect().top / $$.windowHeight <= 0.28) {
					self.mobileContentIsScrolled = false;
				}

				if (self.nodes.phone.get(0).getBoundingClientRect().top / $$.windowHeight >= 0.32) {
					self.mobileContentIsScrolled = false;
				}

				self.scrollSpring._scrollAnimationFrameHandler = requestAnimationFrame(function () {
					self.scrollSpring._scrollAnimationFrameHandler = null;
					self.nodes.wrapper.get(0).scrollTop = self.scrollSpring._scrollPos;
					self.scrollSpring._lastSetScrollPos = self.scrollSpring._scrollPos;
					self.scrollSpring.onScroll();
				});
			}
		});

		var _onceGetStartContentOffset = _.once(_.bind(function () {
			this.startContentInitialOffset = this.nodes.startContent.get(0).getBoundingClientRect().top
					/ $$.windowHeight;

			this.startContentScrollMagicScene.triggerHook(this.startContentInitialOffset.toFixed(2) - 0.005);
			this.contentInnerPaddingScrollMagicScene.triggerHook(this.startContentInitialOffset.toFixed(2) - 0.005);
		}, this));

		this.nodes.wrapper.on('scroll', () => {
			_onceGetStartContentOffset();
		});
	}

	_updateScene () {
		if (!this.scrollMagicController) {
			return;
		}

		this.scrollMagicController.update(true);
		this._recheckBackground();
	}

	destroy () {
		if (this.isDestroyed) {
			return;
		}

		this.isDestroyed = true;
		this.scrollMagicController.destroy();
		this.root.off();
		this.nodes.wrapper.off();

		$$.window.off('resize.' + this.uniqueHash);

		this.scrollMagicController = null;
		this.scrollSpring.callback(() => {});
		this.scrollSpring.destroy();
	}

	pause () {
		this.isPaused = true;
		this.scrollSpring.pause();
	}

	repositioned (isAnimationEnded = false) {
		if (this.isDestroyed) {
			return;
		}

		if (isAnimationEnded) {
			this.nodes.mouseWheelCatcher.hide();

			if (!this.waitingForDependenciesToLoad && !this.isPaused) {
				// а без этого ScrollMagic тупит почему-то и не хочет пересчитывать высоту triggerHook. Его собственными методами не чинится
				$$.window.trigger('resize');
			}
		} else {
			this.nodes.mouseWheelCatcher.show();
			$$.body.removeClass('black-controls');
			this._updateScene();
		}
	}

	resume (comingFromTop) {
		this.isPaused = false;

		if (this.waitingForDependenciesToLoad || this.isDestroyed) {
			return;
		}

		this.scrollSpring.resume();
		this.scrollMagicController.scrollTo(comingFromTop ? 0 : this.nodes.wrapper.prop('scrollHeight'));
	}

	resize () {
		if (this.waitingForDependenciesToLoad || this.isDestroyed) {
			return;
		}

		this.scrollSpring.recalculate();
		this._updateScene();
	}
};
