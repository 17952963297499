var $$ = $$ || {};

$$.ApplesSimple = class ApplesSimple {
	constructor (root) {
		this.root = root;

		// Переменные и флаги

		this._hash = 'portfolio-entry-apples-simple-' + _.uniqueId();
		this._scrollHeight = 0;
		this._paused = false;
		this._destroyed = false;
		this._scrollMagicController = _.noop();

		this._isInitialTick = true; _.defer( () => this._isInitialTick = false );
		this._waitingForDependenciesToLoad = true;

		this.animationOptions = {
			duration: 1000, //milliseconds
			easing: 'ease-in-out', //'linear', a bezier curve, etc.
			//delay: 10, //milliseconds
			//iterations: Infinity, //or a number
			//direction: 'alternate', //'normal', 'reverse', etc.
			fill: 'both' //'backwards', 'forwards', 'none', 'auto'
		};

		this.animations = {
			section: []
		};

		this.lastScrollPosition = 0;

		// Инициализируем

		this._cacheNodes();
		this._bindEvents();

		this.resize();

		this.nodes.loader.show();
		this.nodes.payload.css('opacity', 0);

		$script.ready('vendor-postponed', () => {
			this._ready();
		});
	}

	_cacheNodes () {
		let payload = this.root.children('.apples-payload');

		this.nodes = {
			loader: this.root.children('.js-loading'),
			payload: payload,
			mouseWheelCatcher: $('<div/>').appendTo(this.root),

			section0: payload.children('.section0'),
			s0Inner: payload.find('.section0 .section-inner'),
			s0Leaves: payload.find('.section0 .leaves'),
			s0Macbook: payload.find('.section0 .macbook'),
			s0Ipad: payload.find('.section0 .ipad'),

			s1Video: payload.find('.section1 .video-banner'),

			section2: payload.children('.section2'),
			s2InnerContainer: payload.find('.section2 .images-container'),
			s2Fruits: payload.find('.section2 .fruits'),
			s2Monitors: payload.find('.section2 .monitors'),
			s2PinTarget: payload.find('.section2 .pin-target'),

			section3: payload.find('.section3 .screenshot-block'),
			s3InnerContainer: payload.find('.section3 .screenshot-block'),
			s3Raspberry: payload.find('.section3 .raspberry'),

			section4: payload.find('.section4 .screenshot-block'),
			s4InnerContainer: payload.find('.section4 .screenshot-block'),
			s4Bottole1: payload.find('.section4 .red-bottle'),
			s4Bottole2: payload.find('.section4 .orange-bottle'),
			s4Apples: payload.find('.section4 .apples'),

			s5Video: payload.find('.section5 .video-banner'),

			section6: payload.children('.section6'),
			s6IPad: payload.find('.section6 .ipad'),
			s6Hand: payload.find('.section6 .hand'),
			s6PinTarget: payload.find('.section6 .pin-target')
		};

		this.nodes.mouseWheelCatcher.css({
			position: 'absolute',
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
			display: 'none'
		});
	}

	/**
	 * Вешает обработчики событий на компоненты/элементы.
	 *
	 * @private
	 */
	_bindEvents () {
		this.nodes.s2Monitors.on('load', () => this.resize() );
		this.nodes.s6IPad.on('load', () => this.resize() );
		this.nodes.mouseWheelCatcher.on('mousewheel', () => false);

		this.root.on('mousewheel', (event) => {
			if (this._waitingForDependenciesToLoad) {
				event.stopPropagation();
			}
		});

		this.nodes.payload
			.on('mousewheel', (event) => {
				let scrollValue = this.nodes.payload.prop('scrollTop');

				if ( (event.deltaY > 0 && scrollValue > 0) || (event.deltaY < 0 && scrollValue < this._scrollHeight) ) {
					event.stopPropagation();
				} else if ( (event.deltaY > 0 && scrollValue <= 0) || (event.deltaY < 0 && scrollValue >= this._scrollHeight) ) {
					this._animationPropagation(-1, 1);
				}
			})
			.on('scroll', () => {
				let scrollValue = this.nodes.payload.prop('scrollTop');
				let deltaY = 0;

				(scrollValue > this.lastScrollPosition ? deltaY = -1 : deltaY = 1)

				if ( (deltaY > 0 && scrollValue > 0) || (deltaY < 0 && scrollValue < this._scrollHeight) ) {
					this._animationPropagation(scrollValue, deltaY);
				}

				this.lastScrollPosition = scrollValue;
			});

		let isDetached = () => {
			if (!jQuery.contains(document.documentElement, this.root.get(0))) {
				this.destroy();
				return true;
			}

			return false;
		};

		$$.window.on('resize.' + this._hash, () => {
			if (isDetached()) {
				return
			}

			this.resize();
		});

		this.nodes.s1Video.click(function() {
			if ($(this).hasClass('hasPlayer')) {
				return;
			}

			$(this).addClass('has-player');
		});
	}

	_initAnimations () {
		let postWindow = 0;

		this.nodes.payload.children('section').each((index, event) => {
			this.animations.section[index] = {
				startPosition: $(event).position().top
			};
		});

		// section 0
		this.animations.section[0].leaves = this.nodes.s0Leaves[0].animate([
				{ transform: "translateX(-25%)", opacity: 0 },
				{ transform: "translateX(0)", opacity: 1 }
			],
			_.assign(this.animationOptions, { duration: 1800, delay: 250 })
		);

		this.animations.section[0].leaves.pause();

		postWindow = this.nodes.s0Inner[0].offsetLeft + this.nodes.s0Macbook[0].offsetLeft + this.nodes.s0Macbook[0].width;

		this.animations.section[0].macbook = this.nodes.s0Macbook[0].animate([
				{ transform: "translateX("+ -postWindow +"px)" },
				{ transform: "translateX(0)" }
			],
			_.assign(this.animationOptions, { duration: 1200, delay: 350 })
		);

		this.animations.section[0].macbook.pause();

		postWindow = this.nodes.s0Inner[0].offsetLeft + this.nodes.s0Ipad[0].offsetLeft + this.nodes.s0Ipad[0].width;

		this.animations.section[0]['ipad'] = this.nodes.s0Ipad[0].animate([
				{ transform: "translateX("+ postWindow +"px)" },
				{ transform: "translateX(0)" }
			],
			_.assign(this.animationOptions, { duration: 1200, delay: 350 })
		);

		this.animations.section[0].ipad.pause();

		// section 1

		// section 2
		postWindow = this.nodes.s2InnerContainer[0].offsetLeft + this.nodes.s2Fruits[0].offsetLeft + this.nodes.s2Fruits[0].width;

		this.animations.section[2].fruits = this.nodes.s2Fruits[0].animate([
				{ transform: "translateX("+ -postWindow +"px)" },
				{ transform: "translateX(0)" }
			],
			_.assign(this.animationOptions, { })
		);

		this.animations.section[2].fruits.pause();

		postWindow = this.nodes.s2InnerContainer[0].offsetLeft + this.nodes.s2Monitors[0].offsetLeft + this.nodes.s2Monitors[0].width;

		this.animations.section[2].monitors = this.nodes.s2Monitors[0].animate([
				{ transform: "translateX("+ postWindow +"px)" },
				{ transform: "translateX(0)" }
			],
			_.assign(this.animationOptions, { })
		);

		this.animations.section[2].monitors.pause();

		// section 3
		postWindow = this.nodes.s3InnerContainer[0].offsetLeft + this.nodes.s3Raspberry[0].offsetLeft + this.nodes.s3Raspberry[0].width;

		this.animations.section[3].raspberry = this.nodes.s3Raspberry[0].animate([
				{ transform: "translateX("+ -postWindow +"px)" },
				{ transform: "translateX(0)" }
			],
			_.assign(this.animationOptions, { })
		);

		this.animations.section[3].raspberry.pause();

		// section 4
		this.animations.section[4].bottole1 = this.nodes.s4Bottole1[0].animate([
			{ transform: "translateX("+ -(this.nodes.s4Bottole1[0].width * 3) +"px)" },
			{ transform: "translateX(0)" }
		],
			_.assign(this.animationOptions, { })
		);

		this.animations.section[4].bottole1.pause();

		this.animations.section[4].bottole2 = this.nodes.s4Bottole2[0].animate([
			{ transform: "translateX("+ this.nodes.s4Bottole2[0].width +"px)" },
			{ transform: "translateX(0)" }
		],
			_.assign(this.animationOptions, { })
		);

		this.animations.section[4].bottole2.pause();

		this.animations.section[4].apples = this.nodes.s4Apples[0].animate([
			{ transform: "translateX("+ -this.nodes.s4Apples[0].width +"px)" },
			{ transform: "translateX(0)" }
		],
			_.assign(this.animationOptions, { })
		);

		this.animations.section[4].apples.pause();

		// section 5

		// section 6
		postWindow = this.nodes.s6Hand[0].offsetLeft + this.nodes.s6Hand[0].width;

		this.animations.section[6].hand = this.nodes.s6Hand[0].animate([
				{ transform: "translateX("+ postWindow +"px)", opacity: 0 },
				{ transform: "translateX("+ (postWindow / 2) +"px)", opacity: 1 },
				{ transform: "translateX(0)", opacity: 1 }
			],
			_.assign(this.animationOptions, {

			})
		);

		this.animations.section[6].hand.pause();

		this.animations.section[6].ipad = this.nodes.s6IPad[0].animate([
				{ transform: "translateX(500px)", opacity: 0 },
				{ transform: "translateX(200px)", opacity: 1 },
				{ transform: "translateX(0)", opacity: 1 }
			],
			_.assign(this.animationOptions, {
				duration: 800
			})
		);

		this.animations.section[6].ipad.pause();

		// end
		setTimeout(() => {
			this._animationPropagation(0, -1);
		}, 500);
	}

	_animationPropagation (scrollValue, deltaY) {
		_.forEach(this.animations.section, (oject, sectionId) => {
			if ( scrollValue >= this.animations.section[sectionId]['startPosition'] && deltaY < 0 ) {
				_.forEach(this.animations.section[sectionId], (value, animationObject) => {
					if ( this.animations.section[sectionId][animationObject].playState  ) {

						if ( this.animations.section[sectionId][animationObject].playState === "paused" ) {
							this.animations.section[sectionId][animationObject].play();
						}

						if ( this.animations.section[sectionId][animationObject].playbackRate < 0 ) {
							this.animations.section[sectionId][animationObject].playbackRate = 1;
						}
					}
				});
			} else if ( scrollValue < this.animations.section[sectionId]['startPosition'] && deltaY > 0 ) {
				_.forEach(this.animations.section[sectionId], (value, animationObject) => {
					if ( this.animations.section[sectionId][animationObject].playState  ) {

						if ( this.animations.section[sectionId][animationObject].playState === "paused" ) {
							this.animations.section[sectionId][animationObject].reverse();
						}

						if ( this.animations.section[sectionId][animationObject].playbackRate > 0 ) {
							this.animations.section[sectionId][animationObject].playbackRate = -1;
						}
					}
				});
			}
		});
	}

	_ready () {
		this._scrollHeight = this.nodes.payload[0].scrollHeight - $$.windowHeight;

		this._waitingForDependenciesToLoad = false;

		//this._springScroller.resume();

		// Если вызвано в тот же тик, что и конструктор - ничего не анимируем.
		if (this._isInitialTick) {
			this.nodes.loader.hide();
			this.nodes.payload.css('opacity', 1);
		} else {
			this.nodes.loader.velocity('fadeOut');
			this.nodes.payload.velocity('fadeIn');
		}

		this._initAnimations();
	}

	_updateScene () {
		if (!this._scrollMagicController) {
			return;
		}

		this._scrollMagicController.update(true);
	}

	destroy () {
		if (this._destroyed) {
			return;
		}

		this._destroyed = true;
		this._scrollMagicController.destroy();
		this.root.off();
		this.payload.off();

		$$.window.off('resize.' + this._hash);

		this._scrollMagicController = _.noop();

		//this._springScroller.destroy();
		//this._springScroller = _.noop();
	}

	pause () {
		this._paused = true;

		if (this._waitingForDependenciesToLoad) {
			return;
		}

		//this._springScroller.pause();
	}

	/**
	 * Эта функция должна вызываться извне и уведомлять нас, что позиция слайда на экране изменилась
	 *
	 * @param animationEnded Закончилась ли анимация. На самом деле вызывается только, когда мы и есть слайд к которому осуществлялся переход
	 */
	repositioned (animationEnded = false) {
		if (this._destroyed) {
			return;
		}

		if (animationEnded) {
			this.nodes.mouseWheelCatcher.hide();
			if (!this._waitingForDependenciesToLoad && !this._paused) {
				// а без этого ScrollMagic тупит почему-то и не хочет пересчитывать высоту triggerHook. Его собственными методами не чинится
				$$.window.trigger('resize');
			}

			_.defer(function() {
				$$.body.addClass('black-controls');
			});
		} else {
			this.nodes.mouseWheelCatcher.show();
			$$.body.removeClass('black-controls');
			this._updateScene();
		}
	}

	resize () {
		if (this._waitingForDependenciesToLoad || this._destroyed) {
			return;
		}

		//this._springScroller.recalculate();
		this._updateScene();
	}

	resume (comingFromTop) {
		if ( comingFromTop ) {
			this._animationPropagation(0, -1);
		} else {
			this._animationPropagation(this._scrollHeight, -1);
		}

		this._paused = false;

		if (this._waitingForDependenciesToLoad || this._destroyed) {
			return;
		}

		//this._springScroller.resume();
		//this._scrollMagicController.scrollTo(comingFromTop ? 0 : this.nodes.payload.prop('scrollHeight'));
	}
};
