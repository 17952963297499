$$.makeVideoPlayerHtml = function (videoType, videoId, width, height, autoplay) {
	autoplay = autoplay ? 1 : 0;

	if (videoType == 'youtube') {
		return '<iframe class="youtube-player" type="text/html"'
			+ ' width="' + width + '" height="' + height + '" src="'
			+ 'http://www.youtube.com/embed/' + videoId + '?autoplay=' + autoplay + '&rel=0&amp;controls=0&amp;showinfo=0'
			+ '" frameborder="0" wmode="opaque" autoplay="false"></iframe>';
	} else if (videoType == 'vimeo') {
		return '<iframe wmode="opaque" width="' + width + '" height="' + height + '" src="'
			+ 'http://player.vimeo.com/video/' + videoId + '?autoplay=' + autoplay
			+ '" frameborder="0" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>';
	}

	return '';
};
