const $$ = window.$$ || (window.$$ = {});
//import BaseProjectPageClass from '_baseWork.js';

$$.Playsmart = class Playsmart extends $$.BaseProjectPageClass {
	constructor (root) {
		super(root, 'portfolio-entry-playsmart-');

		this.isMobile = this.root.data('mobile') !== '';

		this._useSmallScrollStep = true;
		this._scrollStep = 100;
	}

	_cacheNodes () {
		super._cacheNodes();

		const payload = this.nodes.payload;

		$.extend(this.nodes, {
			sectionInner:          payload.find('.section-inner'),
			// section #1
			section1:              payload.find('.section-1'),
			section1Inner:         payload.find('.section-1 .section-inner'),
			section1MainLogo:      payload.find('.section-1 .b-logo'),
			section1LeftLogo:      payload.find('.section-1 .b-logo > .logo-left'),
			section1RightLogo:     payload.find('.section-1 .b-logo > .logo-right'),
			section1Logo:          payload.find('.section-1 .b-logo .logo'),
			section1LogoLeftLogo:  payload.find('.section-1 .b-logo .logo .logo-left'),
			section1LogoRightLogo: payload.find('.section-1 .b-logo .logo .logo-right'),

			section2:               payload.find('.section-2'),
			section2ImageWrap:      payload.find('.section-2 .image-wrap'),
			section2ImageWrapBg:    payload.find('.section-2 .image-wrap .bg'),
			section2ImageWrapFront: payload.find('.section-2 .image-wrap .front'),


			sectionTest: payload.find('.section-test')
		});
	}

	_ready () {
		this.nodes.payload.imagesLoaded({
			background: true
		})
			.always(() => {
				super._ready();
			});
	}

	_bindEvents () {
		super._bindEvents();
	}

	_bindAnimationSequence () {
		let timeline;
		let scene;
		let pageHeight = $$.window.height();

		if (this.isMobile) {
			return;
		}

		this.nodes.section1.height(pageHeight);
		this.nodes.sectionInner.height(pageHeight);

		new ScrollMagic.Scene({
			offset:         0,
			triggerHook:    0,
			triggerElement: this.nodes.section1.get(0)
		})
			.setClassToggle(this.nodes.section1.get(0), "animation-1")
			.addTo(this._scrollMagicController);

		new ScrollMagic.Scene({
			duration:       pageHeight,
			tweenChanges:   true,
			triggerHook:    0,
			triggerElement: this.nodes.section1.get(0)
		})
			.setPin(this.nodes.section1.get(0))
			.addTo(this._scrollMagicController);

		new ScrollMagic.Scene({
			offset:         pageHeight * 1.2,
			tweenChanges:   true,
			triggerHook:    1,
			triggerElement: this.nodes.section1.get(0)
		})
			.setClassToggle(this.nodes.section1.get(0), "animation-2")
			.addTo(this._scrollMagicController);

		new ScrollMagic.Scene({
			offset:         pageHeight * 1.5,
			tweenChanges:   true,
			triggerHook:    1,
			triggerElement: this.nodes.section1.get(0)
		})
			.setClassToggle(this.nodes.section1.get(0), "animation-3")
			.addTo(this._scrollMagicController);

		new ScrollMagic.Scene({
			offset:         pageHeight * 1.8,
			tweenChanges:   true,
			triggerHook:    1,
			triggerElement: this.nodes.section1.get(0)
		})
			.setClassToggle(this.nodes.section1.get(0), "animation-4")
			.addTo(this._scrollMagicController);

		let imageFront = this.nodes.section2ImageWrapFront;

		timeline = new TimelineMax()
			.to(imageFront, 0.5, { left: `-=${imageFront.width() - $(window).width()}`, ease: Power1.easeIn });

		new ScrollMagic.Scene({
			duration:       imageFront.width(),
			tweenChanges:   true,
			triggerHook:    0,
			triggerElement: this.nodes.section2.get(0)
		}).setTween(timeline)
			.setPin(this.nodes.section2.get(0))
			.addTo(this._scrollMagicController);

		return;

		/* Section 1 - Запускается автоматически 1 раз
		 * = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
		var onCompleteFirstSlideAnimation = () => {
			"use strict";
			this._springScroller.resume();
		};


		this._springScroller.pause();

		TweenMax.from(this.nodes.section1Logo, 1, { opacity: 0, ease: Power1.easeIn, delay: 1.2 });
		TweenMax.from(this.nodes.section1Middle, 2, { opacity: 0, ease: Power2.easeOut, delay: 0.5 });
		TweenMax.from(this.nodes.section1Up, 3, { top: '-=100%', ease: Power2.easeOut, delay: 0.6 });
		TweenMax.from(this.nodes.section1Down, 3, {
			bottom:     '-=100%',
			ease:       Power2.easeOut,
			delay:      0.6,
			onComplete: onCompleteFirstSlideAnimation
		});

		/* Section 2 - запускается, когда секция на 80% находится во вьюпорте
		 * = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
		return;
		//TweenMax;

		const Middle = this.nodes.section2Middle;

		const secondTimeline = new TimelineMax()
			.from(Middle, 0.5, { top: '+=100%', ease: Power1.easeIn })
			.from(this.nodes.section2News, 1, { scale: 0.5, opacity: 0, ease: Power1.easeIn })

			// Переключение новостей
			.set(this.nodes.section2News2, { opacity: 1, delay: 1 }, '+=1') // Зажглась
			.set(this.nodes.section2News3, { opacity: 1, delay: 1 }, '+=1') // Зажглась
			.set(this.nodes.section2News4, { opacity: 1, delay: 1 }, '+=1') // Зажглась
			.set(this.nodes.section2News5, { opacity: 1, delay: 1 }, '+=1'); // Зажглась

		scene = new ScrollMagic.Scene({
			duration:       0,
			tweenChanges:   true,
			reverse:        false,
			triggerHook:    0.2,
			triggerElement: this.nodes.section2.get(0)
		});

		scene.setTween(secondTimeline);
		scene.addTo(this._scrollMagicController);

		// Еще мы хотим, чтобы назад анимация перематывалась быстрее, чем она анимируется в нормальном направлении

		scene
			.on("leave", function (e) {
				if (e.state === 'BEFORE') {
					secondTimeline.timeScale(5);
				}
			})
			.on("enter", function (e) {
				secondTimeline.timeScale(1);

				if (secondTimeline.progress() === 0) {
					secondTimeline.seek(0);
				}
			});


		/* Section 3
		 * = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */

		//	Появление и смена персон

		const Section3 = this.nodes.section3;

		const section3Timeline = new TimelineMax();

		scene = new ScrollMagic.Scene({
			duration:       0,
			tweenChanges:   true,
			reverse:        false,
			triggerHook:    1,
			triggerElement: Section3.get(0)
		});

		scene.setTween(section3Timeline);
		scene.addTo(this._scrollMagicController);

		scene
			.on("enter", (e) => {
				this._useSmallScrollStep = true;
				this.resize();
			})
			.on("leave", (e) => {
				this._useSmallScrollStep = e.state !== 'BEFORE'; // Возвращаем в False только если находимся выше слайда
				this.resize();
			});

		const Persons = this.nodes.section3Persons;

		const personsTimeline = new TimelineMax()
			.from(Persons, 0.5, { opacity: '0', ease: Power1.easeIn })
			.from(this.nodes.section3Person1, 1, { opacity: 1, delay: 1, ease: Power1.easeIn })
			.from(this.nodes.section3Person2, 1, { opacity: 0, delay: 0, ease: Power1.easeIn })

		scene = new ScrollMagic.Scene({
			duration:       0,
			tweenChanges:   true,
			reverse:        false,
			triggerHook:    0.2,
			triggerElement: Persons.get(0)
		});

		scene.setTween(personsTimeline);
		scene.addTo(this._scrollMagicController);

		scene
			.on("leave", function (e) {
				if (e.state === 'BEFORE') {
					personsTimeline.timeScale(5);
				}
			})
			.on("enter", function (e) {
				personsTimeline.timeScale(1);

				if (personsTimeline.progress() === 0) {
					personsTimeline.seek(0);
				}
			});

		//	Появление ВидеоКонтейнера

		const VideoContainer = this.nodes.section3VideoContainer;

		const videoContainerTimeline = new TimelineMax()
			.from(VideoContainer, 1, { opacity: 0, delay: 0, ease: Power1.easeIn });

		scene = new ScrollMagic.Scene({
			duration:       0,
			tweenChanges:   true,
			reverse:        false,
			triggerHook:    0.2,
			triggerElement: Persons.get(0)
		});

		scene.setTween(videoContainerTimeline);
		scene.addTo(this._scrollMagicController);

		scene
			.on("leave", function (e) {
				if (e.state === 'BEFORE') {
					videoContainerTimeline.timeScale(5);
				}
			})
			.on("enter", function (e) {
				videoContainerTimeline.timeScale(1);

				if (videoContainerTimeline.progress() === 0) {
					videoContainerTimeline.seek(0);
				}
			});

		//	Появление Слайдера

		const Slider = this.nodes.section3Slider;

		const sliderTimeline = new TimelineMax()
			.from(this.nodes.section3Slider, 1, { opacity: 0, delay: 0, ease: Power1.easeIn });

		scene = new ScrollMagic.Scene({
			duration:       0,
			tweenChanges:   true,
			reverse:        false,
			triggerHook:    1,
			triggerElement: Slider.get(0)
		});

		scene.setTween(sliderTimeline);
		scene.addTo(this._scrollMagicController);

		scene
			.on("leave", function (e) {
				if (e.state === 'BEFORE') {
					sliderTimeline.timeScale(5);
				}
			})
			.on("enter", function (e) {
				sliderTimeline.timeScale(1);

				if (sliderTimeline.progress() === 0) {
					sliderTimeline.seek(0);
				}
			});

		//	Появление Персоны

		const Person = this.nodes.section3Person;

		const personTimeline = new TimelineMax()
			.from(this.nodes.section3Person, 1, { opacity: 0, delay: 0, ease: Power1.easeIn });

		scene = new ScrollMagic.Scene({
			duration:       0,
			tweenChanges:   true,
			reverse:        false,
			triggerHook:    1,
			triggerElement: Person.get(0)
		});

		scene.setTween(personTimeline);
		scene.addTo(this._scrollMagicController);

		scene
			.on("leave", function (e) {
				if (e.state === 'BEFORE') {
					personTimeline.timeScale(5);
				}
			})
			.on("enter", function (e) {
				personTimeline.timeScale(1);

				if (personTimeline.progress() === 0) {
					personTimeline.seek(0);
				}
			});


		/* Section 4
		 * = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */

		const Section4 = this.nodes.section4Bg;

		const sectionTimeline = new TimelineMax()
			.from(this.nodes.section4Bg, 1, { opacity: 0, top: '+=50%', delay: 0, ease: Power1.easeIn })
			.from(this.nodes.section4Pages, 1, { opacity: 0, top: '+=50%', delay: 0, ease: Power1.easeIn });

		scene = new ScrollMagic.Scene({
			duration:       0,
			tweenChanges:   true,
			reverse:        false,
			triggerHook:    0.8,
			triggerElement: this.nodes.section4.get(0)
		});

		scene.setTween(sectionTimeline);
		scene.addTo(this._scrollMagicController);

		scene
			.on("leave", function (e) {
				if (e.state === 'BEFORE') {
					sectionTimeline.timeScale(5);
				}
			})
			.on("enter", function (e) {
				sectionTimeline.timeScale(1);

				if (sectionTimeline.progress() === 0) {
					sectionTimeline.seek(0);
				}
			});

		const section4NewsTimeline = new TimelineMax()
			.from(this.nodes.section4News, 1, { opacity: 0, top: '+=900px', delay: 0, ease: Power1.easeIn })
			.to(this.nodes.section4NewsClosed, 1, { top: '955px', delay: 0, ease: Power1.easeIn });

		scene = new ScrollMagic.Scene({
			duration:       0,
			tweenChanges:   true,
			reverse:        false,
			triggerHook:    1,
			triggerElement: this.nodes.section4News.get(0)
		});

		scene.setTween(section4NewsTimeline);
		scene.addTo(this._scrollMagicController);

		scene
			.on("leave", function (e) {
				if (e.state === 'BEFORE') {
					section4NewsTimeline.timeScale(5);
				}
			})
			.on("enter", function (e) {
				section4NewsTimeline.timeScale(1);

				if (section4NewsTimeline.progress() === 0) {
					section4NewsTimeline.seek(0);
				}
			});

	}

	resize () {
		if (this._destroyed) {
			return;
		}

		super.resize();

		this._springScroller.step(this._useSmallScrollStep ? this._scrollStep : $$.windowHeight);
	}
};
