(function($$) {
	class CrossFadeTransition extends $$.AbstractTransition {
		_repaint(progress) {
			const from = this._bitmapFrom;
			const to = this._bitmapTo;
			const output = this._result;
			const context = output.getContext('2d');

			context.save();
			context.drawImage(from, 0, 0);
			context.globalAlpha = progress;
			context.drawImage(to, 0, 0);
			context.restore();
		}
	}

	$$.CrossFadeTransition = CrossFadeTransition;
})(window.$$ || (window.$$ = {}));
