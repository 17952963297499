const $$ = window.$$ || (window.$$ = {});
//import BaseProjectPageClass from '_baseWork.js';

$$.FordMondeo = class FordMondeo extends $$.BaseProjectPageClass {
	constructor (root) {
		super(root, 'portfolio-entry-ford-mondeo-');

		this._useSmallScrollStep = false;
		this._shouldUseBlackControls = false;
		this._maxScreensScale = 2;
		this._screensScaleFactor = 1;
	}

	_cacheNodes () {
		super._cacheNodes();

		const payload = this.nodes.payload;
		const pen = payload.find('.section2 .pen');

		$.extend(this.nodes, {
			fullscreenSections: this.root.find('.section.fullscreen'),

			section1:           payload.find('> .section1'),
			section1Background: payload.find('> .section1 .background'),
			section1Logo:       payload.find('> .section1 .logo'),
			section1LogoImage:  payload.find('> .section1 .logo .ford-logo-image'),
			section1TextLine1:  payload.find('> .section1 .text-line1'),
			section1TextLine2:  payload.find('> .section1 .text-line2'),

			sectionIpad:            payload.find('> .section-ipad'),
			sectionIpadPinTarget:   payload.find('> .section-ipad .pin-target'),
			sectionIpadWrapper:     payload.find('> .section-ipad .overflow-hiddener'),
			sectionIpadCont:        payload.find('> .section-ipad .ipad-container'),
			sectionIpadIpad:        payload.find('> .section-ipad .ipad'),
			sectionIpadScreen:      payload.find('> .section-ipad .ipad .screen'),
			sectionIpadScreenCar:   payload.find('> .section-ipad .ipad .screen .big-picture'),
			sectionIpadScrollable:  payload.find('> .section-ipad .ipad .screen .scrollable'),
			sectionIpadReflection:  payload.find('> .section-ipad .ipad .reflection'),
			sectionIpadTextLine1:   payload.find('> .section-ipad .text-line1'),
			sectionIpadTextLine2:   payload.find('> .section-ipad .text-line2'),

			section2:           payload.find('> .section2'),
			section2TextLine1:  payload.find('> .section2 .text-line1'),
			section2TextLine2:  payload.find('> .section2 .text-line2'),

			section3:           payload.find('> .section3'),
			section3IPad:       payload.find('> .section3 .ipad'),
			section3IPadScreen: payload.find('> .section3 .ipad .screen'),

			section4:           payload.find('> .section4'),
			section4Display:    payload.find('> .section4 .imac'),
			section4Laptop:     payload.find('> .section4 .macbook'),

			section5:           payload.find('> .section5'),
			section5ScrShotL:   payload.find('> .section5 .screenshot.left'),
			section5ScrShotR:   payload.find('> .section5 .screenshot.right'),

			section6:           payload.find('> .section6'),
			section6Background: payload.find('> .section6 .background-overlay'),
			section6Logo:       payload.find('> .section6 .logo'),
			section6LogoImage:  payload.find('> .section6 .logo img'),
			section6TextBlock:  payload.find('> .section6 .thank-you-text-block')
		});
	}

	_ready () {
		this.nodes.payload.imagesLoaded({
			background: true
		})
			.always(() => {
				super._ready();
			});
	}

	_bindAnimationSequence () {
		let timeline;
		let scene;

		new TimelineMax({ onComplete: () => { this.nodes.section1LogoImage.attr('style', ''); } })
			.from(this.nodes.section1LogoImage, 0.2, { opacity: 0, ease: Sine.easeOut, delay: 1 }, 0)
			.from(this.nodes.section1LogoImage, 0.9, { rotationX: '90deg', ease: Sine.easeOut, delay: 1 }, 0)
			.from(this.nodes.section1TextLine1, 0.9, { opacity: 0, y: '+=120vh', ease: Power2.easeOut }, 1.6)
			.from(this.nodes.section1TextLine2, 0.8, { opacity: 0, y: '+=190vh', ease: Power2.easeOut }, 1.7)
		;

		/* Section 2 - анимация текста на втором слайде
		 * = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */

		/*

		timeline = new TimelineMax()
			.from(this.nodes.section2TextLine1 , 0.9 , { opacity: 0, ease: Power2.easeOut }, 0)
			.from(this.nodes.section2TextLine2 , 0.9 , { opacity: 0, ease: Power2.easeOut }, 0)
		;

		new ScrollMagic.Scene({
			duration: 0,
			triggerHook: 0.15,
			reverse: false,
			triggerElement: this.nodes.section2.get(0)
		})
			.setTween(timeline)
			.addTo(this._scrollMagicController);

		// Сценой ниже, будем переключать цвета контролов. Потому что нам нужна реверсэбл, а верхняя не такая.

		scene = new ScrollMagic.Scene({
			duration: () => { return $$.windowHeight; },
			triggerHook: 0.3,
			triggerElement: this.nodes.section2.get(0)
		}).addTo(this._scrollMagicController);

		scene
			.on("enter", () => {
				this._changePageControlsColorScheme(true);
			})
			.on("leave", (e) => {
				this._changePageControlsColorScheme(e.state !== 'BEFORE');
			});
		*/

		this.nodes.section2.hide();
		this.nodes.sectionIpad.show();
		//this._recalculateScreenScale();
		this.resize();
		const self = this;

		function updateScale() {
			self._setIPadScale(this.target.progress);
		}

		timeline = new TimelineMax()
			//.from(this.nodes.sectionIpadIpad, 1 , { scale: this._maxScreensScale, ease: Power1.easeIn }, 0)
			.from({ progress: 0 } , 1 , { progress: 1, onUpdate: updateScale, ease: Power1.easeIn }, 0)
			.from(this.nodes.sectionIpadTextLine1   , 0.5 , { opacity: 0, ease: Power2.easeOut }, 0.5)
			.from(this.nodes.sectionIpadTextLine2   , 0.5 , { opacity: 0, ease: Power2.easeOut }, 0.5)
			.from(this.nodes.sectionIpadReflection  , 0.7 , { opacity: 0, ease: Linear.easeNone }, 0.3)
			// В следующую секунду прячем текущий фон, показываем приложение
			.to(this.nodes.sectionIpadScreenCar     , 0.3 , { left: '9.015%', ease: Linear.easeNone }, 1)
			.to(this.nodes.sectionIpadScreenCar     , 0.7 , { opacity: 0, ease: Power1.easeIn })
			// Следующая секунда - скролл приложения в один шаг
			.to(this.nodes.sectionIpadScrollable    , 2.0 ,   { backgroundPositionY: '100%', ease: Linear.easeNone }, 2)
		;

		new ScrollMagic.Scene({
			duration: () => { return $$.windowHeight * 4; },
			triggerHook: 0,
			triggerElement: this.nodes.sectionIpad.get(0)
		})
			.setTween(timeline)
			.setPin(this.nodes.sectionIpadPinTarget.get(0), { pushFollowers: true })
			.addTo(this._scrollMagicController);

		new ScrollMagic.Scene({
			duration: () => { return $$.windowHeight * 5; },
			triggerHook: 0.15,
			triggerElement: this.nodes.sectionIpad.get(0)
		}).addTo(this._scrollMagicController)
			.on("enter", () => { this._changePageControlsColorScheme(true); })
			.on("leave", () => { this._changePageControlsColorScheme(false); });

		/* Section 4
		 * = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */

		timeline = new TimelineMax()
			.from(this.nodes.section4Display , 1.4 , { opacity: 0, left: '-20%', ease: Power2.easeOut }, 0)
			.from(this.nodes.section4Laptop  , 1.4 , { opacity: 0, right: '-20%', ease: Power2.easeOut }, 0.1)
		;

		new ScrollMagic.Scene({
			duration: 0,
			triggerHook: 0.15,
			reverse: false,
			triggerElement: this.nodes.section4.get(0)
		})
			.setTween(timeline)
			.addTo(this._scrollMagicController);

		new ScrollMagic.Scene({
			duration: () => { return $$.windowHeight; },
			triggerHook: 0.3,
			triggerElement: this.nodes.section4.get(0)
		}).addTo(this._scrollMagicController);
			//.on("enter", () => {
			//	this._changePageControlsColorScheme(false);
			//})
			//.on("leave", (e) => {
			//	this._changePageControlsColorScheme(e.state === 'BEFORE');
			//});

		/* Section 5
		 * = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */

		timeline = new TimelineMax()
			.from(this.nodes.section5ScrShotL , 1.4 , { opacity: 0, left: '-30vw',  ease: Power1.easeOut }, 0)
			.from(this.nodes.section5ScrShotR , 1.4 , { opacity: 0, right: '-30vw', ease: Power1.easeOut }, 0)
		;

		new ScrollMagic.Scene({
			duration: 0,
			triggerHook: 0.15,
			reverse: false,
			triggerElement: this.nodes.section5.get(0)
		})
			.setTween(timeline)
			.addTo(this._scrollMagicController);

		/* Section 6
		 * = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */

		timeline = new TimelineMax()
			.from(this.nodes.section6LogoImage  , 0.2, { opacity: 0, ease: Sine.easeOut }, 0.5)
			.from(this.nodes.section6LogoImage  , 0.9, { rotationX: '90deg', ease: Sine.easeOut }, 0.5)
			.from(this.nodes.section6TextBlock  , 1.1 , { opacity: 0, y: '+=50vh', ease: Power1.easeOut }, 0.5)
			.from(this.nodes.section6Background , 1.0 , { opacity: 1, ease: Power1.easeIn })
		;

		new ScrollMagic.Scene({
			duration: 0,
			triggerHook: 0.15,
			reverse: false,
			triggerElement: this.nodes.section6.get(0)
		})
			.setTween(timeline)
			.addTo(this._scrollMagicController);

		// Все секции, кроме той, что с двумя дисплеями должны смещаться вниз так, чтобы оно выглядело так,
		// будто они стоят на месте, а новые наползают на них.

		const sController = this._scrollMagicController;
		const iPadSection = this.nodes.sectionIpad;

		this.nodes.section1
			//.add(this.nodes.sectionIpad)
			.add(this.nodes.section5)
			.each(function() {
				const target = this;
				const triggerElement = $(this).nextAll().filter(':visible:first').get(0);

				new ScrollMagic.Scene({
					duration: () => { return $$.windowHeight; },
					triggerHook: 1,
					triggerElement: triggerElement
				})
					.addTo(sController)
					.setTween(TweenMax.to(this , 1, {
						top: '100vh',
						onUpdate: function() {
							if (this.target === iPadSection[0]) {
								sController.update();
							}
						},
						ease: Linear.easeNone
					}, 0.5));
			});
	}

	_initScrollSpring () {
		this._springScroller = new $$.SmoothScroller(this.nodes.payload, {
			velocityLimit: 0.35
		}, {
			preventMovingTargetTooFar: true,
			stepSize: $$.windowHeight
		});

		this._springScroller.pause();
		this._springScroller.onScroll = () => this._updateScene();
	}

	_setIPadScale(progress = this._screensScaleFactor) {
		const ipad = this.nodes.sectionIpadIpad;
		const scale = 1 + (this._maxScreensScale - 1) * progress;

		TweenMax.set(ipad, { scale: scale });
	}

	_changePageControlsColorScheme(toBlack = this._shouldUseBlackControls) {
		this._shouldUseBlackControls = !!toBlack;
		$$.body.toggleClass('black-controls', !!toBlack);
	}

	_recalculateScreenScale() {
		const screen = this.nodes.sectionIpadScreen;
		const scaleY = $$.windowHeight / screen.height();
		const scaleX = $$.windowWidth / screen.width();

		this._maxScreensScale = Math.max(scaleX, scaleY);
	}

	repositioned(isAnimationOver) {
		super.repositioned(isAnimationOver);

		if (isAnimationOver) {
			_.defer(() => {
				this._changePageControlsColorScheme();
			});
		} else {
			$$.body.removeClass('black-controls');
		}
	}

	resize () {
		if (this._destroyed) {
			return;
		}

		this.nodes.sectionIpadCont.each(function() {
			const width = $(this).data('originalWidth') || $(this).css('width', '').width();
			const height = $(this).data('originalHeight') || $(this).css('height', '').height();
			$(this).data('originalWidth', width);
			$(this).data('originalHeight', height);

			const newDimensions = $$.calculateDimensions(
				false,
				width, height,
				$$.windowWidth, $$.windowHeight,
				{ upscalingAllowed: false }
			);

			$(this).css({
				width: newDimensions.width,
				height: newDimensions.height
			});
		});

		this._recalculateScreenScale();
		this._setIPadScale();

		super.resize();
	}
};
