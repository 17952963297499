var $$ = $$ || {};

$$.Portfolio = class Portfolio {
	constructor (root) {
		this.root = root;

		this._cacheNodes();
		this._bindEvents();
		this._ready();
	}

	_cacheNodes () {
		this.nodes = {
			grid:           this.root.find('.js-grid'),
			blocks:         this.root.find('.js-grid-item'),
			orderBlock:     this.root.find('.js-order'),
			feedbackButton: this.root.find('.js-open-feedback')
		}
	}

	/**
	 * Вешает обработчики событий на компоненты/элементы.
	 *
	 * @private
	 */
	_bindEvents () {
		this.nodes.grid.on('layoutComplete', () => {
			var position = {};
			var last = this.nodes.blocks.last();

			this.nodes.blocks.each(function (index, element) {
				var block = $(element);

				if (block.position().top > last.position().top) {
					last = block;
				}

				if (block.position().left > last.position().left && block.position().top === last.position().top) {
					last = block;
				}
			});

			position.top = last.position().top;
			position.left = last.position().left + last.outerWidth();
			position.width = $$.windowWidth - position.left;

			if (Math.abs(position.left - $$.windowWidth) < $$.windowWidth / 10) {
				position.top += last.outerHeight();
				position.left = 0;
				position.width = $$.windowWidth;
			}

			position.width = position.width / $$.windowWidth * 100 + '%';
			this.nodes.orderBlock.css(position);
		});

		this.nodes.feedbackButton.on('click', function (event) {
			event.stopPropagation();
			$('body').trigger('menu.open');
		});
	}

	/**
	 * Вызывается на этапе готовности компонентов/элементов к работе с ними.
	 *
	 * В основном метод используется для применения опций, добавления шаблона в DOM.
	 *
	 * @private
	 */
	_ready () {
		if (!$$.isMobile) {
			this.nodes.grid.masonry({
				gutter: 0,
				itemSelector: '.grid-item',
				columnWidth: '.grid-sizer',
				percentPosition: true,
				transitionDuration: 0
			});

			this.nodes.grid.masonry('layout');
		}
	}
};
