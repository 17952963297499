(function($$) {
	const CanvasUtils = $$.CanvasUtils;

	class RadialFadeTransition extends $$.AbstractTransition {
		constructor(from, to, settings) {
			super(from, to, settings);
			this._thirdCanvas = CanvasUtils.createCanvas(to.width, to.height);
		}

		_repaint(progress) {
			const from = this._bitmapFrom;
			const to = this._bitmapTo;
			const output = this._result;
			const context = output.getContext('2d');

			const maskedTo = this._thirdCanvas;
			const mContext = maskedTo.getContext('2d');

			const diag = Math.sqrt( Math.pow(output.width, 2) + Math.pow(output.height, 2) );
			const maxRadius = Math.ceil(diag / 2);

			mContext.save();
			mContext.clearRect(0, 0, output.width, output.height);

			// Creating radial gradient first

			const gradient = mContext.createRadialGradient(
				~~(output.width / 2), ~~(output.height / 2), maxRadius * 1.1 * (0 + progress),
				~~(output.width / 2), ~~(output.height / 2), maxRadius * 3.2 * (0 + progress) // 3.2 is a magic number
			);
			gradient.addColorStop(0, 'white');
			gradient.addColorStop(1, 'rgba(255, 255, 255, 0)');

			mContext.beginPath();
			mContext.globalAlpha = Math.min(1, progress * (1 / 0.4));
			mContext.fillStyle = gradient;
			mContext.fillRect(0, 0, output.width, output.height);

			mContext.globalCompositeOperation = 'source-in';
			mContext.drawImage(to, 0, 0);

			mContext.restore();

			context.drawImage(from, 0, 0);
			context.drawImage(maskedTo, 0, 0);
		}
	}

	$$.RadialFadeTransition = RadialFadeTransition;
})(window.$$ || (window.$$ = {}));
