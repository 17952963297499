var $$ = $$ || {};

$$.window = $(window);
$$.windowWidth = $$.window.width();
$$.windowHeight = $$.window.height();
$$.body = $(document.body);

$$.window.on('resize', () => {
	$$.windowWidth = $$.window.width();
	$$.windowHeight = $$.window.height();
});
