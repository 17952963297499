const $$ = window.$$ || (window.$$ = {});
//import BaseProjectPageClass from '_baseWork.js';

$$.Creopop = class Creopop extends $$.BaseProjectPageClass {
	constructor (root) {
		super(root, 'portfolio-entry-creopop-');

		this._useSmallScrollStep = false;

		this._debouncedSetBreakSpring = _.debounce((value) => {
			this._springScroller._scrollOptions.breakSpring = value;
		}, 400);
	}

	_cacheNodes () {
		super._cacheNodes();

		const payload = this.nodes.payload;
		const pen = payload.find('.section2 .pen');

		$.extend(this.nodes, {
			fullscreenSections: this.root.find('section.fullscreen'),

			// section #1
			section1: payload.find('.section1'),
			section1Display: this.root.find('.section1 .display'),
			section1VideoBlock: this.root.find('.section1 .video-player-container'),

			// section #2
			section2: payload.find('.section2'),
			section2Pen: pen,
			section2PenParts: {
				inkContainer: pen.children('.ink'),
				wireframeLeft: pen.children('.wireframe-left'),
				wireframeRight: pen.children('.wireframe-right'),

				cord: pen.children('.pen-cord'),
				lights: pen.children('.pen-light-sources'),

				batteryIndicators: pen.children('[class*=" battery-state"], [class^="battery-state"]'),
				battery0: pen.children('.battery-state0'),
				battery1: pen.children('.battery-state1'),
				battery2: pen.children('.battery-state2'),
				battery3: pen.children('.battery-state3'),
				battery4: pen.children('.battery-state4'),
				battery5: pen.children('.battery-state5')
			},

			// section #3
			section3: payload.find('.section3'),
			section3Title: payload.find('.section3 .screenshot-title'),
			section3LensFlare: payload.find('.section3 .lens-flare-thing'),
			section3Screenshot: payload.find('.section3 .screenshot-wrapper'),

			// Section #4
			section4: payload.find('.section4'),
			section4Screenshot1: payload.find('.section4 .screenshot1'),
			section4Screenshot2: payload.find('.section4 .screenshot2'),
			section4Screenshot3: payload.find('.section4 .screenshot3'),
			section4Screenshot4: payload.find('.section4 .screenshot4'),

			// Section #5
			section5: payload.find('.section5'),
			section5Pen: payload.find('.section5 .pen'),
			section5PenBaseLayer: payload.find('.section5 .pen .base-layer'),
			section5PenBttnLayer: payload.find('.section5 .pen .mask'),
			section5Icons: payload.find('.section5 .js-icons'),

			// Section #6
			section6: payload.find('.section6'),
			section6Macbook: payload.find('.section6 .macbook'),
			section6Ipad: payload.find('.section6 .ipad'),
			section6Dots: payload.find('.section6 .dots'),
			section6Lines: payload.find('.section6 .js-line')
		});
	}

	_ready () {
		this.nodes.payload.imagesLoaded({
			background: true
		})
			.always(() => {
				super._ready();
			});
	}

	_bindEvents () {
		super._bindEvents();

		this.nodes.fullscreenSections.on('mousewheel', (event) => {
			if (this._springScroller._spring.position().toFixed(4)
					!== this._springScroller._spring.target().toFixed(4)){
				event.stopPropagation();
				event.preventDefault();
			}
		});

		// Sections

		this.nodes.section1VideoBlock.each(function() {
			const container = $(this);

			container.children('.video-cover').click(function() {
				var cover = $(this);

				let player = $$.makeVideoPlayerHtml(container.data('videoType'), container.data('videoId'), '100%', '100%', true);
				player = $(player).hide().insertAfter(this);

				player.velocity('fadeIn', 500, function() {
					cover.remove();
				});
			});
		});
	}

	_bindAnimationSequence () {
		let timeline;
		let scene;


		/* Section 1 - Запускается автоматически 1 раз
		 * = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */

		TweenMax.from(this.nodes.section1Display , 2, { top: '+=75%', ease: Power2.easeOut, delay: 0.5 });
		TweenMax.from(this.nodes.section1Display , 2, { opacity: 0, ease: Power1.easeIn, delay: 0.5 });


		/* Section 2 - запускается, когда секция на 80% находится во вьюпорте
		 * = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */

		const penParts = this.nodes.section2PenParts;

		penParts.batteryIndicators.css('opacity', 0);

		const secondTimeline = new TimelineMax()
			// Собираем ручку, втыкаем кабель
			.from(penParts.inkContainer     , 0.7 , { opacity: 0, ease: Power1.easeOut })
			.from(penParts.wireframeLeft    , 1   , { left: '-50%', opacity: 0, ease: Power1.easeIn }, 0.5)
			.from(penParts.wireframeRight   , 1.3 , { left: '50%', opacity: 0, ease: Power1.easeIn }, 0.9)
			.from(penParts.cord             , 0.9 , { left: '+=35%', opacity: 0, ease: Power1.easeOut })
			// Индикатор заряда
			.set (penParts.battery0         ,       { opacity: 1 }, '+=0.066') // Зажглась
			.set (penParts.battery0         ,       { opacity: 0 }, '+=0.266') // Погасла
			.set (penParts.battery0         ,       { opacity: 1 }, '+=0.233') // Зажглась
			.set (penParts.battery0         ,       { opacity: 0 }, '+=0.233') // Погасла
			.set (penParts.battery1         ,       { opacity: 1 }, '+=0.233') // Зажглась синяя
			.set (penParts.battery1         ,       { opacity: 0 }, '+=0.333')   // дальше с шагом 0.1 переключаем их
			.set (penParts.battery2         ,       { opacity: 1 })
			.set (penParts.battery2         ,       { opacity: 0 }, '+=0.1')
			.set (penParts.battery3         ,       { opacity: 1 })
			.set (penParts.battery3         ,       { opacity: 0 }, '+=0.1')
			.set (penParts.battery4         ,       { opacity: 1 })
			.set (penParts.battery4         ,       { opacity: 0 }, '+=0.1')
			.set (penParts.battery5         ,       { opacity: 1 })

			.to  (penParts.cord             , 0.7 , { left: '+=35%', opacity: 0, ease: Power2.easeIn }, '+=0.5')
			.from(penParts.lights           , 0.5 , { opacity: 0, ease: Power1.easeOut }, '+=0.3')
		;

		scene = new ScrollMagic.Scene({
			duration: 0,
			tweenChanges: true,
			triggerHook: 0.2,
			triggerElement: this.nodes.section2.get(0)
		});

		scene.setTween(secondTimeline);
		scene.addTo(this._scrollMagicController);

		// Еще мы хотим, чтобы назад анимация перематывалась быстрее, чем она анимируется в нормальном направлении

		scene
			.on("leave", function (e) {
				if (e.state === 'BEFORE') {
					secondTimeline.timeScale(5);
				}
			})
			.on("enter", function (e) {
				secondTimeline.timeScale(1);

				if (secondTimeline.progress() === 0) {
					secondTimeline.seek(0);
				}
			});


		/* Section 3
		 * = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */

		timeline = new TimelineMax()
			//.from(this.nodes.section3Screenshot , 1.3, { y: '+=370vh', ease: Power3.easeOut })
			.from(this.nodes.section3Title      , 1.5, { x: '-=50%', opacity: 0, ease: Power3.easeOut })
			.from(this.nodes.section3LensFlare  , 0.8, { opacity: 0, ease: Power2.easeOut }, '-=0.1')
		;

		var scene3 = new ScrollMagic.Scene({
			duration: 0,
			tweenChanges: true,
			triggerHook: 0.05,
			reverse: true,
			triggerElement: this.nodes.section3.get(0)
		});

		// Начиная с этого слайда мы хотим поменять шаг прокрутки на более маленький, чтобы не крутить целыми экранами.
		// К счастью, мы можем это себе позволить. Само обновление происходит в this.resize

		scene3.on("enter", (e) => {
			this._useSmallScrollStep = true;
			this.resize();
		});

		scene3.on("leave", (e) => {
			this._useSmallScrollStep = e.state !== 'BEFORE'; // Возвращаем в False только если находимся выше слайда
			this.resize();

			if (e.state === 'BEFORE') {
				var newTargetPosition;
				var wrapperScrollTop = this.nodes.payload.prop('scrollTop');
				var section2PositionTop = this.nodes.section2.position().top + wrapperScrollTop;
				var wrapperScrollHeight = this.nodes.payload.prop('scrollHeight') - this.nodes.payload.height();
				newTargetPosition = section2PositionTop / wrapperScrollHeight;

				this._springScroller._spring.target(newTargetPosition);
				this._springScroller._scrollOptions.breakSpring = true;
				this._debouncedSetBreakSpring(false);
			}
		});

		scene3.setTween(timeline);
		scene3.addTo(this._scrollMagicController);


		/* Section 4
		 * = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */

		timeline = new TimelineMax()
			.from(this.nodes.section4Screenshot1 , 1, { y: '+=87vh', opacity: 0, ease: Power1.easeOut })
			.from(this.nodes.section4Screenshot4 , 1.3, { x: '+=20%', opacity: 0, ease: Power3.easeOut }, '-=0.2')
			.from(this.nodes.section4Screenshot3 , 1.3, { x: '-=20%', opacity: 0, ease: Power3.easeOut }, '-=0.9')
			.from(this.nodes.section4Screenshot2 , 1.3, { x: '-=20%', opacity: 0, ease: Power3.easeOut }, '-=0.9')
		;

		scene = new ScrollMagic.Scene({
			duration: 0,
			tweenChanges: true,
			triggerHook: 0.65,
			reverse: false,
			triggerElement: this.nodes.section4.get(0)
		});

		scene.setTween(timeline);
		scene.addTo(this._scrollMagicController);


		/* Section 5
		 * = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */

		timeline = new TimelineMax()
			.from(this.nodes.section5Pen , 1.3, { y: '-=20%', opacity: 0, ease: Power1.easeOut })
			.from(this.nodes.section5PenBttnLayer , 0.7, { opacity: 0, ease: Linear.none }, '-=0.5')
		;

		scene = new ScrollMagic.Scene({
			duration: () => { return ~~$$.windowHeight / 2; },
			triggerHook: 0.7,
			triggerElement: this.nodes.section5.get(0)
		});

		scene.setTween(timeline);
		scene.addTo(this._scrollMagicController);

		timeline = new TimelineMax()
			.from(this.nodes.section5Icons, 3, { x: '-=60px', opacity: 0, y: '+=100px', ease: Linear.none });

		scene = new ScrollMagic.Scene({
			duration: () => { return ~~$$.windowHeight / 2 * 1.5; },
			triggerHook: 0.7,
			triggerElement: this.nodes.section5.get(0)
		});

		scene.setTween(timeline);
		scene.addTo(this._scrollMagicController);


		/* Section 6
		 * = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */

		timeline = new TimelineMax()
			.from(this.nodes.section6Macbook , 4.8, {
				right: '100%',
				ease: Power3.easeOut,
				onStart: () => {
					this.nodes.section6Lines.fadeTo(1, 400).addClass('run visible');
				}
			})
			.from(this.nodes.section6Ipad    , 4.8, { left: '100%', ease: Power3.easeOut }, 0)
			.from(this.nodes.section6Dots    , 5  , { opacity: 0, ease: Power2.easeOut }, '-=2.6')
			.duration(4)
		;

		scene = new ScrollMagic.Scene({
			duration: 0,
			tweenChanges: true,
			triggerHook: 0.55,
			reverse: false,
			triggerElement: this.nodes.section6.get(0)
		});

		scene.setTween(timeline);
		scene.addTo(this._scrollMagicController);
	}

	resize () {
		if (this._destroyed) {
			return;
		}

		//this.nodes.section3
		//	.css('height', '')
		//	.css('height', Math.ceil(this.nodes.section3.height() / $$.windowHeight) * $$.windowHeight);

		this.nodes.section1Display.each(function() {
			const width = $(this).data('originalWidth') || $(this).css('width', '').width();
			const height = $(this).data('originalHeight') || $(this).css('height', '').height();

			$(this).data('originalWidth', width);
			$(this).data('originalHeight', height);

			const newDimensions = $$.calculateDimensions(
				false,
				width, height,
				$$.windowWidth * 0.9, $$.windowHeight * 0.92
			);

			$(this).css({
				width: newDimensions.width,
				height: newDimensions.height
			});
		});

		super.resize();

		this._springScroller.step(this._useSmallScrollStep ? 200 : $$.windowHeight);
	}
};
