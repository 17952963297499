const $$ = window.$$ || (window.$$ = {});
//import BaseProjectPageClass from '_baseWork.js';

$$.FordFiesta = class FordFiesta extends $$.BaseProjectPageClass {
	constructor (root) {
		super(root, 'portfolio-entry-ford-fiesta-');

		this._useSmallScrollStep = false;
	}

	_cacheNodes () {
		super._cacheNodes();

		const payload = this.nodes.payload;
		const pen = payload.find('.section2 .pen');

		$.extend(this.nodes, {
			fullscreenSections: this.root.find('.section.fullscreen'),

			section1:           payload.find('> .section1'),
			section1Background: payload.find('> .section1 .background'),
			section1Logo:       payload.find('> .section1 .logo .ford-logo-image'),

			section2:           payload.find('> .section2'),
			section2PinTarget:  payload.find('> .section2 .pin-target'),
			section2Laptop:     payload.find('> .section2 .macbook'),
			section2Display:    payload.find('> .section2 .macbook .display'),

			section3:           payload.find('> .section3'),
			section3Car:        payload.find('> .section3 .car'),
			section3CarImg1:    payload.find('> .section3 .car img:first'),
			section3CarImg2:    payload.find('> .section3 .car img:last'),
			section3Screenshots:payload.find('> .section3 .screenshots'),
			section3Screenshot1:payload.find('> .section3 .screenshots .column:first .screenshot:first'),
			section3Screenshot2:payload.find('> .section3 .screenshots .column:first .screenshot:last'),
			section3Screenshot3:payload.find('> .section3 .screenshots .column.top .screenshot:first'),
			section3Screenshot4:payload.find('> .section3 .screenshots .column.top .screenshot:last'),

			section4: payload.find('> .section4'),
			section4Bg: payload.find('> .section4 .background-overrider'),
			section4Text: payload.find('> .section4 .thank-you-text-block')
		});
	}

	_ready () {
		this.nodes.payload.imagesLoaded({
			background: true
		})
			.always(() => {
				super._ready();
			});
	}

	_bindAnimationSequence () {
		let timeline;
		let scene;

		TweenMax.from(this.nodes.section1Background, 1, { opacity: 0, ease: Sine.easeIn, delay: 1 });
		TweenMax.from(this.nodes.section1Logo, 0.5, { height: '0%', ease: Sine.easeOut, delay: 1 });


		/* Section 2.1 - анимация монитора на экран, 1 раз, не перематываемая
		 * = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */

		const secondTimeline1 = new TimelineMax()
			.from(this.nodes.section2Laptop , 1.6 , { opacity: 0, ease: Power1.easeOut })
			.from(this.nodes.section2Laptop , 2.3 , { top: '-=50%', ease: Power2.easeOut }, 0)
		;

		scene = new ScrollMagic.Scene({
			duration: 0,
			triggerHook: 0.15,
			reverse: false,
			triggerElement: this.nodes.section2.get(0)
		});

		scene.setTween(secondTimeline1);
		scene.addTo(this._scrollMagicController);


		/* Section 2.2 - Прокрутка в мониторе
		 * = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */

		const secondTimeline2 = new TimelineMax()
			.to({}                          , 0.2  , {})
			.to(this.nodes.section2Display  , 0.93 , {
				backgroundPosition: 'center 100%',
				ease: Linear.easeNone
			})
			.to({}                          , 0.1  , {})
		;

		scene = new ScrollMagic.Scene({
			duration: () => { return $$.windowHeight * 2 },
			//tweenChanges: true,
			triggerHook: 0,
			triggerElement: this.nodes.section2.get(0)
		});

		scene.setTween(secondTimeline2);
		scene.setPin(this.nodes.section2PinTarget.get(0));
		scene.addTo(this._scrollMagicController);

		/* Section 3.1 - сначала машинка, потом скриншотики. Снова делаем в нескольких частях
		 * машинку не перематываем
		 * = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */

		this.nodes.section3Car.show();

		timeline = new TimelineMax()
			//.to(this.nodes.section3CarImg1, 0.5, { opacity: 0 }, 0.4)
			.to(this.nodes.section3CarImg2, 0.4, { opacity: 1 }, 0.2)
			.to(this.nodes.section3Car,     1.2  , { left: '+=55%', x: 0, ease: Power2.easeIn }, 0)
			.from(this.nodes.section3Screenshots, 0.5 , { opacity: 0, ease: Power1.easeOut })
		;

		scene = new ScrollMagic.Scene({
			duration: 0,
			tweenChanges: true,
			triggerHook: 0.36,
			reverse: false,
			triggerElement: this.nodes.section3.get(0)
		});

		scene.setTween(timeline);
		scene.addTo(this._scrollMagicController);

		// Section 3.1 - первые 2 скриншота

		timeline = new TimelineMax()
			.from(this.nodes.section3Screenshot1, 0.7 , { y: '+=30%', opacity: 0, ease: Power1.easeOut })
			.from(this.nodes.section3Screenshot3, 0.7 , { y: '+=30%', opacity: 0, ease: Power1.easeOut }, 0.3)
		;

		scene = new ScrollMagic.Scene({
			duration: 0,
			tweenChanges: true,
			triggerHook: 0.4,
			reverse: false,
			triggerElement: this.nodes.section3Screenshot1.get(0)
		});

		scene.setTween(timeline);
		scene.addTo(this._scrollMagicController);

		// Section 3.2 - первые 2 скриншота

		timeline = new TimelineMax()
			.from(this.nodes.section3Screenshot2, 0.7 , { y: '+=30%', opacity: 0, ease: Power1.easeOut })
			.from(this.nodes.section3Screenshot4, 0.7 , { y: '+=30%', opacity: 0, ease: Power1.easeOut }, 0.3)
		;

		scene = new ScrollMagic.Scene({
			duration: 0,
			tweenChanges: true,
			triggerHook: 0.6,
			reverse: false,
			triggerElement: this.nodes.section3Screenshot2.get(0)
		});

		scene.setTween(timeline);
		scene.addTo(this._scrollMagicController);

		/* Section 4
		 * = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */

		timeline = new TimelineMax()
			.from(this.nodes.section4Bg  , 1.7 , { opacity: 1, ease: Power1.easeOut })
			.from(this.nodes.section4Text, 1.7 , { opacity: 0, ease: Power1.easeOut }, 0)
		;

		scene = new ScrollMagic.Scene({
			duration: 0,
			tweenChanges: true,
			triggerHook: 0.2,
			reverse: false,
			triggerElement: this.nodes.section4.get(0)
		});

		scene.setTween(timeline);
		scene.addTo(this._scrollMagicController);
	}

	repositioned(isAnimationOver) {
		super.repositioned(isAnimationOver);

		if (isAnimationOver) {
			_.defer(function() {
				$$.body.addClass('black-controls');
			});
		} else {
			$$.body.removeClass('black-controls');
		}
	}

	resize () {
		if (this._destroyed) {
			return;
		}

		super.resize();

		this._springScroller.step(200);
	}
};
