var $$ = $$ || {};

$$.AbstractBlur = class AbstractBlur {
	constructor () {
		this.options = {
			left:              0,
			top:               0,
			width:             300,
			height:            150,
			startBlurDuration: 400,
			scaleDuration:     900,
			endBlurDuration:   500,
			leftPercent:       25,
			topPercent:        25,
			widthPercent:      50,
			heightPercent:     50,
			preBlurDuration:   300,
			blurTimeStep:	   50
		};

		this.hiddenCanvas = $('<canvas>');
		this.virtualContext = this.hiddenCanvas[0].getContext('2d');
	}

	_fillWhollyCanvas (nativeImage, alpha = 1) {
		this.virtualContext.globalAlpha = alpha;

		this.virtualContext.drawImage(
			nativeImage,
			this.options.left,
			this.options.top,
			this.options.width,
			this.options.height
		);

		this.virtualContext.globalAlpha = 1;
	}

	_applyCanvasChanges (context) {
		var pixels = this.virtualContext.getImageData(
			this.options.left,
			this.options.top,
			this.options.width,
			this.options.height
		);

		context.putImageData(pixels, this.options.left, this.options.top);
	}

	_blurVirtualCanvas (radius) {
		stackBoxBlurWidthCanvasRGB(
			this.virtualContext,
			this.options.left,
			this.options.top,
			this.options.width,
			this.options.height,
			radius
		);
	}

	_fillRegionCanvas (nativeImage, alphaChannel = 1) {
		var regionLeft = parseInt(this.options.width * this.options.leftPercent / 100);
		var regionTop = parseInt(this.options.height * this.options.topPercent / 100);
		var regionWidth = parseInt(this.options.width * this.options.widthPercent / 100);
		var regionHeight = parseInt(this.options.height * this.options.heightPercent / 100);

		this.virtualContext.globalAlpha = alphaChannel;

		this.virtualContext.drawImage(
			nativeImage,
			regionLeft,
			regionTop,
			regionWidth,
			regionHeight
		);

		this.virtualContext.globalAlpha = 1;
	}

	_fillBlendCanvas (context, startImage, endImage, startRadius, endRadius, alphaChannel = 1, isRegion = true) {
		if (isRegion) {
			this._fillWhollyCanvas(startImage);
			this._fillRegionCanvas(endImage, alphaChannel);
			this._blurVirtualCanvas(startRadius);
			this._applyCanvasChanges(context);
		} else {
			this._fillWhollyCanvas(startImage);
			this._fillWhollyCanvas(endImage, alphaChannel);
			this._blurVirtualCanvas(startRadius);
			this._applyCanvasChanges(context);
		}
	}

	_blendingImageOnCanvas (context, startImageUrl, endImageUrl, startRadius, endRadius, alphaChannel) {
		var startImage = this.images[startImageUrl];
		var endImage = this.images[endImageUrl];

		var action = () => {
			if (!(startImage && endImage)) {
				return;
			}

			this._fillBlendCanvas(context, startImage[0], endImage[0], startRadius, endRadius, alphaChannel, false);
		};

		if (!startImage) {
			startImage = $('<img>');

			startImage.one('load', () => {
				action();
			});

			startImage.attr('src', startImageUrl);
		}

		if (!endImage) {
			endImage = $('<img>');

			endImage.one('load', () => {
				action();
			});

			endImage.attr('src', endImageUrl);
		}

		action();
	}
};
