var $$ = $$ || {};

$$.Moronezh = class Moronezh {
	constructor(root) {
		this.root = root;
		this.uniqueHash = `portfolio-entry-moronezh-${_.uniqueId()}}`;

		this.scrollPos = 0;
		this.scrollHeight = 0;
		this.waitingForDependenciesToLoad = false;
		this._isInitialTick = true; _.defer( () => this._isInitialTick = false );

		this._destroyed = false;
		this._scrollMagicController = null;
		this.isPaused = false;
		this.carSceneIsFinished = false;
		this.isMouseScroll = false;

		this._cacheNodes();
		this._bindEvents();
		this._initScrollSpring();
		this.resize();

		this.nodes.loader.show();
		this.nodes.wrapper.css('opacity', 0);
		this._preventMouseWheel = true;

		this._ready();

		this._debouncedGoToNearestSection = _.debounce(() => {
			if (this._springScroller._spring.position().toFixed(4)
					=== this._springScroller._spring.target().toFixed(4)) {
				this._goToNearestSection();
			}
		}, 400);

		this._recheckBackground = _.debounce(() => {
			BackgroundCheck.refresh();
		}, 200);
	}

	_cacheNodes () {
		this.nodes = {
			mouseWheelCatcher: $('<div/>').appendTo(this.root),
			loader: this.root.children('.js-loading'),
			wrapper: this.root.find('.js-wrapper'),
			sections: this.root.find('.js-section'),
			pinTargets: this.root.find('.js-pin-target'),
			start: this.root.find('.js-start'),
			nameLogo: this.root.find('.js-name-logo'),
			startSection: this.root.find('.js-start-section'),
			patternSVG: this.root.find('.js-pattern-svg'),
			snow: this.root.find('.js-snow'),
			phone: this.root.find('.js-phone'),
			pad: this.root.find('.js-pad'),
			book: this.root.find('.js-book'),
			navPrev: this.root.find('.js-nav-prev'),
			navNext: this.root.find('.js-nav-next'),
			screenShots: this.root.find('.js-screen-shot'),
			tizers: this.root.find('.js-tizer'),
			tizersSection: this.root.find('.js-tizers-section'),
			deliverySection: this.root.find('.js-delivery-section'),
			deliveryBg: this.root.find('.js-delivery-bg'),
			hand: this.root.find('.js-hand'),
			pad2: this.root.find('.js-pad2'),
			car: this.root.find('.js-car'),
			popupsSection: this.root.find('.js-popups-section'),
			popup1: this.root.find('.js-popup1'),
			popup2: this.root.find('.js-popup2'),
			popup3: this.root.find('.js-popup3'),
			popup4: this.root.find('.js-popup4'),
			layoutSection: this.root.find('.js-layout-section'),
			buckets: this.root.find('.js-buckets'),
			cones: this.root.find('.js-cones'),
			iconsSection: this.root.find('.js-icons-section'),
			iconsSet: this.root.find('.js-icons-set'),
			finalSection: this.root.find('.js-final-section'),
			pad3: this.root.find('.js-pad3'),
			hand2: this.root.find('.js-hand2'),
			deliverySectionTitle: this.root.find('.js-delivery-section-title'),
			deliveryText: this.root.find('.js-delivery-text'),
			portions: this.root.find('.js-portion')
		};

		this.nodes.mouseWheelCatcher.css({
			position: 'absolute',
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
			display: 'none'
		});
	}

	_bindEvents () {
		this.nodes.wrapper.on('mousewheel', () => {
			this.isMouseScroll = true;
		});

		this.nodes.wrapper.on('scroll', () => {
			if(!this.isMouseScroll) {
				if (!$$.isMobile) {
					this._debouncedGoToNearestSection();
				}
			}

			this.isMouseScroll = false;
		});

		this.root.add(this.nodes.layoutSection).on('mousewheel', () => {
			this._springScroller.step(this.nodes.layoutSection.outerHeight(true) / 10);
		});

		this.root.add(this.nodes.pinTargets)
			.on('mousewheel', (event) => {
				if (this._waitingForDependenciesToLoad) {
					event.stopPropagation();
				}

				if (this._preventMouseWheel) {
					event.preventDefault();
					event.stopPropagation();
					return false;
				}

				var mousewheelTarget = $(event.currentTarget);
				this._springScroller.step($$.windowHeight);

				if (this._springScroller._spring.position().toFixed(4)
						!== this._springScroller._spring.target().toFixed(4)){
					event.stopPropagation();
					event.preventDefault();
				} else {
					this._springScroller._scrollOptions.breakSpring = false;

					if (mousewheelTarget.parent().filter('.js-popups-section').length) {
						this.preventWheelOptions = {
							deltaValue: 1,
							flag: true
						};
					}

					if (mousewheelTarget.parent().filter('.js-icons-section').length) {
						this.preventWheelOptions = {
							deltaValue: -1,
							flag: true
						};

						this._springScroller.step($$.windowHeight * 1.2);
					}

					if (mousewheelTarget.find('.js-delivery-section').length && this.carSceneIsFinished) {
						event.stopPropagation();
						event.preventDefault();

						if (this.deliveryTimeLine.progress()) {
							return;
						}

						this.deliveryTimeLine.play();
						this._scrollMagicController.update(true);
						this.deliveryTimeLine.progress(0);

						_.delay(() => {
							this.carSceneIsFinished = false;
						}, 800);
					}

					if (mousewheelTarget.find('.js-tizers-section').length) {
						this.deliveryTimeLine.progress(0);
						this.deliveryTimeLine.pause();
					}
				}
			});

		this.nodes.layoutSection.on('mousewheel', (event) => {
			if (this._springScroller._spring.position().toFixed(4)
					=== this._springScroller._spring.target().toFixed(4)){
				this.preventWheelOptions.flag = false;
			}

			let delta = event.deltaY / Math.abs(event.deltaY);

			if (this.preventWheelOptions && this.preventWheelOptions.flag
					&& this.preventWheelOptions.deltaValue === delta) {
				event.stopPropagation();
				event.preventDefault();
			}
		});

		var isDetached = () => {
			if (!jQuery.contains(document.documentElement, this.root.get(0))) {
				this.destroy();
				return true;
			}

			return false;
		};

		$$.window.on('resize.' + this.uniqueHash, () => {
			if (isDetached()) {
				return;
			}

			this.resize();
		});

		this.nodes.navNext.on('click', _.bind(function (event) {
			var currentActiveScreenShotIndex = this.nodes.screenShots.filter('.active').index();

			this.nodes.screenShots.removeClass('active')
					.eq((this.screenShotsLength + currentActiveScreenShotIndex + 1) % this.screenShotsLength)
					.addClass('active');
		}, this));

		this.nodes.navPrev.on('click', _.bind(function (event) {
			var currentActiveScreenShotIndex = this.nodes.screenShots.filter('.active').index();

			this.nodes.screenShots.removeClass('active')
					.eq((this.screenShotsLength + currentActiveScreenShotIndex) % this.screenShotsLength)
					.addClass('active');
		}, this));
	}

	_loadDependencies (callback = _.noop) {
		// По какой-то причине JSON больше не мапится в data-объект здесь, хотя делает это во время выполнения
		// ServiceSlider._preloadExtraResources, так что читаем из дата-аттрибута вручную.

		let dependencies = this.root.attr('data-dependencies');

		try { dependencies = $.parseJSON(dependencies); }
		catch (e) { dependencies = []; }

		if (_.isArray(dependencies) && dependencies.length) {
			$script.order(dependencies, callback);
		} else {
			callback();
		}
	}

	_bindAnimationSequence () {
		if (this._scrollMagicController) {
			throw 'Already initialized';
		} else if (this._destroyed) {
			return;
		}

		this._scrollMagicController = new ScrollMagic.Controller({
			container: this.nodes.wrapper.get(0),
			globalSceneOptions: {
				tweenChanges: true,
				triggerHook: 0.6
			}
		});

		var headerTimeLine = new TimelineMax()
				.from(this.nodes.snow, 1, { opacity: '0' })
				.delay(1);

		var nameLogoTimeLine = new TimelineMax()
				.from(this.nodes.nameLogo, 1.2, { opacity: '0' })
				.from(this.nodes.start, 1, {
					onComplete: _.bind(function () {
						this.nodes.start.css('height', '100%');
						this.nodes.startSection.css('backgroundImage', 'none');
						this.nodes.patternSVG.remove();
					}, this),
					top: '-967px',
					ease: Linear.easeNone
				})
				.delay(1.5);

		new ScrollMagic.Scene({
			triggerElement: this.nodes.startSection.get(0)
		})
			.addTo(this._scrollMagicController)
			.setTween(nameLogoTimeLine);

		new ScrollMagic.Scene({
			triggerElement: this.nodes.startSection.get(0)
		})
			.addTo(this._scrollMagicController)
			.setTween(headerTimeLine);

		var phoneTimeLine = new TimelineMax()
			.from(this.nodes.phone, 1, { right: '-150%', ease: Linear.easeNone })
			.delay(4).duration(1.2);

		new ScrollMagic.Scene({
			triggerElement: this.nodes.startSection.get(0)
		})
				.addTo(this._scrollMagicController)
				.setTween(phoneTimeLine);

		var padTimeLine = new TimelineMax()
			.from(this.nodes.pad, 1, {
				onComplete: _.bind(function () {
					this.nodes.navPrev.add(this.nodes.navNext).addClass('opacity-animate');

					_.delay(_.bind(function () {
						this.nodes.navPrev.add(this.nodes.navNext).removeClass('opacity-animate').addClass('small');
					}, this), 1600);
				}, this),
				right: '-150%',
				ease: Linear.easeNone })
			.delay(4).duration(1.2);

		new ScrollMagic.Scene({
			triggerElement: this.nodes.startSection.get(0)
		})
				.addTo(this._scrollMagicController)
				.setTween(padTimeLine);

		var bookTimeLine = new TimelineMax()
				.from(this.nodes.book, 1, { right: '-100%', ease: Linear.easeNone })
				.delay(4).duration(1.2);

		new ScrollMagic.Scene({
			triggerElement: this.nodes.startSection.get(0)
		})
			.addTo(this._scrollMagicController)
			.setTween(bookTimeLine);

		var tizersTimeLine = new TimelineMax()
			.to({}, 1, {
				onComplete: _.bind(function () {
					var delay = 140;

					this.nodes.tizers.each(_.bind(function (index, element) {
					    _.delay(_.bind(function () {
					        $(element).addClass('large');
					    }, this), delay);

						delay = delay + 120;
					}, this));
				}, this),
				onReverseComplete: _.bind(function () {
				    this.nodes.tizers.removeClass('large');
				}, this)
			});

		new ScrollMagic.Scene({
			triggerElement: this.nodes.tizersSection.get(0)
		})
				.addTo(this._scrollMagicController)
				.setTween(tizersTimeLine);

		this.deliveryTimeLine = new TimelineMax()
			.to(this.nodes.car, 1, {
				left: '110%',
				ease: Linear.easeNone
			})
			.from(this.nodes.deliveryBg, 0.65, {
				top: '120%',
				opacity: '0',
				ease: Linear.easeNone
			})
			.from(this.nodes.deliveryText, 0.4, { opacity: '0', ease: Linear.easeNone})
			.from(this.nodes.pad2, 0.4, {
				bottom: '-9.375%',
				opacity: '0',
				ease: Linear.easeNone
			})
			.from(this.nodes.hand, 0.4, {
				bottom: '31.65%',
				opacity: '0',
				ease: Linear.easeNone
			})
			.delay(0.3);

		new ScrollMagic.Scene({
			triggerElement: this.nodes.deliverySection.get(0)
		})
				.addTo(this._scrollMagicController)
				.setTween(this.deliveryTimeLine);

		var carTimeLine = new TimelineMax()
				.from(this.nodes.car, 2, {
					left: '-50%',
					ease: Power1.easeOut, y: 0,
					onComplete: _.bind(function () {
						this.carSceneIsFinished = true;
					}, this)
				});

		new ScrollMagic.Scene({
			triggerElement: this.nodes.deliverySection.get(0)
		})
				.addTo(this._scrollMagicController)
				.setTween(carTimeLine);

		var portionChangeInterval = null;

		var popupsTimeLine = new TimelineMax()
			.from(this.nodes.popup1, 0.8, {
				opacity: '0',
				ease: Linear.easeNone
			})
			.from(this.nodes.popup2, 0.8, {
				opacity: '0',
				ease: Linear.easeNone
			})
			.from(this.nodes.popup3, 0.8, {
				opacity: '0',
				ease: Linear.easeNone
			})
			.from(this.nodes.popup4, 0.8, {
				opacity: '0',
				ease: Linear.easeNone,
				onStart: _.bind(function () {
					portionChangeInterval = setInterval(_.bind(function () {
						var visibleItemIndex = this.nodes.portions.filter(':visible').index();

					    this.nodes.portions
						    .eq((this.nodes.portions.length + visibleItemIndex + 1) % this.nodes.portions.length)
							.show().siblings().filter('.js-portion').hide();
					}, this), 850);
				}, this),
				onReverseComplete: _.bind(function () {
				    clearInterval(portionChangeInterval);
				}, this)
			}).delay(0.8);

		new ScrollMagic.Scene({
			triggerElement: this.nodes.popupsSection.get(0)
		})
				.addTo(this._scrollMagicController)
				.setTween(popupsTimeLine);

		this._layoutSectionScrollMagicController = new ScrollMagic.Controller({
			container: this.nodes.wrapper.get(0),
			globalSceneOptions: {
				tweenChanges: true,
				triggerHook: 0.1
			}
		});

		new ScrollMagic.Scene({
			triggerElement: this.nodes.layoutSection.get(0),
			duration: this.nodes.layoutSection.height() - $$.windowHeight
		})
			.on("leave", () => {
				var newTargetPosition;
				var wrapperScrollTop = this.nodes.wrapper.prop('scrollTop');
				var popupSectionPositionTop = this.nodes.popupsSection.position().top + wrapperScrollTop;
				var iconsSectionPositionTop = this.nodes.iconsSection.position().top + wrapperScrollTop;
				var wrapperScrollHeight = this.nodes.wrapper.prop('scrollHeight') - this.nodes.wrapper.height();

				if (this.nodes.iconsSection.get(0).getBoundingClientRect().top < $$.windowHeight * 2) {
					newTargetPosition = iconsSectionPositionTop / wrapperScrollHeight;
				}

				if (this.nodes.layoutSection.get(0).getBoundingClientRect().top > 0) {
					newTargetPosition = popupSectionPositionTop / wrapperScrollHeight;
				}

				this._springScroller._spring.target(newTargetPosition);
				this._springScroller._scrollOptions.breakSpring = true;
			})
			.addTo(this._layoutSectionScrollMagicController);

		var bucketsTimeLine = new TimelineMax()
				.from(this.nodes.buckets, 1, {
					left: '-38%',
					ease: Linear.easeNone
				});

		new ScrollMagic.Scene({
			duration: () => $$.windowHeight / 2,
			triggerHook: 1,
			triggerElement: this.nodes.buckets.get(0)
		})
				.setTween(bucketsTimeLine)
				.addTo(this._scrollMagicController);

		var conesTimeLine = new TimelineMax()
				.from(this.nodes.cones, 1, {
					right: '-30%',
					ease: Linear.easeNone
				});

		new ScrollMagic.Scene({
			duration: () => $$.windowHeight / 2,
			triggerHook: 1,
			triggerElement: this.nodes.cones.get(0)
		})
				.setTween(conesTimeLine)
				.addTo(this._scrollMagicController);

		var iconsTimeLine = new TimelineMax()
				.from(this.nodes.iconsSet, 1, {
					opacity: '0',
					top: '33%',
					ease: Linear.easeNone
				})
				.delay(1)
				.duration(0.5);

		new ScrollMagic.Scene({
			triggerElement: this.nodes.iconsSection.get(0)
		})
				.addTo(this._scrollMagicController)
				.setTween(iconsTimeLine);

		var pad3TimeLine = new TimelineMax()
				.from(this.nodes.pad3, 1, {
					right: '92.7%',
					opacity: '0',
					ease: Linear.easeNone })
				.delay(0.7).duration(0.7);

		new ScrollMagic.Scene({
			triggerElement: this.nodes.finalSection.get(0)
		})
				.addTo(this._scrollMagicController)
				.setTween(pad3TimeLine);

		var hand2TimeLine = new TimelineMax()
				.from(this.nodes.hand2, 1, {
					right: '-10%',
					opacity: '0',
					ease: Linear.easeNone })
				.delay(0.7).duration(0.7);

		new ScrollMagic.Scene({
			triggerElement: this.nodes.finalSection.get(0)
		})
				.addTo(this._scrollMagicController)
				.setTween(hand2TimeLine);
	}

	_ready () {
		this.waitingForDependenciesToLoad = false;

		_.delay(() => {
			this._preventMouseWheel = false;
		}, 1200);

		this._springScroller.resume();

		if (this._isInitialTick) {
			this.nodes.loader.hide();
			this.nodes.wrapper.css('opacity', 1);
		} else {
			this.nodes.loader.velocity('fadeOut');
			this.nodes.wrapper.velocity('fadeIn');
		}

		try {
			BackgroundCheck.init({
				targets: '.p-header .logo, .js-breadcrumbs, .button-switch-state-right-wrapper, .js-slash-menu',
				images: '.b-moronezh img',
				classes: {
					dark: 'background--light',
					light: 'background--dark',
					complex: 'background--complex'
				}
			});

			_.defer(function() { BackgroundCheck.refresh(); });
		} catch(e) {}

		this.screenShotsLength = this.nodes.screenShots.length;
		this._bindAnimationSequence();
	}

	_initScrollSpring () {
		this._springScroller = new $$.SmoothScroller(this.nodes.wrapper, {
			velocityLimit: 0.35
		}, {
			preventMovingTargetTooFar: true,
			doNotRound: true,
			stepSize: $$.windowHeight
		});


		this._updateScene();
		this._springScroller.onScroll = () => this._updateScene();
	}

	_updateScene () {
		if (!this._scrollMagicController) {
			return;
		}

		this._scrollMagicController.update(true);
		this._layoutSectionScrollMagicController.update(true);
		this._recheckBackground();
	}

	destroy () {
		if (this._destroyed) {
			return;
		}

		this._destroyed = true;
		this._scrollMagicController.destroy();
		this.root.off();
		this.nodes.wrapper.off();

		$$.window.off('resize.' + this.uniqueHash);

		this._scrollMagicController = _.noop();
		this._recheckBackground = _.noop;

		this._springScroller.destroy();
		this._springScroller = _.noop();
	}

	pause () {
		this.isPaused = true;

		if (this._waitingForDependenciesToLoad) {
			return;
		}

		this._springScroller.pause();
	}

	repositioned (isAnimationEnded = false) {
		if (this._destroyed) {
			return;
		}

		if (isAnimationEnded) {
			this.nodes.mouseWheelCatcher.hide();

			if (!this.waitingForDependenciesToLoad && !this.isPaused) {
				// а без этого ScrollMagic тупит почему-то и не хочет пересчитывать высоту triggerHook. Его собственными методами не чинится
				$$.window.trigger('resize');

				_.defer(() => {
					$$.body.addClass('black-controls');
				});
			}
		} else {
			$$.body.removeClass('black-controls');
			this.nodes.mouseWheelCatcher.show();
			this._updateScene();
		}
	}

	resume (comingFromTop) {
		this.isPaused = false;

		if (this.waitingForDependenciesToLoad || this._destroyed) {
			return;
		}

		this._springScroller.resume();
		this._scrollMagicController.scrollTo(comingFromTop ? 0 : this.nodes.wrapper.prop('scrollHeight'));
	}

	resize () {
		if (this.waitingForDependenciesToLoad || this._destroyed) {
			return;
		}

		this._springScroller.recalculate();
		this._updateScene();
		this._goToNearestSection();
	}

	_goToNearestSection () {
		var nearestSection;

		this.nodes.sections.each(_.bind(function (index, section) {
		    if (section.getBoundingClientRect().top < $$.windowHeight / 2
				    && section.getBoundingClientRect().top > -($$.windowHeight / 2)) {
			    nearestSection = $(section);
			    return false;
		    }
		}, this));

		if (_.isUndefined(nearestSection)) {
			return false;
		}

		var wrapperScrollTop = this.nodes.wrapper.prop('scrollTop');
		var nearestSectionPositionTop = nearestSection.position().top + wrapperScrollTop;
		var wrapperScrollHeight = this.nodes.wrapper.prop('scrollHeight') - this.nodes.wrapper.height();
		var newTargetPosition = nearestSectionPositionTop / wrapperScrollHeight;

		this._springScroller._spring.target(newTargetPosition);
	}
};
