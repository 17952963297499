var $$ = $$ || {};

/* NOTE: Заметка по ньюансам ScrollMagic для тех, кто будет использовать этот код для референса.
 *
 * Самые большие подводные грабли здесь с методом .setPin в инстансе Scene скроллмэджика.
 * Не делайте .setPin на тот элемент, который анимируете. Этому есть 2 причины:
 *
 * 1. Элемент начнет мерцать во время анимации, если так же поставлено .tweenChanges(true)
 *    Почему так происходит точно не знаю, но есть мнение, что элемент переносится в доме в этот момент.
 *    У меня при этом не получилось повторить проблему на jsfiddle когда делал тест кейс.
 * 2. Когда вы пините элемент - сцена считывает стили закрепляемого элемента в момент вызова функции.
 *    но к тому моменту мы уже написали в коде new Timeline или TweenMax.to и несмотря на то, что следом,
 *    после добавления твина к сцене она его останавливает - он все равно успевает примениться.
 *    В следствии чего .setPin получает неверное представление о том, где должен находиться элемент.
 *    СкроллМэджик явно пытается как-то работать вокруг этого случая, но получается у него не очень и результаты
 *    разнятся для разных версий GSAP.
 *    В ише <https://github.com/janpaepke/ScrollMagic/issues/424> я оставил кучу фиддлов с разными комбинациями
 *    создания твина, сцены и прочего, но самый надежный вариант - завернуть всё во враппер.
 *
 * Я не думаю, что это где-то написано в доках, но pinned элемент может быть только один на сцену.
 *
 * Еще один важный момент - это метод инстанса `ScrollMagic` (обычно именуется контроллером) `.enabled()`.
 * Не выключайте контроллер, от этого тоже ломаются закрепленные элементы, иногда он просто не хочет включаться назад.
 * Вместо этого лучше просто не давать слою прокручиваться.
 *
 * Последнее: во время входа слайда на экран он уже активен и может быть прокручен пользователем. Закрепленные
 * элементы закрепятся в неправильной позиции и останутся там до следующего скролла. Это не совсем то поведение, что мы
 * хотим, так что можно перехватывать `mousewheel` событие во время этой анимации каким-нибудь элементом и не давать
 * скроллить. События смены слайда планировалось добавить к window или $$.application, но пока еще не готово.
 *
 * И читшит по GSAP: https://ihatetomatoes.net/wp-content/uploads/2015/08/GreenSock-Cheatsheet-2.pdf
 */

$$.Apples = class Apples {
	constructor (root) {
		this.root = root;

		// Переменные и флаги

		this._hash = 'portfolio-entry-apples-' + _.uniqueId();
		this._scrollHeight = 0; // В нашем случае соответствует максимально возможному scrollTop
		this._paused = false;
		this._destroyed = false;
		this._scrollMagicController = _.noop();

		this._isInitialTick = true; _.defer( () => this._isInitialTick = false );
		this._waitingForDependenciesToLoad = true;

		// Инициализируем

		this._cacheNodes();
		this._bindEvents();
		this._initScrollSpring();

		this.resize();

		this.nodes.loader.show();
		this.nodes.payload.css('opacity', 0);

		$script.ready('vendor-postponed', () => {
			this._ready();
		});
	}

	_cacheNodes () {
		let payload = this.root.children('.apples-payload');

		this.nodes = {
			loader: this.root.children('.js-loading'),
			payload: payload,
			mouseWheelCatcher: $('<div/>').appendTo(this.root),

			section1: payload.children('.section1'),
			s1Leaves: payload.find('.section1 .leaves'),
			s1Macbook: payload.find('.section1 .macbook'),
			s1Ipad: payload.find('.section1 .ipad'),
			s1PinTarget: payload.find('.section1 .pin-target'),

			s2Video: payload.find('.section2 .video-banner'),

			section3: payload.children('.section3'),
			s3Fruits: payload.find('.section3 .fruits'),
			s3Monitors: payload.find('.section3 .monitors'),
			s3ImagesContainer: payload.find('.section3 .images-container'),
			s3PinTarget: payload.find('.section3 .pin-target'),

			section41: payload.find('.section4 .screenshot-block:first'),
			section42: payload.find('.section4 .screenshot-block:last'),
			s4Raspberry: payload.find('.section4 .raspberry'),
			s4Bottole1: payload.find('.section4 .red-bottle'),
			s4Bottole2: payload.find('.section4 .orange-bottle'),
			s4Apples: payload.find('.section4 .apples'),

			section6: payload.children('.section6'),
			s6IPad: payload.find('.section6 .ipad'),
			s6Hand: payload.find('.section6 .hand'),
			s6PinTarget: payload.find('.section6 .pin-target')
		};

		this.nodes.mouseWheelCatcher.css({
			position: 'absolute',
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
			display: 'none'
		});
	}

	/**
	 * Вешает обработчики событий на компоненты/элементы.
	 *
	 * @private
	 */
	_bindEvents () {
		this.nodes.s3Monitors.on('load', () => this.resize() );
		this.nodes.s6IPad.on('load', () => this.resize() );
		this.nodes.mouseWheelCatcher.on('mousewheel', () => false);

		this.root.on('mousewheel', (event) => {
			if (this._waitingForDependenciesToLoad) {
				event.stopPropagation();
			}
		});

		let isDetached = () => {
			if (!jQuery.contains(document.documentElement, this.root.get(0))) {
				this.destroy();
				return true;
			}

			return false;
		};

		$$.window.on('resize.' + this._hash, () => {
			if (isDetached()) {
				return
			}

			this.resize();
		});

		/*this.nodes.s2Video.click(function() {
			let popup = new $$.VideoPopup($(this).data('videoData'));

			$$.application.appendPopup(popup);
			popup.show(500);
		});*/

		this.nodes.s2Video.click(function() {
			if ($(this).hasClass('hasPlayer')) {
				return;
			}

			$(this).addClass('has-player');

			/*let player = $('<div class="player-container"/>');
			let videoData = $(this).data('videoData');
			let iframeUrl = '';

			if (videoData.provider == 'vimeo') {
				iframeUrl = '//player.vimeo.com/video/' + videoData.videoId + '?byline=0&portrait=0&autoplay=1&wmode=transparent';
			} else if (videoData.provider == 'youtube') {
				iframeUrl = '//www.youtube.com/embed/' + videoData.videoId + '?rel=0&showinfo=0&autoplay=1&iv_load_policy=3&wmode=transparent';
			} else {
				return;
			}

			player
				.append('<iframe class="player" src="" width="100%" height="100%" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen wmode=”opaque”></iframe>')
				.appendTo(this)
				.find('iframe').attr('src', iframeUrl);*/
		});
	}

	_initScrollSpring () {
		this._springScroller = new $$.SmoothScroller(this.nodes.payload, {
			velocityLimit: 0.35
		});

		this._springScroller.pause();

		this._springScroller.onScroll = () => this._updateScene();
	}

	_bindAnimationSequence () {
		if (this._scrollMagicController) {
			throw 'Already initialized';
		} else if (this._destroyed) {
			return;
		}

		this._scrollMagicController = new ScrollMagic.Controller({
			container: this.nodes.payload.get(0),
			globalSceneOptions: { // Имеет больший приоритет над сценами
				//tweenChanges: true // Без пиннед элементов смотрится странно порой
			}
		});

		let timeline;

		// Section 1
		/*
		timeline = new TimelineMax()
			.from(this.nodes.s1Leaves  , 1.8, { left: '-25%', opacity: 0, ease: Power2.easeOut }) // http://greensock.com/ease-visualizer
			.from(this.nodes.s1Macbook , 1.2, { right: '100%', ease: Sine.easeOut }, 0.2)
			.from(this.nodes.s1Ipad    , 1.2, { left: '100%', ease: Sine.easeOut }, 0.2);

		new ScrollMagic.Scene({
			duration: 2700,
			tweenChanges: true
		}).setTween(timeline)
			.setPin(this.nodes.s1PinTarget.get(0))
			.addTo(this._scrollMagicController);
		*/

		// Section 1
		timeline = new TimelineMax()
			.from(this.nodes.s1Leaves  , 1.8, { transform: "matrix(1,0,0,1,"+ -($$.windowWidth * 0.25) +",0)", opacity: 0, ease: Power2.easeOut }) // http://greensock.com/ease-visualizer
			.from(this.nodes.s1Macbook , 1.2, { right: '100%', ease: Power3.easeOut }, 0.2)
			.from(this.nodes.s1Ipad    , 1.2, { left: '100%', ease: Power3.easeOut }, 0.2);

		timeline
			.duration(4)
			.seek(0)
			.play();

		// section 2
		//document.getElementById('vimeo1').play();

		// Section 3, pinned version, looks terrible and makes no sense

		// pinned version
		/*timeline = new TimelineMax()
			.from(this.nodes.s3Fruits  ,      1, { right: '100%', ease: Sine.easeOut })
			.from(this.nodes.s3Monitors,      1, { left: '60%', ease: Sine.easeOut }, 0)
			.to(this.nodes.s3ImagesContainer, 1, { y: '-75%', ease: Sine.easeOut }, 0)
			.to({}, 0.2, {}); // небольшая пауза

		new ScrollMagic.Scene({
			duration: () => 3500,
			triggerHook: 0.7,
			triggerElement: this.nodes.section3.get(0)
		}).setTween(timeline)
			.setPin(this.nodes.s3PinTarget.get(0))
			.addTo(this._scrollMagicController)
			.addIndicators();*/

		// Section 3

		timeline = new TimelineMax()
			.from(this.nodes.s3Fruits   , 1, { transform: "matrix(1,0,0,1,"+ -$$.windowWidth +",0)", ease: Sine.easeOut })
			.from(this.nodes.s3Monitors , 1, { transform: "matrix(1,0,0,1,"+ ($$.windowWidth * 0.6) +",0)", ease: Sine.easeOut }, 0)
			.to({}, 0.2, {}); // небольшая пауза

		new ScrollMagic.Scene({
			duration: () => this.nodes.s3ImagesContainer.height(),
			triggerHook: 0.7,
			triggerElement: this.nodes.section3.get(0)
		}).setTween(timeline)
			.addTo(this._scrollMagicController);

		// Section 4.1

		timeline = new TimelineMax()
			.from(this.nodes.s4Raspberry, 1, { transform: "matrix(1,0,0,1,"+ -($$.windowWidth * 0.93) +",0)", ease: Sine.easeOut });

		new ScrollMagic.Scene({
			duration: () => $$.windowHeight,
			triggerHook: 1,
			triggerElement: this.nodes.s4Raspberry.get(0)
		})
			.setTween(timeline)
			.addTo(this._scrollMagicController);

		// Section 4.2

		timeline = new TimelineMax()
			.from(this.nodes.s4Bottole2 , 1.8, { right: '0', ease: Sine.easeOut })
			.from(this.nodes.s4Bottole1 , 1,   { left: '0', ease: Sine.easeOut }, 0.5)
			.from(this.nodes.s4Apples   , 1,   { left: '0', ease: Sine.easeOut }, 0.8);

		new ScrollMagic.Scene({
			duration: () => $$.windowHeight * 1.5,
			triggerHook: 1,
			triggerElement: this.nodes.section42.get(0)
		})
			.setTween(timeline)
			.addTo(this._scrollMagicController);

		// Section 6

		/*timeline = new TimelineMax()
			.from(this.nodes.s6Hand, 1, { left: '100%', opacity: 0, ease: Power1.easeOut })
			.from(this.nodes.s6IPad, 1, { left: '0', opacity: 0, ease: Power1.easeOut }, 0)
			.to({}, 0.3, {}); // небольшая пауза;

		new ScrollMagic.Scene({
			duration: () => $$.windowHeight * 2,
			triggerElement: this.nodes.section6.get(0),
			triggerHook: 0,
			tweenChanges: true
		})
			.setPin(this.nodes.s6PinTarget.get(0))
			.setTween(timeline)
			.addTo(this._scrollMagicController);*/

		this.nodes.s6PinTarget.css('height', 'auto');

		timeline = new TimelineMax()
			.from(this.nodes.s6Hand, 1, { transform: "matrix(1,0,0,1,"+ $$.windowWidth +",0)", opacity: 0, ease: Sine.easeOut })
			.from(this.nodes.s6IPad, 1, { transform: "matrix(1,0,0,1,0,0)", opacity: 0, ease: Sine.easeOut }, 0)
			.duration(1.5);

		new ScrollMagic.Scene({
			triggerElement: this.nodes.section6.get(0),
			triggerHook: 0.6,
			tweenChanges: true
		})
			.addTo(this._scrollMagicController)
			.setTween(timeline);
	}

	_ready () {
		this._waitingForDependenciesToLoad = false;

		this._springScroller.resume();

		this.nodes.payload
			.css({
				zIndex: '0', // без этого стики элементы будут отрисовываться поверх активного слайда
				overflow: 'auto'
			});

		// Если вызвано в тот же тик, что и конструктор - ничего не анимируем.
		if (this._isInitialTick) {
			this.nodes.loader.hide();
			this.nodes.payload.css('opacity', 1);
		} else {
			this.nodes.loader.velocity('fadeOut');
			this.nodes.payload.velocity('fadeIn');
		}

		this._bindAnimationSequence();
	}

	_updateScene () {
		if (!this._scrollMagicController) {
			return;
		}

		this._scrollMagicController.update(true);
	}

	destroy () {
		if (this._destroyed) {
			return;
		}

		this._destroyed = true;
		this._scrollMagicController.destroy();
		this.root.off();
		this.nodes.payload.off();

		$$.window.off('resize.' + this._hash);

		this._scrollMagicController = _.noop();

		this._springScroller.destroy();
		this._springScroller = _.noop();
	}

	pause () {
		this._paused = true;

		if (this._waitingForDependenciesToLoad) {
			return;
		}

		this._springScroller.pause();
	}

	/**
	 * Эта функция должна вызываться извне и уведомлять нас, что позиция слайда на экране изменилась
	 *
	 * @param animationEnded Закончилась ли анимация. На самом деле вызывается только, когда мы и есть слайд к которому осуществлялся переход
	 */
	repositioned (animationEnded = false) {
		if (this._destroyed) {
			return;
		}

		if (animationEnded) {
			this.nodes.mouseWheelCatcher.hide();
			if (!this._waitingForDependenciesToLoad && !this._paused) {
				// а без этого ScrollMagic тупит почему-то и не хочет пересчитывать высоту triggerHook. Его собственными методами не чинится
				$$.window.trigger('resize');
			}

			_.defer(function() {
				$$.body.addClass('black-controls');
			});
		} else {
			this.nodes.mouseWheelCatcher.show();
			$$.body.removeClass('black-controls');
			this._updateScene();
		}
	}

	resize () {
		if (this._waitingForDependenciesToLoad || this._destroyed) {
			return;
		}

		this.nodes.s1PinTarget.width(this.root.width());
		this.nodes.s3PinTarget.width(this.root.width());
		this.nodes.s3ImagesContainer.height(this.nodes.s3Monitors.height());

		this._springScroller.recalculate();
		this._updateScene();
	}

	resume (comingFromTop) {
		this._paused = false;

		if (this._waitingForDependenciesToLoad || this._destroyed) {
			return;
		}

		this._springScroller.resume();
		this._scrollMagicController.scrollTo(comingFromTop ? 0 : this.nodes.payload.prop('scrollHeight'));
	}
};
