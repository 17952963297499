var $$ = $$ || {};

$$.OuterBlur = class OuterBlur {
	constructor () {
		this.blurredCanvases = {};
		this.baseBlur = new $$.BaseBlur();
	}

	/**
	 * Размытие фоновой картинки для случаев, где не требуется от фона
	 * какой-либо динамики
	 * @param {jQuery} block - блок, в который требуется положить размытую картинку
	 * @param {String} imageUrl - картинка, которую требуется размыть
	 * @param radius - радиус размытия
	 */
	fillBlurredBackground (block, imageUrl, radius = 11) {
		var canvas = $('<canvas class="g-canvas-background">');
		var context = canvas[0].getContext('2d');

		block.prepend(canvas);
		this.baseBlur.fillWhollyCanvas(context, imageUrl, radius);
	}

	/**
	 * Регистрируем внешнюю картинку, которую нужно запомнить
	 * @param {jQuery} canvas
	 * @param {jQuery} imageBlock
	 * @param {Number} radius
	 */
	registerAndLoadAnimatedBlur (canvas, imageBlock, radius = 9) {
		var imageUrl = imageBlock.data('src');
		var context = canvas[0].getContext('2d');
		var deferred = $.Deferred();

		imageBlock.attr('src', imageUrl);

		this.baseBlur.fillWhollyCanvas(context, imageUrl, radius).then(() => {
			deferred.resolve();
		});

		this.blurredCanvases[imageUrl] = { canvas, imageBlock, radius };

		return deferred.promise();
	}

	/**
	 * Здесь необходимо передать url картинки, которая регистрировалась при помощи
	 * метода registerAndLoadAnimatedBlur и зарегестрированный вместе с этой картинкой
	 * канвас разблюрится
	 * @param imageUrl
	 */
	animatedUnBlur (imageUrl) {
		var item = this.blurredCanvases[imageUrl];
		var context = item.canvas[0].getContext('2d');
		var image = item.imageBlock[0];
		var deferred = $.Deferred();

		this.baseBlur.fillWhollyCanvasAnimate(context, image.src, 10, 2).then(() => {
			item.imageBlock.velocity('fadeIn', 50, () => {
				deferred.resolve();
			});
		});

		return deferred.promise();
	}

	/**
	 * Здесь необходимо передать url картинки, которая регистрировалась при помощи
	 * метода registerAndLoadAnimatedBlur и зарегестрированный вместе с этой картинкой
	 * канвас заблюрится
	 * @param imageUrl
	 */
	animatedBlur (imageUrl) {
		var item = this.blurredCanvases[imageUrl];
		var context = item.canvas[0].getContext('2d');
		var image = item.imageBlock[0];
		var deferred = $.Deferred();

		item.imageBlock.velocity('fadeOut', 50, () => {
			this.baseBlur.fillWhollyCanvasAnimate(context, image.src, 2, 10);
			deferred.resolve();
		});

		return deferred.promise();
	}
};
