(function($$) {
	function getCanvasFromArgs(widthOrCanvas, height) {
		let canvas;

		if (_.isNumber(widthOrCanvas) && _.isNumber(height)) {
			canvas = CanvasUtils.createCanvas(widthOrCanvas, height);
		} else if (widthOrCanvas && CanvasUtils.isCanvas(widthOrCanvas)) {
			canvas = widthOrCanvas;
		} else {
			throw "Unexpected input. Expected two numbers representing width and height of the new canvas, or reference to existing canvas element.";
		}

		return canvas;
	}

	class CanvasUtils {
		/**
		 * Блюрит картинку через стак блюр
		 *
		 * @param bitmap
		 * @param radius
		 * @param downscalingAllowed - improves performance
		 * @param modifyGivenCanvas - должен ли канвас быть склонирован или можно редактировать переданный. По-умолчанию будет скопирован
		 * @param extraDownSamplingOnMobile
		 * @returns {HTMLCanvasElement}
		 */
		static blur(bitmap, radius, downscalingAllowed = true, modifyGivenCanvas = false, extraDownSamplingOnMobile = true) {
			const canvas = modifyGivenCanvas ? bitmap : CanvasUtils.cloneCanvas(bitmap);
			const scale = (downscalingAllowed ? Math.max(0.15, Math.min(1, 1 - radius / 70)) : 1);
			const downSamplingRate = scale * ((extraDownSamplingOnMobile && $$.config.isMobile) ? 0.5 : 1 );

			if (scale === 1) {
				stackBoxBlurWidthCanvasRGB(
					canvas,
					0, 0,
					canvas.width, canvas.height,
					radius
				);

				return canvas;
			}

			var minDimensions = $$.calculateDimensions(false, canvas.width, canvas.height, 192, 192);
			const downscaled = CanvasUtils.createCanvas(
				Math.max(minDimensions.width, ~~(canvas.width * downSamplingRate)),
				Math.max(minDimensions.height, ~~(canvas.height * downSamplingRate))
			);
			CanvasUtils.copyBitmap(canvas, downscaled, { mode: 'stretch' });

			stackBoxBlurWidthCanvasRGB(
				downscaled.getContext('2d'),
				0, 0,
				downscaled.width, downscaled.height,
				~~(radius * 0.8 * scale)
			);

			CanvasUtils.copyBitmap(downscaled, canvas, { mode: 'stretch' });

			return canvas;
		}

		/**
		 * Copies bitmap from source to target. When target sizes are different from the source.
		 * It really shines when source and target canvases are of the different size.
		 *
		 * @param {HTMLCanvasElement} source
		 * @param {HTMLCanvasElement} target
		 * @param {Object} [settings]
		 * @param {Object} [settings.mode='cover'] - one of 'cover', 'contain', 'as-is', 'stretch'
		 * @param {Boolean} [settings.upscale=true] - if allowed to be upscaled or not
		 * @param {Number[]|Number} [settings.alignment] - x and y alignment factors. Or a single number representing both
		 * @returns {*}
		 *
		 * TODO: may want to add new mode later: tile. Because why not.
		 */
		static copyBitmap(source, target, settings) {
			const ctx = target.getContext('2d');

			const opts = _.extend({}, {
				mode: 'cover', // 'cover', 'contain', 'as-is', 'stretch'
				upscale: true,
				alignment: [0.5, 0.5]
			}, settings);

			if (!_.isArray(opts.alignment)) {
				opts.alignment = [opts.alignment, opts.alignment];
			}

			if (opts.mode === 'stretch') {
				ctx.drawImage(source, 0, 0, source.width, source.height,
					0, 0, target.width, target.height);
			} else {
				let dimensions = $$.calculateDimensions(
					opts.mode === 'cover',
					source.width, source.height,
					target.width, target.height,
					{
						positionX: opts.alignment[0],
						positionY: opts.alignment[1],
						upscale: opts.upscale
					}
				);

				if (opts.mode === 'as-is') {
					dimensions.width = source.width;
					dimensions.height = source.height;
					dimensions.top = (target.height - source.height) * opts.alignment[1];
					dimensions.left = (target.width - source.width) * opts.alignment[0];
				}

				ctx.drawImage(source, dimensions.left, dimensions.top, dimensions.width, dimensions.height);
			}

			return target;
		}

		/**
		 * Makes exact copy of source
		 *
		 * @param source
		 * @returns {HTMLCanvasElement}
		 */
		static cloneCanvas(source) {
			const newCanvas = CanvasUtils.createCanvas(source.width, source.height);
			const context = newCanvas.getContext('2d');

			context.drawImage(source, 0, 0);

			return newCanvas;
		}

		/**
		 * Creates new canvas
		 *
		 * @param {Number} width
		 * @param {Number} height
		 * @returns {HTMLCanvasElement}
		 */
		static createCanvas(width, height) {
			const newCanvas = document.createElement('canvas');
			newCanvas.width = width;
			newCanvas.height = height;

			return newCanvas;
		}

		/**
		 * If element is canvas element or not
		 *
		 * @param element
		 * @returns {Boolean}
		 */
		static isCanvas(element) {
			return $(element).is('canvas');
		}

		/**
		 * Draws img on canvas element
		 * This function has two signatures.
		 *
		 *  - image, canvasElement, settings
		 *  - image, width, height, settings
		 *
		 * @param {HTMLImageElement} image
		 * @param {Number|HTMLCanvasElement} widthOrCanvas - target canvas or width of desired canvas
		 * @param {Number|Object} [heightOrSettings] - height of canvas or settings object
		 * @param {Object} [settings] - settings object
		 * @returns {HTMLCanvasElement}
		 */
		static imageToCanvas(image, widthOrCanvas, heightOrSettings, settings) {
			const canv = CanvasUtils.createCanvas(image.naturalWidth, image.naturalHeight);
			const ctx2 = canv.getContext('2d');

			ctx2.drawImage(image, 0, 0);

			return CanvasUtils.copyBitmap(canv, getCanvasFromArgs(widthOrCanvas, heightOrSettings), !_.isNumber(widthOrCanvas) ? heightOrSettings : settings);
		}

		/**
		 * Creates canvas out of image url (or draws it over given canvas element, depending on signature used.
		 * Options are almost the same as for imageToCanvas, in exception of callback function, which is called when image is loaded
		 *
		 * @param imageUrl
		 * @param widthOrCanvas
		 * @param height
		 * @param callback
		 * @param settings
		 * @returns {HTMLCanvasElement}
		 */
		static imageUrlToCanvas(imageUrl, widthOrCanvas, height, callback = function() {}, settings = {}) {
			const deferred = new $.Deferred();

			const canvas = getCanvasFromArgs(widthOrCanvas, height);
			const img = new Image();

			img.onload = function() {
				CanvasUtils.imageToCanvas(img, canvas, settings);
				callback(canvas);
				deferred.resolve(canvas);
			};

			img.onerror = function() {
				deferred.reject(imageUrl);
			};

			img.src = imageUrl;

			return deferred.promise();
		}
	}

	$$.CanvasUtils = CanvasUtils;
})(window.$$ || (window.$$ = {}));
