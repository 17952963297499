(function($$) {
	/**
	 * Вычисляет размеры и сдвиг дочернего блока, чтобы он покрывал или оказывался вписан в родительский блок.
	 *
	 * @param {Boolean} circumscribe Нужно ли описать или вписать
	 * @param {Number} childWidth - Ширина целевого элемента
	 * @param {Number} childHeight - Высота целевого элемента
	 * @param {Number} parentWidth - Ширина родительского блока
	 * @param {Number} parentHeight - Высота родительского блока
	 * @param {Object} [options] - Дополнительные опции. Можно указать выравнивание. По умолчанию по центру
	 * @param {Number} [options.positionX] - Коэффициент смещения по горизонтали
	 * @param {Number} [options.positionY] - Коэффициент смещения по вертикали
	 * @param {Number} [options.upscale] - Коэффициент смещения по вертикали
	 *
	 * @return {Object} { left, top, width, height }
	 */
	$$.calculateDimensions = function(circumscribe, childWidth, childHeight, parentWidth, parentHeight, options) {
		options = $.extend({
			positionX: 0.5, // 0 - left, 1 - right
			positionY: 0.5, // 0 - top, 1 - bottom,
			upscale: true
		}, options);

		options.positionX = Math.min(Math.max(options.positionX, 0), 1);
		options.positionY = Math.min(Math.max(options.positionY, 0), 1);

		var parentAR = parentWidth / parentHeight;
		var childAR = childWidth / childHeight;
		var result = {};

		if (circumscribe == childAR < parentAR) {
			result.width = Math.min(parentWidth, options.upsccale ? childWidth : Infinity);
			result.height = Math.ceil(result.width / childAR);
		} else {
			result.height = Math.min(parentHeight, options.upsccale ? childHeight : Infinity);
			result.width = Math.ceil(result.height * childAR);
		}

		result.left = Math.floor((parentWidth - result.width) * options.positionX);
		result.top = Math.floor((parentHeight - result.height) * options.positionY);

		return result;
	}
})(window.$$ || (window.$$ = {}));
