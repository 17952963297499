var $$ = $$ || {};

$$.Config = class Config {
	constructor () {
		this.options = {
			hoverDelay: 50
		};

		this.backgrounds = jsData.backgrounds;
		this.validUrls = jsData.validRoutes;
		this.isMobile = jsData.isMobile;
		this.pageNotFoundUrl = jsData.pageNotFoundUrl;

		this.videos = {
			//'/portfolio': {
			//	'/portfolio/tuilry': '/site/assets/videos/work_shot.mp4',
			//	'/services': '/site/assets/videos/work_shot.mp4'
			//}
		};
	}

	getPageNotFound (url) {
		return this.pageNotFoundUrl;
	}

	isPageNotFound (url) {
		return !_.includes(this.validUrls, url);
	}

	getVideoUrl (startUrl, finishUrl) {
		var fromVideos = this.videos[startUrl];
		var video;

		if (fromVideos) {
			video = fromVideos[finishUrl];
		}

		if (fromVideos && !finishUrl) {
			video = _.first(
				_.values(fromVideos)
			);
		}

		return video;
	}
};
