(function($$) {
	/**
	 * Preloads set of images
	 *
	 * @param {Array} urls
	 * @returns {jQuery.Deferred}
	 * @param {Boolean} [keepReferences = false] If true - additional key 'references' will be supplied withing data object, once promise is resolved (only contains images successfully loaded)
	 * @param considerLoadedWhenSizesAreKnown
	 */
	$$.preload = function(urls, keepReferences = false, considerLoadedWhenSizesAreKnown = false) {
		if (!_.isArray(urls)) {
			throw "Unexpected type of parameter";
		}

		var deferred = new $.Deferred(),
			references = {},
			itemsStatus = [];

		var progressCount = 0;

		$.each(urls, function(index, src) {
			const img = new Image();

			function onload(success) {
				img.onload = _.noop;
				img.onerror = _.noop;

				if (success) {
					references[src] = img;
				}

				itemsStatus[index] = success;
				progressCount++;
				progressChanged();
			}

			img.onload = function() { onload(true); };
			img.onerror = function() { onload(false); };

			if (considerLoadedWhenSizesAreKnown) {
				// TODO: replace with single setInterval maybe?

				setTimeout(function() {
					if (img.naturalWidth != 0)
						img.onload();
				}, 4000);
			}


			img.src = src;
		});

		function progressChanged() {
			var progress = progressCount / urls.length;
			var data = {
				count: progressCount,
				itemsStatus: itemsStatus,
				progress: progress
			};

			deferred.notify(data);

			if (progress >= 1) {
				deferred.resolve(_.extend({}, data, keepReferences ? { references: references } : {}));
			}
		}

		return deferred.promise();
	};
})(window.$$ || (window.$$ = {}));
