var $$ = $$ || {};

$$.Restart = class Restart {
	constructor (root) {
		this.root = root;

		// Переменные и флаги

		this._hash = 'portfolio-entry-apples-' + _.uniqueId();
		this._scrollHeight = 0; // В нашем случае соответствует максимально возможному scrollTop
		this._paused = false;
		this._destroyed = false;
		this._scrollMagicController = null;

		this._isInitialTick = false;
		this._waitingForDependenciesToLoad = true;

		this._scrollPos = 0;
		this._scrollAnimationFrameHandler = null;

		this.currentSectionIndex = 0;
		this.scrollNow = false;

		// Инициализируем
		this._cacheNodes();
		this._bindEvents();
		this._initScrollSpring();
		this.resize();
		this._ready();
	}

	_cacheNodes () {
		let payload = this.root.children('.apples-payload');

		this.nodes = {
			loader: this.root.children('.js-loading'),
			payload: payload,
			mouseWheelCatcher: $('<div/>').appendTo(this.root),
			sections: this.root.find('section'),

			section1: payload.children('.section1'),
			s1Logo: payload.find('.section1 .logo'),
			s1PinTarget: payload.find('.section1 .pin-target'),

			section2: payload.children('.section2'),
			s2Background: payload.find('.section2 .section-background'),
			s2TextLine1: payload.find('.section2 .text-line-1'),
			s2TextLine2: payload.find('.section2 .text-line-2'),
			s2TextLine3: payload.find('.section2 .text-line-3'),
			s2TextLine4: payload.find('.section2 .text-line-4'),
			s2PinTarget: payload.find('.section2 .pin-target'),

			section3: payload.children('.section3'),
			s3ImagesContainer: payload.find('.section3 .images-container'),
			s3Sign1: payload.find('.section3 .sign-1'),
			s3Plus1: payload.find('.section3 .plus-1'),
			s3Sign2: payload.find('.section3 .sign-2'),
			s3Plus2: payload.find('.section3 .plus-2'),
			s3Sign3: payload.find('.section3 .sign-3'),
			s3Equally: payload.find('.section3 .equally'),
			s3Logo: payload.find('.section3 .color-logo'),
			s3PinTarget: payload.find('.section3 .pin-target'),

			section4: payload.find('.section4'),
			s4Card1: payload.find('.section4 .card-1'),
			s4Card1Shadow: payload.find('.section4 .card-1-shadow'),
			s4Card2: payload.find('.section4 .card-2'),
			s4Card3: payload.find('.section4 .card-3'),
			s4Card4: payload.find('.section4 .card-4'),
			s4Card5: payload.find('.section4 .card-5'),
			s4Card6: payload.find('.section4 .card-6'),
			s4Card7: payload.find('.section4 .card-7'),
			s4Card8: payload.find('.section4 .card-8'),
			s4PinTarget: payload.find('.section4 .pin-target'),

			section5: payload.children('.section5'),
			s5Cards: payload.find('.section5 .visit-cards'),
			s5CardsInBox: payload.find('.section5 .visit-cards-in-box'),
			s5PinTarget: payload.find('.section5 .pin-target'),

			section6: payload.children('.section6'),
			s6IconsWrapperTop: payload.find('.section6 .wrapper-top'),
			s6IconsWrapperBottom: payload.find('.section6 .wrapper-bottom'),
			s6PinTarget: payload.find('.section6 .pin-target'),

			section7: payload.children('.section7'),
			s7AtomizerBackground: payload.find('.section7 .large-r-arrow'),
			s7Atomizer: payload.find('.section7 .atomizer'),
			s7PinTarget: payload.find('.section7 .pin-target')
		};

		this.nodes.mouseWheelCatcher.css({
			position: 'absolute',
			top: 0,
			left: 0,
			zIndex: 99999,
			width: '100%',
			height: '100%',
			display: 'none'
		});
	}

	/**
	 * Вешает обработчики событий на компоненты/элементы.
	 *
	 * @private
	 */
	_bindEvents () {
		this.nodes.mouseWheelCatcher.on('mousewheel', () => false);

		this.root.on('mousewheel', (event) => {
			if (this._waitingForDependenciesToLoad) {
				event.stopPropagation();
			}
		});

		this.nodes.payload
			.on('mousewheel', () => {
				if ( !this.scrollNow ) {
					this.scrollNow = true;
					this.nodes.mouseWheelCatcher.show();

					setTimeout(() => {
						this.scrollNow = false;
						this.nodes.mouseWheelCatcher.hide();
					}, this._springScroller._springOptions.rigidness + 50);
				}
			});

		let isDetached = () => {
			if (!jQuery.contains(document.documentElement, this.root.get(0))) {
				this.destroy();
				return true;
			}

			return false;
		};

		$$.window.on('resize.' + this._hash, () => {
			if (isDetached()) {
				return
			}

			this.resize();
		});
	}

	_initScrollSpring () {
		this._springScroller = new $$.SmoothScroller(this.nodes.payload, {
			velocityLimit: 0.35,
			damping: 1,
			rigidness: 300
		}, {
			preventMovingTargetTooFar: true,
			stepSize: $$.windowHeight
		});

		this._springScroller.pause();

		this._springScroller.onScroll = () => this._updateScene();
	}

	_bindAnimationSequence () {
		if (this._scrollMagicController) {
			throw 'Already initialized';
		} else if (this._destroyed) {
			return;
		}

		this._scrollMagicController = new ScrollMagic.Controller({
			container: this.nodes.payload.get(0),
			globalSceneOptions: {
				tweenChanges: true,
				triggerHook: 0
			}
		});

		let timeline;

		// Section 1
		timeline = new TimelineMax()
			.from(this.nodes.s1Logo, 1, { top: '200%', ease: Sine.easeOut }, 1)
			.duration(1.5);

		new ScrollMagic.Scene({
			triggerElement: this.nodes.section1.get(0)
		})
			.addTo(this._scrollMagicController)
			.setTween(timeline);

		// Section 2
		timeline = new TimelineMax()
			.from(this.nodes.s2Background, .5, { opacity: '0', ease: Power1.easeOut })
			.from(this.nodes.s2TextLine1, 1, { left: '100%', ease: Power1.easeOut }, .5)
			.from(this.nodes.s2TextLine2, 1, { left: '110%', ease: Power1.easeOut }, .5)
			.from(this.nodes.s2TextLine3, 1, { left: '120%', ease: Power1.easeOut }, .5)
			.from(this.nodes.s2TextLine4, 1, { left: '130%', ease: Power1.easeOut }, .5)
			.duration(1.5);

		new ScrollMagic.Scene({
			triggerElement: this.nodes.section2.get(0)
		})
			.setTween(timeline)
			.addTo(this._scrollMagicController);

		// Section 3

		timeline = new TimelineMax()
			.from(this.nodes.s3Sign1, .3, { opacity: '0', ease: Sine.easeOut })
			.from(this.nodes.s3Plus1, .3, { opacity: '0', ease: Sine.easeOut })
			.from(this.nodes.s3Sign2, .3, { opacity: '0', ease: Sine.easeOut })
			.from(this.nodes.s3Plus2, .3, { opacity: '0', ease: Sine.easeOut })
			.from(this.nodes.s3Sign3, .3, { opacity: '0', ease: Sine.easeOut })
			.from(this.nodes.s3Equally, .3, { opacity: '0', ease: Sine.easeOut })
			.from(this.nodes.s3Logo, .3, { opacity: '0', ease: Sine.easeOut })
			.duration(1.5);

		new ScrollMagic.Scene({
			triggerElement: this.nodes.section3.get(0)
		})
			.setTween(timeline)
			.addTo(this._scrollMagicController);

		// Section 4

		timeline = new TimelineMax()
			.from(this.nodes.s4Card1, .5, { top: '-110%', ease: Sine.easeOut })
			.from(this.nodes.s4Card1Shadow, .05, { top: '350px', opacity: '0', ease: Sine.easeOut }, .45)
			.from(this.nodes.s4Card2, .5, { top: '-220%', ease: Sine.easeOut }, 0)
			.from(this.nodes.s4Card3, .5, { top: '-330%', ease: Sine.easeOut }, 0)
			.from(this.nodes.s4Card4, .5, { top: '-440%', ease: Sine.easeOut }, 0)
			.from(this.nodes.s4Card5, .5, { top: '-550%', ease: Sine.easeOut }, 0)
			.from(this.nodes.s4Card6, .5, { top: '-660%', ease: Sine.easeOut }, 0)
			.from(this.nodes.s4Card7, .5, { top: '-770%', ease: Sine.easeOut }, 0)
			.from(this.nodes.s4Card8, .5, { top: '-800%', ease: Sine.easeOut }, 0)
			.duration(1.5);

		new ScrollMagic.Scene({
			triggerElement: this.nodes.section4.get(0)
		})
			.setTween(timeline)
			.addTo(this._scrollMagicController);


		// Section 5

		timeline = new TimelineMax()
			.from(this.nodes.s5Cards, .9, { left: 0, opacity: 0, ease: Sine.easeOut })
			.from(this.nodes.s5CardsInBox, 1, { right: 0, opacity: 0, ease: Sine.easeOut }, .1)
			.duration(1.5);

		new ScrollMagic.Scene({
			triggerElement: this.nodes.section5.get(0)
		})
			.setTween(timeline)
			.addTo(this._scrollMagicController);

		// Section 6

		timeline = new TimelineMax()
			.from(this.nodes.s6IconsWrapperTop, 1, { top: -120 + 'px', opacity: 0, ease: Power1.easeOut })
			.from(this.nodes.s6IconsWrapperBottom, 1, { bottom: - 120 + 'px', opacity: 0, ease: Power1.easeOut }, 0)
			.duration(1.5);

		new ScrollMagic.Scene({
			triggerElement: this.nodes.section6.get(0)
		})
			.setTween(timeline)
			.addTo(this._scrollMagicController);

		// Section 7

		timeline = new TimelineMax()
			.from(this.nodes.s7AtomizerBackground, .5, { opacity: 0, ease: Power1.easeOut })
			.from(this.nodes.s7Atomizer, 1, { bottom: '-100%', ease: Power1.easeOut })
			.duration(1.5);

		new ScrollMagic.Scene({
			triggerElement: this.nodes.section7.get(0)
		})
			.setTween(timeline)
			.addTo(this._scrollMagicController);
	}

	_ready () {
		this._waitingForDependenciesToLoad = false;
		this._scrollHeight = (this.nodes.sections.length - 1) * $$.windowHeight;

		this._springScroller.resume();

		$script.ready('vendor-postponed', () => {
			this._bindAnimationSequence();
		});
	}

	_updateScene () {
		if (!this._scrollMagicController) {
			return;
		}

		this._scrollMagicController.update(true);
	}

	destroy () {
		if (this._destroyed) {
			return;
		}

		this._destroyed = true;
		this._scrollMagicController.destroy();
		this.root.off();
		this.payload.off();

		$$.window.off('resize.' + this._hash);

		this._scrollMagicController = _.noop();

		this._springScroller.destroy();
		this._springScroller = _.noop();
	}

	pause () {
		this._paused = true;

		if (this._waitingForDependenciesToLoad) {
			return;
		}

		this._springScroller.pause();
	}

	repositioned (animationEnded = false) {
		if (this._destroyed) {
			return;
		}

		if (animationEnded) {
			this.nodes.mouseWheelCatcher.hide();
			if (!this._waitingForDependenciesToLoad && !this._paused) {
				// а без этого ScrollMagic тупит почему-то и не хочет пересчитывать высоту triggerHook. Его собственными методами не чинится
				$$.window.trigger('resize');
			}
		} else {
			this.nodes.mouseWheelCatcher.show();
			this._updateScene();
		}
	}

	resize () {
		if (this._waitingForDependenciesToLoad || this._destroyed) {
			return;
		}

		this._springScroller.recalculate();
		this._updateScene();
	}

	resume (comingFromTop) {
		this._paused = false;

		if (this._waitingForDependenciesToLoad || this._destroyed) {
			return;
		}

		this._springScroller.resume();
		this._scrollMagicController.scrollTo(comingFromTop ? 0 : this.nodes.payload.prop('scrollHeight'));
	}
};
