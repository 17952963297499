var $$ = $$ || {};

$$.MoronezhTest = class MoronezhTest {
	constructor(root) {
		this.root = root;
		this.uniqueHash = `portfolio-entry-moronezh-${_.uniqueId()}}`;

		this.scrollPos = 0;
		this.scrollHeight = 0;
		this.scrollAnimationFrameHandler = null;
		this.waitingForDependenciesToLoad = false;
		this._isInitialTick = true; _.defer( () => this._isInitialTick = false );

		this._destroyed = false;
		this._scrollMagicController = null;
		this.isPaused = false;
		this.carSceneIsFinished = false;
		this.isMouseScroll = false;

		this._cacheNodes();
		this._bindEvents();
		this._initScrollSpring();
		this.resize();

		this.nodes.loader.show();
		this.nodes.wrapper.css('opacity', 0);
		this._preventMouseWheel = true;

		this.scenesActions = [];
		this._bindScenes();

		this._ready();

		this._debouncedGoToNearestSection = _.debounce(() => {
			if (this._springScroller._spring.position().toFixed(4)
					=== this._springScroller._spring.target().toFixed(4)) {
				this._goToNearestSection();
			}
		}, 400);

		this._recheckBackground = _.debounce(() => {
			BackgroundCheck.refresh();
		}, 200);
	}

	_cacheNodes () {
		this.nodes = {
			mouseWheelCatcher: $('<div/>').appendTo(this.root),
			loader: this.root.children('.js-loading'),
			wrapper: this.root.find('.js-wrapper'),
			sections: this.root.find('.js-section'),
			pinTargets: this.root.find('.js-pin-target'),
			start: this.root.find('.js-start'),
			nameLogo: this.root.find('.js-name-logo'),
			startSection: this.root.find('.js-start-section'),
			patternSVG: this.root.find('.js-pattern-svg'),
			snow: this.root.find('.js-snow'),
			phone: this.root.find('.js-phone'),
			pad: this.root.find('.js-pad'),
			book: this.root.find('.js-book'),
			navPrev: this.root.find('.js-nav-prev'),
			navNext: this.root.find('.js-nav-next'),
			screenShots: this.root.find('.js-screen-shot'),
			tizers: this.root.find('.js-tizer'),
			tizersSection: this.root.find('.js-tizers-section'),
			deliverySection: this.root.find('.js-delivery-section'),
			deliveryBg: this.root.find('.js-delivery-bg'),
			hand: this.root.find('.js-hand'),
			pad2: this.root.find('.js-pad2'),
			car: this.root.find('.js-car'),
			popupsSection: this.root.find('.js-popups-section'),
			popup1: this.root.find('.js-popup1'),
			popup2: this.root.find('.js-popup2'),
			popup3: this.root.find('.js-popup3'),
			popup4: this.root.find('.js-popup4'),
			layoutSection: this.root.find('.js-layout-section'),
			buckets: this.root.find('.js-buckets'),
			cones: this.root.find('.js-cones'),
			iconsSection: this.root.find('.js-icons-section'),
			iconsSet: this.root.find('.js-icons-set'),
			finalSection: this.root.find('.js-final-section'),
			pad3: this.root.find('.js-pad3'),
			hand2: this.root.find('.js-hand2'),
			deliverySectionTitle: this.root.find('.js-delivery-section-title'),
			deliveryText: this.root.find('.js-delivery-text'),
			portions: this.root.find('.js-portion')
		};

		this.nodes.mouseWheelCatcher.css({
			position: 'absolute',
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
			display: 'none'
		});
	}

	_bindEvents () {
		this.nodes.wrapper.on('mousewheel', () => {
			this.isMouseScroll = true;
		});

		this.nodes.wrapper.on('scroll', () => {
			if(!this.isMouseScroll) {
				if (!$$.isMobile) {
					this._debouncedGoToNearestSection();
				}
			}

			this.isMouseScroll = false;
		});

		this.root.add(this.nodes.layoutSection).on('mousewheel', () => {
			this._springScroller.step(this.nodes.layoutSection.outerHeight(true) / 10);
		});

		this.scrollData = {
			relativeScrollTop: 0,
			direction: 1
		};

		this.nodes.wrapper.on('scroll', (event) => {
			var relativeScrollTop = this._springScroller._spring.position() * 100;
			var direction = relativeScrollTop - this.scrollData.relativeScrollTop  > 0 ? 1 : -1;

			this.scrollData = {
				relativeScrollTop,
				direction
			};

			_.forEach(this.scenesActions, (actionParameters) => {
				if ((actionParameters.triggerPoint < this.scrollData.relativeScrollTop
						&& actionParameters.direction > 0)
					|| (actionParameters.triggerPoint > this.scrollData.relativeScrollTop
						&& actionParameters.direction < 0)) {
					this.scrollData.direction > 0 ? actionParameters.action() : actionParameters.backAction();
					actionParameters.direction = -actionParameters.direction;
				}
			});

			if (this.scrollData.relativeScrollTop < 61 && this.scrollData.direction < 0
				|| this.scrollData.relativeScrollTop > 68 && this.scrollData.direction > 0) {
				var newTargetPosition;
				var wrapperScrollTop = this.nodes.wrapper.prop('scrollTop');
				var wrapperScrollHeight = this.nodes.wrapper.prop('scrollHeight') - this.nodes.wrapper.height();


				if (this.scrollData.relativeScrollTop < 61 && this.scrollData.relativeScrollTop > 59
						&& this.scrollData.direction < 0) {
					var popupSectionPositionTop = this.nodes.popupsSection.position().top + wrapperScrollTop;

					newTargetPosition = popupSectionPositionTop / wrapperScrollHeight;

					this._springScroller._spring.target(newTargetPosition);
					this._springScroller._scrollOptions.breakSpring = true;
				}

				if (this.scrollData.relativeScrollTop > 68 && this.scrollData.relativeScrollTop < 70
						&& this.scrollData.direction > 0) {
					var iconsSectionPositionTop = this.nodes.iconsSection.position().top + wrapperScrollTop;

					newTargetPosition = iconsSectionPositionTop / wrapperScrollHeight;

					this._springScroller._spring.target(newTargetPosition);
					this._springScroller._scrollOptions.breakSpring = true;
				}
			}
		});

		this.root.add(this.nodes.pinTargets)
			.on('mousewheel', (event) => {
				if (this._waitingForDependenciesToLoad) {
					event.stopPropagation();
				}

				if (this._preventMouseWheel) {
					event.preventDefault();
					event.stopPropagation();
					return false;
				}

				var mousewheelTarget = $(event.currentTarget);
				this._springScroller.step($$.windowHeight);

				if (this._springScroller._spring.position().toFixed(4)
					!== this._springScroller._spring.target().toFixed(4)){
					event.stopPropagation();
					event.preventDefault();
				} else {
					this._springScroller._scrollOptions.breakSpring = false;

					if (mousewheelTarget.parent().filter('.js-popups-section').length) {
						this.preventWheelOptions = {
							deltaValue: 1,
							flag: true
						};
					}

					if (mousewheelTarget.parent().filter('.js-icons-section').length) {
						this.preventWheelOptions = {
							deltaValue: -1,
							flag: true
						};

						this._springScroller.step($$.windowHeight * 1.2);
					}

					if (mousewheelTarget.find('.js-delivery-section').length && this.carSceneIsFinished) {
						event.stopPropagation();
						event.preventDefault();

						this.nodes.car.removeClass('active').addClass('new-active');
						this.nodes.deliveryBg.addClass('transitioned active');
						this.nodes.deliveryText.addClass('transitioned active');
						this.nodes.pad2.addClass('transitioned active');
						this.nodes.hand.addClass('transitioned active');

						_.delay(() => {
							this.carSceneIsFinished = false;
						}, 800);
					}

					if (mousewheelTarget.find('.js-tizers-section').length) {
						//this.deliveryTimeLine.progress(0);
						//this.deliveryTimeLine.pause();
					}
				}
			});

		this.nodes.layoutSection.on('mousewheel', (event) => {
			if (this._springScroller._spring.position().toFixed(4)
				=== this._springScroller._spring.target().toFixed(4)){
				this.preventWheelOptions.flag = false;
			}

			let delta = event.deltaY / Math.abs(event.deltaY);

			if (this.preventWheelOptions && this.preventWheelOptions.flag
				&& this.preventWheelOptions.deltaValue === delta) {
				event.stopPropagation();
				event.preventDefault();
			}
		});

		var isDetached = () => {
			if (!jQuery.contains(document.documentElement, this.root.get(0))) {
				this.destroy();
				return true;
			}

			return false;
		};

		$$.window.on('resize.' + this.uniqueHash, () => {
			if (isDetached()) {
				return;
			}

			this.resize();
		});

		this.nodes.navNext.on('click', _.bind(function (event) {
			var currentActiveScreenShotIndex = this.nodes.screenShots.filter('.active').index();

			this.nodes.screenShots.removeClass('active')
				.eq((this.screenShotsLength + currentActiveScreenShotIndex + 1) % this.screenShotsLength)
				.addClass('active');
		}, this));

		this.nodes.navPrev.on('click', _.bind(function (event) {
			var currentActiveScreenShotIndex = this.nodes.screenShots.filter('.active').index();

			this.nodes.screenShots.removeClass('active')
				.eq((this.screenShotsLength + currentActiveScreenShotIndex) % this.screenShotsLength)
				.addClass('active');
		}, this));
	}

	_registerSceneParameters (triggerPoint, action, backAction) {
		this.scenesActions.push({
			triggerPoint,
			action,
			backAction: backAction || _.noop,
			direction: 1
		});
	}

	_bindScenes () {
		// SCENE #1
		this._registerSceneParameters(0, () => {
			this.nodes.snow.addClass('active');
			this.nodes.nameLogo.addClass('active');
			this.nodes.start.addClass('active');
			this.nodes.pad.addClass('active');
			this.nodes.phone.addClass('active');
			this.nodes.book.addClass('active');

			_.delay(() => {
				this.nodes.start.css('height', '100%');
				this.nodes.startSection.css('backgroundImage', 'none');
				this.nodes.patternSVG.remove();
			}, 2590);

			_.delay(() => {
				this.nodes.navPrev.add(this.nodes.navNext).addClass('opacity-animate');

				_.delay(_.bind(function () {
					this.nodes.navPrev.add(this.nodes.navNext).removeClass('opacity-animate').addClass('small');
				}, this), 1120);
			}, 3640);
		});

		// SCENE #2
		this._registerSceneParameters(9, () => {
			_.delay(() => {
				var delay = 140;

				this.nodes.tizers.each(_.bind(function (index, element) {
					_.delay(_.bind(function () {
						$(element).addClass('large');
					}, this), delay);

					delay = delay + 120;
				}, this));
			}, 1000);
		}, () => {
			this.nodes.tizers.removeClass('large');
		});

		// SCENE #3

		this._registerSceneParameters(16, () => {
			this.nodes.car.addClass('transitioned active');

			_.delay(() => {
				this.carSceneIsFinished = true;
			}, 2000);
		}, () => {
			this.nodes.car.removeClass('transitioned active new-active');
			this.nodes.deliveryBg.removeClass('transitioned active');
			this.nodes.deliveryText.removeClass('transitioned active');
			this.nodes.pad2.removeClass('transitioned active');
			this.nodes.hand.removeClass('transitioned active');
		});

		// SCENE #4

		this._registerSceneParameters(36, () => {
			this.nodes.popup1.addClass('transitioned active');
			this.nodes.popup2.addClass('transitioned active');
			this.nodes.popup3.addClass('transitioned active');
			this.nodes.popup4.addClass('transitioned active');
		}, () => {
			this.nodes.popup1.removeClass('transitioned active');
			this.nodes.popup2.removeClass('transitioned active');
			this.nodes.popup3.removeClass('transitioned active');
			this.nodes.popup4.removeClass('transitioned active');
		});

		this._registerSceneParameters(58, () => {
			this.nodes.buckets.addClass('active');
		}, () => {
			this.nodes.buckets.removeClass('active');
		});

		this._registerSceneParameters(62, () => {
			this.nodes.cones.addClass('active');
		}, () => {
			this.nodes.cones.removeClass('active');
		});

		this._registerSceneParameters(80, () => {
			this.nodes.iconsSet.addClass('active');
		}, () => {
			this.nodes.iconsSet.removeClass('active');
		});

		this._registerSceneParameters(94, () => {
			this.nodes.hand2.addClass('active');
			this.nodes.pad3.addClass('active');
		}, () => {
			this.nodes.hand2.removeClass('active');
			this.nodes.pad3.removeClass('active');
		});
	}

	_ready () {
		this.waitingForDependenciesToLoad = false;

		var initialScene = _.remove(this.scenesActions, (actionParameters) => {
			return !actionParameters.triggerPoint
		});

		_.forEach(initialScene, (actionParameters) => {
			actionParameters.action();
		});

		_.delay(() => {
			this._preventMouseWheel = false;
		}, 1200);

		this._springScroller.resume();

		if (this._isInitialTick) {
			this.nodes.loader.hide();
			this.nodes.wrapper.css('opacity', 1);
		} else {
			this.nodes.loader.velocity('fadeOut');
			this.nodes.wrapper.velocity('fadeIn');
		}

		this.nodes.snow.addClass('active');

		try {
			BackgroundCheck.init({
				targets: '.p-header .logo, .js-breadcrumbs, .button-switch-state-right-wrapper, .js-slash-menu',
				images: '.b-moronezh img',
				classes: {
					dark: 'background--light',
					light: 'background--dark',
					complex: 'background--complex'
				}
			});

			_.defer(function() { BackgroundCheck.refresh(); });
		} catch(e) {}

		this.screenShotsLength = this.nodes.screenShots.length;
	}

	_initScrollSpring () {
		this._springScroller = new $$.SmoothScroller(this.nodes.wrapper, {
			velocityLimit: 0.35
		}, {
			preventMovingTargetTooFar: true,
			doNotRound: true,
			stepSize: $$.windowHeight
		});


		this._updateScene();
		this._springScroller.onScroll = () => this._updateScene();
	}

	_updateScene () {
		if (!this._scrollMagicController) {
			return;
		}

		this._scrollMagicController.update(true);
		this._layoutSectionScrollMagicController.update(true);
		this._recheckBackground();
	}

	destroy () {
		if (this._destroyed) {
			return;
		}

		this._destroyed = true;
		this._scrollMagicController.destroy();
		this.root.off();
		this.nodes.wrapper.off();

		$$.window.off('resize.' + this.uniqueHash);

		this._scrollMagicController = _.noop();
		this._recheckBackground = _.noop;

		this._springScroller.destroy();
		this._springScroller = _.noop();
	}

	pause () {
		this.isPaused = true;

		if (this._waitingForDependenciesToLoad) {
			return;
		}

		this._springScroller.pause();
	}

	repositioned (isAnimationEnded = false) {
		if (this._destroyed) {
			return;
		}

		if (isAnimationEnded) {
			this.nodes.mouseWheelCatcher.hide();

			if (!this.waitingForDependenciesToLoad && !this.isPaused) {
				// а без этого ScrollMagic тупит почему-то и не хочет пересчитывать высоту triggerHook. Его собственными методами не чинится
				$$.window.trigger('resize');

				_.defer(() => {
					$$.body.addClass('black-controls');
				});
			}
		} else {
			$$.body.removeClass('black-controls');
			this.nodes.mouseWheelCatcher.show();
			this._updateScene();
		}
	}

	resume (comingFromTop) {
		this.isPaused = false;

		if (this.waitingForDependenciesToLoad || this._destroyed) {
			return;
		}

		this._springScroller.resume();
		this._scrollMagicController.scrollTo(comingFromTop ? 0 : this.nodes.wrapper.prop('scrollHeight'));
	}

	resize () {
		if (this.waitingForDependenciesToLoad || this._destroyed) {
			return;
		}

		this._springScroller.recalculate();
		this._updateScene();
		this._goToNearestSection();
	}

	_goToNearestSection () {
		var nearestSection;

		this.nodes.sections.each(_.bind(function (index, section) {
			if (section.getBoundingClientRect().top < $$.windowHeight / 2
				&& section.getBoundingClientRect().top > -($$.windowHeight / 2)) {
				nearestSection = $(section);
				return false;
			}
		}, this));

		if (_.isUndefined(nearestSection)) {
			return false;
		}

		var wrapperScrollTop = this.nodes.wrapper.prop('scrollTop');
		var nearestSectionPositionTop = nearestSection.position().top + wrapperScrollTop;
		var wrapperScrollHeight = this.nodes.wrapper.prop('scrollHeight') - this.nodes.wrapper.height();
		var newTargetPosition = nearestSectionPositionTop / wrapperScrollHeight;

		this._springScroller._spring.target(newTargetPosition);
	}
};
