var $$ = $$ || {};

$$.AngstremCorp = class AngstremCorp {
	constructor (root) {
		this.root = root;

		// Переменные и флаги

		this._hash = 'portfolio-entry-portfolio-' + _.uniqueId();
		this._scrollHeight = 0; // В нашем случае соответствует максимально возможному scrollTop
		this._paused = false;
		this._destroyed = false;
		this._scrollMagicController = null;

		this._isInitialTick = true; setTimeout(() => this._isInitialTick = false, 0);
		this._waitingForDependenciesToLoad = true;

		this._scrollPos = 0;
		this._scrollAnimationFrameHandler = null;

		this.currentSectionIndex = 0;

		// Инициализируем

		this._cacheNodes();
		this._bindEvents();
		this._initScrollSpring();

		this.sliderInterval = 0;

		this._recheckBackground = _.throttle(() => {
			try {
				BackgroundCheck.refresh();
			} catch (e) {

			}
		}, 200);

		$script.ready('vendor-postponed', () => {
			this._ready();
		});
	}

	_cacheNodes () {
		let payload = this.root.children('.portfolio-payload');

		this.nodes = {
			loader: this.root.children('.js-loading'),
			payload: payload,
			mouseWheelCatcher: this.root.find('.g-mouse-wheel-catcher'),
			sections: this.root.find('section'),

			section1: payload.children('.section1'),
			s1PinTarget1: payload.find('.section1 .pin-target1'),
			s1Block: payload.find('.section1 .block'),
			s1PageImage1: payload.find('.section1 .page-1'),

			section11: payload.find('.section1-1'),
			s1PinTarget2: payload.find('.section1-1 .pin-target2'),
			s1PageImage2: payload.find('.section1-1 .page-2-block-1'),
			s1PageImage3: payload.find('.section1-1 .page-2-block-2'),
			s1PageImage4: payload.find('.section1-1 .page-2-block-3'),
			s1PageImage5: payload.find('.section1-1 .page-2-block-4'),
			s1PageImage6: payload.find('.section1-1 .page-2-block-5'),
			s1PageImage7: payload.find('.section1-1 .page-2-block-6'),
			s1PageImage8: payload.find('.section1-1 .page-2-block-7'),
			s1PageImage9: payload.find('.section1-1 .page-2-block-8'),
			s1PageImage10: payload.find('.section1-1 .page-2-block-9'),
			s1PageImage11: payload.find('.section1-1 .page-2-icon-people'),
			s1PageImage12: payload.find('.section1-1 .page-2-icon-cam'),
			s1PageImage13: payload.find('.section1-1 .page-2-icon-tie'),
			s1PageImage14: payload.find('.section1-1 .page-2-button-discover-more'),

			section2: payload.children('.section2'),
			s2PinTarget: payload.find('.section2 .pin-target'),
			s2Block: payload.find('.section2 .block'),
			s2BorderLeft: payload.find('.section2 .border-left'),
			s2BorderRight: payload.find('.section2 .border-right'),
			s2TextBlock: payload.find('.section2 .text-content-wrapper'),

			section3: payload.children('.section3'),
			s3PinTarget: payload.find('.section3 .pin-target'),
			s3Block: payload.find('.section3 .block'),
			s3NotebookWrapper: payload.find('.section3 .notebook-wrapper'),
			s3NotebookContent: payload.find('.section3 .notebook-content'),

			section4: payload.find('.section4'),
			s4PinTarget: payload.find('.section4 .pin-target'),
			s4Block: payload.find('.section4 .block'),
			s4BorderLeft: payload.find('.section4 .border-left'),
			s4BorderRight: payload.find('.section4 .border-right'),
			s4Pictograms1: payload.find('.section4 .pictograms-line-1'),
			s4Pictograms2: payload.find('.section4 .pictograms-line-2'),
			s4Pictograms3: payload.find('.section4 .pictograms-line-3'),

			section5: payload.children('.section5'),
			s5PinTarget: payload.find('.section5 .pin-target'),
			s5PinTarget2: payload.find('.section5 .pin-target2'),
			s5Image1: payload.find('.section5 .image1'),
			s5Image2: payload.find('.section5 .image2'),
			s5Image3: payload.find('.section5 .image3'),
			s5Image4: payload.find('.section5 .image4'),
			s5Image5: payload.find('.section5 .image5'),
			s5Image6: payload.find('.section5 .image6'),
			s5Image7: payload.find('.section5 .image7'),
			s5Image8: payload.find('.section5 .image8'),

			section6: payload.children('.section6'),
			s6PinTarget: payload.find('.section6 .pin-target')
		};
	}

	/**
	 * Вешает обработчики событий на компоненты/элементы.
	 *
	 * @private
	 */
	_bindEvents () {
		this.nodes.mouseWheelCatcher.on('mousewheel', () => false);

		this.root.on('mousewheel', (event) => {
			if (this._waitingForDependenciesToLoad) {
				event.stopPropagation();
			}
		});

		this.nodes.payload.on('scroll', () => {
			this._recheckBackground();
		});

		let isDetached = () => {
			if (!jQuery.contains(document.documentElement, this.root.get(0))) {
				this.destroy();
				return true;
			}

			return false;
		};

		$$.window.on('resize.' + this._hash, () => {
			if (isDetached()) {
				return
			}

			this.resize();
		});
	}

	_initScrollSpring () {
		this._springScroller = new $$.SmoothScroller(this.nodes.payload, {
			velocityLimit: 0.35
		}, {
			preventMovingTargetTooFar: true,
			stepSize: 200
		});

		this._springScroller.pause();

		this._springScroller.onScroll = () => this._updateScene();

		this.nodes.section1.addClass('preloading');
	}

	_bindAnimationSequence () {
		if (this._scrollMagicController) {
			throw 'Already initialized';
		} else if (this._destroyed) {
			return;
		}

		this._scrollMagicController = new ScrollMagic.Controller({
			container: this.nodes.payload.get(0),
			globalSceneOptions: {
				tweenChanges: true,
				triggerHook: 0
			}
		});

		let timeline;

		// Section 1
		this.nodes.section1.height( this.nodes.s1PageImage1.height() );

		new ScrollMagic.Scene({
			triggerElement: this.nodes.s1PinTarget1.get(0),
			duration: () => this.nodes.section1.height() //1950
		})
			.setPin(this.nodes.s1PinTarget1.get(0))
			.addTo(this._scrollMagicController);

		// Section 1-1
		timeline = new TimelineMax()
			//.from(this.nodes.s1PageImage2, 1, { transform: "matrix(1,0,0,1,0,15)", ease: Sine.easeOut }, 0)//144
			.from(this.nodes.s1PageImage3, 1, { transform: "matrix(1,0,0,1,0,125)", ease: Sine.easeOut }, 0)//262
			.from(this.nodes.s1PageImage4, 1, { transform: "matrix(1,0,0,1,0,100)", ease: Sine.easeOut }, 0)//197
			.from(this.nodes.s1PageImage5, 1, { transform: "matrix(1,0,0,1,0,150)", ease: Sine.easeOut }, 0)//339
			.from(this.nodes.s1PageImage11,1, { transform: "matrix(1,0,0,1,0,55)", ease: Sine.easeOut }, 0)//622
			.from(this.nodes.s1PageImage6, 1, { transform: "matrix(1,0,0,1,0,125)", ease: Sine.easeOut }, 0)//622
			.from(this.nodes.s1PageImage7, 1, { transform: "matrix(1,0,0,1,0,150)", ease: Sine.easeOut }, 0);//664

		new ScrollMagic.Scene({
			triggerElement: this.nodes.s1PinTarget2.get(0),
			duration: () => $$.windowHeight
		})
			.addTo(this._scrollMagicController)
			.setTween(timeline);

		timeline = new TimelineMax()
			.from(this.nodes.s1PageImage8,  1, { transform: "matrix(1,0,0,1,0,100)", ease: Sine.easeOut }, 0)//821
			.from(this.nodes.s1PageImage9,  1, { transform: "matrix(1,0,0,1,0,125)", ease: Sine.easeOut }, 0)//953
			.from(this.nodes.s1PageImage10, 1, { transform: "matrix(1,0,0,1,0,155)", ease: Sine.easeOut }, 0)//1166
			.from(this.nodes.s1PageImage12, 1, { transform: "matrix(1,0,0,1,0,100)", ease: Sine.easeOut }, 0)//1166
			.from(this.nodes.s1PageImage13, 1, { transform: "matrix(1,0,0,1,0,150)", ease: Sine.easeOut }, 0)//1311
			.from(this.nodes.s1PageImage14, 1, { transform: "matrix(1,0,0,1,0,120)", ease: Sine.easeOut }, 0);//1411

		new ScrollMagic.Scene({
			triggerElement: this.nodes.section11.get(0),
			duration: () => $$.windowHeight * 1.5
		})
			.addTo(this._scrollMagicController)
			.setTween(timeline);

		// Section 2
		timeline = new TimelineMax()
			.from(this.nodes.s2Block, 1, { opacity: 0, ease: Sine.easeIn }, 0)
			.from(this.nodes.s2BorderLeft,  1.1, { transform: "matrix(1,0,0,1,-200,0)", ease: Sine.easeOut }, 0)
			.from(this.nodes.s2BorderRight, 1.1, { transform: "matrix(1,0,0,1,200,0)", ease: Sine.easeOut }, 0);

		new ScrollMagic.Scene({
			triggerElement: this.nodes.s2PinTarget.get(0),
			duration: () => $$.windowHeight
		})
			.setPin(this.nodes.s2PinTarget.get(0))
			.addTo(this._scrollMagicController)
			.setTween(timeline);

		// Section 3
		timeline = new TimelineMax()
			.from(this.nodes.s3NotebookWrapper, .5, {
				transform: "matrix(1, 0, 0, 1, -" + $$.windowWidth + ", 0)",
				ease: Power2.easeOut
			})
			.to(this.nodes.s3NotebookContent, 1, {
				transform: "matrix(1, 0, 0, 1, 0, -1613)",
				ease: Power2.easeInOut
			});//1613 = this.nodes.s3NotebookContent.height() - this.nodes.s3NotebookContent.parent().height()

		new ScrollMagic.Scene({
			triggerElement: this.nodes.section3.get(0),
			duration: () => 2000
		})
			.setPin(this.nodes.s3PinTarget.get(0))
			.addTo(this._scrollMagicController)
			.setTween(timeline);

		// Section 4
		timeline = new TimelineMax()
			.from(this.nodes.s4Block, 1.5, { opacity: 0, ease: Power2.easeIn }, 0)
			.from(this.nodes.s4BorderLeft,  1.5, { transform: "matrix(1, 0, 0, 1, -200, 0)", ease: Power2.easeOut }, 0)
			.from(this.nodes.s4BorderRight, 1.5, { transform: "matrix(1, 0, 0, 1, 200, 0)", ease: Power2.easeOut }, 0)
			.from(this.nodes.s4Pictograms1, 1.5, { transform: "matrix(1, 0, 0, 1, -300, 0)", ease: Power2.easeOut }, 0)
			.from(this.nodes.s4Pictograms2, 1.5, { transform: "matrix(1, 0, 0, 1, 200, 0)", ease: Power2.easeOut }, 0)
			.from(this.nodes.s4Pictograms3, 1.5, { transform: "matrix(1, 0, 0, 1, -200, 0)", ease: Power2.easeOut }, 0);

		new ScrollMagic.Scene({
			triggerElement: this.nodes.section4.get(0),
			duration: () => $$.windowHeight
		})
			.setPin(this.nodes.s4PinTarget.get(0))
			.addTo(this._scrollMagicController)
			.setTween(timeline);

		// Section 5
		timeline = new TimelineMax()
			.from(this.nodes.s5Image1, 1, { transform: "matrix(1, 0, 0, 1, 0, 100)", ease: Power2.easeOut }, 0)//10
			.from(this.nodes.s5Image2, 1, { transform: "matrix(1, 0, 0, 1, 0, 20)", ease: Power2.easeOut }, 0)//10
			.from(this.nodes.s5Image3, 1, { transform: "matrix(1, 0, 0, 1, 0, 80)", ease: Power2.easeOut }, 0);//0

		new ScrollMagic.Scene({
			triggerElement: this.nodes.section5.get(0),
			duration: () => $$.windowHeight,
			offset: -$$.windowHeight
		})
			.addTo(this._scrollMagicController)
			.setTween(timeline);

		timeline = new TimelineMax()
			.from(this.nodes.s5Image4, 1, { transform: "matrix(1, 0, 0, 1, 0, 30)", ease: Power2.easeOut }, 0)//273
			.from(this.nodes.s5Image5, 1, { transform: "matrix(1, 0, 0, 1, 0, 110)", ease: Power2.easeOut }, 0);//232

		new ScrollMagic.Scene({
			triggerElement: this.nodes.section5.get(0),
			duration: () => $$.windowHeight,
			offset: -($$.windowHeight / 2)
		})
			.addTo(this._scrollMagicController)
			.setTween(timeline);

		timeline = new TimelineMax()
			.from(this.nodes.s5Image6, 1, { transform: "matrix(1, 0, 0, 1, 0, 90)", ease: Power2.easeOut }, 0)//594
			.from(this.nodes.s5Image7, 1, { transform: "matrix(1, 0, 0, 1, 0, 50)", ease: Power2.easeOut }, 0)//677
			.from(this.nodes.s5Image8, 1, { transform: "matrix(1, 0, 0, 1, 0, 120)", ease: Power2.easeOut }, 0);//670

		new ScrollMagic.Scene({
			triggerElement: this.nodes.section5.get(0),
			duration: () => this.nodes.section5.height()
		})
			.addTo(this._scrollMagicController)
			.setTween(timeline);


		// Section 6
		new ScrollMagic.Scene({
			triggerElement: this.nodes.section6.get(0),
			duration: () => $$.windowHeight / 2
		})
			.setPin(this.nodes.s6PinTarget.get(0))
			.addTo(this._scrollMagicController);
	}

	_ready () {
		this._waitingForDependenciesToLoad = false;

		this._springScroller.resume();

		try {
			BackgroundCheck.init({
				targets: '.p-header .logo, .b-breadcrumbs, .button-switch-state-right-wrapper, .category-controls'
			});

			_.defer(function () {
				BackgroundCheck.refresh();
			})
		} catch (e) {
		}

		this.resize();
		this.nodes.section1.removeClass('preloading');

		this._bindAnimationSequence();

		this.nodes.mouseWheelCatcher.hide();
		this.nodes.loader.hide();
	}

	_updateScene () {
		if (!this._scrollMagicController) {
			return;
		}

		this._scrollMagicController.update(true);
		this._recheckBackground();
	}

	destroy () {
		if (this._destroyed) {
			return;
		}

		this._destroyed = true;
		this._scrollMagicController.destroy();
		this.root.off();
		this.payload.off();

		$$.window.off('resize.' + this._hash);

		this._scrollMagicController = _.noop();
		this._recheckBackground = _.noop;

		this._springScroller.destroy();
		this._springScroller = _.noop();
	}

	pause () {
		this._paused = true;

		if (this._waitingForDependenciesToLoad) {
			return;
		}

		this._springScroller.pause();
	}

	/**
	 * Эта функция должна вызываться извне и уведомлять нас, что позиция слайда на экране изменилась
	 *
	 * @param animationEnded Закончилась ли анимация. На самом деле вызывается только, когда мы и есть слайд к которому осуществлялся переход
	 */
	repositioned (animationEnded = false) {
		if (this._destroyed) {
			return;
		}

		if (animationEnded) {

			if (!this._waitingForDependenciesToLoad && !this._paused) {
				this.nodes.mouseWheelCatcher.hide();
				// а без этого ScrollMagic тупит почему-то и не хочет пересчитывать высоту triggerHook. Его собственными методами не чинится
				$$.window.trigger('resize');
			}

			_.defer(function () {
				$$.body.addClass('black-controls');
			});
		} else {
			this.nodes.mouseWheelCatcher.show();
			$$.body.removeClass('black-controls');
			this._updateScene();
		}
	}

	resize () {
		if (this._waitingForDependenciesToLoad || this._destroyed) {
			return;
		}

		this._springScroller.recalculate();
		this._updateScene();
	}

	resume (comingFromTop) {
		this._paused = false;

		if (this._waitingForDependenciesToLoad || this._destroyed) {
			return;
		}

		this._springScroller.resume();
		this._scrollMagicController.scrollTo(comingFromTop ? 0 : this.nodes.payload.prop('scrollHeight'));
	}
};
