const $$ = window.$$ || (window.$$ = {});

$$.BaseProjectPageClass = class BaseProjectPageClass {
	constructor (root, hashPrefix) {
		this.root = root;

		// Переменные и флаги

		this._hash = _.uniqueId(hashPrefix);
		this._paused = false;
		this._destroyed = false;

		this._isInitialTick = true;
		_.defer(() => this._isInitialTick = false);

		// Инициализируем

		this._cacheNodes();
		this._bindEvents();
		this._initScrollSpring();

		this._scrollMagicController = new ScrollMagic.Controller({
			container: this.nodes.payload.get(0)
		});

		this.resize();

		this.nodes.loader.show();
		this.nodes.payload.css('opacity', 0);

		$script.ready('vendor-postponed', () => {
			this._ready()
		});
	}

	_cacheNodes () {
		const payload = this.root.children('.js-project-page-payload');
		const mousewheelCatcher = $('<div/>')
			.css({
				position: 'absolute',
				top:      0,
				left:     0,
				width:    '100%',
				height:   '100%',
				display:  'none'
			})
			.appendTo(this.root);

		this.nodes = {
			loader:            this.root.children('.js-loading'),
			payload:           payload,
			mouseWheelCatcher: mousewheelCatcher
		};
	}

	/**
	 * Вешает обработчики событий на компоненты/элементы.
	 *
	 * @private
	 */
	_bindEvents () {
		// General

		this.nodes.mouseWheelCatcher.on('mousewheel swipeUp swipeDown', () => false);

		let isDetached = () => {
			if (!jQuery.contains(document.documentElement, this.root.get(0))) {
				this.destroy();
				return true;
			}

			return false;
		};

		$$.window.on('resize.' + this._hash, () => {
			if (!isDetached()) {
				this.resize();
			}
		});
	}

	_initScrollSpring () {
		this._springScroller = new $$.SmoothScroller(this.nodes.payload);
		this._springScroller.pause();

		this._springScroller.onScroll = () => this._updateScene();
	}

	_bindAnimationSequence () {

	}

	_ready () {
		this._springScroller.resume();

		this.nodes.payload
			.css({
				zIndex:   '0', // без этого стики элементы будут отрисовываться поверх активного слайда
				overflowY: 'auto',
				overflowX: 'hidden'
			});

		// Если вызвано в тот же тик, что и конструктор - ничего не анимируем.
		if (this._isInitialTick) {
			this.nodes.loader.hide();
			this.nodes.payload.css('opacity', 1);
		} else {
			this.nodes.loader.velocity('fadeOut');
			this.nodes.payload.velocity('fadeIn');
		}

		this._bindAnimationSequence();
		this.resize();
	}

	_updateScene () {
		if (!this._scrollMagicController) {
			return;
		}

		this._scrollMagicController.update(true);
	}

	destroy () {
		if (this._destroyed) {
			return;
		}

		this._destroyed = true;
		this._scrollMagicController.destroy();
		this.root.off();
		this.nodes.payload.off();

		$$.window.off('resize.' + this._hash);

		this._scrollMagicController = _.noop();

		this._springScroller.destroy();
		this._springScroller = _.noop();
	}

	pause () {
		this._paused = true;
		this._springScroller.pause();
	}

	/**
	 * Эта функция должна вызываться извне и уведомлять нас, что позиция слайда на экране изменилась
	 *
	 * @param animationEnded Закончилась ли анимация. На самом деле вызывается только, когда мы и есть слайд к которому осуществлялся переход
	 */
	repositioned (animationEnded = false) {
		if (this._destroyed) {
			return;
		}

		if (animationEnded) {
			this.nodes.mouseWheelCatcher.hide();
			if (!this._paused) {
				// а без этого ScrollMagic тупит почему-то и не хочет пересчитывать высоту triggerHook. Его собственными методами не чинится
				$$.window.trigger('resize');
			}
		} else {
			this.nodes.mouseWheelCatcher.show();
			this._updateScene();
		}
	}

	resize () {
		if (this._destroyed) {
			return;
		}

		this._springScroller.step($$.windowHeight);
		this._springScroller.recalculate();

		this._updateScene();
	}

	resume (comingFromTop) {
		this._paused = false;

		if (this._destroyed) {
			return;
		}

		this._springScroller.resume();
		this._scrollMagicController.scrollTo(comingFromTop ? 0 : this.nodes.payload.prop('scrollHeight'));
	}
};

//export default BaseProjectPageClass;
