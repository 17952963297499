$$.Route = class Route {
	constructor (root, options = {}) {
		this.options = {
			delay: 600,
			duration: 300
		};

		this.root = root;
		this.pagesContent = {};

		_.assign(this.options, options);

		this._cacheNodes();
		this._bindEvents();
		this.initialize();
	}

	initialize () {
		this.currentUrl = window.location.pathname;
		this.menuItems = {};
		this.activeMenuItem = $();

		this.nodes.slashItems.each((index, element) => {
			var slashItem = $(element);

			this.menuItems[slashItem.attr('href')] = slashItem;

			if (slashItem.hasClass('active')) {
				this.activeMenuItem = slashItem;
			}
		});

		this._registerRoutes();
	}

	_cacheNodes () {
		this.nodes = {
			contentWrap: this.root.find('.js-content-wrap'),
			footer:      this.root.find('.js-footer'),
			breadcrumbs: this.root.find('.js-breadcrumbs'),
			slashMenu:   $('.js-main-menu'),
			title:       $('title')
		};

		this.nodes.slashItems = this.nodes.slashMenu.find('.js-slash-item');
	}

	createComponents () {
		this.nodes.contentWrap.find('.js-map-block').each(function () {
			new $$.GoogleMap($(this));
		});

		this.nodes.contentWrap.find('.js-service-slider').each(function () {
			new $$.ServiceSlider($(this));
		});

		this.nodes.contentWrap.find('.js-contacts-feedback-form-wrapper').each(function () {
			new $$.Contacts($(this));
		});

		this.nodes.contentWrap.find('.js-portfolio').each(function () {
			new $$.Portfolio($(this));
		});

		this.nodes.contentWrap.find('.js-breadcrumbs').each(function () {
			new $$.Breadcrumbs($(this));
		});

		this.nodes.contentWrap.find('.js-person').each(function () {
			new $$.PersonBackground($(this));
		});
	}

	_unBindEvents () {
		$$.window.off('mouseup.gallery');
		$$.window.off('mousemove.gallery');
		$$.window.off('resize.gallery');

		$$.window.off('resize.serviceslider');
		$$.window.off('keydown.serviceslider');

		$$.window.off('resize.bigimage');

		$$.window.off('resize.videofeedback');

		$$.window.off('resize.textblock');

		$('body').off('click.breadcrumbs');
	}

	_bindEvents () {
		$$.body.on('navigateEffect.animationStart', () => {
			$$.body.trigger('menu.close');
		});
	}

	_fadePageOut() {
		const defered = $.Deferred();

		const fallbackTimeout = setTimeout(function() {
			// defered.reject();
			defered.resolve(); // failproof
		}, this.options.duration + 1);

		this.nodes.contentWrap
			.velocity('stop', true)
			.velocity({opacity: 0});

		this.nodes.footer
			.velocity('stop', true)
			.velocity({opacity: 0});

		this.nodes.breadcrumbs
			.velocity('stop', true)
			.velocity({
				opacity: 0
			}, this.options.duration, 'swing', () => {
				this.nodes.breadcrumbs.hide();
				this.nodes.contentWrap.hide();

				clearTimeout(fallbackTimeout);
				defered.resolve();
			});

		return defered.promise();
	}

	_fadePageIn() {
		const defered = $.Deferred();

		const fallbackTimeout = setTimeout(function() {
			// defered.reject();
			defered.resolve(); // failproof
		}, this.options.duration + 1);

		this.nodes.breadcrumbs
			.velocity('stop', true)
			.velocity('fadeIn');

		this.nodes.footer
			.velocity('stop', true)
			.velocity('fadeIn');

		this.nodes.contentWrap
			.velocity('stop', true)
			.velocity('fadeIn', function() {
				clearTimeout(fallbackTimeout);
				defered.resolve();
			});

		return defered.promise();
	}

	_registerRoutes () {
		page('*', (urlParameters) => {
			var urlPath = urlParameters.canonicalPath;
			var parameterPosition = urlPath.indexOf('?');

			if (parameterPosition !== -1) {
				urlPath = urlPath.slice(0, parameterPosition);
			}

			// Ignoring redirect to current page
			if (this.currentUrl === urlPath) {
				return;
			}

			var newUrlFragments = urlPath.split('/');
			var oldUrl = this.currentUrl;
			var currentMenuItem = this.menuItems['/' + newUrlFragments[1]];

			this.currentUrl = urlPath;

			// Запуск перехода
			var animationDelayPromise = $$.backgroundEffects.startCrossPageTransition(oldUrl, this.currentUrl);

			$$.body.addClass('page-transitioning');

			this._switchMenu(currentMenuItem);

			var ajaxPromise = this._loadRemotePage();
			var url = this.currentUrl;
			const fadePromise = this._fadePageOut();

			// Вставка контента с новой страницы
			$.whenAll(1000, ajaxPromise, fadePromise, animationDelayPromise)
				.always((results) => {
					var pageLoadResults = results[1];

					// Если случилось несколько переходов подряд - не обновляем контент и даем последующему обработчику
					// сделать всё как надо. В случае медленного соединения страница может подвиснуть на некоторое время
					// в промежуточном состоянии - это не так страшно. Фон должен анимироваться в это время всё равно
					if (url !== this.currentUrl) {
						return;
					}

					if (pageLoadResults[0] === 'resolved') {
						$$.body.trigger('route.onload');
						this._updatePageContent(pageLoadResults[1]);
						$$.body.removeClass('page-transitioning');
					} else {
						// Нужно хотя бы показать старую, а то застрянем

						//this._fadePageIn();
						//$$.body.removeClass('page-transitioning');

						alert('Произошла ошибка при загрузке страницы. Страница будет перезагружена');
						window.location.reload(true);
					}
				});
		});

		page();
	}

	_switchMenu (currentMenuItem) {
		// Переключение и закрытие меню
		if (this.activeMenuItem) {
			this.activeMenuItem.removeClass('active');
		}

		if (currentMenuItem) {
			this.activeMenuItem = currentMenuItem;
			this.activeMenuItem.addClass('active');
			this.nodes.slashMenu.trigger('slashmenu.update');
		}
	}

	_loadRemotePage () {
		var pageContent = this.pagesContent[this.currentUrl];
		var ajaxPromise = $.Deferred();
		var loadUrl = this.currentUrl;

		if ($$.config.isPageNotFound(loadUrl)) {
			loadUrl = $$.config.getPageNotFound(loadUrl);
		}

		if (pageContent) {
			ajaxPromise.resolve(pageContent);
		} else {
			$.ajax({
				type: 'GET',
				dataType: 'html',
				url: loadUrl
			})
				.then((response) => {
					this.pagesContent[loadUrl] = response;
					ajaxPromise.resolve(response);
				})
				.fail((foo, bar, baz) => {
					ajaxPromise.reject(foo, bar, baz);
				});
		}

		return ajaxPromise.promise();
	}

	_updatePageContent (response) {
		var root = $(response);
		var content = root.find('.js-content-wrap').html();
		var breadcrumbs = root.find('.js-breadcrumbs').html();
		var footer = root.find('.js-footer').html();
		var isError = root.find('.js-error').length;
		var title;

		title = response.match(/<title[^>]*>([^<]*)<\/title>/);
		title = title ? title[1] : '';

		if (isError) {
			$$.body.addClass('p-error');
		} else {
			$$.body.removeClass('p-error');
		}

		this.nodes.contentWrap.children().remove();
		this.nodes.contentWrap
			.show()
			.css('opacity', 0).html(content);

		this.nodes.breadcrumbs.html(breadcrumbs);
		this.nodes.footer.html(footer);
		this.nodes.title.text(title);

		this._fadePageIn();

		$$.body.removeClass('white-controls black-controls');

		this._unBindEvents();
		this.createComponents();
	}
};
