var $$ = $$ || {};

$$.Tuilerie = class Tuilerie {
	constructor (root) {
		this.root = root;
		this.mobile = this.root.data('mobile');
		this.animationBlocks = {};
		this.scrollOffsetTop = 0;

		this._cacheNodes();
		this._bindEvents();
		this._ready();
	}

	_cacheNodes () {
		this.nodes = {
			inner: this.root.find('.inner-work'),
			jsAnimationBlock: this.root.find('.js-animation-block')
		};

		this.animationNodes = {
			screenSaver: this.root.find('.js-animate-screensaver'),
			carBlock: this.root.find('.js-car'),
			bookFlowerParalax: this.root.find('.js-book-flower-paralax'),
			ipadParalax: this.root.find('.js-ipad-paralax'),
			ipadSlider: this.root.find('.js-ipad-slider'),
			devicesSlider: this.root.find('.js-device-slider'),
			finish: this.root.find('.js-finish')
		}
	}

	resume () {
		//this.videoBlock().resize();
		//this.videoBlock().play();
	}

	/**
	 * Вешает обработчики событий на компоненты/элементы.
	 *
	 * @private
	 */
	_bindEvents () {
		this.root.on('mousewheel', (event) => {
			event.preventDefault();

			var root = $(event.currentTarget);

			if (event.deltaY > 0 && this.scrollOffsetTop === 0) {
				//this.videoBlock().playBack();
			}

			var outSideBlock = ((event.deltaY < 0 && this.scrollOffsetTop >= (this.nodes.inner.height() - $$.windowHeight - 30)) || (event.deltaY > 0 && this.scrollOffsetTop === 0));

			if (((event.deltaY < 0 && this.scrollOffsetTop >= (this.nodes.inner.height() - $$.windowHeight - 30)) || (event.deltaY > 0 && this.scrollOffsetTop === 0))) {
			}
			else {
				if (!this.mobile) {
					if (this.pageScroll(event.deltaY, this.scrollOffsetTop)) {
						return false;
					}
				}

				event.stopPropagation();
			}

			if (event.deltaY < 0) {
				this.scrollOffsetTop += 50;

			} else {
				this.scrollOffsetTop -= 50;

				if (this.scrollOffsetTop <= 0) {
					this.scrollOffsetTop = 0;
				}
			}

			root.scrollTop(this.scrollOffsetTop);
		});
	}

	_ready () {
		this.resize();

		var innerHeight = 0;

		if (!this.mobile) {
			this.initAnimationBlock();
		} else {
			this._setMobileAnimation();
		}

		this.nodes.inner.children('div').each(function () {
			innerHeight += $(this).outerHeight(true);
		});

		this.nodes.inner.css({
			width: '100%',
			height: `${innerHeight}px`
		});

		this.root.width($$.window.width() + 17);

		this.animationBlocks['screenSaver'].animation.play();
	}

	/**
	 * Устанавливает видео на нужный момент для мобил.
	 * @private
	 */
	_setMobileAnimation () {
		//this.videoBlock().setCurrentTime(2.241708);
	}

	initAnimationBlock () {
		var firstElement = this.animationNodes['screenSaver'];
		var firstOffsetTop = firstElement.offset().top;

		_.forEach(this.animationNodes, (value, key) => {
			var offsetTop = 0;

			if (firstOffsetTop === 0) {
				offsetTop = value.offset().top;
			} else {
				offsetTop = value.offset().top - $$.window.height();
			}

			this.animationBlocks[key] = {
				id: key,
				element: value,
				scrollTop: $$.NormilizeHeight(offsetTop),
				height: value.outerHeight(true),
				animated: false,
				animationOnTop: false,
				animation: new $$.Tuilerie[key](value, {
					offsetBlock: $$.NormilizeHeight(offsetTop)
				})
			};
		});

		/**
		 * Задаем свойство для блоков у которых анимация должна начинаться от начала экрана
		 * @type {boolean}
		 */
		this.animationBlocks['ipadSlider'].animationOnTop = true;
		this.animationBlocks['devicesSlider'].animationOnTop = true;

		/**
		 * Разделяем блоки на те у которых анимация сверху и на те, у которых анимация от центра экрана
		 */
		this.topAnimationBlock = _.where(this.animationBlocks, {animationOnTop: true});
		this.middleAnimationBlock = _.where(this.animationBlocks, {animationOnTop: false});
	}

	pageScroll (eventY, scrollTop) {
		var isScroll = true;


		/**
		 * Ищем входимость блока анимация для которого начинается в центре экрана
		 */
		var middleAnimationBlock = _.filter(this.middleAnimationBlock, function (block) {
			return _.inRange(scrollTop + $$.NormilizeHeight($$.window.height() / 2), block.scrollTop, block.height + block.scrollTop);
		});

		/**
		 * Ищем входимость блока анимация для которого начинается в верху экрана
		 */
		var topAnimationBlock = _.filter(this.topAnimationBlock, function (block) {
			return block.scrollTop === scrollTop;
		});

		_.forEach(middleAnimationBlock, (value, key) => {
			if (!value.animated) {
				if (_.isObject(value.animation)) {
					value.animation.scrollOffsetTop = this.scrollOffsetTop;

					value.animation.init(eventY);
					isScroll = value.animation.isScroll;
				} else {
					value.animated = true;
					isScroll = true;
				}
			}
		});

		_.forEach(topAnimationBlock, (value, key) => {
			if (!value.animated) {
				if (_.isObject(value.animation)) {
					value.animation.scrollOffsetTop = this.scrollOffsetTop;

					value.animation.init(eventY);
					isScroll = value.animation.isScroll;
				} else {
					value.animated = true;
					isScroll = true;
				}
			}
		});

		return !isScroll;
	}

	resize () {
		$$.window.on('resize.work', () => {

			this.root.width($$.window.width() + 17);


			if (this.animationBlocks.length) {
				this.animationBlocks['screenSaver'].animation.resize();
			}

			//this.videoBlock().resize();

			var windowHeight = $$.window.height();

			this.nodes.jsAnimationBlock.each((index, element) => {
				var elementHeight = $(element).height();

				if (elementHeight <= windowHeight) {
					if (elementHeight > 0) {
						elementHeight = $$.NormilizeHeight(elementHeight);
					} else {
						elementHeight = $$.NormilizeHeight(windowHeight);
					}
				} else {
					elementHeight = $$.NormilizeHeight(elementHeight);
				}

				$(element).height(elementHeight);
			});
		});

		$$.window.trigger('resize.work');
	}
};


$$.Tuilerie.screenSaver = class TuilerieScreenSaver {
	/**
	 * Анимация видео
	 * @param root
	 * @param options
	 */
	constructor (root = $, options = {}) {
		this.root = root;
		this.options = options;
		this.isScroll = true;

		this.nodes = {
			video: this.root.find('.js-screensaver')[0]
		};
	}

	init () {
		this.isScroll = true;
	}

	resize () {
		this.root.css({
			height: `${$$.NormilizeHeight($$.window.height())}px`
		});
	}

	play () {
		this.setCurrentTime(0);
		this.nodes.video.play();

		this.nodes.video.addEventListener('playing', () => {
			var interval = setInterval(() => {
				if (this.nodes.video.currentTime >= 2.241708) {
					this.pause();
					clearInterval(interval);
				}
			}, 100);
		});
	}

	pause () {
		this.nodes.video.pause();
	}

	setCurrentTime (time = 0) {
		this.nodes.video.currentTime = time;
	}

	getCurrentTime () {
		return this.nodes.video.currentTime;
	}
};
$$.Tuilerie.carBlock = class TuilerieCarBlock {
	/**
	 *
	 * Анимация машины
	 *
	 * @param root
	 * @param options
	 */
	constructor (root = $, options = {}) {
		this.root = root;
		this.options = options;

		this.isScroll = true;
	}

	init (eventY) {
		var image = this.root.find('img');

		if (eventY < 0 && image.css('opacity') < 1) {
			var opacity = image.css('opacity');
			var left = parseFloat(image.css('left')) / $$.window.width() * 100;

			image.css({
				opacity: parseFloat(opacity) + 0.1,
				left: left + 5 + '%'
			});
		} else if (eventY > 0 && image.css('opacity') > 0) {
			if (this.scrollOffsetTop <= this.options.offsetBlock) {
				var opacity = image.css('opacity');
				var left = parseFloat(image.css('left')) / $$.window.width() * 100;

				image.css({
					opacity: parseFloat(opacity) - 0.1,
					left: left - 5 + '%'
				});
			}
		}
	}
};
$$.Tuilerie.bookFlowerParalax = class TuilerieBookFlowerParalax {
	/**
	 *
	 * Анимация ноутбука с цветами
	 *
	 * @param root
	 * @param options
	 */
	constructor (root = $, options = {}) {
		this.root = root;
		this.options = options;

		this.isScroll = true;
	}

	init (eventY) {
		var image7 = this.root.find('.image-7');
		var image8 = this.root.find('.image-8');

		if (eventY < 0 && parseInt(image7.css('bottom')) < 290) {
			var bottomImage1 = parseInt(image7.css('bottom'));
			var bottomImage2 = parseInt(image8.css('bottom'));

			image7.css('bottom', bottomImage1 + 30);
			image8.css('bottom', bottomImage2 + 10);
		} else if (eventY > 0 && parseInt(image7.css('bottom')) > -20) {
			var bottomImage1 = parseInt(image7.css('bottom'));
			var bottomImage2 = parseInt(image8.css('bottom'));

			image7.css('bottom', bottomImage1 - 30);
			image8.css('bottom', bottomImage2 - 10);
		}
	}
};
$$.Tuilerie.ipadParalax = class TuilerieIpadParalax {
	/**
	 *
	 * Анимация iPad
	 *
	 * @param root
	 * @param options
	 */
	constructor (root = $, options = {}) {
		this.root = root;
		this.options = options;

		this.isScroll = true;
	}

	init (eventY) {
		var image9 = this.root.find('.image-9');
		var image10 = this.root.find('.image-10');

		if (eventY < 0 && parseInt(image9.css('margin-top')) - 12 > -63) {
			var image1 = parseInt(image9.css('margin-top'));
			var image2 = parseInt(image10.css('margin-top'));

			image9.css('margin-top', image1 - 12 + 'rem');
			image10.css('margin-top', image2 - 6 + 'rem');
		}

		if (eventY > 0 && parseInt(image9.css('margin-top')) - 12 <= 9) {
			var image1 = parseInt(image9.css('margin-top'));
			var image2 = parseInt(image10.css('margin-top'));

			image9.css('margin-top', image1 + 12 + 'rem');
			image10.css('margin-top', image2 + 6 + 'rem');
		}
	}
};
$$.Tuilerie.ipadSlider = class TuilerieIpadSlider {
	/**
	 *
	 * Анимация слайдера ipad
	 *
	 * @param root
	 * @param options
	 */
	constructor (root = $, options = {}) {
		this.root = root;
		this.options = options;

		this.isScroll = true;
	}

	init (eventY) {
		var image13 = this.root.find('.image-13');
		if (eventY < 0) {
			if (parseInt(image13.css('top')) > -1620) {
				var image = parseInt(image13.css('top'));

				image13.css('top', image - 50 + 'rem');
				this.isScroll = false;
			} else {
				this.isScroll = true;
			}
		} else if (eventY > 0) {
			if (parseInt(image13.css('top')) < 0) {
				var image = parseInt(image13.css('top'));

				image13.css('top', image + 50 + 'rem');
				this.isScroll = false;
			} else {
				this.isScroll = true;
			}
		} else {
			//this.isScroll = true;
		}
	}
};
$$.Tuilerie.devicesSlider = class TuilerieDevicesSlider {
	/**
	 * Анимация девайсов
	 * @param root
	 * @param options
	 */
	constructor (root = $, options = {}) {
		this.root = root;
		this.options = options;

		this.isScroll = true;


		this.notebookImageWidth = this.root.find('.notebook').width();
		this.ipadImageWidth = this.root.find('.ipad').width();

		this.root.find('.notebook img').each((index, element) => {
			$(element).width(this.notebookImageWidth);
		});

		this.root.find('.ipad-inner img').each((index, element) => {
			$(element).width(this.ipadImageWidth);
		});

		this.notebookInnerWidth = this.notebookImageWidth * this.root.find('.notebook img').length;
		this.ipadInnerWidth = this.ipadImageWidth * this.root.find('.ipad img').length;

		this.root.find('.notebook-inner').width(this.notebookInnerWidth);
		this.root.find('.ipad-inner').width(this.ipadInnerWidth);

		this.notebookPosition = 0;
		this.ipadPosition = 0;
	}

	init (eventY) {
		var image = this.root.find('img');

		var noteBookPosition = Math.abs(this.notebookPosition) + this.notebookImageWidth < this.notebookInnerWidth;
		var ipadBookPosition = Math.abs(this.ipadPosition) + this.ipadImageWidth < this.ipadInnerWidth;

		if (eventY < 0) {
			if (noteBookPosition || ipadBookPosition) {
				this.notebookPosition -= this.notebookImageWidth;
				this.ipadPosition -= this.ipadImageWidth;

				if (Math.abs(this.notebookPosition) < this.notebookInnerWidth) {
					this.root.find('.notebook-inner').css({
						right: this.notebookPosition + 'rem'
					});
				} else {
					this.notebookPosition += this.notebookImageWidth;
				}

				if (Math.abs(this.ipadPosition) < this.ipadInnerWidth) {
					this.root.find('.ipad-inner').css({
						left: this.ipadPosition + 'rem'
					});
				} else {
					this.ipadPosition += this.ipadImageWidth;
				}

				this.isScroll = false;
			} else {
				this.isScroll = true;
			}


		} else {
			if (this.notebookPosition < 0 || this.ipadPosition < 0) {
				this.notebookPosition += this.notebookImageWidth;
				this.ipadPosition += this.ipadImageWidth;

				if (this.notebookPosition <= 0) {
					this.root.find('.notebook-inner').css({
						right: this.notebookPosition + 'rem'
					});
				} else {
					this.notebookPosition -= this.notebookImageWidth;
				}

				if (this.ipadPosition <= 0) {
					this.root.find('.ipad-inner').css({
						left: this.ipadPosition + 'rem'
					});
				} else {
					this.ipadPosition -= this.ipadImageWidth;
				}

				this.isScroll = false;
			} else {
				this.isScroll = true;
			}
		}
	}
};
$$.Tuilerie.finish = class TuilerieFinish {
	/**
	 *
	 * Анимация Цветов
	 *
	 * @param root
	 * @param options
	 */
	constructor (root = $, options = {}) {
		this.root = root;
		this.options = options;

		this.isScroll = true;
	}

	init (eventY) {
		var image = this.root.find('img');

		if (eventY < 0 && image.css('opacity') < 1) {
			var opacity = image.css('opacity');

			image.css({
				opacity: parseFloat(opacity) + 0.1
			});
		} else if (eventY > 0 && image.css('opacity') > 0) {
			var opacity = image.css('opacity');

			image.css({
				opacity: parseFloat(opacity) - 0.1
			});
		}
	}
};

$$.NormilizeHeight = function (number) {
	if (number === 0) {
		return number;
	}

	number = parseInt(number);

	var height = parseInt(number.toString().substring(number.toString().length - 2));

	if ((100 - height) < 50) {
		number += 100 - height;
	} else {
		number += 50 - height;
	}

	return number;
};
