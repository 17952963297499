var $$ = $$ || {};

$$.FeedbackButtonBlock = class FeedbackButtonBlock {
	constructor (root) {
		this.root = root;
		this._bindEvents();
	}

	/**
	 * Вешает обработчики событий на компоненты/элементы.
	 *
	 * @private
	 */
	_bindEvents () {
		this.root.on('click', (event) => {
			//event.stopPropagation(); // закомментировано из-за google analytics
			event.preventDefault();
            _.defer(() => {
                $('body').trigger('menu.open', [this.root.data('message')]);
            }, this);
		});
	}
};
