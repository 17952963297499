var $$ = $$ || {};

$$.Uroven = class Uroven {
	constructor (root) {
		this.root = root;

		// Переменные и флаги

		this.currentSectionIndex = 0;

		this._hash = 'portfolio-entry-uroven-' + _.uniqueId();
		this._scrollHeight = 0; // В нашем случае соответствует максимально возможному scrollTop
		this._paused = false;
		this._destroyed = false;

		this._isInitialTick = true;
		setTimeout(() => this._isInitialTick = false, 0);

		// Инициализируем

		this._cacheNodes();
		this._bindEvents();

		this._scrollMagicController = new ScrollMagic.Controller({
			globalSceneOptions: {
				triggerHook: 'onLeave'
			}
		});

		this.nodes.loader.show();
		this.nodes.payload.css('opacity', 0);

		this._recheckBackground = _.debounce((light = true) => {
			if (light && this.nodes.sections.eq(this.currentSectionIndex).hasClass('light')) {
				$$.body.removeClass('white-controls').addClass('black-controls');
			} else {
				$$.body.removeClass('black-controls').addClass('white-controls');
			}
		}, 200);

		this.resize();

		$script.ready('vendor-postponed', () => {
			this._ready()
		});
	}

	_cacheNodes () {
		let payload = this.root.children('.uroven-payload');

		this.nodes = {
			navigation: $('.p-header .logo, .js-breadcrumbs, .button-switch-state-right-wrapper, .js-slash-menu'),
			loader: this.root.children('.js-loading'),
			payload: payload,
			mouseWheelCatcher: $('<div/>').appendTo(this.root),
			sections: this.root.find('section'),

			section1: payload.children('.section1'),
			s1Logo: payload.find('.section1 .logo'),

			section2: payload.children('.section2'),
			s2Items: payload.find('.section2 .item'),
			s2Ok: payload.find('.section2 .ok'),

			section3: payload.children('.section3'),
			s3Plan: payload.find('.section3 .plan'),
			s3Plus: payload.find('.section3 .plus'),
			s3Bubble: payload.find('.section3 .bubble'),
			s3Equal: payload.find('.section3 .equal'),
			s3Logo: payload.find('.section3 .logo'),
			s3Badge: payload.find('.section3 .badge'),

			section4: payload.children('.section4'),
			s4White: payload.find('.section4 .card-white'),
			s4Blue: payload.find('.section4 .card-blue'),
			s4Envelop: payload.find('.section4 .envelop'),

			section5: payload.children('.section5'),
			s5Gloves: payload.find('.section5 .gloves'),
			s5Cap: payload.find('.section5 .cap'),

			section6: payload.children('.section6'),
			s6Ipad: payload.find('.section6 .ipad'),
			s6Screen: payload.find('.section6 .screen'),

			section7: payload.children('.section7'),
			s7Left: payload.find('.section7 .left'),
			s7Right: payload.find('.section7 .right'),

			section8: payload.children('.section8'),

			rows: $(`.b-uroven .uroven-payload .uroven-section .row`)
		};

		this.nodes.mouseWheelCatcher.css({
			position: 'absolute',
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
			display: 'none'
		});
	}

	/**
	 * Вешает обработчики событий на компоненты/элементы.
	 *
	 * @private
	 */
	_bindEvents () {
		this.nodes.mouseWheelCatcher.on('mousewheel', () => false);

		let isDetached = () => {
			if (!jQuery.contains(document.documentElement, this.root.get(0))) {
				this.destroy();
				return true;
			}

			return false;
		};

		$$.window.on('resize.' + this._hash, () => {
			if (isDetached()) {
				return
			}

			this.resize();
		});

		this.nodes.payload.swipeUp((event) => {
			this._scrollController(event, -1);
		});

		this.nodes.payload.swipeDown((event) => {
			this._scrollController(event, +1);
		});

		this.nodes.payload.on('mousewheel', (event) => {
			this._scrollController(event, event.deltaY);
		});
	}

	_scrollController (event, delta) {
		if (this.isAnimating) {
			event.preventDefault();
			event.stopPropagation();
			return false;
		}

		if (this.currentSectionIndex - delta >= 0
			&& this.currentSectionIndex - delta < this.nodes.sections.length && !this.isAnimating) {
			this.isAnimating = true;

			event.preventDefault();
			event.stopPropagation();

			this.nodes.sections.eq(this.currentSectionIndex - delta).show();

			let scrollTop = this.nodes.payload.scrollTop();
			let newTop = scrollTop + this.nodes.sections.eq(this.currentSectionIndex - delta).offset().top;

			if (delta > 0) {
				newTop = scrollTop - this.nodes.sections.eq(this.currentSectionIndex - delta).parent().outerHeight(true);
			}

			this.nodes.payload.stop(true, true).animate({
				scrollTop: newTop
			}, 1000, () => {
				this.nodes.sections.eq(this.currentSectionIndex).hide();

				this.currentSectionIndex -= delta;
				this.isAnimating = false;

				this._recheckBackground();
			});
		}
	}

	_bindAnimationSequence () {
		if (this._destroyed || $$.isMobile) {
			return;
		}

		new ScrollMagic.Scene({
			triggerElement: this.nodes.section1.get(0)
		})
			.setPin(this.nodes.section1.get(0))
			.addTo(this._scrollMagicController);

		new ScrollMagic.Scene({
			triggerElement: this.nodes.section2.get(0)
		})
			.setPin(this.nodes.section2.get(0))
			.addTo(this._scrollMagicController);

		new ScrollMagic.Scene({
			triggerElement: this.nodes.section3.get(0)
		})
			.setPin(this.nodes.section3.get(0))
			.addTo(this._scrollMagicController);

		new ScrollMagic.Scene({
			triggerElement: this.nodes.section4.get(0)
		})
			.setPin(this.nodes.section4.get(0))
			.addTo(this._scrollMagicController);

		new ScrollMagic.Scene({
			triggerElement: this.nodes.section5.get(0)
		})
			.setPin(this.nodes.section5.get(0))
			.addTo(this._scrollMagicController);

		new ScrollMagic.Scene({
			triggerElement: this.nodes.section6.get(0)
		})
			.setPin(this.nodes.section6.get(0))
			.addTo(this._scrollMagicController);

		new ScrollMagic.Scene({
			triggerElement: this.nodes.section7.get(0)
		})
			.setPin(this.nodes.section7.get(0))
			.addTo(this._scrollMagicController);

		new ScrollMagic.Scene({
			triggerElement: this.nodes.section8.get(0)
		})
			.setPin(this.nodes.section8.get(0))
			.addTo(this._scrollMagicController);

		let timeline;

		// Section 1
		timeline = new TimelineMax()
			.from(this.nodes.s1Logo, 1, { marginTop: '50%', opacity: 0, ease: Power2.easeOut });

		timeline
			.duration(3)
			.seek(0)
			.play();

		// Section 2

		timeline = new TimelineMax()
			.from(this.nodes.s2Items.eq(0), 0.8, { opacity: 0, ease: Sine.easeOut })
			.from(this.nodes.s2Items.eq(1), 0.8, { opacity: 0, ease: Sine.easeOut }, 0.2)
			.from(this.nodes.s2Items.eq(2), 0.8, { opacity: 0, ease: Sine.easeOut }, 0.4)
			.from(this.nodes.s2Items.eq(3), 0.8, { opacity: 0, ease: Sine.easeOut }, 0.6)
			.from(this.nodes.s2Items.eq(4), 0.8, { opacity: 0, ease: Sine.easeOut }, 0.8)
			.from(this.nodes.s2Items.eq(5), 0.8, { opacity: 0, ease: Sine.easeOut }, 1)
			.from(this.nodes.s2Ok, 0.8, { opacity: 0, ease: Sine.easeOut }, 1.4);

		new ScrollMagic.Scene({
			triggerHook: 0.1,
			triggerElement: this.nodes.section2.get(0)
		}).setTween(timeline)
			.addTo(this._scrollMagicController);

		timeline = new TimelineMax()
			.from(this.nodes.s3Plan, 1, { opacity: 0, ease: Sine.easeOut })
			.from(this.nodes.s3Plus, 1, { opacity: 0, ease: Sine.easeOut }, 0.2)
			.from(this.nodes.s3Bubble, 1, { opacity: 0, ease: Sine.easeOut }, 0.4)
			.from(this.nodes.s3Equal, 1, { opacity: 0, ease: Sine.easeOut }, 0.6)
			.from(this.nodes.s3Logo, 1, { opacity: 0, ease: Sine.easeOut }, 0.8);

		new ScrollMagic.Scene({
			triggerHook: 0.1,
			triggerElement: this.nodes.section3.get(0)
		}).setTween(timeline)
			.addTo(this._scrollMagicController);

		timeline = new TimelineMax()
			.from(this.nodes.s4White, 0.8, { transform: 'translateX(950px)', opacity: 0, ease: Sine.easeOut })
			.from(this.nodes.s4Blue, 0.8, { transform: 'translateX(1060px)', opacity: 0, ease: Sine.easeOut }, 0.2)
			.from(this.nodes.s4Envelop, 0.8, { transform: 'translateX(990px)', opacity: 0, ease: Sine.easeOut }, 0.4);

		new ScrollMagic.Scene({
			triggerHook: 0.3,
			triggerElement: this.nodes.section4.get(0)
		}).setTween(timeline)
			.addTo(this._scrollMagicController);

		timeline = new TimelineMax()
			.from(this.nodes.s5Cap, 0.8, { transform: 'translateX(-400px)', opacity: 0, ease: Sine.easeOut })
			.from(this.nodes.s5Gloves, 0.8, { transform: 'translateX(-330px)', opacity: 0, ease: Sine.easeOut }, 0.2);

		new ScrollMagic.Scene({
			triggerHook: 0.3,
			triggerElement: this.nodes.section5.get(0)
		}).setTween(timeline)
			.addTo(this._scrollMagicController);

		timeline = new TimelineMax()
			.from(this.nodes.s6Ipad, 0.8, { transform: 'translateX(-340px)', opacity: 0, ease: Sine.easeOut })
			.from(this.nodes.s6Screen, 0.8, { opacity: 0, ease: Sine.easeOut }, 1);

		new ScrollMagic.Scene({
			triggerHook: 0.3,
			triggerElement: this.nodes.section6.get(0)
		}).setTween(timeline)
			.addTo(this._scrollMagicController);

		timeline = new TimelineMax()
			.from(this.nodes.s7Left, 0.8, { left: '-500px', opacity: 0, ease: Sine.easeOut })
			.from(this.nodes.s7Right, 0.8, { left: '1075px', opacity: 0, ease: Sine.easeOut }, 0.2);

		new ScrollMagic.Scene({
			triggerHook: 0.3,
			triggerElement: this.nodes.section7.get(0)
		}).setTween(timeline)
			.addTo(this._scrollMagicController);
	}

	_ready () {
		this.nodes.payload
			.css({
				zIndex: '0',
				overflow: 'hidden'
			});

		// Если вызвано в тот же тик, что и конструктор - ничего не анимируем.
		if (this._isInitialTick) {
			this.nodes.loader.hide();
			this.nodes.payload.css('opacity', 1);
		} else {
			this.nodes.loader.velocity('fadeOut');
			this.nodes.payload.velocity('fadeIn');
		}

		this._bindAnimationSequence();
	}

	destroy () {
		if (this._destroyed) {
			return;
		}

		this._recheckBackground(false);

		this._destroyed = true;
		this._scrollMagicController.destroy();
		this._recheckBackground = _.noop;
		this.root.off();
		this.nodes.payload.off();

		$$.window.off('resize.' + this._hash);

		this._scrollMagicController = null;
	}

	pause () {
		this._paused = true;
		this._recheckBackground(false);
	}

	/**
	 * Эта функция должна вызываться извне и уведомлять нас, что позиция слайда на экране изменилась
	 *
	 * @param animationEnded Закончилась ли анимация. На самом деле вызывается только, когда мы и есть слайд к которому осуществлялся переход
	 */
	repositioned (animationEnded = false) {
		if (this._destroyed) {
			return;
		}

		if (animationEnded) {
			this.nodes.mouseWheelCatcher.hide();
			if (!this._paused) {
				// а без этого ScrollMagic тупит почему-то и не хочет пересчитывать высоту triggerHook. Его собственными методами не чинится
				$$.window.trigger('resize');
			}
		} else {
			this.nodes.mouseWheelCatcher.show();
			this._scrollMagicController.update(true);
		}
	}

	resize () {
		if (this._destroyed) {
			return;
		}

		var scale = $$.isMobile && $$.windowWidth < $$.windowHeight ? $$.windowWidth / 1200 : $$.windowHeight / 1000;

		scale = scale.toFixed(3);

		if ($$.windowWidth < 1600) {
			this.nodes.rows.css({ transform: `scale(${scale})` });
		} else {
			this.nodes.rows.css({ transform: `none` });
		}

		this._scrollHeight = this.nodes.payload.prop('scrollHeight') - this.root.height();
		this._scrollMagicController.update();
	}

	resume (comingFromTop) {
		this._paused = false;

		if (this._destroyed) {
			return;
		}

		this.nodes.sections.eq(this.currentSectionIndex).hide();
		this.currentSectionIndex = comingFromTop ? 0 : this.nodes.sections.length - 1;
		this.nodes.sections.eq(this.currentSectionIndex).show();

		this.nodes.payload.stop(true, true).animate({
			scrollTop: comingFromTop ? 0 : this.nodes.payload.prop('scrollHeight')
		}, 0);

		this._recheckBackground();
	}
};
