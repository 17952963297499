$$.SmoothScroller = class SmoothScroller {
	constructor(element, springOptions, scrollOptions) {
		this._element = $(element);
		this._hash = _.uniqueId('scrollSpring_');
		this._isCrazyMouse = false;

		this._scrollOptions = $.extend({
			stepSize: 200,
			preventMovingTargetTooFar: false,
			doNotRound: false,
			breakSpring: false
		}, scrollOptions);

		this._stepSizePx = this._scrollOptions.stepSize;
		this._scrollHeight = 0; // В нашем случае соответствует максимально возможному scrollTop
		this._scrollPos = 0;
		this._scrollAnimationFrameHandler = null;
		this._lastSetScrollPos = 0; // this._scrollPos может успеть измениться до того как выполнится коллбэк кийфрейма
									// так что для проверки мы ли обновили scrollTop элемента оно не сгодится.
		this._spring = null;
		this._springOptions = _.assign({
			rigidness: 200,
			damping: 2,
			forcePower: 1
		}, springOptions, {
			positionLimits: [0, 1],
			targetLimits: [0, 1]
		});

		this.onScroll = _.noop; // Переопределяемый пользователем коллбэк

		this._initSpring();
		this._bindEvents();
	}

	_bindEvents () {
		const canBeScrolled = (scrolledDownQuestionMark = true) => {
			return (scrolledDownQuestionMark && this._scrollPos < this._scrollHeight - 5)
				|| (!scrolledDownQuestionMark && this._scrollPos > 5);
		};

		this._element
			.on('mousewheel.' + this._hash, _.throttle((event) => {
				// Когда находимся в начальном или конечном положении и пользователь продолжает крутить колесо в направлении,
				// где больше нет пути - пропускаем событие. Пусть браузер или код выше отдувается
				if (!canBeScrolled(event.deltaY <= -1)) {
					return;
				}

				if (this._scrollOptions.breakSpring) {
					event.preventDefault();

					return false;
				}

				let delta = event.deltaY;

				// Опытным путем установлено, что мышь Magic Mouse на MacOS тригеррит абсолютную величину event.deltaY
				// от 0 до >100, на Windows эта величина не достигает 10 (в 95% случаев 1)

				if (Math.abs(delta) >= 20) {
					this._isCrazyMouse = true;
				}

				if ((this._isCrazyMouse && Math.abs(delta) === 1) || !delta) {
					event.stopPropagation();
					event.preventDefault();
					return false;
				}

				// множители и делители ниже используются для получения оптимальной скорости скролла кейсов на MacOS;
				// определены опытным путем. Увеличение делителя (40) приведет к замедлению. Однако, если, delta будет
				// менее 0.45 - скрость будет неестественно медленной, поэтому, в этом случае, мы переопределяем ее

				if (this._stepSizePx >= $$.windowHeight || !this._isCrazyMouse) {
					delta = delta / Math.abs(delta);
				} else {
					event.deltaY = event.deltaY / 40;
					delta = delta / 40;

					if (Math.abs(delta) < 0.45) {
						delta = 0.51 * (delta / Math.abs(delta));
					}
				}

				// Иначе сдвигаем цель скролла вверх/вниз.
				// Начинаем с определения шага скролла (фактор)

				const stepSize = this._stepSizePx / this._scrollHeight;
				const currentTarget = this._spring.target();
				const currentPos = this._spring.position();

				// Можно было бы просто сдвигать таргет уже, но тут может быть небольшой неприятный момент:
				// Скажем, степсайз нам передали в высоту с вьюпорт, а прокрутка внутри элемента оказалась смещена
				// пользователем или браузером немного. Мы тогда так и будем ходим между промежуточными состояниями
				// Так что сначала округляем текущий таргет до ближайшего значения кратного степсайзу, а уже от него
				// двигаемся куда нужно

				const steppingFrom = this._scrollOptions.preventMovingTargetTooFar ? currentPos : currentTarget;

				const completeSteps = this._scrollOptions.doNotRound ? steppingFrom / stepSize
						: Math.round(steppingFrom / stepSize);

				const newTarget = completeSteps * stepSize + (delta * -1 * stepSize);

				this._spring.target(newTarget);

				event.stopPropagation();
				event.preventDefault();

				return false;
			}, 100))


			// Мы так же не хотим ломать работу полосы прокрутки, mouse3-скролла и прокрутку браузера при поиске, так что
			// слушаем событие, и если оказывается, что новое значение отличается от того, что мы проставили - усмиряем пружину.
			.on('scroll.' + this._hash, () => {
				let scrollTop = this._element.scrollTop();
				let posFactor = scrollTop / this._scrollHeight;

				if (scrollTop == this._lastSetScrollPos) {
					return;
				}

				this._scrollPos = scrollTop;
				this._spring.target(posFactor);
				this._spring.position(posFactor);
				this._spring.velocity(0);

				// Мы так же не хотим перетирать значение скролла, так как это случится неприлично позже реального события.

				cancelAnimationFrame(this._scrollAnimationFrameHandler);
				this._scrollAnimationFrameHandler = null;
			});

		// We also should supress swipe events if there's still place to scroll

		let lastY;

		this._element
			.on(`touchstart.${this._hash}`, (e) => {
				lastY = e.originalEvent.touches[0].clientY;
			})
			.on(`touchmove.${this._hash}`, (e) => {
				var currentY = e.originalEvent.touches[0].clientY;

				if(canBeScrolled(currentY < lastY)) {
					e.stopPropagation();
				}

				lastY = currentY
			});
	}

	_initSpring () {
		const self = this;

		const actualSpringOptions = _.assign(this._springOptions, {
			step: function() {
				let oldScrollPos = self._scrollPos;

				// Высчитываем значение прокрутки

				self._scrollPos = Math.round(this.position() * self._scrollHeight);

				// Если нет фрейма в очереди - ставим. Если скролл остановился, но последняя выставленная позиция
				// не соответствует правильной - тоже ставим. Иначе не ставим.

				if (
					self._scrollAnimationFrameHandler !== null
					|| (oldScrollPos == self._scrollPos && self._scrollPos === self._lastSetScrollPos)
				) {
					return;
				}

				self._scrollAnimationFrameHandler = requestAnimationFrame(function() {
					self._scrollAnimationFrameHandler = null;

					self._element.get(0).scrollTop = self._scrollPos;
					self._lastSetScrollPos = self._scrollPos;
					self.onScroll();
				});
			}
		});

		this._spring = new $$.Simulation.Spring(actualSpringOptions);
	}

	destroy () {
		if (!this._spring) {
			return;
		}

		this._element.off('.' + this._hash);
		this._spring.destroy();

		this._element = _.noop();
		this._spring = _.noop();
		this.onScroll = _.noop;

		cancelAnimationFrame(this._scrollAnimationFrameHandler);
	}

	pause () {
		this._spring.frozen(true);
	}

	recalculate () {
		this._scrollHeight = this._element.prop('scrollHeight') - this._element.height();
	}

	goToNearestStepValue () {
		const scrollTop = this._element.prop('scrollTop');
		const nearestStepValue = Math.round(scrollTop / this._stepSizePx);
		const nearestTarget = nearestStepValue * this._stepSizePx / this._scrollHeight;

		this._spring.target(nearestTarget);
	}

	resume () {
		this._spring.frozen(false);
	}

	step (newVal) {
		if (newVal == null) {
			return this._stepSizePx;
		}

		if (!_.isNumber(newVal)) {
			throw "Number expected.";
		}

		this._stepSizePx = newVal;
		this.recalculate();
	}
};
