var $$ = $$ || {};

$$.VideoBlock = class VideoBlock {
	constructor (root) {
		this.root = root;

		this._bindEvents();
	}

	/**
	 *
	 * @param videoType
	 * @param videoId
	 * @param width
	 * @param height
	 * @returns {*}
	 * @private
	 */

	_makeVideoPlayerHtml (videoType, videoId, width, height) {
		if (videoType === 'youtube') {
			return '<iframe class="youtube-player" type="text/html"'
				+ ' width="' + width + '" height="' + height + '" src="'
				+ '//www.youtube.com/embed/' + videoId + '?autoplay=0&rel=0&amp;controls=0&amp;showinfo=0'
				+ '" frameborder="0" wmode="opaque" autoplay="false"></iframe>';
		} else if (videoType === 'vimeo') {
			return '<iframe wmode="opaque" width="' + width + '" height="' + height + '" src="'
				+ '//player.vimeo.com/video/' + videoId + '?autoplay=1'
				+ '" frameborder="0" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>';
		}

		return '';
	}

	_template (player = '') {
		return `<div class="g-video-popup js-video-popup">
			<div class="close inner-lines-rotated js-close-video-block">
				<span class="lines-wrapper">
					<div class="line line-top"></div>
					<div class="line line-middle"></div>
					<div class="line line-bottom"></div>
				</span>
			</div>
			<div class="video-block">${player}</div>
		</div>`;
	}

	/**
	 * Вешает обработчики событий на компоненты/элементы.
	 *
	 * @private
	 */
	_bindEvents () {
		this.root.on('click', (event) => {
			event.preventDefault();
			$('body').append(this._template(this._makeVideoPlayerHtml(this.root.data('videoType'), this.root.data('videoId'), 500, 281)));
		});

		$('body').on('click', '.js-close-video-block', function () {
			$(this).parent().velocity('fadeOut', 200, function () {
				$(this).remove();
			});
		});
	}
}
