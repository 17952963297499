(function($$) {
	$$.AbstractFilter = class AbstractFilter {
		constructor(sourceDrawable, value) {
			this._value = value;

			this._source = sourceDrawable;
			this._result = document.createElement('canvas');
			this._result.width = sourceDrawable.width;
			this._result.height = sourceDrawable.height;
		}

		getBitmap(copy = false) {
			if (!copy) {
				return this._result;
			}

			return CanvasUtils.cloneCanvas(this._result);
		}

		destroy() {
			this._tickerValues = null;
			this._source = null;
			this._canvas = null;
		}

		setValue(value) {
			this._value = value;
		}

		setSource(newSource) {
			if (this._source.width !== newSource.width || this._source.height !== newSource.height) {
				throw "New source must be of the same size as the old one";
			}

			this._source = newSource;
		}
	};
})(window.$$ || (window.$$ = {}));
