var $$ = $$ || {};

// INFO: Категориями в этом слайдере называются вертикальные слайды. А горизонтальные слайды внутри вертикальных - слайдами. Немного запутанно.

$$.ServiceSlider = class ServiceSlider {
	constructor (root, options = {}) {
		var defaultOptions = {
			duration: 1000,
			easing: 'swing'
		};

		this.root = root;
		this.options = _.merge(options, defaultOptions, _.defaults);
		this.currentCategoryIndex = 0;
		this.currentSlideIndex = 0;
		this._isCrazyMouse = false;

		this.slideWidth = 0;
		this.slideHeight = 0;

		this.isAnimating = false;

		this._cacheNodes();
		this._createComponents();
		this._bindEvents();
		this._ready();
	}

	_cacheNodes () {
		this.nodes = {
			categoriesContainers: this.root.find('.js-categories'),
			slidesContainers: this.root.find('.js-slides'),
			slides: this.root.find('.js-slide'),
			left: this.root.find('.js-left'),
			right: this.root.find('.js-right'),
			categoriesControlsContainer: this.root.find('.js-category-controls'),
			categoriesBulls: [],
			slidesControlsContainer: this.root.find('.js-slide-controls'),
			slidesBulls: [],
			slashMenu: this.root.find('.js-slash-menu'),
			items: this.root.find('.js-item'),
			currentSlide: $(),
			background: $('.js-background-container')
		};
	}

	/**
	 * Создает необходимые компоненты.
	 *
	 * @private
	 */
	_createComponents () {
		this.serviceManager = new $$.ServiceManager();
	}

	/**
	 * Вешает обработчики событий на компоненты/элементы.
	 *
	 * @private
	 */
	_bindEvents () {
		this.nodes.left.on('click', () => {
			this._goTo(this.currentCategoryIndex, this.currentSlideIndex - 1);
		});

		this.nodes.right.on('click', () => {
			this._goTo(this.currentCategoryIndex, this.currentSlideIndex + 1);
		});

		this.nodes.categoriesControlsContainer.on('click', '.js-category-bull', (event) => {
			this._goTo($(event.target).index(), 0);
		});

		this.nodes.slidesControlsContainer.on('click', '.js-slide-bull', (event) => {
			this._goTo(this.currentCategoryIndex, $(event.target).index());
		});

		this.root.on('mousewheel.serviceslider', (event) => {
			let delta = event.deltaY;

			if (Math.abs(delta) >= 20) {
				this._isCrazyMouse = true;
			}

			if (this._isCrazyMouse && Math.abs(delta) === 1) {
				event.stopPropagation();
				event.preventDefault();
				return;
			}

			delta = delta / Math.abs(delta);
			event.deltaY = event.deltaY / 40;

			if (!this.isAnimating) {
				this._goTo(this.currentCategoryIndex, this.currentSlideIndex - delta);
			}
		});

		$$.window.on('resize.serviceslider', () => {
			this._resizeSlides();
		});

		$$.window.on("keydown.serviceslider", (event) => {
			if (event.ctrlKey) {
				switch (event.which) {
					case 37: // left
						this._goTo(this.currentCategoryIndex, this.currentSlideIndex - 1);
						break;

					case 38: // up
						this._goTo(this.currentCategoryIndex - 1, this.currentSlideIndex);
						break;

					case 39: // right
						this._goTo(this.currentCategoryIndex, this.currentSlideIndex + 1);
						break;

					case 40: // down
						this._goTo(this.currentCategoryIndex + 1, this.currentSlideIndex);
						break;
				}
			} else {
				switch (event.which) {
					case 33: // Page Up
						this._goTo(0, 0);
						break;

					case 34: // Page Down
						this._goTo(this._getLastCategoryIndex(), 0);
						break;
				}
			}
		});

		this.root.swipeLeft(() => {
			if (!this.isAnimating && this.currentSlideIndex < this._getLastSlideIndex()) {
				this._goTo(this.currentCategoryIndex, this.currentSlideIndex + 1);
			}
		});

		this.nodes.slides.on('touchmove', (event) => {
			event.preventDefault();
		});

		this.root.swipeRight(() => {
			if (!this.isAnimating && this.currentSlideIndex > 0) {
				this._goTo(this.currentCategoryIndex, this.currentSlideIndex - 1);
			}
		});

		this.root.swipeUp(() => {
			var scrollContainer = this.nodes.currentSlide.find('.js-scrolling');
			var maxScroll = scrollContainer.find('.js-inner').height() - $$.windowHeight - 30;

			if (scrollContainer.length && scrollContainer.scrollTop() < maxScroll) {
				return;
			}

			if (!this.isAnimating) {
				this._goTo(this.currentCategoryIndex + 1, 0);
			}
		});

		this.root.swipeDown(() => {
			var scrollContainer = this.nodes.currentSlide.find('.js-scrolling');

			if (scrollContainer.length && scrollContainer.scrollTop() > 0) {
				return;
			}

			if (!this.isAnimating) {
				this._goTo(this.currentCategoryIndex - 1, 0);
			}
		});
	}

	/**
	 * Вызывается на этапе готовности компонентов/элементов к работе с ними.
	 *
	 * В основном метод используется для применения опций, добавления шаблона в DOM.
	 *
	 * @private
	 */
	_ready () {
		if (!this.nodes.slides.length) {
			this.root.hide();
			return;
		}

		var startUrl = window.location.pathname;
		var startCategoryIndex = this.nodes.categoriesContainers.find(`[data-url="${startUrl}"]`).index();

		this.currentCategoryIndex = startCategoryIndex !== -1 ? startCategoryIndex : 0;
		this.currentSlideIndex = 0;

		this._resizeSlides();

		let containedFragments = [];

		this.nodes.items.filter((index, element) => {
			return $(element).data('background-url');
		}).each((index, element) => {
			var item = $(element);
			containedFragments.push(item.data('backgroundId'));
		});

		$$.backgroundEffects.preloadFragmentBackgrounds(containedFragments);
		this._createCategoryBulls();

		this._goTo(this.currentCategoryIndex, this.currentSlideIndex);
	}

	_resizeSlides () {
		this.slideWidth = this.root.width();
		this.slideHeight = this.root.height();

		this.nodes.slides.css({
			width: this.slideWidth,
			height: this.slideHeight
		});

		this.nodes.slidesContainers.each((index) => {
			var currentSlidesContainer = this.nodes.slidesContainers.eq(index);
			var slidesCount = currentSlidesContainer.find('.js-slide').length;

			currentSlidesContainer.css({ width: slidesCount * this.slideWidth });
		});

		this.nodes.categoriesContainers.velocity('stop', true).velocity({
			translateY: -this.currentCategoryIndex * this.slideHeight
		}, 0);

		this.nodes.slidesContainers.velocity('stop', true).velocity({
			translateX: -this.currentSlideIndex * this.slideWidth
		}, 0);
	}

	_createCategoryBulls () {
		_.times(this._getLastCategoryIndex() + 1, (index) => {
			this.nodes.categoriesBulls.push($(`<li class="item js-category-bull js-slash-item">${index + 1}</li>`));
		});

		if (this._getLastCategoryIndex() > 0) {
			this.nodes.categoriesBulls[this.currentCategoryIndex].addClass('active');
			this.nodes.categoriesControlsContainer.append(this.nodes.categoriesBulls);
		}

		this.slashMenu = new $$.SlashMenu(this.nodes.slashMenu);
		this.nodes.slashMenu.trigger('slashmenu.update');
	}

	_createSlideBulls () {
		this.nodes.slidesControlsContainer.empty('.js-slide-bull');

		if (this._getLastSlideIndex()) {
			_.times(this._getLastSlideIndex() + 1, () => {
				this.nodes.slidesBulls.push($('<li class="item js-slide-bull"></li>'));
			});

			this.nodes.slidesBulls[0].addClass('active');
			this.nodes.slidesControlsContainer.append(this.nodes.slidesBulls);
		}
	}

	/**
	 * Возвращает последний индекс слайда в текушей категории
	 * @returns {number}
	 * @private
	 */
	_getLastSlideIndex () {
		return this.nodes.slidesContainers.eq(this.currentCategoryIndex).children().length - 1;
	}

	/**
	 * Возвращает последний индекс категории
	 * @returns {number}
	 * @private
	 */
	_getLastCategoryIndex () {
		return this.nodes.slidesContainers.length - 1;
	}

	/**
	 * Переход на заданную категорию
	 * @param index
	 * @private
	 */
	_goToCategory (index) {
		this.nodes.slidesBulls = [];

		if (this._getLastCategoryIndex() < index || index < 0) {
			return;
		} else {
			this.currentCategoryIndex = index;
		}

		this.isAnimating = true;
		this.nodes.slides.trigger('animatingInOut');

		// Анимируем

		this.nodes.categoriesContainers.velocity('stop', true).velocity({
			translateY: -this.currentCategoryIndex * this.slideHeight
		}, {
			duration: this.options.duration,
			easing: this.options.easing,
			progress: () => {
				this.serviceManager.informAnimationProgress();

				// TODO: $.application оказывается пересоздан каждый раз, как меняется страница. Код пересчета фона нужно перенести
				// $$.application.checkBackground(); // (если он когда-нибудь заработает)
			},
			complete: () => {
				this.serviceManager.informAnimationEnd();
				this.isAnimating = false;
			}
		});

		this.nodes.categoriesControlsContainer.find('.js-category-bull')
			.removeClass('active')
			.eq(this.currentCategoryIndex)
			.addClass('active');

		this.nodes.slashMenu.trigger('slashmenu.update');

		if (this.currentCategoryIndex !== this.nodes.slidesControlsContainer.data('category')) {
			this._createSlideBulls();
			this.nodes.slidesControlsContainer.data('category', this.currentCategoryIndex);
		}

		var slideUrl = this.nodes.categoriesContainers.children().eq(this.currentCategoryIndex).data('url');
		var slideTitle = this.nodes.categoriesContainers.children().eq(this.currentCategoryIndex).data('title');

		if (slideUrl && slideTitle) {
			history.replaceState(null, slideTitle, slideUrl);
			document.title = slideTitle;
		}
	}

	/**
	 * Переход на заданный слайд
	 * @param index
	 * @private
	 */
	_goToSlide (index) {
		if (index > this._getLastSlideIndex()) {
			if (this.currentCategoryIndex !== this._getLastCategoryIndex()) {
				this._goToCategory(this.currentCategoryIndex + 1);
				this.currentSlideIndex = 0;
			} else {
				this._goToCategory(this.currentCategoryIndex + 1);
				this.currentSlideIndex = this._getLastSlideIndex();
			}
		} else if (index < 0) {
			if (this.currentCategoryIndex) {
				this._goToCategory(this.currentCategoryIndex - 1);
				this.currentSlideIndex = this._getLastSlideIndex();
			} else {
				this._goToCategory(this.currentCategoryIndex - 1);
				this.currentSlideIndex = 0;
			}
		} else {
			this.currentSlideIndex = index;
		}

		this.isAnimating = true;

		this.nodes.slidesContainers.eq(this.currentCategoryIndex).velocity('stop', true).velocity({
			translateX: -this.currentSlideIndex * this.slideWidth
		}, this.options.duration, this.options.easing, ()=> {
			this.isAnimating = false;
		});

		this.nodes.slidesControlsContainer.find('.js-slide-bull.active').removeClass('active');
		this.nodes.slidesControlsContainer.find('.js-slide-bull').eq(this.currentSlideIndex).addClass('active');
	}

	/**
	 * Переход на слайд и категорию
	 * @param categoryIndex
	 * @param slideIndex
	 * @private
	 */
	_goTo (categoryIndex, slideIndex) {
		if ((slideIndex < 0 && this.currentCategoryIndex === 0)
			|| (slideIndex > this._getLastSlideIndex() && this.currentCategoryIndex === this._getLastCategoryIndex())) {
			return;
		}

		let oldCategoryIndex = this.currentCategoryIndex;
		let oldSlideIndex = this.currentSlideIndex;
		let effectDuration = this.options.duration * 0.975; // Он может чуть запаздывать из-за блюра, так что просим анимироваться быстрее.

		this._goToCategory(categoryIndex);
		this._goToSlide(slideIndex);

		let newCategoryIndex = this.currentCategoryIndex;
		let newSlideIndex = this.currentSlideIndex;

		// From which side we **enter** the slide (opposite to which direction slide moves from)
		const directionVerticalFromTop = oldCategoryIndex <= newCategoryIndex;
		const directionHorizontalFromLeft = oldSlideIndex <= newSlideIndex;
		const directionNone = oldCategoryIndex === newCategoryIndex && oldSlideIndex === newSlideIndex;

		const prevCategorySlide = this.nodes.slidesContainers.eq(oldCategoryIndex);
		const currentCategorySlide = this.nodes.slidesContainers.eq(this.currentCategoryIndex);
		const currentSlideWithinCategory = currentCategorySlide.children('.js-slide').eq(newSlideIndex);
		/// ?! Не знаю почему оно не вместе с каегорией, но данные фона хранятся на нем.
		const currentCategoryContainerErm = this.nodes.items.eq(this.currentCategoryIndex);
		const prevCategory = this.nodes.items.eq(oldCategoryIndex);

		// If "category" index changed - background should be changed in vertical direction.
		// If "category" stayed the same - must find out in which direction slides gone.
		// Background is backgroundId of category slide, overloaded by current slide backgroundId if present.

		if (prevCategorySlide.find('.b-portfolio-work, .video-feedback-block').length) {
			effectDuration = 0;

			setTimeout(() => {
				$$.backgroundEffects.show();
			}, effectDuration);
		}

		if (!currentCategorySlide.find('.b-portfolio-work, .video-feedback-block').length) {
			$$.backgroundEffects.revealFragmentBg(
				[
					currentCategoryContainerErm.data('backgroundId'),
					currentSlideWithinCategory.data('backgroundId')
				],
				directionNone ? 'none'
					: oldCategoryIndex !== newCategoryIndex ? directionVerticalFromTop ? 'bottom' : 'top'
					: directionHorizontalFromLeft ? 'right' : 'left',
				effectDuration, // Он может чуть запаздывать из-за блюра, так что просим анимироваться быстрее.
				this.options.easing,
				[
					prevCategory.data('backgroundId')
				]
			);
		} else {
			setTimeout(() => {
				$$.backgroundEffects.hide();
			}, effectDuration);
		}

		// Инициализируем или возобновляем жс код слайда

		this.nodes.currentSlide = currentSlideWithinCategory;
		this.serviceManager.enterSlide(this.nodes.currentSlide, this.options.duration, directionVerticalFromTop);

		this._updateSlideArrows();
	}

	_updateSlideArrows () {
		if (this.currentSlideIndex === 0 || this._getLastSlideIndex() === 0) {
			this.nodes.left.addClass('disabled')
		} else if (this.nodes.left.hasClass('disabled')) {
			this.nodes.left.removeClass('disabled');
		}

		if (this.currentSlideIndex === this._getLastSlideIndex()
			|| this._getLastSlideIndex() === 0) {
			this.nodes.right.addClass('disabled')
		} else if (this.nodes.right.hasClass('disabled')) {
			this.nodes.right.removeClass('disabled');
		}
	}
};
