/**
 * Вычисляет размеры и сдвиг дочернего блока, чтобы он покрывал или оказывался вписан в родительский блок.
 *
 * @param {Boolean} circumscribe Нужно ли описать или вписать
 * @param {Number} childWidth - Ширина целевого элемента
 * @param {Number} childHeight - Высота целевого элемента
 * @param {Number} parentWidth - Ширина родительского блока
 * @param {Number} parentHeight - Высота родительского блока
 * @param {Object} [options] - Дополнительные опции. Можно указать выравнивание. По умолчанию по центру
 * @param {Number} [options.positionX] - Коэффициент смещения по горизонтали
 * @param {Number} [options.positionY] - Коэффициент смещения по вертикали
 *
 * @return {Object} { left, top, width, height }
 */
$$.calculateDimensions = function(circumscribe, childWidth, childHeight, parentWidth, parentHeight, options) {
	options = $.extend({
		positionX: 0.5, // 0 - left, 1 - right
		positionY: 0.5, // 0 - top, 1 - bottom
		upscalingAllowed: true
	}, options);

	options.positionX = $$.clamp(options.positionX, 0, 1);
	options.positionY = $$.clamp(options.positionY, 0, 1);

	var parentAR = parentWidth / parentHeight;
	var childAR = childWidth / childHeight;
	var result = {};

	if (circumscribe == childAR < parentAR) {
		result.width = options.upscalingAllowed ? parentWidth : Math.min(childWidth, parentWidth);
		result.height = Math.ceil(result.width / childAR);
	} else {
		result.height = parentHeight;
		result.height = options.upscalingAllowed ? parentHeight : Math.min(childHeight, parentHeight);
		result.width = Math.ceil(result.height * childAR);
	}

	result.left = Math.floor((parentWidth - result.width) * options.positionX);
	result.top = Math.floor((parentHeight - result.height) * options.positionY);

	return result;
};
