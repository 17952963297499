const $$ = window.$$ || (window.$$ = {});
//import BaseProjectPageClass from '_baseWork.js';

$$.Tuileries = class Tuileries extends $$.BaseProjectPageClass {
	constructor (root) {
		super(root, 'portfolio-entry-tuileries-');

		this._useSmallScrollStep = false;
		this._maxScreensScale = 2;
		this._screensScaleFactor = 1;
	}

	_cacheNodes () {
		super._cacheNodes();

		const payload = this.nodes.payload;
		const pen = payload.find('.section2 .pen');

		$.extend(this.nodes, {
			fullscreenSections: this.root.find('.section.fullscreen'),

			section1:           payload.find('> .section1'),
			section1Logo:       payload.find('> .section1 .logo'),

			section2Animatable: payload.find('> .section2 .js-pw-animation-target'),

			sectionParallax1:   payload.find('> .section3 .parallax-block.leaves'),
			sectionParallax1L1: payload.find('> .section3 .parallax-block.leaves .top-layer'),
			sectionParallax1L2: payload.find('> .section3 .parallax-block.leaves .bottom-layer'),

			sectionParallax2:   payload.find('> .section3 .parallax-block.ipad'),
			sectionParallax2L1: payload.find('> .section3 .parallax-block.ipad .overlay'),
			sectionParallax2L2: payload.find('> .section3 .parallax-block.ipad .screen'),

			section4:           payload.find('> .section4'),
			section4PinTarget:  payload.find('> .section4 .pin-target'),
			section4Scrollable: payload.find('> .section4 .screen'),

			section5Slider1:    payload.find('> .section5 .screen.big .slides'),
			section5Slider1Prev:payload.find('> .section5 .screen.big .prev'),
			section5Slider1Next:payload.find('> .section5 .screen.big .next'),
			section5Slider2:    payload.find('> .section5 .screen.small .slides'),
			section5Slider2Prev:payload.find('> .section5 .screen.small .prev'),
			section5Slider2Next:payload.find('> .section5 .screen.small .next'),
			sliderContainer:    payload.find('> .section5 .js-slider-container'),

			section6:           payload.find('> .section6'),
			section6TY:         payload.find('> .section6 .thank-you')
		});
	}

	_ready () {
		this.nodes.payload.imagesLoaded({
			background: true
		})
			.always(() => {
				super._ready();
			});
	}

	_bindAnimationSequence () {
		const controller = this._scrollMagicController;
		let timeline;

		this.nodes.section1Logo.hide();
		setTimeout(() => {
			this.nodes.section1Logo.show().addClass('animate');
		}, 700);

		// Секция 2

		var previousElementStartedFromTheLeft = true;

		this.nodes.section2Animatable.each(function() {
			const target = {
				x: (previousElementStartedFromTheLeft ? '+' : '-') + '=90%',
				opacity: 0,
				ease: Power2.easeOut
			};

			new ScrollMagic.Scene({
				duration: () => { return ~~($$.windowHeight * 0.65); },
				triggerHook: 1,
				triggerElement: this
			})
				.setTween(new TimelineMax().from(this, 1, target))
				.addTo(controller);

			previousElementStartedFromTheLeft = !previousElementStartedFromTheLeft;
		});

		// Параллаксы

		timeline = new TimelineMax()
			.to(this.nodes.sectionParallax1L2  , 1, { top: '15%', ease: Linear.easeNone }, 0)
			.from(this.nodes.sectionParallax1L1  , 0.6, { backgroundPositionY: '100%', ease: Power1.easeIn }, 0.4)
		;

		new ScrollMagic.Scene({
			duration: () => { return ~~($$.windowHeight * 1.2); },
			triggerHook: 0.9,
			triggerElement: this.nodes.sectionParallax1.get(0)
		})
			.setTween(timeline)
			.addTo(this._scrollMagicController);

		// Айпэд

		timeline = new TimelineMax()
			.to(this.nodes.sectionParallax2L1  , 1, { top: '-40%', ease: Power1.easeOut }, 0)
			.to(this.nodes.sectionParallax2L2  , 1, { top: '-20%', ease: Power1.easeOut }, 0)
		;

		new ScrollMagic.Scene({
			duration: () => { return ~~($$.windowHeight * 0.8); },
			triggerHook: 0.75,
			triggerElement: this.nodes.sectionParallax2.get(0)
		})
			.setTween(timeline)
			.addTo(this._scrollMagicController);

		// Айпэд со скроллируемой страницей внутри

		timeline = new TimelineMax()
			.to({}, 0.1, {})
			.to(this.nodes.section4Scrollable  , 1, { backgroundPositionY: '100%', ease: Linear.easeNone })
			.to({}, 0.1, {})
		;

		new ScrollMagic.Scene({
			duration: () => { return $$.windowHeight * 2; },
			triggerHook: 0,
			triggerElement: this.nodes.section4.get(0)
		})
			.setTween(timeline)
			.setPin(this.nodes.section4PinTarget.get(0))
			.addTo(this._scrollMagicController);

		timeline = new TimelineMax()
				.to({}, 0.1, {
					onStart: () => {
						console.log('start')
						this.nodes.sliderContainer.find('.ripple').addClass('blick');
					},
					onComplete: () => {
						console.log('complete')
						this.nodes.sliderContainer.find('.ripple').removeClass('blick');
					},
					onReverseComplete: () => {
						this.nodes.sliderContainer.find('.ripple').removeClass('blick');
					}
				});

		new ScrollMagic.Scene({
			duration: () => $$.windowHeight,
			triggerHook: ($$.windowHeight / 3) / $$.windowHeight,
			triggerElement: this.nodes.sliderContainer.get(0)
		})
				.setTween(timeline)
				.addTo(this._scrollMagicController);

		// Спасибо за внимание

		timeline = new TimelineMax()
			.from(this.nodes.section6TY  , 1, { opacity: 0, ease: Power1.easeIn })
		;

		new ScrollMagic.Scene({
			duration: () => { return ~~($$.windowHeight * 0.45); },
			triggerHook: 0.5,
			triggerElement: this.nodes.section6.get(0)
		})
			.setTween(timeline)
			.addTo(this._scrollMagicController);

		this._initSliders();
	}

	_initSliders() {
		this.nodes.section5Slider1.carouFredSel({
			pauseOnHover: true,
			auto:         true,
			items:        1,
			height:       '100%',
			scroll:       {
				items:    1,
				duration: 600,
				easing:   'easeOutCirc'
			},
			prev:         this.nodes.section5Slider1Prev,
			next:         this.nodes.section5Slider1Next
		});

		this.nodes.section5Slider2.carouFredSel({
			pauseOnHover: true,
			auto:         true,
			items:        1,
			height:       '100%',
			scroll:       {
				items:    1,
				duration: 600,
				easing:   'easeOutCirc'
			},
			prev:         this.nodes.section5Slider2Prev,
			next:         this.nodes.section5Slider2Next,
			synchronise:  [this.nodes.section5Slider1, true]
		});
	}

	repositioned(isAnimationOver) {
		super.repositioned(isAnimationOver);

		if (isAnimationOver) {
			_.defer(function() {
				$$.body.addClass('black-controls');
			});
		} else {
			$$.body.removeClass('black-controls');
		}
	}

	resize () {
		if (this._destroyed) {
			return;
		}

		super.resize();

		this._springScroller.step($$.windowHeight / 5);
		this._initSliders();
	}
};
