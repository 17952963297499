const $$ = window.$$ || (window.$$ = {});

$$.Philips = class Philips extends $$.BaseProjectPageClass {
	constructor (root) {
		super(root, 'portfolio-entry-philips-');

		this.isMobile = this.root.data('mobile') === '' ? false : true;

		this._useSmallScrollStep = false;
		this._scrollStep = 200;
	}

	_cacheNodes () {
		super._cacheNodes();

		const payload = this.nodes.payload;

		$.extend(this.nodes, {
			sections: this.root.find('.js-animation-block'),
			// Block-1
			block1:                this.root.find('.section-block-1'),
			block1Left:            this.root.find('.section-block-1 .anim-left'),
			block1Right:           this.root.find('.section-block-1 .anim-right'),
			block1Description:     this.root.find('.section-block-1 .description'),
			block1DescriptionText: this.root.find('.section-block-1 .description .text'),

			// Block-2
			block2:       this.root.find('.section-block-2'),
			block2BgLeft: this.root.find('.section-block-2 .bg-anim-left'),
			block2Left:   this.root.find('.section-block-2 .anim-left'),
			block2Right:  this.root.find('.section-block-2 .anim-right'),
			block2Bottom: this.root.find('.section-block-2 .bottom'),

			// Block-3
			block3:       this.root.find('.section-block-3'),
			block3Left:   this.root.find('.section-block-3 .anim-left'),
			block3Right:  this.root.find('.section-block-3 .anim-right'),
			block3Bottom: this.root.find('.section-block-3 .bottom'),

			// Block-4
			block4:       this.root.find('.section-block-4'),
			block4Left:   this.root.find('.section-block-4 .anim-left'),
			block4Right:  this.root.find('.section-block-4 .anim-right'),
			block4Bottom: this.root.find('.section-block-4 .bottom')
		});
	}

	_ready () {
		if (this._waitingForDependenciesToLoad) {
			return;
		}

		this.nodes.payload.imagesLoaded({
			background: true
		})
			.always(() => {
				super._ready();
			});
	}

	_bindEvents () {
		"use strict";
		super._bindEvents();

		this.nodes.sections.on('mousewheel', (event) => {
			if (this._springScroller._spring.position().toFixed(4)
					!== this._springScroller._spring.target().toFixed(4)){
				event.stopPropagation();
				event.preventDefault();
			}
		});
	}

	_bindAnimationSequence () {
		let timeline;
		let scene;

		if (this.isMobile) {
			return;
		}

		/* Section 1 - Запускается автоматически 1 раз
		 * = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
		var onCompleteFirstSlideAnimation = () => {
			"use strict";
			this._springScroller.resume();
		};

		this._springScroller.pause();

		TweenMax.from(this.nodes.block1Left, 1, { left: '-1000%', top: '-350px', ease: Power1.easeIn, delay: 0 });
		TweenMax.from(this.nodes.block1Right, 1, { width: '100%', right: '-100%', ease: Power1.easeIn, delay: 0 });
		TweenMax.from(this.nodes.block1Description, 1, { marginTop: '1000px', ease: Power1.easeIn, delay: 0.5 });
		TweenMax.from(this.nodes.block1DescriptionText, 3, {
			opacity:    0,
			ease:       Power2.easeOut,
			delay:      1.6,
			onComplete: onCompleteFirstSlideAnimation
		});

		/* Section 2 - запускается, когда секция на 80% находится во вьюпорте
		 * = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */

		//TweenMax;

		const timeline2 = new TimelineMax()
			.from(this.nodes.block2BgLeft, 0.5, { left: '-=1000px', ease: Power1.easeIn })
			.from(this.nodes.block2Left, 0.5, { left: '-=1000px', ease: Power1.easeIn })
			.from(this.nodes.block2Right, 0.5, { right: '-=1000px', ease: Power1.easeIn })
			.from(this.nodes.block2Bottom, 0.5, { marginTop: '1000px', ease: Power1.easeIn });

		scene = new ScrollMagic.Scene({
			duration:       0,
			tweenChanges:   true,
			reverse:        false,
			triggerHook:    0.1,
			triggerElement: this.nodes.block2.get(0)
		});

		scene.setTween(timeline2);
		scene.addTo(this._scrollMagicController);

		/* Section 3 - запускается, когда секция на 80% находится во вьюпорте
		 * = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */

		//TweenMax;

		const timeline3 = new TimelineMax()
			.from(this.nodes.block3Left, 1, { left: '-=100%', ease: Power1.easeIn })
			.from(this.nodes.block3Right, 1, { right: '-=100%', ease: Power1.easeIn })
			.from(this.nodes.block3Bottom, 1, { marginTop: '800px', ease: Power1.easeIn });

		scene = new ScrollMagic.Scene({
			duration:       20,
			tweenChanges:   true,
			reverse:        false,
			triggerHook:    0.2,
			triggerElement: this.nodes.block3.get(0)
		});

		scene.setTween(timeline3);
		scene.addTo(this._scrollMagicController);

		/* Section 4 - запускается, когда секция на 80% находится во вьюпорте
		 * = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */

		//TweenMax;

		const timeline4 = new TimelineMax()
			.from(this.nodes.block4Left, 1, { top: '1000px', ease: Power1.easeIn })
			.from(this.nodes.block4Right, 1.5, { right: '-=5000px', ease: Power1.easeIn });

		scene = new ScrollMagic.Scene({
			duration:       20,
			tweenChanges:   true,
			reverse:        false,
			triggerHook:    0.2,
			triggerElement: this.nodes.block4.get(0)
		});

		scene.setTween(timeline4);
		scene.addTo(this._scrollMagicController);
	}

	resize () {
		if (this._waitingForDependenciesToLoad || this._destroyed) {
			return;
		}

		//this.nodes.fullscreenSections.height($$.windowHeight);

		super.resize();

		this.root.width($$.window.width() + 17);

		var windowHeight = $$.window.height();

		this.nodes.payload.find('section').each((index, element) => {
			$(element).height(windowHeight - 100);
		});

		this._springScroller.step(this._useSmallScrollStep ? this._scrollStep : $$.windowHeight);
	}
};

$$.NormilizeHeight = function (number) {
	if (number === 0) {
		return number;
	}

	number = parseInt(number);

	var height = parseInt(number.toString().substring(number.toString().length - 2));

	if ((100 - height) < 50) {
		number += 100 - height;
	} else {
		number += 50 - height;
	}

	return number;
};
