var $$ = $$ || {};

$$.PersonBackground = class PersonBackground {
	constructor (root) {
		this.root = root;

		this.context = null;
		this.image = new Image();

		this._cacheNodes();
		this._bindEvents();
	}

	_cacheNodes () {
		this.nodes = {
			background: this.root.find('.js-bg-image'),
			canvas:     this.root.find('.js-canvas'),
			personLink: this.root.find('.sub-title-block')
		}
	}

	/**
	 * Вешает обработчики событий на компоненты/элементы.
	 *
	 * @private
	 */
	_bindEvents () {
		this.nodes.personLink.on('mouseenter', () => {
			$$.backgroundEffects.animateBlur(false);
		});

		this.nodes.personLink.on('mouseleave', () => {
			$$.backgroundEffects.animateBlur(true);
		});

		$$.window.on('resize', () => {
			var cssProperties = $$.calculateFullScreenCSS(this.imageHeight / this.imageWidth);

			this.nodes.canvas.css(cssProperties);
			this.nodes.background.css(cssProperties);
		});
	}
};
