var $$ = $$ || {};

$$.Breadcrumbs = class Breadcrumbs {
	constructor (root) {
		this.root = root;

		this.overlayHeigh = 0;
		this.isOpen = false;

		this._cacheNodes();
		this._bindEvents();
	}

	_cacheNodes () {
		this.nodes = {
			droplist:    this.root.find('.js-droplist'),
			overlay:     this.root.find('.js-overlay'),
			closeButton: this.root.find('.js-close'),
			itemContainer: this.root.find('.items'),
			items:       this.root.find('.item')
		}
	}

	/**
	 * Вешает обработчики событий на компоненты/элементы.
	 *
	 * @private
	 */
	_bindEvents () {
		this.nodes.droplist.on('click', '.active', (event) => {
			event.preventDefault();
			event.stopPropagation();

			this.root.toggleClass('active');
			this.nodes.droplist.toggleClass('active');
			this.nodes.closeButton.toggleClass('active');

			if (this.nodes.droplist.hasClass('active')) {
				this.overlayHeigh = this.nodes.itemContainer.height() + this.nodes.itemContainer.offset().top * 2 ;

				this.nodes.overlay.css({
					height: this.overlayHeigh,
					top:    -this.overlayHeigh
				});
			}

			this.nodes.overlay.velocity('stop').velocity({ top: this.isOpen ? -this.overlayHeigh : 0 });
			this.isOpen = !this.isOpen;
		});

		this.nodes.closeButton.on('click', () => {
			this.root.removeClass('active');
			this.nodes.droplist.removeClass('active');
			this.nodes.closeButton.removeClass('active');

			this.nodes.overlay.velocity('stop').velocity({ top: -this.overlayHeigh });
			this.isOpen = false;
		});

		$('body').on('click.breadcrumbs', (event) => {
			var inRoot = $(event.target).parents().is(this.root);

			if (!inRoot) {
				this.root.removeClass('active');
				this.nodes.droplist.removeClass('active');
				this.nodes.closeButton.removeClass('active');
				this.nodes.overlay.velocity('stop').velocity({ top: -this.nodes.overlay.height() });
				this.isOpen = false;
			}
		});
	}
};
