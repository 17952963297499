window.$$ = window.$$ || {};


/**
 *
 *
 * @extends $$.Popup
 *
 * @param videoData
 * @param videoData.videoId
 * @param videoData.provider
 * @param videoData.cover
 * @constructor
 */
$$.VideoPopup = function(videoData) {
	$$.Popup.call(this);

	this.url = '';
	if (videoData.provider == 'vimeo') {
		this.url = '//player.vimeo.com/video/' + videoData.videoId + '?byline=0&portrait=0&autoplay=1&wmode=transparent';
	} else if (videoData.provider == 'youtube') {
		this.url = '//www.youtube.com/embed/' + videoData.videoId + '?rel=0&showinfo=0&autoplay=1&iv_load_policy=3&wmode=transparent';
	} else {
		return;
	}

	this.nodes.videoContainer = $(
		'<div class="b-video-popup-body">\
			<iframe class="player" src="" width="100%" height="100%" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen wmode=”opaque”></iframe>\
		</div>'
	);

	this.nodes.videoContainer.appendTo(this.nodes.contentArea);
	this.setBackground(videoData.cover);
	this.setClass('video');
};

$$.VideoPopup.prototype = $.extend({}, $$.Popup.prototype, {
	show: function(speed, callback) {
		var self = this;

		doWithSpeedAndCallback(this, function(speed, callback) {
			$$.Popup.prototype.show.call(this, speed, function() {
				self.nodes.videoContainer.find('iframe').attr('src', self.url);
				callback();
			});
		}, speed, callback, 600);
	},

	hide: function(speed, callback) {
		var self = this;

		doWithSpeedAndCallback(this, function(speed, callback) {
			$$.Popup.prototype.hide.call(this, speed, function() {
				self.nodes.videoContainer.find('iframe').attr('src', '');
				callback();
			});
		}, speed, callback, 600);
	}
});
