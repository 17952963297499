(function($$) {
	/** @type Config */
	const blurAnimationTime = 600;
	const blurRadius = 65;
	const preloadedImages = {}; // Here we're going to keep references to the loaded images and use them. Probably. Later.

	function getBitmapFromImageUrl(imageUrl, width, height) {
		if (preloadedImages[imageUrl]) {
			const deferred = $.Deferred();
			const texture = CanvasUtils.imageToCanvas(preloadedImages[imageUrl], width, height);
			deferred.resolve(texture);
			return deferred.promise();
		}

		return CanvasUtils.imageUrlToCanvas(imageUrl, width, height);
	}


	/** @type CanvasUtils */
	const CanvasUtils = $$.CanvasUtils;

	$$.BackgroundEffectsPC = class BackgroundEffectsPC extends $$.BackgroundEffects {
		constructor(imagesData, fragmentBackgrounds, container = 'body') {
			super(imagesData, fragmentBackgrounds, container);

			const rootBg = imagesData['/'];
			this.bluredBackgrounds = {};

			this._rendererWidth = rootBg['bgWidth'] || 1600;
			this._rendererHeight = rootBg['bgHeight'] || 900;
			this._crossPageDuration = 1300;
			this._isCrossPageTransitionInProgress = false;
			this._isSlideBgTransitionInProgress = false;

			this._renderer = new $$.BackgroundRenderer(this._rendererWidth, this._rendererHeight);
			this._renderer.setOutputTarget(this._canvas);

			const blurFilter = new $$.BlurFilter(this._renderer.getBitmap(), blurRadius, false);
			this._blurFilter = new $$.AnimatedFilterWrapper(blurFilter, {
				duration: blurAnimationTime,
				startImmediately: false,
				easing: 'easeInOutQuad',
				values: [0, blurRadius],
				valueProcessor: function(value) { return ~~value; },
				onUpdate: () => { this._renderer.redraw(); }
			});

			this._renderer.addFilter(blurFilter);
			this._blurFilter.seek(1);

			// Extra in-memory canvas, where we're will keep a reference to unblurred version of current background.
			this._currentBackgroundUnblurred = this._renderer.getBitmap(true);
			this._currentBackgroundDataObject = null;

			// Getting background for current url

			this._updateCurrentBackground($$.route.currentUrl)
				.always(() => {
					this._preloadSomeOfTheImages();
				});

			this._subscribeToPageEvents();
		}

		/**
		 * Берет текстуру, блюррит ее и переходит к ней через фейд.
		 * UPD: на самом деле блюррим мы ее только, если это не делается через фильтр.
		 *
		 * @param targetBitmap
		 * @param quick
		 * @param partOfCrossPageTransition - просто выключает проверку на наличие межстраничной анимации
		 * @private
		 */
		_blurAndFadeToBitmap(targetBitmap, quick = true, partOfCrossPageTransition = false) {
			if (this._isCrossPageTransitionInProgress && !partOfCrossPageTransition) {
				return;
			}

			let realTarget = targetBitmap;

			if (!this._blurFilter.getFilter().isWebGLSupported()) {
				realTarget = CanvasUtils.blur(targetBitmap, blurRadius);
			}

			const TransitionStyle = quick ? $$.CrossFadeTransition : $$.RadialFadeTransition;
			const duration = quick ? 800 : this._crossPageDuration;

			const transition = new TransitionStyle(
				this._renderer.getBitmap(true, false),
				realTarget,
				{
					duration: duration,
					easing: 'easeOutSine'
				}
			);

			this._currentBackgroundUnblurred = targetBitmap;
			this._renderer.registerTransition(transition);
		}

		/**
		 * Возвращает текуший фон, без примененных к нему фильтров. Блюра, например.
		 *
		 * @returns {*}
		 * @private
		 */
		_getCurrentBackground() {
			if (this._blurFilter.getFilter().isWebGLSupported()) {
				return this._renderer.getBitmap(true, false);
			} else {
				return this._currentBackgroundUnblurred;
			}
		}

		/**
		 * Склеивает 2 картинки в одну.
		 * Используется ТОЛЬКО для компоновки картинки, на которую переключаемся
		 * через фейд при наведении на ссылки
		 *
		 * @param {HTMLCanvasElement} bigImage
		 * @param {HTMLCanvasElement} smallImage
		 * @param {Object} [outerImageData]
		 * @private
		 */
		_composeBackground(bigImage, smallImage, outerImageData = this._getBackgroundData($$.route.currentUrl)) {
			const output = CanvasUtils.cloneCanvas(bigImage);
			const ctx = output.getContext('2d');

			const vw = bigImage.width;
			const vh = bigImage.height;

			const iw = bigImage.width * outerImageData.displayScale;
			const ih = bigImage.height * outerImageData.displayScale;

			ctx.drawImage(
				smallImage,
				(vw - iw) * outerImageData.displayAlignment[0], (vh - ih) * outerImageData.displayAlignment[1],
				iw, ih
			);

			return output;
		}

		_createCanvas(container) {
			super._createCanvas();

			if (this._canvas) {
				return;
			}

			this._canvas = CanvasUtils.createCanvas(100, 100);

			// Прикрепляем канвас к дому, подписываемся на ресайз

			container.prepend(this._canvas);

			const resizeCanvas = () => {
				this._canvas.width = container.width();
				this._canvas.height = container.height();
			};

			resizeCanvas();
			$$.window.resize(resizeCanvas);
		}

		/**
		 * Возвращает 0, если переход между страницами должен осуществиться через фейд,
		 * 1 если нужно двигаться в глубь монитора
		 * и -1, если нужно двигаться в обратном направлении (из монитора)
		 *
		 * @param {String} targetUrl
		 * @param {String} [currentUrl]
		 * @returns {number}
		 * @private
		 */
		_determineBackgroundsContainment(targetUrl, currentUrl = $$.route.currentUrl) {
			const targetUrlData = this._getBackgroundData(targetUrl);
			const currentUrlData = this._currentBackgroundDataObject ? this._currentBackgroundDataObject : this._getBackgroundData(currentUrl);

			if (!targetUrlData || !currentUrlData || targetUrlData === currentUrlData) {
				return null;
			}

			if (!targetUrlData.hasDisplay && !currentUrlData.hasDisplay) {
				return 0;
			} else if (targetUrlData.hasDisplay && currentUrlData.hasDisplay) {
				// Считаем по глубине страниц
				const currentPageDepth = (currentUrl + '/').replace(/\/{2,}/g, '/').match(/\//g).length;
				const targetPageDepth = (targetUrl + '/').replace(/\/{2,}/g, '/').match(/\//g).length;

				return targetPageDepth >= currentPageDepth ? 1 : -1;
			} else {
				// Дисплей есть на оодной из двух картинок. У кого дисплей - туда и двигаемся
				return currentUrlData.hasDisplay ? 1 : -1;
			}
		}

		/**
		 * Принимает объект с данными фона, возвращает промис.
		 * При резолвинге промиса отдает канвас.
		 *
		 * @param bgDataObject
		 * @param {null|Boolean} HQVersion - если бул - означает в высоком или низком разрешении грузить. Если нулл - сначала загрузится в низком, потом подгрузится в большом и заменится
		 * @returns {*}
		 * @private
		 */
		_getTextureFromBackgroundData(bgDataObject, HQVersion = null) {
			const isMobile = $$.config.isMobile;
			const LQBackgroundUrl = bgDataObject.bgUrlLQ;
			const HQBackgroundUrl = bgDataObject.bgUrl;
			const mobileBackgroundUrl = bgDataObject.mobileBgUrl;

			let imageUrl = isMobile ? mobileBackgroundUrl : HQVersion === true ? HQBackgroundUrl : LQBackgroundUrl;
			var deferred = $.Deferred();

			// Если десктопы - сначала грузим маленькую текстуру, потом большую
			// Если имеем предзагруженную картинку - используем ее и переписываем флаги

			if (!isMobile && preloadedImages[HQBackgroundUrl]) {
				HQVersion = true;
				imageUrl = HQBackgroundUrl;
			}

			getBitmapFromImageUrl(imageUrl, this._rendererWidth, this._rendererHeight)
				.then(function(canvas) {
					deferred.resolve(canvas);

					// Если можно подгрузить картинку лучшего качества, но тяжелее - делаем это.
					// (Нельзя - если мы с нее начали или мы на мобильном)
					// передача канваса во второй аргумент просто перерисует содержимое канваса.

					if (!isMobile && HQVersion === null) {
						CanvasUtils.imageUrlToCanvas(bgDataObject.bgUrl, canvas);
					}
				})
				.fail(function() {
					deferred.reject();
				});

			return deferred.promise();
		}

		/**
		 * Принимает ссылку на страницу, возвращает промис.
		 * При резолвинге промиса отдает канвас.
		 *
		 * @param pageUrl
		 * @param {null|Boolean} HQVersion - если бул - означает в высоком или низком разрешении грузить. Если нулл - сначала загрузится в низком, потом подгрузится в большом и заменится
		 * @param lookupParentPages
		 * @returns {*}
		 * @private
		 * @param lookupParentPages
		 */
		_getTextureFromPageUrl(pageUrl, HQVersion = null, lookupParentPages = true) {
			var imageData = this._getBackgroundData(pageUrl, lookupParentPages);

			// Если не нашли - реджектим

			if (imageData === null) {
				var deferred = $.Deferred();
				deferred.reject();

				return deferred.promise();
			}

			// Либо передаем эстафету дальше
			return this._getTextureFromBackgroundData(imageData, HQVersion);
		}

		/**
		 * Предзагружает все фоновые картинки.
		 * @private
		 */
		_preloadSomeOfTheImages() {
			var LQImages = [];
			var HQImages = [];
			var mobileImages = [];

			_.each(this._imagesData, function(obj) {
				if (obj.bgUrl) {
					HQImages.push(obj.bgUrl);
					LQImages.push(obj.bgUrlLQ);
				} else if (obj.mobileBgUrl) {
					mobileImages.push(obj.mobileBgUrl);
				}
			});


			$$.preload(LQImages, true) // Если будут проблемы с вылетом за доступное количество памяти на телефонах - можно поменять на флаг isMobile
				.then(function(results) {
					_.extend(preloadedImages, results.references);

					// Без этого на всяких 4G будет более отзывчиво(!)
					//setTimeout(function() {
					//	$$.preload(HQImages).then(function(results) { _.extend(preloadedImages, results.references); });
					//}, 2000);
				});
		}

		_subscribeToPageEvents() {
			const self = this;
			let lastHoveredLink = null;
			let timeoutHandler = null;

			// Слушаем ховеры ссылок
			$$.body.on('mouseenter', 'a[href]', function() {
				if (this === lastHoveredLink) {
					return;
				}

				lastHoveredLink = this;
				clearTimeout(timeoutHandler);

				timeoutHandler = setTimeout(() => {
					self.reactOnLinkHover($(this).attr('href'));
				}, 100);
			});
		}

		/**
		 * Предполагается к использованию 1 раз, в самом начале. Тупо загружает картинку для текущей страницы и ставит ее на фон.
		 * Промис резолвится после загрузки картинки.
		 *
		 * @returns {*}
		 * @private
		 */
		_updateCurrentBackground() {
			super._updateCurrentBackground();

			const deferred = new $.Deferred();
			const currentUrl = $$.route.currentUrl;
			const bgData = this._getBackgroundData(currentUrl);

			this._currentBackgroundDataObject = bgData;

			this._getTextureFromPageUrl($$.route.currentUrl)
				.then((texture) => {
					if (currentUrl !== $$.route.currentUrl || this._currentBackgroundDataObject !== bgData) {
						return;
					}

					this._blurAndFadeToBitmap(texture);
					deferred.resolve();
				})
				.fail(function() {
					deferred.reject();
				});

			return deferred.promise();
		}

		animateBlur(blurred) {
			const deferred = new $.Deferred();

			this._blurFilter.reverse(!blurred);
			this._blurFilter.start();

			if (!this._blurFilter.getFilter().isWebGLSupported()) {
				let animateToCanvas = this._getCurrentBackground();

				if (blurred) {
					animateToCanvas = CanvasUtils.blur(animateToCanvas, blurRadius);
				}

				const transition = new $$.CrossFadeTransition(
					this._renderer.getBitmap(true),
					animateToCanvas,
					{
						duration: blurAnimationTime,
						easing: 'swing'
					}
				);

				this._renderer.registerTransition(transition);
			}

			setTimeout(function() {
				deferred.resolve();
			}, blurAnimationTime);

			return deferred.promise();
		}

		preloadFragmentBackgrounds(fragments) {
			let urlsToPreload = [];
			let interestingKey = $$.config.isMobile ? 'mobileBgUrl' : 'bgUrl';

			_.each(fragments, (fragmentId) => {
				let fragmentData = this._fragmentsData[fragmentId];
				if (fragmentData && fragmentData[interestingKey]) {
					urlsToPreload.push(fragmentData[interestingKey]);
				}
			});

			return $$.preload(urlsToPreload, true)
				.then(function(results) { _.extend(preloadedImages, results.references); });
		}

		/**
		 * Делает всю работу по подмене фона при наведении на ссылки.
		 *
		 * @param url
		 */
		reactOnLinkHover(url) {
			const direction = this._determineBackgroundsContainment(url);

			if (this._isCrossPageTransitionInProgress
				|| this._isSlideBgTransitionInProgress
				|| direction === null || direction < 1
			) {
				return;
			}

			// Загружаем картинку, проверяем, что все еще не запущен транзишон перехода между страницами,
			// композируем новый фон, фейдимся в него.

			this._getTextureFromPageUrl(url, false, false)
				.then((texture) => {
					if (this._isCrossPageTransitionInProgress || this._isSlideBgTransitionInProgress)
						return;

					const composed = this._composeBackground(this._getCurrentBackground(), texture);
					this._blurAndFadeToBitmap(composed);
				});
		}

		revealFragmentBg(fragmentIds, direction='none', duration = 1000, easing = 'swing', prevFragmentId = []) {
			super.revealFragmentBg();

			if (!_.includes(['none', 'top', 'right', 'bottom', 'left'], direction)) {
				throw "Wrong `direction` supplied";
			}

			const deferred = $.Deferred();
			let fragmentBgData = null;
			let prevFragmentBgData = null;

			_.each(fragmentIds, (fragment) => {
				const obj = this._fragmentsData[fragment];
				fragmentBgData = obj ? obj : fragmentBgData;
			});

			_.each(prevFragmentId, (fragment) => {
				const obj = this._fragmentsData[fragment];
				prevFragmentBgData = obj ? obj : prevFragmentBgData;
			});

			if (!prevFragmentBgData && direction !== 'none') {
				prevFragmentBgData = this._getBackgroundData(prevFragmentId[0], true, false);
			}

			if (!fragmentBgData && direction !== 'none') {
				fragmentBgData = this._getBackgroundData($$.route.currentUrl, true, false);
			}

			if (!fragmentBgData) {
				deferred.reject();
				return deferred.promise();
			}

			if (fragmentBgData === this._currentBackgroundDataObject) {
				deferred.resolve();
				return deferred.promise();
			}

			this._isSlideBgTransitionInProgress = true;
			this._currentBackgroundDataObject = fragmentBgData;

			// Image is probably preloaded by now
			this._getTextureFromBackgroundData(fragmentBgData, true)
				.fail(function() { deferred.reject(); })
				.then((targetBitmap) => {
					if (!this._blurFilter.getFilter().isWebGLSupported()) {
						this.bluredBackgrounds[fragmentBgData.bgUrl] = this.bluredBackgrounds[fragmentBgData.bgUrl] || CanvasUtils.blur(targetBitmap, blurRadius);
						this.bluredBackgrounds[prevFragmentBgData.bgUrl] = this.bluredBackgrounds[prevFragmentBgData.bgUrl] || CanvasUtils.blur(this._renderer.getBitmap(false, false), blurRadius);
					} else {
						if (!this.bluredBackgrounds[fragmentBgData.bgUrl]) {
							this.bluredBackgrounds[fragmentBgData.bgUrl] = fx.canvas();

							let textureTarget = this.bluredBackgrounds[fragmentBgData.bgUrl].texture(targetBitmap);
							this.bluredBackgrounds[fragmentBgData.bgUrl].draw(textureTarget).triangleBlur(blurRadius).update();
						}

						if (!this.bluredBackgrounds[prevFragmentBgData.bgUrl]) {
							this.bluredBackgrounds[prevFragmentBgData.bgUrl] = fx.canvas();

							let textureSource = this.bluredBackgrounds[prevFragmentBgData.bgUrl].texture(this._renderer.getBitmap(false, false));
							this.bluredBackgrounds[prevFragmentBgData.bgUrl].draw(textureSource).triangleBlur(blurRadius).update();
						}
					}

					let	transitionTarget = this.bluredBackgrounds[fragmentBgData.bgUrl];
					let	transitionSource = this.bluredBackgrounds[prevFragmentBgData.bgUrl];

					let Transition = direction === 'none' ? $$.CrossFadeTransition : $$.SimpleSlideTransition;

					let transition = new Transition(transitionSource, transitionTarget, {
						duration: duration,
						easing: easing,
						direction: direction
					});

					this._renderer.registerTransition(transition);

					setTimeout(() => {
						this._currentBackgroundUnblurred = targetBitmap;
						this._isSlideBgTransitionInProgress = false;
						deferred.resolve();
					}, this._crossPageDuration);
				});

			return deferred.promise();
		}

		/**
		 * Инициирует анимацию перехода от одной страницы к другой
		 *
		 * @param pageFromUrl
		 * @param pageToUrl
		 * @returns {*}
		 */
		startCrossPageTransition(pageFromUrl, pageToUrl) {
			super.startCrossPageTransition(pageFromUrl, pageToUrl);

			const deferred = new $.Deferred();
			const direction = this._determineBackgroundsContainment(pageToUrl, pageFromUrl);

			if (direction === null) {
				deferred.reject();
				return deferred.promise();
			}

			this._isCrossPageTransitionInProgress = true;
			const outerImageData = this._getBackgroundData(direction > 0 ? pageFromUrl : pageToUrl);
			let blurPromise = direction === 0 ? {} : this.animateBlur(false);

			$.when(blurPromise, this._getTextureFromPageUrl(pageToUrl, true))
				.then((useless, texture) => {
					this._currentBackgroundDataObject = this._getBackgroundData(pageToUrl);

					if (direction === 0) {
						this._blurAndFadeToBitmap(texture, false, true);

						setTimeout(() => {
							this._isCrossPageTransitionInProgress = false;
							this._currentBackgroundUnblurred = texture;
							this.animateBlur(true);

							deferred.resolve();
						}, ~~(this._crossPageDuration * 0.3));
					} else {
						//const current = this._renderer.getBitmap(true, false);
						const current = this._currentBackgroundUnblurred;

						let outer = current;
						let inner = texture;

						if (direction < 0) {
							outer = this._composeBackground(texture, current, outerImageData);
							inner = current;
						}

						const transition = new $$.DNProTransition(outer, inner, {
							duration: this._crossPageDuration,
							easing: 'easeInOutCubic', //'easeInOutCirc',
							reverse: direction < 0,
							alignment: outerImageData.displayAlignment,
							scale: outerImageData.displayScale
						});

						this._renderer.registerTransition(transition);

						setTimeout(() => {
							this._currentBackgroundUnblurred = this._renderer.getBitmap(true, false);

							this.animateBlur(true).then(() => {
								this._isCrossPageTransitionInProgress = false;
							});

							deferred.resolve();
						}, this._crossPageDuration);
					}
				})
				.fail(function() {
					deferred.reject();
				});


			return deferred.promise();
		}
	};
})(window.$$ || (window.$$ = {}));

