var $$ = $$ || {};

$$.BaseBlur = class BaseBlur extends $$.AbstractBlur {
	constructor () {
		super();

		this.images = {};
		this.intervals = [];
	}

	/**
	 * Заполнение канваса одной картинкой с указанным радиусом размытия.
	 * @param context
	 * @param imageUrl
	 * @param radius
	 */
	fillWhollyCanvas (context, imageUrl, radius) {
		var hiddenImage = this.images[imageUrl];
		var deferred =  $.Deferred();

		if (hiddenImage) {
			this._fillWhollyCanvas(hiddenImage[0]);
			this._blurVirtualCanvas(radius);
			this._applyCanvasChanges(context);

			deferred.resolve();
		} else {
			hiddenImage = $('<img>');

			hiddenImage.one('load', () => {
				this._fillWhollyCanvas(hiddenImage[0]);
				this._blurVirtualCanvas(radius);
				this._applyCanvasChanges(context);

				deferred.resolve();
			});

			hiddenImage.attr('src', imageUrl);
			this.images[imageUrl] = hiddenImage;
		}

		return deferred.promise();
	}

	fillRegionCanvas (context, globalImageUrl, regionImageUrl, globalRadius, regionRadius, alphaChannel = 1) {
		var hiddenImage = this.images[globalImageUrl];
		var hiddenRegionImage = this.images[regionImageUrl];

		var action = () => {
			if (!(hiddenImage && hiddenRegionImage)) {
				return;
			}

			this._fillBlendCanvas(
				context,
				hiddenImage[0],
				hiddenRegionImage[0],
				globalRadius,
				regionRadius,
				alphaChannel,
				true
			);
		};

		if (!hiddenImage) {
			hiddenImage = $('<img>');

			hiddenImage.one('load', () => {
				action();
			});

			hiddenImage.attr('src', globalImageUrl);
		}

		if (!hiddenRegionImage) {
			hiddenRegionImage = $('<img>');

			hiddenRegionImage.one('load', () => {
				action();
			});

			hiddenRegionImage.attr('src', regionImageUrl);
		}

		action();
	}

	fillRegionCanvasAnimate (context, globalImageUrl, regionImageUrl,
							 startRadius, endRadius, duration = 400, step = 50) {
		var summaryTime = 0;
		var deltaRadius = startRadius - endRadius;
		var stepRadius = deltaRadius / (duration / step);
		var index = 0;
		var deferred = $.Deferred();

		this.intervals.forEach((intervalId) => {
			clearInterval(intervalId);
		});

		var id = setInterval(() => {
			summaryTime += step;
			index++;

			this.fillRegionCanvas(
				context,
				globalImageUrl,
				regionImageUrl,
				parseInt(startRadius - stepRadius * index),
				parseInt(startRadius - stepRadius * index)
			);

			if (summaryTime + step >= duration) {
				deferred.resolve();
			}

			if (summaryTime >= duration) {
				clearInterval(id);
			}
		}, step);

		this.intervals.push(id);

		return deferred.promise();
	}

	/**
	 * Плавное изменение радиуса размытия.
	 *
	 * @param context
	 * @param imageUrl
	 * @param startRadius
	 * @param endRadius
	 * @param duration
	 * @param step
	 * @returns {*}
	 */
	fillWhollyCanvasAnimate (context, imageUrl, startRadius, endRadius, duration = 400, step = 50) {
		var summaryTime = 0;
		var deltaRadius = startRadius - endRadius;
		var stepRadius = deltaRadius / (duration / step);
		var index = 0;
		var deferred = $.Deferred();

		this.intervals.forEach((intervalId) => {
			clearInterval(intervalId);
		});

		var id = setInterval(() => {
			summaryTime += step;
			index++;

			this.fillWhollyCanvas(context, imageUrl, parseInt(startRadius - stepRadius * index));

			if (summaryTime + step >= duration) {
				deferred.resolve();
			}

			if (summaryTime >= duration) {
				clearInterval(id);
			}
		}, step);

		this.intervals.push(id);

		return deferred.promise();
	}

	/**
	 * Плавная замена одной картинки с одним радиусом размытия на другую с другим радиусом размытия.
	 * Можно указать заменить не весь канвас, а регион, но радиус размытия региона будет такой же,
	 * как у всей области
	 *
	 * @param context
	 * @param imageUrl
	 * @param tinyImageUrl
	 * @param startRadius
	 * @param endRadius
	 * @param step
	 * @param isRegion
	 * @returns {*}
	 */
	animatedChangeCanvasContent (context, imageUrl, tinyImageUrl, startRadius, endRadius, step = 50, isRegion = true) {
		var index = 0;
		var deferred = $.Deferred();
		var alphaChannel = 0.1;

		this.intervals.forEach((intervalId) => {
			clearInterval(intervalId);
		});

		var id = setInterval(() => {
			alphaChannel += 0.1;
			index++;

			if (isRegion) {
				this.fillRegionCanvas(context, imageUrl, tinyImageUrl, startRadius, endRadius, alphaChannel);
			} else {
				this._blendingImageOnCanvas(context, imageUrl, tinyImageUrl, startRadius, endRadius, alphaChannel);
			}

			if (alphaChannel >= 0.9) {
				deferred.resolve();
			}

			if (alphaChannel >= 1) {
				clearInterval(id);
			}
		}, step);

		this.intervals.push(id);

		return deferred.promise();
	}

	cleanResources () {
		this.images = {};
	}

	_generateBlendedImage (bigImage, smallImage) {
		if (!bigImage.attr('src') || !smallImage.attr('src')) {
			return;
		}

		var canvas = document.createElement('canvas');
		var context = canvas.getContext('2d');

		this._fillWhollyCanvas(bigImage.get(0));
		this._fillRegionCanvas(smallImage.get(0));
		this._applyCanvasChanges(context);

		return canvas.toDataURL();
	}
};
