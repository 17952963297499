window.$$ = window.$$ || {};

(function() {
	var _popupInstanceId = 0;

/**
 * Базовый класс попапа
 *
 * @constructor
 */
$$.Popup = function() {
	$$.Emitter.call(this);

	this._hash = 'popup' + (_popupInstanceId++);
	this.isVisible = false;
	this.neverShownBefore = true;
	this.isShowingIn = false;
	this.hideByClickOnBlank = true;

	this.dom = $(
		'<div class="b-popup">\
			<div class="background">\
				\
			</div>\
			<div class="content-area">\
				\
			</div>\
			<div class="close-button">\
				<svg class="icon-close"><use xlink:href="#icon-close"></use></svg>\
				<svg class="icon-close-hover"><use xlink:href="#icon-close-hover"></use></svg>\
			</div>\
		</div>'
	);

	this.nodes = {
		backgrounds: this.dom.find('.background'),
		contentArea: this.dom.find('.content-area'),
		closeBtn: this.dom.find('.close-button')
	};

	// Binds and stuff

	var self = this;

	this.nodes.closeBtn.click(function() {
		self.hide(400, function() {
			self.destroy();
		});
	});

	this.dom.hide();

	this._windowResizeCallback = function() {
		self.resize();
	};

	this._closeByEscCallback = function(e) {
		if (!self.isVisible) {
			return;
		}

		if (e.keyCode == 27) {
			self.nodes.closeBtn.click();
			return false;
		}
	};

	setTimeout(function () {
		$$.window.on('click.' + self._hash, function(e) {
			if (self.nodes.contentArea.has(e.target).length
				|| self.nodes.closeBtn.is(e.target) || self.nodes.closeBtn.has(e.target).length)
				return;

			if (self.dom.has(e.target).length || self.dom.is(e.target)) {
				if (!self.hideByClickOnBlank) {
					return;
				}

				self.nodes.closeBtn.click();
				return false;
			}
		});
	}, 0);

	/*this.nodes.contentArea.click(function(e) {
		if (self.nodes.contentArea.is(e.target) || self.nodes) {
			self.nodes.closeBtn.click();
			return false;
		}
	});*/

	$$.window.on('resize', this._windowResizeCallback);
	$$.window.on('keydown', this._closeByEscCallback);
};

//$$.Popup.prototype = {
$$.Popup.prototype = $.extend({}, $$.Emitter.prototype, {
	appendTo: function(target) {
		this.dom.appendTo(target);
	},

	setBackground: function(url, duration) {
		duration = parseInt(duration);
		duration = isNaN(duration) ? 0 : Math.max(0, duration);

		var image = $('<div class="image"></div>');
		image.appendTo(this.nodes.backgrounds);
		image.css('backgroundImage', 'url(' + url + ')');
		image.hide().fadeTo(duration, 1);

		/*return;

		var $img = $('<img/>');
		$img.appendTo(this.nodes.backgrounds);
		$img.attr('src', url);

		if (!duration) {
			$img.removeAttr('style').show();
		} else {
			$img.load(function() {
				$img.removeAttr('style').hide().fadeTo(duration, 1);
			});
		}*/
	},

	destroy: function() {
		var self = this;

		typeof this.emit == 'function' ? this.emit('destroy') : void 0;

		this.off();

		if (this.nodes.contentArea)
			this.nodes.contentArea.children().detach();

		$.each(this.nodes, function(key, value) {
			if (!self.nodes[key]) {
				return;
			}

			self.nodes[key].empty().off().removeData().remove();
			self.nodes[key] = null;
		});

		this.dom.detach().removeData().off();
		$$.window.off('resize', this._windowResizeCallback);
		$$.window.off('click.' + this._hash);
		$$.window.off('keydown', this._closeByEscCallback);
	},

	setClass: function(className) {
		className = className || '';
		this.dom.removeAttr('class').addClass('b-popup').addClass(className);
	},

	hideAndDestroy: function(speed, callback) {
		var self = this;
		callback = callback || function() {};

		this.hide(speed, function() {
			self.destroy();
			callback();
		});
	},

	setContent: function(dom) {
		this.nodes.contentArea.empty().append(dom);
		return this;
	},

	show: function(speed, callback) {
		var self = this;

		doWithSpeedAndCallback(this, function(normalizedSpeed, normalizedCallback) {
			if (self.isShowingIn) {
				return;
			}

			self.isShowingIn = true;
			self.emit('showing');
			$$.body.trigger('lockScroll');

			this.dom
				.stop(true)
				.velocity("stop")
				.fadeTo(normalizedSpeed, 1, function() {
					self.isShowingIn = false;
					normalizedCallback();
				});
		}, speed, callback, 0);

		setTimeout(function() {
			self.isVisible = true;
		});
	},

	hide: function(speed, callback) {
		doWithSpeedAndCallback(this, function(normalizedSpeed, normalizedCallback) {
			var self = this;
			self.isShowingIn = false;

			$$.body.trigger('unlockScroll');
			self.emit('hiding');

			this.dom
				.stop(true)
				.velocity("stop")
				.fadeOut(normalizedSpeed, function() {
					self.isShowingIn = false;
					self.isVisible = false;
					normalizedCallback();
				});
		}, speed, callback, 0);
	},

	resize: function() {}
});

})();


/**
 *       ( ����� ���������� )
 *                v
 * ��������������� �������-�������, ����� ����� ��������� 2 ��������� �������� � ��������.
 * ��������� ������ ������ ���������, � ��������� � ������ ��������, ���� � ��������� �������� � �������� �� ������,
 * ���� � ��������� �������. �������������� ��� ������������� ������ ������ ������� �������
 *
 * <pre>
 * someMethod: parseFunction(speed, callback) {
		 *     doWithSpeedAndCallback(this, function(speed, callback) {
		 *         this.dom.fadeOut(speed, function() {
		 *             callback();
		 *         });
		 *     }, speed, callback, 350);
		 * }
 * </pre>
 *
 * @param {Object} self
 * @param {Function} [action]
 * @param {*} [speed]
 * @param {*} [callback]
 * @param {number} [defaultAnimationSpeed]
 */
function doWithSpeedAndCallback(self, action, speed, callback, defaultAnimationSpeed) {
	var result = {
		speed: defaultAnimationSpeed,
		callback: function() {}
	};

	if (typeof speed == 'function') {
		result.callback = speed;
	} else if (typeof callback == 'function') {
		result.callback = callback;
	}

	if (!isNaN(speed = parseInt(speed))) {
		result.speed = Math.max(0, speed);
	}

	action.call(self, result.speed, result.callback);
}
