var $$ = $$ || {};

$$.NicePeople = class NicePeople extends $$.BaseProjectPageClass {
	constructor (root) {
		super(root, 'portfolio-entry-nicepeople-');
	}

	_cacheNodes () {
		super._cacheNodes();

		const payload = this.nodes.payload;

		$.extend(this.nodes, {
			section4: payload.children('.section4'),
			s4Ipad: payload.find('.section4 .ipad'),
			s4Page: payload.find('.section4 .image')
		});
	}

	/**
	 * Вешает обработчики событий на компоненты/элементы.
	 *
	 * @private
	 */
	_bindEvents () {
		super._bindEvents();
	}

	_bindAnimationSequence () {
		let timeline = new TimelineMax()
			.to(this.nodes.s4Page, 1, {
				top: -this.nodes.s4Page.height() + 540,
				ease: Linear.easeNone
			});

		new ScrollMagic.Scene({
			duration: () => {
				return this.nodes.s4Page.height() - 540;
			},
			tweenChanges: true,
			triggerHook: 0,
			triggerElement: this.nodes.section4.get(0)
		}).setTween(timeline)
			.setPin(this.nodes.section4.get(0))
			.addTo(this._scrollMagicController);
	}

	_ready () {
		this.nodes.payload.imagesLoaded({
			background: true
		})
			.always(() => {
				super._ready();
			});
	}

	resize () {
		super.resize();

		this._springScroller.step(200);
	}
};
