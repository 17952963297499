var $$ = $$ || {};

$$.Tabs = class Tabs {
	constructor (root) {
		this.root = root;

		this._cacheNodes();
		this._bindEvents();
	}

	_cacheNodes () {
		this.nodes = {
			tabLinks:    this.root.find('.js-tab-link'),
			tabContents: this.root.find('.js-tab-content')
		};
	}

	/**
	 * Вешает обработчики событий на компоненты/элементы.
	 *
	 * @private
	 */
	_bindEvents () {
		this.nodes.tabLinks.on('click', (event) => {
			event.preventDefault();
			var element = $(event.currentTarget);

			if (element.hasClass('active')) {
				return;
			}

			element.siblings().removeClass('active');
			element.addClass('active');

			this.nodes.tabContents.eq(element.index()).siblings().velocity('fadeOut', 200, () => {
				this.nodes.tabContents.eq(element.index()).velocity('fadeIn');
			});
		});
	}
}
