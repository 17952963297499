const $$ = window.$$ || (window.$$ = {});
//import BaseProjectPageClass from '_baseWork.js';

$$.Rodina = class Rodina extends $$.BaseProjectPageClass {
	constructor (root) {
		super(root, 'portfolio-entry-rodina-');
		this.isMobile = this.root.data('mobile') === '' ? false : true;
		this._useSmallScrollStep = false;
		this._scrollStep = 200;
	}

	_cacheNodes () {
		super._cacheNodes();

		const payload = this.nodes.payload;

		$.extend(this.nodes, {
			fullscreenSections: this.root.find('.section.fullscreen'),

			// section #1
			section1:       payload.find('.section-1'),
			section1Up:     this.root.find('.section-1 .up'),
			section1Middle: this.root.find('.section-1 .middle'),
			section1Logo:   this.root.find('.section-1 .logo'),
			section1Down:   this.root.find('.section-1 .down'),

			// section #2
			section2:       payload.find('.section-2'),
			section2Middle: this.root.find('.section-2 .middle'),
			section2News:   this.root.find('.section-2 .news'),
			section2News1:  this.root.find('.section-2 .news-1'),
			section2News2:  this.root.find('.section-2 .news-2'),
			section2News3:  this.root.find('.section-2 .news-3'),
			section2News4:  this.root.find('.section-2 .news-4'),
			section2News5:  this.root.find('.section-2 .news-5'),

			// section #3
			section3:               payload.find('.section-3'),
			section3Persons:        this.root.find('.section-3 .persons'),
			section3Person1:        this.root.find('.section-3 .persons .person-1'),
			section3Person2:        this.root.find('.section-3 .persons .person-2'),
			section3VideoContainer: this.root.find('.section-3 .video-container'),
			section3Slider:         this.root.find('.section-3 .slider'),
			section3Person:         this.root.find('.section-3 .person'),

			// section #4
			section4:           payload.find('.section-4'),
			section4Bg:         payload.find('.section-4 .bg'),
			section4Pages:      payload.find('.section-4 .pages'),
			section4News:       payload.find('.section-4 .news'),
			section4NewsClosed: payload.find('.section-4 .news .news-closed')
		});
	}

	_ready () {
		this.nodes.payload.imagesLoaded({
			background: true
		})
			.always(() => {
				super._ready();
			});
	}

	_bindEvents () {
		super._bindEvents();
	}

	_bindAnimationSequence () {
		let timeline;
		let scene;

		if (this.isMobile) {
			return;
		}

		/* Section 1 - Запускается автоматически 1 раз
		 * = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
		var onCompleteFirstSlideAnimation = () => {
			"use strict";
			this._springScroller.resume();
		};


		this._springScroller.pause();

		TweenMax.from(this.nodes.section1Logo, 1, { opacity: 0, ease: Power1.easeIn, delay: 1.2 });
		TweenMax.from(this.nodes.section1Middle, 2, { opacity: 0, ease: Power2.easeOut, delay: 0.5 });
		TweenMax.from(this.nodes.section1Up, 3, { top: '-=100%', ease: Power2.easeOut, delay: 0.6 });
		TweenMax.from(this.nodes.section1Down, 3, {
			bottom:     '-=100%',
			ease:       Power2.easeOut,
			delay:      0.6,
			onComplete: onCompleteFirstSlideAnimation
		});

		/* Section 2 - запускается, когда секция на 80% находится во вьюпорте
		 * = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */

		//TweenMax;

		const Middle = this.nodes.section2Middle;

		const secondTimeline = new TimelineMax()
			.from(Middle, 0.5, { top: '+=100%', ease: Power1.easeIn })
			.from(this.nodes.section2News, 1, { scale: 0.5, opacity: 0, ease: Power1.easeIn })

			// Переключение новостей
			.set(this.nodes.section2News2, { opacity: 1, delay: 1 }, '+=1') // Зажглась
			.set(this.nodes.section2News3, { opacity: 1, delay: 1 }, '+=1') // Зажглась
			.set(this.nodes.section2News4, { opacity: 1, delay: 1 }, '+=1') // Зажглась
			.set(this.nodes.section2News5, { opacity: 1, delay: 1 }, '+=1'); // Зажглась

		scene = new ScrollMagic.Scene({
			duration:       0,
			tweenChanges:   true,
			reverse:        false,
			triggerHook:    0.2,
			triggerElement: this.nodes.section2.get(0)
		});

		scene.setTween(secondTimeline);
		scene.addTo(this._scrollMagicController);

		// Еще мы хотим, чтобы назад анимация перематывалась быстрее, чем она анимируется в нормальном направлении

		scene
			.on("leave", function (e) {
				if (e.state === 'BEFORE') {
					secondTimeline.timeScale(5);
				}
			})
			.on("enter", function (e) {
				secondTimeline.timeScale(1);

				if (secondTimeline.progress() === 0) {
					secondTimeline.seek(0);
				}
			});


		/* Section 3
		 * = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */

		//	Появление и смена персон

		const Section3 = this.nodes.section3;

		const section3Timeline = new TimelineMax();

		scene = new ScrollMagic.Scene({
			duration:       0,
			tweenChanges:   true,
			reverse:        false,
			triggerHook:    1,
			triggerElement: Section3.get(0)
		});

		scene.setTween(section3Timeline);
		scene.addTo(this._scrollMagicController);

		scene
			.on("enter", (e) => {
				this._useSmallScrollStep = true;
				this.resize();
			})
			.on("leave", (e) => {
				this._useSmallScrollStep = e.state !== 'BEFORE'; // Возвращаем в False только если находимся выше слайда
				this.resize();
			});

		const Persons = this.nodes.section3Persons;

		const personsTimeline = new TimelineMax()
			.from(Persons, 0.5, { opacity: '0', ease: Power1.easeIn })
			.from(this.nodes.section3Person1, 1, { opacity: 1, delay: 1, ease: Power1.easeIn })
			.from(this.nodes.section3Person2, 1, { opacity: 0, delay: 0, ease: Power1.easeIn });

		scene = new ScrollMagic.Scene({
			duration:       0,
			tweenChanges:   true,
			reverse:        false,
			triggerHook:    0.2,
			triggerElement: Persons.get(0)
		});

		scene.setTween(personsTimeline);
		scene.addTo(this._scrollMagicController);

		scene
			.on("leave", function (e) {
				if (e.state === 'BEFORE') {
					personsTimeline.timeScale(5);
				}
			})
			.on("enter", function (e) {
				personsTimeline.timeScale(1);

				if (personsTimeline.progress() === 0) {
					personsTimeline.seek(0);
				}
			});

		//	Появление ВидеоКонтейнера

		const VideoContainer = this.nodes.section3VideoContainer;

		const videoContainerTimeline = new TimelineMax()
			.from(VideoContainer, 1, { opacity: 0, delay: 0, ease: Power1.easeIn });

		scene = new ScrollMagic.Scene({
			duration:       0,
			tweenChanges:   true,
			reverse:        false,
			triggerHook:    0.2,
			triggerElement: Persons.get(0)
		});

		scene.setTween(videoContainerTimeline);
		scene.addTo(this._scrollMagicController);

		scene
			.on("leave", function (e) {
				if (e.state === 'BEFORE') {
					videoContainerTimeline.timeScale(5);
				}
			})
			.on("enter", function (e) {
				videoContainerTimeline.timeScale(1);

				if (videoContainerTimeline.progress() === 0) {
					videoContainerTimeline.seek(0);
				}
			});

		//	Появление Слайдера

		const Slider = this.nodes.section3Slider;

		const sliderTimeline = new TimelineMax()
			.from(this.nodes.section3Slider, 1, { opacity: 0, delay: 0, ease: Power1.easeIn });

		scene = new ScrollMagic.Scene({
			duration:       0,
			tweenChanges:   true,
			reverse:        false,
			triggerHook:    1,
			triggerElement: Slider.get(0)
		});

		scene.setTween(sliderTimeline);
		scene.addTo(this._scrollMagicController);

		scene
			.on("leave", function (e) {
				if (e.state === 'BEFORE') {
					sliderTimeline.timeScale(5);
				}
			})
			.on("enter", function (e) {
				sliderTimeline.timeScale(1);

				if (sliderTimeline.progress() === 0) {
					sliderTimeline.seek(0);
				}
			});

		//	Появление Персоны

		const Person = this.nodes.section3Person;

		const personTimeline = new TimelineMax()
			.from(this.nodes.section3Person, 1, { opacity: 0, delay: 0, ease: Power1.easeIn });

		scene = new ScrollMagic.Scene({
			duration:       0,
			tweenChanges:   true,
			reverse:        false,
			triggerHook:    1,
			triggerElement: Person.get(0)
		});

		scene.setTween(personTimeline);
		scene.addTo(this._scrollMagicController);

		scene
			.on("leave", function (e) {
				if (e.state === 'BEFORE') {
					personTimeline.timeScale(5);
				}
			})
			.on("enter", function (e) {
				personTimeline.timeScale(1);

				if (personTimeline.progress() === 0) {
					personTimeline.seek(0);
				}
			});


		/* Section 4
		 * = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */

		const Section4 = this.nodes.section4Bg;

		const sectionTimeline = new TimelineMax()
			.from(this.nodes.section4Bg, 1, { opacity: 0, top: '+=50%', delay: 0, ease: Power1.easeIn })
			.from(this.nodes.section4Pages, 1, { opacity: 0, top: '+=50%', delay: 0, ease: Power1.easeIn });

		scene = new ScrollMagic.Scene({
			duration:       0,
			tweenChanges:   true,
			reverse:        false,
			triggerHook:    0.8,
			triggerElement: this.nodes.section4.get(0)
		});

		scene.setTween(sectionTimeline);
		scene.addTo(this._scrollMagicController);

		scene
			.on("leave", function (e) {
				if (e.state === 'BEFORE') {
					sectionTimeline.timeScale(5);
				}
			})
			.on("enter", function (e) {
				sectionTimeline.timeScale(1);

				if (sectionTimeline.progress() === 0) {
					sectionTimeline.seek(0);
				}
			});

		const section4NewsTimeline = new TimelineMax()
			.from(this.nodes.section4News, 1, { opacity: 0, top: '+=900px', delay: 0, ease: Power1.easeIn })
			.to(this.nodes.section4NewsClosed, 1, { top: '955px', delay: 0, ease: Power1.easeIn });

		scene = new ScrollMagic.Scene({
			duration:       0,
			tweenChanges:   true,
			reverse:        false,
			triggerHook:    1,
			triggerElement: this.nodes.section4News.get(0)
		});

		scene.setTween(section4NewsTimeline);
		scene.addTo(this._scrollMagicController);

		scene
			.on("leave", function (e) {
				if (e.state === 'BEFORE') {
					section4NewsTimeline.timeScale(5);
				}
			})
			.on("enter", function (e) {
				section4NewsTimeline.timeScale(1);

				if (section4NewsTimeline.progress() === 0) {
					section4NewsTimeline.seek(0);
				}
			});

	}

	resize () {
		if (this._destroyed) {
			return;
		}

		this.nodes.fullscreenSections.height($$.windowHeight);

		let ratio = $$.windowWidth / 1600;

		var newsWidth = ratio * 942;
		var newsHeight = (942 / 1.042035398230088) * ratio;

		var newsMargin = {};

		if (newsWidth > 942 || newsHeight > 904) {
			newsMargin = {
				left: `-${942 / 2}px`,
				top:  `-${904 / 2}px`
			};
		} else {
			newsMargin = {
				left: `-${newsWidth / 2}px`,
				top:  `-${newsHeight / 2}px`
			};
		}

		this.nodes.section2News.css({
			width:      newsWidth,
			height:     newsHeight,
			marginLeft: newsMargin.left,
			marginTop:  newsMargin.top
		});

		super.resize();

		this._springScroller.step(this._useSmallScrollStep ? this._scrollStep : $$.windowHeight);
	}
};
