var $$ = $$ || {};

$$.BigImageBlock = class BigImageBlock {
	constructor (root) {
		this.root = root;

		this.image = new Image();

		this._cacheNodes();
		this._bindEvents();
		this._ready();
	}

	_cacheNodes () {
		this.nodes = {
			inner:   this.root.parents('.b-content-blocks'),
			image:   $(this.image),
			loading: this.root.find('js-loading')
		};
	}

	/**
	 * Вешает обработчики событий на компоненты/элементы.
	 *
	 * @private
	 */
	_bindEvents () {
		this.root.on('mousewheel', (event) => {
			if (((event.deltaY < 0 && this.root.scrollTop() >= (this.nodes.image.height() - $$.windowHeight - 30)) ||
				(event.deltaY > 0 && this.root.scrollTop() === 0))) {
			} else {
				event.stopPropagation();
			}
		});

		this.image.onload = () => {
			this.root.append($(this.image)).hide().velocity('fadeIn');
			this.nodes.loading.hide();
		};

		$$.window.on('resize.bigimage', () => {
			this.root.width(this.nodes.inner.width() + 17);
		});
	}

	_ready () {
		this.nodes.inner.css({
			left: 0,
			transform: "translateY(-50%)",
			width: '100%',
			height: '100%'
		});

		this.root.width(this.nodes.inner.width() + 17);

		this.nodes.image.addClass('js-inner');
		this.nodes.image.css({
			width:  '100%',
			height: 'auto'
		});

		this.image.src = this.root.data('src');
	}
};
