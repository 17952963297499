var $$ = $$ || {};

$$.ShareBlock = class ShareBlock {
	constructor (root) {
		this.root = root;

		this.opened = false;

		this._cacheNodes();
		this._bindEvents();
		this._ready();
	}

	/**
	 * @private
	 */
	_cacheNodes () {
		this.nodes = {
			toggleShare: this.root.find('.js-toggle-share'),
			shareBlock:  this.root.find('.js-social-share')
		}
	}

	_template (data = {}) {
		var url = '';

		return `<div class="social-likes"
					data-title="${data.title}"
					data-description="${data.description}"
					data-media="${data.media}"
					data-url="${location.href}">
					<div class="facebook" title="Поделиться ссылкой на Фейсбуке">Facebook</div>
					<div class="twitter" title="Поделиться ссылкой в Твиттере">Twitter</div>
					<div class="mailru" title="Поделиться ссылкой в Моём мире">Мой мир</div>
					<div class="vkontakte" title="Поделиться ссылкой во Вконтакте">Вконтакте</div>
					<div class="odnoklassniki" title="Поделиться ссылкой в Одноклассниках">Одноклассники</div>
					<div class="plusone" title="Поделиться ссылкой в Гугл-плюсе">Google+</div>
					<div class="pinterest" title="Поделиться картинкой на Пинтересте" data-media="">Pinterest</div>
				 	<div class="clearfix"></div>
				</div>`;
	}

	/**
	 * Вешает обработчики событий на компоненты/элементы.
	 *
	 * @private
	 */
	_bindEvents () {
		this.nodes.toggleShare.on('click', (event) => {
			event.preventDefault();

			if (this.opened) {
				this.nodes.shareBlock.slideUp();
				this.opened = false;
			} else {
				this.nodes.shareBlock.slideDown();
				this.opened = true;
			}
		});
	}

	/**
	 * Вызывается на этапе готовности компонентов/элементов к работе с ними.
	 *
	 * В основном метод используется для применения опций, добавления шаблона в DOM.
	 *
	 * @private
	 */
	_ready () {
		var shareButtons = this._template(this.nodes.shareBlock.data());

		this.nodes.shareBlock.empty();
		this.nodes.shareBlock.append(shareButtons);

		var shareBlock = this.nodes.shareBlock.find('.social-likes');

		shareBlock.socialLikes();
	}
}
