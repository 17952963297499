(function($$) {
	const defaultSettings = {
		duration: 400,
		easing: 'easeInSine',
		loop: false,
		reverse: false,
		yoyo: false,
		startImmediately: true
	};

	function getEasing(name) {
		var lib = $.Velocity ? $.Velocity.Easings : $.easing;
		return lib[name] || $.easing[$.easing.def] || $.easing.linear;
	}

	/**
	 * Basically a black box that transitions from one bitmap to another.
	 * Has two inputs and one output. Can be configured
	 */
	$$.AbstractAnimatable = class AbstractAnimatable {
		constructor(settings) {
			this._settings = _.extend({}, defaultSettings, settings);

			this._tickerValues = {
				jqAnimateDummy: $({progress: 0}),
				velocityDummy: $.Velocity ? $('<div>') : null,
				easingFunction: getEasing(this._settings.easing),
				tickerStartedFrom: 0, // 0..1
				tickerProgress: 0,
				isPlayingForward: true,
				isPlaying: false,
				isLooped: false,
				isFinished: false,
				yoyoCounter: 0
			};

			this._animationProgress = 0;
			this._isResultCanvasUpToDate = false; // if represents current transition state or not

			this.reverse(this._settings.reverse);
			this.loop(this._settings.loop);
			this.stop(true);

			if (this._settings.startImmediately) {
				this.start();
			}
		}

		_recalculateAnimationProgress(linearProgress) {
			const easingFunc = this._tickerValues.easingFunction;
			const duration = this._settings.duration;

			this._animationProgress = easingFunc(linearProgress, linearProgress * duration, 0, 1, duration);
		}

		_startTicker() {
			const tickerValues = this._tickerValues;
			const initialProgress = tickerValues.tickerProgress;
			const duration = this._settings.duration * (tickerValues.isPlayingForward ? (1 - initialProgress) : initialProgress);
			const target = tickerValues.isPlayingForward ? 1 : 0;

			if (tickerValues.isPlaying) { return; }
			tickerValues.isPlaying = true;

			// Timeouts are needed to make it work with velocity. Otherwise it breaks silently for some reason
			const onComplete = tickerValues.onComplete || (tickerValues.onComplete = () => {
					if (this._settings.yoyo && ++tickerValues.yoyoCounter <= (tickerValues.isLooped ? Infinity : 1) ) {
						setTimeout(() => {
							this.reverse();
						}, 0);

						return;
					} else if (tickerValues.isLooped) {
						setTimeout(() => {
							this._stopTicker(true);
							this._startTicker();
						}, 0);

						return;
					}

					tickerValues.isPlaying = false;
					tickerValues.isFinished = true;
					tickerValues.yoyoCounter = 0;
				});

			if ($.Velocity && 0) {
				tickerValues.velocityDummy
					.velocity('stop', true)
					.velocity({ tween: [target, initialProgress] }, {
						duration: duration,
						easing: 'linear',
						complete: onComplete,
						progress: tickerValues.onProgressVelocity || (tickerValues.onProgressVelocity = (a, b, c, d, tweenValueProgress) => {
							tickerValues.tickerProgress = tweenValueProgress;
							this._recalculateAnimationProgress(tweenValueProgress);
						})
					});
			} else {
				tickerValues.jqAnimateDummy.stop(true);
				tickerValues.jqAnimateDummy[0].progress = initialProgress; // resetting current state. It may have changed because of seek method

				tickerValues.jqAnimateDummy.animate(
					{
						progress: target
					}, {
						duration: duration,
						easing: 'linear',
						complete: onComplete,
						progress: tickerValues.onProgressJQ || (tickerValues.onProgressJQ = (a) => {
							tickerValues.tickerProgress = a.elem.progress;
							this._recalculateAnimationProgress(a.elem.progress);
						})
					}
				);
			}
		}

		_stopTicker(resetProgress = false) {
			const tickerValues = this._tickerValues;

			if (!tickerValues.isPlaying) {
				return;
			}

			if ($.Velocity && 0) {
				tickerValues.velocityDummy.velocity('stop', true);
			} else {
				tickerValues.jqAnimateDummy.stop(true);
			}

			tickerValues.isPlaying = false;

			if (resetProgress) {
				tickerValues.tickerProgress = tickerValues.isPlayingForward ? 0 : 1;
				this._recalculateAnimationProgress(tickerValues.tickerProgress);
			}
		}

		destroy() {
			this._stopTicker(true);
			this._tickerValues = null;
		}

		getProgress() {
			return this._animationProgress;
		}

		isAnimating() {
			return !!this._tickerValues.isPlaying;
		}

		// Starts or resumes transition
		start() {
			this._startTicker();
		}

		// Stops and rewinds to beginning
		stop() {
			this._stopTicker(true);
			this._tickerValues.yoyoCounter = 0;
		}

		// Pauses transition, preserving current position
		pause() {
			this._stopTicker();
		}

		/**
		 * Seeks to factor.
		 * @param factor
		 */
		seek(factor) {
			const wasPlaying = this._tickerValues.isPlaying;
			const tickerValues = this._tickerValues;

			tickerValues.progress = tickerValues.isPlayingForward ? 0 : 1;

			if (wasPlaying) {
				this._startTicker()
			}
		}

		/**
		 *
		 * @param {Boolean} [direction] - when specified true stands for reversed direction and false sets it to straight. When ommited - current value inverted
		 */
		reverse(direction = null) {
			const tickerValues = this._tickerValues;
			const wasPlaying = tickerValues.isPlaying;
			const oldValue = tickerValues.isPlayingForward;

			this._stopTicker();

			if (_.isBoolean(direction)) {
				tickerValues.isPlayingForward = !direction;
			} else {
				tickerValues.isPlayingForward = !tickerValues.isPlayingForward;
			}

			const newValue = tickerValues.isPlayingForward;

			if (oldValue !== newValue) {
				if (tickerValues.isPlayingForward) {
					tickerValues.tickerProgress = tickerValues.tickerProgress === 1 ? 0 : tickerValues.tickerProgress
				} else {
					tickerValues.tickerProgress = tickerValues.tickerProgress === 0 ? 1 : tickerValues.tickerProgress;
				}
			}

			if (wasPlaying) {
				this._startTicker();
			}
		}

		/**
		 *
		 * @param {Boolean} [value] - when specified it determines if looped or not, otherwise current state will be inverted
		 */
		loop(value = null) {
			const tickerValues = this._tickerValues;
			const wasPlaying = tickerValues.isPlaying;
			const oldValue = tickerValues.isLooped;

			if (_.isBoolean(value)) {
				tickerValues.isLooped = value;
			} else {
				tickerValues.isLooped = !tickerValues.isLooped;
			}

			if (tickerValues.isLooped !== oldValue && wasPlaying) {
				this._stopTicker();
				this._startTicker();
			}
		}
	};

})(window.$$ || (window.$$ = {}));
