var $$ = $$ || {};

$$.Videosguschenka = class Videosguschenka {
	constructor(root) {
		this.root = root;
		this.uniqueHash = `portfolio-entry-videosguschenka-${_.uniqueId()}}`;

		this.scrollPos = 0;
		this.scrollHeight = 0;
		this.scrollAnimationFrameHandler = null;
		this.waitingForDependenciesToLoad = false;
		this.isTouchDevice = false;
		this._isInitialTick = true; _.defer( () => this._isInitialTick = false );

		this._destroyed = false;
		this._scrollMagicController = null;
		this.isPaused = false;

		this._cacheNodes();
		this._bindEvents();
		this._initScrollSpring();
		this.resize();

		this.nodes.loader.show();
		this.nodes.wrapper.css('opacity', 0);

		this._ready();


		this._recheckBackground = _.debounce(() => {
			BackgroundCheck.refresh();
		}, 200);
	}

	_cacheNodes () {
		this.nodes = {
			mouseWheelCatcher: $('<div/>').appendTo(this.root),
			loader: this.root.children('.js-loading'),
			wrapper: this.root.find('.js-wrapper'),
			noiseOverlay: this.root.find('.js-noise-overlay'),
			noiseOverlayInner: this.root.find('.js-noise-overlay-inner'),
			logo: this.root.find('.js-logo'),
			miniLogo: this.root.find('.js-mini-logo'),
			cardsGroup1: this.root.find('.js-cards-group1'),
			cardsGroup2: this.root.find('.js-cards-group2'),
			bageBg: this.root.find('.js-bage-bg'),
			shirt: this.root.find('.js-shirt-buffer'),
			camera: this.root.find('.js-camera'),
			car: this.root.find('.js-car'),
			loudspeaker: this.root.find('.js-loudspeaker'),
			chair: this.root.find('.js-chair'),
			letterLogo: this.root.find('.js-letter-logo'),
			head: this.root.find('.js-head'),
			videoClips: this.root.find('.js-video-clip')
		};

		this.nodes.mouseWheelCatcher.css({
			position: 'absolute',
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
			display: 'none'
		});
	}

	_bindEvents () {
		this.nodes.mouseWheelCatcher.on('mousewheel', () => false);

		var isDetached = () => {
			if (!jQuery.contains(document.documentElement, this.root.get(0))) {
				this.destroy();
				return true;
			}

			return false;
		};

		$$.window.on('resize.' + this.uniqueHash, () => {
			if (isDetached()) {
				return
			}

			if (this.isTouchDevice) {
				this.nodes.noiseOverlay.height(this.nodes.wrapper.prop('scrollHeight'));
			}

			this.resize();
		});

		var onceSetNoiseOverlayHeight = _.once(() => {
			this.nodes.noiseOverlay.css('position', 'absolute').height(this.nodes.wrapper.prop('scrollHeight'));
		});

		this.nodes.noiseOverlay.on(`touchstart.${this.uniqueHash}`, () => {
			this.isTouchDevice = true;
			onceSetNoiseOverlayHeight();
		});
	}

	_bindAnimationSequence () {
		if (this._scrollMagicController) {
			throw 'Already initialized';
		} else if (this._destroyed) {
			return;
		}

		this._scrollMagicController = new ScrollMagic.Controller({
			container: this.nodes.wrapper.get(0),
			globalSceneOptions: {}
		});

		var logoTimeLine =  new TimelineMax();

		logoTimeLine.add(TweenMax.from(this.nodes.miniLogo, 1, {
			opacity: '0',
			ease: Sine.easeOut
		}));

		new ScrollMagic.Scene({
			duration: () => $$.windowHeight / 2,
			triggerHook: 1 - (this.nodes.miniLogo.height() / $$.windowHeight),
			triggerElement: this.nodes.miniLogo.get(0)
		})
			.setTween(logoTimeLine)
			.addTo(this._scrollMagicController);

		var cardsGroup1Line = new TimelineMax();

		cardsGroup1Line.add(TweenMax.from(this.nodes.cardsGroup1, 1, {
			left: '-128%',
			ease: Sine.easeOut
		}));

		new ScrollMagic.Scene({
			duration: () => $$.windowHeight / 2,
			triggerHook: 0.65,
			triggerElement: this.nodes.cardsGroup1.get(0)
		})
				.setTween(cardsGroup1Line)
				.addTo(this._scrollMagicController);

		var cardsGroup2Line = new TimelineMax();

		cardsGroup2Line.add(TweenMax.from(this.nodes.cardsGroup2, 1, {
			left: '84%',
			ease: Sine.easeOut
		}));

		new ScrollMagic.Scene({
			duration: () => $$.windowHeight * 1.35,
			triggerHook: 0.65,
			triggerElement: this.nodes.cardsGroup2.get(0)
		})
				.setTween(cardsGroup2Line)
				.addTo(this._scrollMagicController);

		var bageLine = new TimelineMax();

		bageLine.add(TweenMax.from(this.nodes.bageBg, 1, {
			opacity: '0',
			ease: Sine.easeOut
		}));

		new ScrollMagic.Scene({
			duration: () => $$.windowHeight * 1.2,
			triggerHook: 1,
			triggerElement: this.nodes.bageBg.get(0)
		})
				.setTween(bageLine)
				.addTo(this._scrollMagicController);

		var shirtLine = new TimelineMax();

		shirtLine.add(TweenMax.from(this.nodes.shirt, 1, {
			height: '40vw',
			ease: Sine.easeOut
		}));

		new ScrollMagic.Scene({
			duration: () => $$.windowHeight * 0.9,
			triggerHook: 1,
			triggerElement: this.nodes.shirt.get(0)
		})
				.setTween(shirtLine)
				.addTo(this._scrollMagicController);

		var cameraLine = new TimelineMax();

		cameraLine.add(TweenMax.from(this.nodes.camera, 1, {
			right: '-77vw',
			ease: Linear.easeNone
		}));

		new ScrollMagic.Scene({
			duration: () => $$.windowHeight * 0.7,
			triggerHook: 1,
			triggerElement: this.nodes.camera.get(0)
		})
				.setTween(cameraLine)
				.addTo(this._scrollMagicController);

		var carLine = new TimelineMax();

		carLine.add(TweenMax.from(this.nodes.car, 1, {
			opacity: '0',
			ease: Linear.easeNone
		}));

		new ScrollMagic.Scene({
			duration: () => $$.windowHeight * 1.2,
			triggerHook: 1,
			triggerElement: this.nodes.car.get(0)
		})
			.setTween(carLine)
			.addTo(this._scrollMagicController);

		var loudspeakerLine = new TimelineMax();

		loudspeakerLine.add(TweenMax.from(this.nodes.loudspeaker, 1, {
			left: '-14%',
			ease: Linear.easeNone
		}));

		new ScrollMagic.Scene({
			duration: () => $$.windowHeight / 2,
			triggerHook: 0.8,
			triggerElement: this.nodes.loudspeaker.get(0)
		})
				.setTween(loudspeakerLine)
				.addTo(this._scrollMagicController);


		var chairLine = new TimelineMax();

		chairLine.add(TweenMax.from(this.nodes.chair, 1, {
			left: '90%',
			ease: Linear.easeNone
		}));

		new ScrollMagic.Scene({
			duration: () => $$.windowHeight / 2,
			triggerHook: 0.8,
			triggerElement: this.nodes.chair.get(0)
		})
				.setTween(chairLine)
				.addTo(this._scrollMagicController);

		var letterLogoLine = new TimelineMax();

		letterLogoLine.add(TweenMax.from(this.nodes.letterLogo, 1, {
			opacity: '0',
			ease: Linear.easeNone
		}));

		new ScrollMagic.Scene({
			duration: () => $$.windowHeight * 1.2,
			triggerHook: 1,
			triggerElement: this.nodes.letterLogo.get(0)
		})
				.setTween(letterLogoLine)
				.addTo(this._scrollMagicController);

		var headLine = new TimelineMax();

		headLine.add(TweenMax.from(this.nodes.head, 1, {
			right: '-90vw',
			ease: Linear.easeNone
		}));

		new ScrollMagic.Scene({
			duration: () => $$.windowHeight * 0.75,
			triggerHook: 0.65,
			triggerElement: this.nodes.head.get(0)
		})
				.setTween(headLine)
				.addTo(this._scrollMagicController);

		this.nodes.videoClips.each(_.bind(function (index, element) {
		    var videoClip = $(element);

			var videoClipTimeLine = new TimelineMax()
				.to({}, 1, {
					onStart: _.bind(function () {
						videoClip.get(0).play();
					}, this),
					onComplete: _.bind(function () {
						videoClip.get(0).pause();
					}, this),
					onReverseComplete: _.bind(function () {
						videoClip.get(0).pause();
					}, this)
				});

			new ScrollMagic.Scene({
				duration: () => $$.windowHeight + videoClip.height(),
				triggerHook: 1,
				triggerElement: videoClip.get(0)
			})
				.addTo(this._scrollMagicController)
				.setTween(videoClipTimeLine);

		}, this))
	}

	_ready () {
		this.waitingForDependenciesToLoad = false;

		this._springScroller.resume();

		if (this._isInitialTick) {
			this.nodes.loader.hide();
			this.nodes.wrapper.css('opacity', 1);
		} else {
			this.nodes.loader.velocity('fadeOut');
			this.nodes.wrapper.velocity('fadeIn');
		}

		try {
			BackgroundCheck.init({
				targets: '.p-header .logo, .js-breadcrumbs, .button-switch-state-right-wrapper, .js-slash-menu',
				images: '.b-videosguschenka img',
				classes: {
					dark: 'background--light',
					light: 'background--dark',
					complex: 'background--complex'
				}
			});

			_.defer(function() { BackgroundCheck.refresh(); })
		} catch(e) {}

		this._bindAnimationSequence();
	}

	_initScrollSpring () {
		this._springScroller = new $$.SmoothScroller(this.nodes.wrapper, {
			velocityLimit: 0.35
		});

		this._springScroller.pause();

		this._springScroller.onScroll = () => this._updateScene();
	}

	_updateScene () {
		if (!this._scrollMagicController) {
			return;
		}

		this._scrollMagicController.update(true);
		this._recheckBackground();
	}

	destroy () {
		if (this._destroyed) {
			return;
		}

		this._destroyed = true;
		this._scrollMagicController.destroy();
		this.root.off();
		this.nodes.wrapper.off();

		$$.window.off('resize.' + this.uniqueHash);

		this._scrollMagicController = _.noop();
		this._recheckBackground = _.noop;

		this._springScroller.destroy();
		this._springScroller = _.noop();
	}

	pause () {
		this.nodes.logo.stop().fadeTo(1500, 0);
		this.nodes.noiseOverlay.stop().fadeOut(1500);
		this.isPaused = true;

		if (this._waitingForDependenciesToLoad) {
			return;
		}

		this._springScroller.pause();
	}

	repositioned (isAnimationEnded = false) {
		if (this._destroyed) {
			return;
		}

		if (isAnimationEnded) {
			this.nodes.mouseWheelCatcher.hide();

			if (!this.waitingForDependenciesToLoad && !this.isPaused) {
				// а без этого ScrollMagic тупит почему-то и не хочет пересчитывать высоту triggerHook. Его собственными методами не чинится
				$$.window.trigger('resize');

				_.defer(() => {
					this.nodes.logo.stop().fadeTo(2500, 1);
					this.nodes.noiseOverlay.stop().fadeIn(1500);
					$$.body.addClass('black-controls');
				});
			}
		} else {
			$$.body.removeClass('black-controls');
			this.nodes.mouseWheelCatcher.show();
			this._updateScene();
		}
	}

	resume (comingFromTop) {
		this.isPaused = false;

		if (this.waitingForDependenciesToLoad || this._destroyed) {
			return;
		}

		this._springScroller.resume();
		this._scrollMagicController.scrollTo(comingFromTop ? 0 : this.nodes.wrapper.prop('scrollHeight'));
	}

	resize () {
		if (this.waitingForDependenciesToLoad || this._destroyed) {
			return;
		}

		this._springScroller.recalculate();
		this._updateScene();
	}
};
