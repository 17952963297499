var $$ = $$ || {};

$$.GoogleMap = class GoogleMap {
	constructor (root = $, options = {}) {
		var defaultOptions = {
			contactsInfoWidth: 600,
			mapOptions: {
				zoom: 16,
				styles: [
					{
						"featureType":"all",
						"elementType":"labels.text.fill",
						"stylers":[
							{"saturation":36},
							{"color":"#000000"},
							{"lightness":40}
						]
					},
					{
						"featureType":"all",
						"elementType":"labels.text.stroke",
						"stylers":[
							{"visibility":"on"},
							{"color":"#000000"},
							{"lightness":16}
						]
					},
					{
						"featureType":"all",
						"elementType":"labels.icon",
						"stylers":[{"visibility":"off"}]
					},
					{
						"featureType":"administrative",
						"elementType":"geometry.fill",
						"stylers":[
							{"color":"#000000"},
							{"lightness":20}
						]
					},
					{
						"featureType":"administrative",
						"elementType":"geometry.stroke",
						"stylers":[
							{"color":"#000000"},
							{"lightness":17},
							{"weight":1.2}
						]
					},
					{
						"featureType":"landscape",
						"elementType":"geometry",
						"stylers":[
							{"color":"#000000"},
							{"lightness":20}
						]
					},
					{
						"featureType":"poi",
						"elementType":"geometry",
						"stylers":[
							{"color":"#000000"},
							{"lightness":21}
						]
					},
					{
						"featureType":"road.highway",
						"elementType":"geometry.fill",
						"stylers":[
							{"color":"#000000"},
							{"lightness":17}
						]
					},
					{
						"featureType":"road.highway",
						"elementType":"geometry.stroke",
						"stylers":[
							{"color":"#000000"},
							{"lightness":29},
							{"weight":0.2}
						]
					},
					{
						"featureType":"road.arterial",
						"elementType":"geometry",
						"stylers":[
							{"color":"#000000"},
							{"lightness":18}
						]
					},
					{
						"featureType":"road.local",
						"elementType":"geometry",
						"stylers":[
							{"color":"#000000"},
							{"lightness":16}
						]
					},
					{
						"featureType":"transit",
						"elementType":"geometry",
						"stylers":[
							{"color":"#000000"},
							{"lightness":19}
						]
					},
					{
						"featureType":"water",
						"elementType":"geometry",
						"stylers":[
							{"color":"#000000"},
							{"lightness":17}
						]
					}
				],
				disableDefaultUI: true
			}
		};

		this.root = root;
		this.options = _.merge(options || {}, defaultOptions, _.defaults);
		this.initialize();
	}

	initialize () {
		this._createMap();
	}

	_createMap () {
		var coordinates = this.root.data('coordinates');

		this.centerMap = new google.maps.LatLng(coordinates[0], coordinates[1]);
		this.map = new google.maps.Map(this.root.get(0), this.options.mapOptions);
		this.map.setCenter(this.centerMap);

		google.maps.event.addListenerOnce(this.map, 'idle', () => {
			this.updatedMapCenter = this._calculateCenter();
			this._addMarker();
			this.map.setCenter(this.updatedMapCenter);
		});
	}

	_calculateCenter () {
		var scale = Math.pow(2, this.map.getZoom());
		var projection = this.map.getProjection();
		var wc = projection.fromLatLngToPoint(this.centerMap);
		var shiftByWidth = 0;
		var leftShift = 0;

		if ($$.windowWidth > 980) {
			shiftByWidth = - ($$.windowWidth - this.options.contactsInfoWidth) / 2;
			leftShift = $$.windowWidth / 2 - this.options.contactsInfoWidth;
		}

		var shiftByHeight = 0;
		var offsetWidth = ((wc.x * scale) + shiftByWidth + leftShift) / scale;
		var offsetHeight = ((wc.y * scale) + shiftByHeight) / scale;
		var sw = new google.maps.Point(offsetWidth, offsetHeight);

		return projection.fromPointToLatLng(sw);
	}

	_addMarker () {
		setTimeout(() => {
				new google.maps.Marker({
					position: this.centerMap,
					map: this.map,
					animation: google.maps.Animation.DROP,
					icon: '/site/assets/images/map-pin.png'
				});
			},
			500
		);
	}
}
