(function($$) {
	function getParentRoutes(url) {
		var urls = [];

		while(true) {
			url = url.replace(/(^\/.*?)\/[^\/]+\/?$/, '$1');
			if (url === urls[urls.length - 1]) { break; }
			urls.push(url);
		}

		urls.push('/');
		return urls;
	}

	function isDataObjectHasBackgroundWeNeed(bgData) {
		return bgData && bgData[$$.config.isMobile ? 'mobileBgUrl' : 'bgUrl'];
	}

	$$.BackgroundEffects = class BackgroundEffects {
		constructor(imagesData, fragmentBackgrounds, container = 'body') {
			this._imagesData = imagesData; /* Могут не иметь фона, надо проверять */
			this._fragmentsData = fragmentBackgrounds /* Gh */ ;
			this._container = $(container);

			this._createCanvas(this._container);
		}

		_createCanvas() {
		}

		_getBackgroundData(pageUrl, lookupParentPages = true, lookupChildFragments = true) {
			var imageData = this._imagesData[pageUrl];

			// Если у страницы фона есть валидная первая секция с другой фоновой картинкой - она и будет правильной
			// Но ее так же может не быть и нам тогда надо найти правильный дочерний

			if (imageData) {
				const child = this._fragmentsData[imageData.firstChildFragments[0]];
				const childsChild = this._fragmentsData[imageData.firstChildFragments[0]];

				imageData = isDataObjectHasBackgroundWeNeed(childsChild) ? childsChild
					: isDataObjectHasBackgroundWeNeed(child) ? child : imageData;

				// Если объект нашли, но у него нет нужного фона - лезем по потомкам.
				// После проверки выше у нас остается шанс, что подстраницы дальше первой будут иметь фон. Мы хотим его.

				if (!isDataObjectHasBackgroundWeNeed(imageData) && lookupChildFragments) {
					_.each(imageData.childFragments, (fragmentId) => {
						var fragmentBg = this._fragmentsData[fragmentId];

						if (isDataObjectHasBackgroundWeNeed(fragmentBg)) {
							imageData = fragmentBg;
							return false;
						}
					});
				}
			}

			// Если не нашлось в дочерних или нет данных для страницы - ищем ближайший родительский.
			// Фон должен быть на выходе обязательно, а то всё сломается (визуально).

			if (lookupParentPages && !isDataObjectHasBackgroundWeNeed(imageData)) {
				_.each(getParentRoutes(pageUrl), (parentPage) => {
					imageData = this._imagesData[parentPage];
					return !isDataObjectHasBackgroundWeNeed(imageData);
				});
			}

			return isDataObjectHasBackgroundWeNeed(imageData) ? imageData : null;
		}

		/**
		 * Предполагается к использованию 1 раз, в самом начале. Тупо загружает картинку для текущей страницы и ставит ее на фон.
		 * Промис резолвится после загрузки картинки.
		 *
		 * @returns {*}
		 * @private
		 */
		_updateCurrentBackground() {
		}

		animateBlur() {
		}

		preloadFragmentBackgrounds() {
		}

		revealFragmentBg() {
		}

		/**
		 * Инициирует анимацию перехода от одной страницы к другой
		 *
		 * @param pageFromUrl
		 * @param pageToUrl
		 * @returns {*}
		 */
		startCrossPageTransition() {
			this.show();
		}

		show() {
			this._container.show();
		}

		hide() {
			this._container.hide();
		}
	};
})(window.$$ || (window.$$ = {}));

