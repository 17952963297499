(function ($$) {
	$$.BackgroundEffectsMobile = class BackgroundEffectsMobile extends $$.BackgroundEffects {
		constructor (imagesData, fragmentBackgrounds, container = 'body') {
			super(imagesData, fragmentBackgrounds, container);

			this._imagesData = imagesData;
			/* Могут не иметь фона, надо проверять */
			this._fragmentsData = fragmentBackgrounds /* Gh */;
			this._crossPageDuration = 1300;

			this._createCanvas($(container));

			this._updateCurrentBackground($$.route.currentUrl);
		}

		_createCanvas (container) {
			super._createCanvas();

			if (this._canvas) {
				return;
			}

			this._canvas = $('<div>').css({
				position: 'fixed',
				top: 0,
				left: 0,
				width: '100vw',
				height: '100vh',
				backgroundPosition: 'center',
				backgroundSize: 'cover'
			});

			container.prepend(this._canvas);

			this.overlay = this._canvas.clone();
			this.overlay.insertAfter(this._canvas);
			this.overlay.hide();
		}

		/**
		 * Предполагается к использованию 1 раз, в самом начале. Тупо загружает картинку для текущей страницы и ставит ее на фон.
		 * Промис резолвится после загрузки картинки.
		 *
		 * @returns {*}
		 * @private
		 */
		_updateCurrentBackground () {
			super._updateCurrentBackground();

			const deferred = new $.Deferred();
			const currentUrl = $$.route.currentUrl;
			const bgData = this._getBackgroundData(currentUrl);

			this._canvas.css({
				backgroundImage: `url('${bgData.mobileBgUrl}')`
			});

			deferred.resolve();

			return deferred.promise();
		}

		/**
		 * Инициирует анимацию перехода от одной страницы к другой
		 *
		 * @param pageFromUrl
		 * @param pageToUrl
		 * @returns {*}
		 */
		startCrossPageTransition (pageFromUrl, pageToUrl) {
			super.startCrossPageTransition(pageFromUrl, pageToUrl);

			const deferred = new $.Deferred();
			const pageToBGData = this._getBackgroundData(pageToUrl);

			this.overlay.hide();

			this.overlay.css({
				backgroundImage: `url('${pageToBGData.mobileBgUrl}')`
			});

			this.overlay.fadeIn(this._crossPageDuration, () => {
				this.overlay.hide();

				this._canvas.css({
					backgroundImage: `url('${pageToBGData.mobileBgUrl}')`
				});

				deferred.resolve();
			});

			return deferred.promise();
		}
	};
})(window.$$ || (window.$$ = {}));

