var $$ = $$ || {};

$$.AngstremIM = class AngstremIM {
	constructor (root) {
		this.root = root;

		// Переменные и флаги

		this._hash = 'portfolio-entry-angstremim-' + _.uniqueId();
		this._scrollHeight = 0; // В нашем случае соответствует максимально возможному scrollTop
		this._paused = false;
		this._destroyed = false;

		this._isInitialTick = true;
		_.defer(() => this._isInitialTick = false);

		// Инициализируем

		this._cacheNodes();
		this._bindEvents();
		this._initScrollSpring();

		this._scrollMagicController = new ScrollMagic.Controller({
			container: this.nodes.payload.get(0)
		});

		this.nodes.loader.show();
		this.nodes.payload.css('opacity', 0);

		this._recheckBackground = _.debounce(() => {
			try {
				BackgroundCheck.refresh();
			} catch (e) {
			}
		}, 200);

		this.resize();

		$script.ready('vendor-postponed', () => {
			this._ready()
		});
	}

	_cacheNodes () {
		let payload = this.root.children('.angstremim-payload');

		this.nodes = {
			loader: this.root.children('.js-loading'),
			payload: payload,
			mouseWheelCatcher: $('<div/>').appendTo(this.root),

			section1: payload.children('.section1'),
			s1Pin: payload.find('.section1 .pin-target'),
			s1Mainpage: payload.find('.section1 .mainpage'),
			s1Pluses: payload.find('.section1 .pluses'),
			s1Popup: payload.find('.section1 .popup'),

			section2: payload.children('.section2'),
			s2Popup: payload.find('.section2 .popup'),
			s2Anri: payload.find('.section2 .anri'),
			s2Calculate: payload.find('.section2 .calculate'),

			section3: payload.children('.section3'),
			s3Left: payload.find('.section3 .left-image'),
			s3Right: payload.find('.section3 .right-image'),

			section4: payload.children('.section4'),
			s4Paralax: payload.find('.section4 .paralax'),

			section6: payload.children('.section6'),
			s6Ipad: payload.find('.section6 .ipad'),

			section7: payload.children('.section7')
		};

		this.nodes.mouseWheelCatcher.css({
			position: 'absolute',
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
			display: 'none'
		});
	}

	/**
	 * Вешает обработчики событий на компоненты/элементы.
	 *
	 * @private
	 */
	_bindEvents () {
		this.nodes.mouseWheelCatcher.on('mousewheel', () => false);

		/**
		 * Раскоментировтаь если нужно отключить пружину
		 */
		//this.nodes.payload.on('mousewheel', (event) => {
		//	var isBottom = this.nodes.payload.scrollTop() + $$.windowHeight === this.nodes.payload.get(0).scrollHeight;
		//	var isTop = this.nodes.payload.scrollTop() === 0;
		//	var toTop = event.deltaY == 1;
		//
		//	if (!(isTop && toTop || isBottom && !toTop)) {
		//		event.stopPropagation();
		//	}
		//});

		this.nodes.payload.swipeUp((event) => {
			var isBottom = this.nodes.payload.scrollTop() + $$.windowHeight === this.nodes.payload.get(0).scrollHeight;

			if (!isBottom) {
				event.stopPropagation();
			}
		});

		this.nodes.payload.swipeDown((event) => {
			var isTop = this.nodes.payload.scrollTop() === 0;

			if (!isTop) {
				event.stopPropagation();
			}
		});

		this.nodes.payload.on('scroll', () => {
			this._recheckBackground();
		});

		let isDetached = () => {
			if (!jQuery.contains(document.documentElement, this.root.get(0))) {
				this.destroy();
				return true;
			}

			return false;
		};

		$$.window.on('resize.' + this._hash, () => {
			if (isDetached()) {
				return
			}

			this.resize();
		});
	}

	_initScrollSpring () {
		this._springScroller = new $$.SmoothScroller(this.nodes.payload, {
			velocityLimit: 0.35
		});

		this._springScroller.pause();

		this._springScroller.onScroll = () => this._updateScene();
	}

	_bindAnimationSequence () {
		if (this._destroyed || $$.isMobile) {
			return;
		}

		let timeline;

		timeline = new TimelineMax()
			.from(this.nodes.s1Pluses, 1, { opacity: 0, ease: Linear.easeNone }, 1.5)
			.from(this.nodes.s1Popup, 1, { opacity: 0, ease: Linear.easeNone }, 2);

		new ScrollMagic.Scene({
			triggerElement: this.nodes.section1.get(0),
			triggerHook: 0
		}).setTween(timeline)
			.addTo(this._scrollMagicController);

		timeline = new TimelineMax()
			.from(this.nodes.s2Popup, 1, { opacity: 0, transform: 'translateX(-825px)', ease: Linear.easeNone })
			.from(this.nodes.s2Anri, 1, { opacity: 0, transform: 'translateX(860px)', ease: Linear.easeNone }, 0.5)
			.from(this.nodes.s2Calculate, 1, { opacity: 0, transform: 'translateY(300px)', ease: Linear.easeNone }, 1);

		new ScrollMagic.Scene({
			duration: () => this.nodes.section2.outerHeight() / 2,
			triggerElement: this.nodes.section2.get(0),
			triggerHook: 0
		}).setTween(timeline)
			.addTo(this._scrollMagicController);

		timeline = new TimelineMax()
			.from(this.nodes.s3Left, 1, { left: -470, ease: Linear.easeNone }, 0)
			.from(this.nodes.s3Right, 1, { transform: 'translateX(580px)', ease: Linear.easeNone }, 0);

		new ScrollMagic.Scene({
			duration: () => this.nodes.section3.outerHeight(),
			offset: -800,
			triggerElement: this.nodes.section3.get(0),
			triggerHook: 1
		}).setTween(timeline)
			.addTo(this._scrollMagicController);

		timeline = new TimelineMax()
			.from(this.nodes.s4Paralax, 1, { backgroundPositionY: 0, ease: Linear.easeNone }, 0);

		new ScrollMagic.Scene({
			duration: () => this.nodes.section4.outerHeight(),
			triggerElement: this.nodes.section4.get(0),
			triggerHook: 0.5
		}).setTween(timeline)
			.addTo(this._scrollMagicController);

		timeline = new TimelineMax()
			.from(this.nodes.s6Ipad, 1, {
				opacity: 0,
				transform: 'translateX(500px) rotate(30deg)',
				ease: Linear.easeNone
			}, 0);

		new ScrollMagic.Scene({
			duration: () => this.nodes.section6.outerHeight() * 0.7,
			triggerElement: this.nodes.section6.get(0),
			triggerHook: 0.7
		}).setTween(timeline)
			.addTo(this._scrollMagicController);
	}

	_ready () {
		this._springScroller.resume();

		this.nodes.payload
			.css({
				zIndex: '0',
				overflow: 'auto'
			});

		if (this._isInitialTick) {
			this.nodes.loader.hide();
			this.nodes.payload.css('opacity', 1);
		} else {
			this.nodes.loader.velocity('fadeOut');
			this.nodes.payload.velocity('fadeIn');
		}

		try {
			BackgroundCheck.init({
				targets: '.p-header .logo, .b-breadcrumbs, .button-switch-state-right-wrapper, .category-controls',
				images: '.b-angstremim img, .section7'
			});

			_.defer(function () {
				BackgroundCheck.refresh();
			})
		} catch (e) {
		}

		this._bindAnimationSequence();
	}

	_updateScene () {
		if (!this._scrollMagicController) {
			return;
		}

		this._scrollMagicController.update(true);
		this._recheckBackground();
	}

	destroy () {
		if (this._destroyed) {
			return;
		}

		this._destroyed = true;
		this._scrollMagicController.destroy();
		this.root.off();
		this.nodes.payload.off();

		$$.window.off('resize.' + this._hash);

		this._scrollMagicController = _.noop();
		this._recheckBackground = _.noop;

		this._springScroller.destroy();
		this._springScroller = _.noop();
	}

	pause () {
		this._paused = true;
		this._springScroller.pause();
	}

	/**
	 * Эта функция должна вызываться извне и уведомлять нас, что позиция слайда на экране изменилась
	 *
	 * @param animationEnded Закончилась ли анимация. На самом деле вызывается только, когда мы и есть слайд к которому осуществлялся переход
	 */
	repositioned (animationEnded = false) {
		if (this._destroyed) {
			return;
		}

		if (animationEnded) {
			this.nodes.mouseWheelCatcher.hide();
			if (!this._paused) {
				$$.window.trigger('resize');
			}

			_.defer(function () {
				$$.body.addClass('black-controls');
			});
		} else {
			this.nodes.mouseWheelCatcher.show();
			$$.body.removeClass('black-controls');
			this._updateScene();
		}
	}

	resize () {
		if (this._destroyed) {
			return;
		}

		this._springScroller.recalculate();

		this._updateScene();
	}

	resume (comingFromTop) {
		this._paused = false;

		if (this._destroyed) {
			return;
		}

		this._springScroller.resume();

		this._scrollMagicController.scrollTo(comingFromTop ? 0 : this.nodes.payload.prop('scrollHeight'));
	}
};
