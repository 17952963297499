var $$ = $$ || {};

$$.GoogleAnalytics = class GoogleAnalytics {
	constructor () {
		if (!window.ga) {
			console.log('ga error');
		}
	}

	reachGoal (goal) {
		if (window.ga) {
			ga('send', 'event', 'click', goal); // "t0.send" если есть тег менеджер
		}
	}

	reachPage (page) {
		if (window.ga) {
			ga('send', 'pageview', page); // "t0.send" если есть тег менеджер
		}
	}

	reachVirtualPage(goal) {
		this.reachPage ('/virtual/' + goal);
	}
};
