var $$ = $$ || {};

$$.YandexMetrika = class YandexMetrika {
	constructor (counter) {
		if (!counter) {
			//console.log('ya error');
		} else {
			this.counter = counter;
		}
	}

	reachGoal (goal) {
		if (this.counter) {
			this.counter.reachGoal(goal);
		}
	}
};

