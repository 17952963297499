const $$ = window.$$ || (window.$$ = {});

$$.Agrarus = class Agrarus extends $$.BaseProjectPageClass {
	constructor (root) {
		super(root, 'portfolio-entry-agrarus-');

		this._useSmallScrollStep = false;
	}

	_cacheNodes () {
		super._cacheNodes();

		const payload = this.nodes.payload;

		$.extend(this.nodes, {
			pageControls: $('.p-header, .js-button-switch-state-right-wrapper, .js-slash-menu'),
			loader: this.root.children('.js-loading'),
			mouseWheelCatcher: this.root.find('.g-mouse-wheel-catcher'),
			sections: this.root.find('section'),

			section1: payload.children('.section1'),

			section2: payload.children('.section2'),
			s2LaptopAndPcWrapper: payload.find('.section2 .laptop-and-pc-wrapper'),

			section3: payload.children('.section3'),
			s3Image1: payload.find('.section3 .image-1'),
			s3Image2: payload.find('.section3 .image-2'),
			s3Image3: payload.find('.section3 .image-3'),
			s3Image4: payload.find('.section3 .image-4'),
			s3Image5: payload.find('.section3 .image-5'),
			s3Image6: payload.find('.section3 .image-6'),
			s3Image7: payload.find('.section3 .image-7'),
			s3Image8: payload.find('.section3 .image-8'),
			s3MilkText: payload.find('.section3 .milk-text'),

			section4: payload.children('.section4'),
			s4TabletWrapper: payload.find('.section4 .tablet-wrapper'),
			s4Tablet: payload.find('.section4 .tablet'),
			s4TabletPopup: payload.find('.section4 .tablet-popup'),
			s4ReturnFocusCacher: payload.find('.section4 .return-focus-cacher'),

			section5: payload.children('.section5'),
			s5Image1: payload.find('.section5 .image-1'),
			s5Image2: payload.find('.section5 .image-2'),
			s5Image3: payload.find('.section5 .image-3'),
			s5Image4: payload.find('.section5 .image-4'),
			s5Image5: payload.find('.section5 .image-5'),

			section6: payload.children('.section6'),
			s6Image6: payload.find('.section6 .image-6'),
			s6Image7: payload.find('.section6 .image-7'),

			section7: payload.children('.section7'),
			s7Image8: payload.find('.section7 .image-8'),
			s7Image9: payload.find('.section7 .image-9'),

			section8: payload.children('.section8'),
			s8PinTarget: payload.find('.section8 .pin-target'),
			s8Image1Overlap: payload.find('.section8 .image-wrapper-1 .overlap'),
			s8Image4OverlapTop: payload.find('.section8 .image-wrapper-4 .overlap-top'),
			s8Image4OverlapRight: payload.find('.section8 .image-wrapper-4 .overlap-right'),
			s8Image4OverlapLeft: payload.find('.section8 .image-wrapper-4 .overlap-left'),
			s8Image5Overlap: payload.find('.section8 .image-wrapper-5 .overlap')
		});
	}

	_ready () {
		super._ready();

		this.nodes.pageControls.addClass('dark');
	}

	_bindEvents () {
		super._bindEvents();

		/*this.nodes.payload.on('mousewheel', () => {
			if ( !this.disableScroll ) {
				this.disableScroll = true;
				this.nodes.mouseWheelCatcher.show();

				setTimeout(() => {
					this.nodes.mouseWheelCatcher.hide();
					this.disableScroll = false;
				}, 500);
			}
		});*/
	}

	_bindAnimationSequence () {
		let timeline;

		this._scrollMagicController = new ScrollMagic.Controller({
			container: this.nodes.payload.get(0),
			globalSceneOptions: {
				tweenChanges: true,
				triggerHook: 0
			}
		});

		// Section 1 ***************************************************************************************************
		new ScrollMagic.Scene({
			triggerElement: this.nodes.section1.get(0),
			offset: 10
		})
			.addTo(this._scrollMagicController);

		// Section 2 ***************************************************************************************************
		timeline = new TimelineMax()
			.from(this.nodes.s2LaptopAndPcWrapper, 1, {
				transform: "matrix(1,0,0,1,0," + $$.windowHeight + ")",
				ease: Sine.easeOut
			}, 0);

		new ScrollMagic.Scene({
			triggerElement: this.nodes.section2.get(0),
			offset: -($$.windowHeight * 0.1)
		})
			.addTo(this._scrollMagicController)
			.setTween(timeline);

		new ScrollMagic.Scene({
			triggerElement: this.nodes.section2.get(0),
			offset: 10
		})
			.addTo(this._scrollMagicController);

		// section 3 ***************************************************************************************************
		timeline = new TimelineMax()
			.from(this.nodes.s3Image1, 1, { transform: "matrix(1,0,0,1,0," + $$.windowHeight + ")", ease: Sine.easeOut }, 0)
			.from(this.nodes.s3Image2, 1, { transform: "matrix(1,0,0,1,0," + $$.windowHeight + ")", ease: Sine.easeOut }, .1)
			.from(this.nodes.s3Image3, 1, { transform: "matrix(1,0,0,1,0," + $$.windowHeight + ")", ease: Sine.easeOut }, .2)
			.from(this.nodes.s3Image4, 1, { transform: "matrix(1,0,0,1,0," + $$.windowHeight + ")", ease: Sine.easeOut }, .3)
			.from(this.nodes.s3Image5, 1, { transform: "matrix(1,0,0,1,0," + $$.windowHeight + ")", ease: Sine.easeOut }, .4)
			.from(this.nodes.s3Image6, 1, { transform: "matrix(1,0,0,1,0," + $$.windowHeight + ")", ease: Sine.easeOut }, .5)
			.from(this.nodes.s3Image7, 1, { transform: "matrix(1,0,0,1,0," + $$.windowHeight + ")", ease: Sine.easeOut }, .6)
			.from(this.nodes.s3Image8, 1, { transform: "matrix(1,0,0,1,0," + $$.windowHeight + ")", ease: Sine.easeOut }, .7)
			.from(this.nodes.s3MilkText, 1, { opacity: 0, ease: Sine.easeOut }, 1);

		new ScrollMagic.Scene({
			triggerElement: this.nodes.section3.get(0),
			offset: -($$.windowHeight * 0.1)
		})
			.addTo(this._scrollMagicController)
			.setTween(timeline);

		new ScrollMagic.Scene({
			triggerElement: this.nodes.section3.get(0),
			offset: 10
		})
			.addTo(this._scrollMagicController);

		// section 4 ***************************************************************************************************
		timeline = new TimelineMax()
			.from(this.nodes.s4TabletWrapper, 1, { transform: "matrix(1,0,0,1,0," + ($$.windowHeight + 1) + ")", ease: Sine.easeOut }, 0)
			.to(this.nodes.s4TabletPopup, .01, { zIndex: '3', ease: Sine.easeOut }, 1.25);

		new ScrollMagic.Scene({
			triggerElement: this.nodes.section4.get(0),
			offset: -($$.windowHeight * 0.1)
		})
			.addTo(this._scrollMagicController)
			.setTween(timeline);

		new ScrollMagic.Scene({
			triggerElement: this.nodes.s4ReturnFocusCacher.get(0)
		})
			.on("leave", () => {
				this._useSmallScrollStep = false;
				this.resize();
			})
			.addTo(this._scrollMagicController);

		new ScrollMagic.Scene({
			triggerElement: this.nodes.section4.get(0),
			offset: 10
		})
			.addTo(this._scrollMagicController);

		// section 5 ***************************************************************************************************
		timeline = new TimelineMax()
			.from(this.nodes.s5Image1, 1, { opacity: 0, transform: "scale(0)", ease: Sine.easeOut })
			.from(this.nodes.s5Image2, 1, { opacity: 0, transform: "scale(0)", ease: Sine.easeOut })
			.from(this.nodes.s5Image3, 1, { opacity: 0, transform: "scale(0)", ease: Sine.easeOut })
			.from(this.nodes.s5Image4, 1, { opacity: 0, transform: "scale(0)", ease: Sine.easeOut })
			.from(this.nodes.s5Image5, 1, { opacity: 0, transform: "scale(0)", ease: Sine.easeOut });

		new ScrollMagic.Scene({
			triggerElement: this.nodes.section5.get(0),
			duration: $$.windowHeight,
			offset: -($$.windowHeight * 0.25)
		})
			.addTo(this._scrollMagicController)
			.setTween(timeline);

		new ScrollMagic.Scene({
			triggerElement: this.nodes.section5.get(0),
			offset: -1
		})
			.on("enter", () => {
				this._useSmallScrollStep = true;
				this.resize();
				setTimeout(() => {
					this.nodes.mouseWheelCatcher.hide();
					this.disableScroll = true;
				}, 50);
			})
			.on("leave", () => {
				this._useSmallScrollStep = false;
				this.resize();
				this.disableScroll = false;
			})
			.addTo(this._scrollMagicController);

		// section 6 ***************************************************************************************************
		timeline = new TimelineMax()
			.from(this.nodes.s6Image6, 1, { opacity: 0, transform: "scale(0)", ease: Sine.easeOut })
			.from(this.nodes.s6Image7, 1, { opacity: 0, transform: "scale(0)", ease: Sine.easeOut });

		new ScrollMagic.Scene({
			triggerElement: this.nodes.section6.get(0),
			duration: $$.windowHeight,
			offset: -($$.windowHeight * 0.25)
		})
			.addTo(this._scrollMagicController)
			.setTween(timeline);

		// section 7 ***************************************************************************************************
		timeline = new TimelineMax()
			.from(this.nodes.s7Image8, 1, { opacity: 0, transform: "scale(0)", ease: Sine.easeOut })
			.from(this.nodes.s7Image9, 1, { opacity: 0, transform: "scale(0)", ease: Sine.easeOut });

		new ScrollMagic.Scene({
			triggerElement: this.nodes.section7.get(0),
			duration: $$.windowHeight * 0.5,
			offset: -($$.windowHeight * 0.5)
		})
			.addTo(this._scrollMagicController)
			.setTween(timeline);

		new ScrollMagic.Scene({
			triggerElement: this.nodes.section7.get(0)
		})
			.on("enter", () => {
				this._useSmallScrollStep = false;
				this.resize();
			})
			.on("leave", () => {
				this._useSmallScrollStep = true;
				this.resize();
			})
			.addTo(this._scrollMagicController);

		// section 8 ***************************************************************************************************
		timeline = new TimelineMax()
			.from(this.nodes.s8Image1Overlap, 1, { transform: "matrix(1,0,0,1,115,0)", ease: Sine.easeOut }, 0)
			.from(this.nodes.s8Image4OverlapTop, 1, { transform: "matrix(1,0,0,1,0,-115)", ease: Sine.easeOut }, 0)
			.from(this.nodes.s8Image4OverlapRight, 1, { transform: "matrix(1,0,.47,1,115,0)", ease: Sine.easeOut }, 0)
			.from(this.nodes.s8Image4OverlapLeft, 1, { transform: "matrix(1,0,-0.47,1,-115,0)", ease: Sine.easeOut }, 0)
			.from(this.nodes.s8Image5Overlap, 1, { transform: "matrix(1,0,0,1,0,-115)", ease: Sine.easeOut }, 0);

		new ScrollMagic.Scene({
			triggerElement: this.nodes.section8.get(0),
			offset: -10
		})
			.setTween(timeline)
			.addTo(this._scrollMagicController);
	}

	_initScrollSpring () {
		this._springScroller = new $$.SmoothScroller(this.nodes.payload, {
			velocityLimit: 0.35,
			damping: 1,
			rigidness: 20
		}, {
			preventMovingTargetTooFar: true,
			stepSize: $$.windowHeight
		});

		this._springScroller.pause();

		this._springScroller.onScroll = () => this._updateScene();
	}

	pause () {
		super.pause();

		this.nodes.pageControls.removeClass('dark');
	}

	resume (comingFromTop) {
		super.resume();

		this.nodes.pageControls.addClass('dark');

		if ( comingFromTop === "underfined" ) {
			comingFromTop = true;
		}
		
		this._scrollMagicController.scrollTo(comingFromTop ? 0 : this.nodes.payload.prop('scrollHeight'));
	}

	resize() {
		super.resize();

		this._springScroller.step(this._useSmallScrollStep ? ($$.windowHeight / 3) : $$.windowHeight);
	}
};
